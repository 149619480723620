import React from 'react';
import LineGraph from '../graph-templates/LineGraph';
import {
  lightModeState,
  accountState,
  accountsState,
  accounts2MonthsAgoState,
  accounts3MonthsAgoState,
  accounts30DaysAgoState,
  userState,
  googleReportsSelectedState,
  microsoftReportsSelectedState,
  lsaReportsSelectedState,
  adDataSelectedState,
} from '../../recoil/atoms';
import { adminAccountState } from '../../recoil/selectors/dataSelectors';
import { useRecoilValue } from 'recoil';
import { Account } from '../../helpers/types';

type Props = {
  isPrint?: boolean;
};
const LeadsLineGraph = ({ isPrint }: Props) => {
  const lightMode = useRecoilValue(lightModeState);
  const user = useRecoilValue(userState);
  const superadminAccount = useRecoilValue(accountState);
  const adminAccount = useRecoilValue(adminAccountState);
  const googleSelected = useRecoilValue(googleReportsSelectedState);
  const microsoftSelected = useRecoilValue(microsoftReportsSelectedState);
  const adSelected = useRecoilValue(adDataSelectedState);
  const lsaSelected = useRecoilValue(lsaReportsSelectedState);
  const account =
    user?.role === 'superadmin' ? superadminAccount : adminAccount;

  const filterAccounts = (accs: Account[]) => {
    return accs?.filter((acc) => acc.name === account?.name);
  };

  const accounts = useRecoilValue(accountsState);
  const accounts2MonthsAgo = useRecoilValue(accounts2MonthsAgoState);
  const accounts3MonthsAgo = useRecoilValue(accounts3MonthsAgoState);
  const accounts30DaysAgo = useRecoilValue(accounts30DaysAgoState);

  const accountLastMonth = filterAccounts(accounts)[0];
  const account2MonthsAgo = filterAccounts(accounts2MonthsAgo)[0];
  const account3MonthsAgo = filterAccounts(accounts3MonthsAgo)[0];
  const account30DaysAgo = filterAccounts(accounts30DaysAgo)[0];

  const leads3MonthsAgo = account3MonthsAgo
    ? (account3MonthsAgo.google_account && googleSelected
        ? parseFloat(account3MonthsAgo.google_account.conversions)
        : 0) +
      (account3MonthsAgo.microsoft_account && microsoftSelected
        ? parseFloat(account3MonthsAgo.microsoft_account.conversions)
        : 0) +
      (account3MonthsAgo.lsa_account && lsaSelected
        ? parseFloat(account3MonthsAgo.lsa_account.conversions)
        : 0) +
      (adSelected && account3MonthsAgo.additional_data?.conversions
        ? account3MonthsAgo.additional_data?.conv_operation === '+'
          ? parseFloat(account3MonthsAgo.additional_data?.conversions)
          : -parseFloat(account3MonthsAgo.additional_data?.conversions)
        : 0)
    : 0;

  const leads2MonthsAgo = account2MonthsAgo
    ? (account2MonthsAgo.google_account && googleSelected
        ? parseFloat(account2MonthsAgo.google_account.conversions)
        : 0) +
      (account2MonthsAgo.microsoft_account && microsoftSelected
        ? parseFloat(account2MonthsAgo.microsoft_account.conversions)
        : 0) +
      (account2MonthsAgo.lsa_account && lsaSelected
        ? parseFloat(account2MonthsAgo.lsa_account.conversions)
        : 0) +
      (adSelected && account2MonthsAgo.additional_data?.conversions
        ? account2MonthsAgo.additional_data?.conv_operation === '+'
          ? parseFloat(account2MonthsAgo.additional_data?.conversions)
          : -parseFloat(account2MonthsAgo.additional_data?.conversions)
        : 0)
    : 0;

  const leadsLastMonth = accountLastMonth
    ? (accountLastMonth.google_account && googleSelected
        ? parseFloat(accountLastMonth.google_account.conversions)
        : 0) +
      (accountLastMonth.microsoft_account && microsoftSelected
        ? parseFloat(accountLastMonth.microsoft_account.conversions)
        : 0) +
      (accountLastMonth.lsa_account && lsaSelected
        ? parseFloat(accountLastMonth.lsa_account.conversions)
        : 0) +
      (adSelected && accountLastMonth.additional_data?.conversions
        ? accountLastMonth.additional_data?.conv_operation === '+'
          ? parseFloat(accountLastMonth.additional_data?.conversions)
          : -parseFloat(accountLastMonth.additional_data?.conversions)
        : 0)
    : 0;

  const leads30DaysAgo = account30DaysAgo
    ? (account30DaysAgo.google_account && googleSelected
        ? parseFloat(account30DaysAgo.google_account.conversions)
        : 0) +
      (account30DaysAgo.microsoft_account && microsoftSelected
        ? parseFloat(account30DaysAgo.microsoft_account.conversions)
        : 0) +
      (account30DaysAgo.lsa_account && lsaSelected
        ? parseFloat(account30DaysAgo.lsa_account.conversions)
        : 0)
    : 0;

  return (
    <LineGraph
      title='Leads Per Coverage'
      data1={leads3MonthsAgo}
      data2={leads2MonthsAgo}
      data3={leadsLastMonth}
      data4={leads30DaysAgo}
      backgroundColor={
        lightMode ? 'rgba(225, 204, 230, .3)' : 'rgba(51, 0, 0, .1)'
      }
      borderColor='rgb(205, 130, 158)'
      pointBorderColor='rgb(205, 130, 158)'
      isPrint={isPrint}
    />
  );
};

export default LeadsLineGraph;
