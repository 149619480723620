import React from 'react';
import ReportsBoxTemplate from '../info-boxes-templates/ReportsBoxTemplate';
import {
  accountState,
  userState,
  accountsState,
  accounts2MonthsAgoState,
  accounts3MonthsAgoState,
  accounts30DaysAgoState,
  googleReportsSelectedState,
  microsoftReportsSelectedState,
} from '../../recoil/atoms';
import { adminAccountState } from '../../recoil/selectors/dataSelectors';
import { useRecoilValue } from 'recoil';
import { Account } from '../../helpers/types';
import LineGraph from '../graph-templates/LineGraph';

const Remarketing = () => {
  const googleSelected = useRecoilValue(googleReportsSelectedState);
  const microsoftSelected = useRecoilValue(microsoftReportsSelectedState);
  const user = useRecoilValue(userState);
  const account = useRecoilValue(
    user?.role === 'superadmin' ? accountState : adminAccountState
  );
  const accountsLastMonth = useRecoilValue(accountsState);
  const accounts2MonthsAgo = useRecoilValue(accounts2MonthsAgoState);
  const accounts3MonthsAgo = useRecoilValue(accounts3MonthsAgoState);
  const accounts30DaysAgo = useRecoilValue(accounts30DaysAgoState);

  const filterAccount = (accs: Account[]) =>
    accs.filter((acc) => acc.name === account.name)[0];

  const accountLastMonth = filterAccount(accountsLastMonth);
  const account2MonthsAgo = filterAccount(accounts2MonthsAgo);
  const account3MonthsAgo = filterAccount(accounts3MonthsAgo);
  const account30DaysAgo = filterAccount(accounts30DaysAgo);

  const accountCPL = account
    ? account.remarketing_campaign &&
      account.bing_remarketing_campaign &&
      googleSelected &&
      microsoftSelected
      ? (parseFloat(account.remarketing_campaign.spend) +
          parseFloat(account.bing_remarketing_campaign.spend)) /
        (parseFloat(account.remarketing_campaign.conversions) +
          parseFloat(account.bing_remarketing_campaign.conversions) >
        0
          ? parseFloat(account.remarketing_campaign.conversions) +
            parseFloat(account.bing_remarketing_campaign.conversions)
          : 1)
      : account.remarketing_campaign && googleSelected
      ? parseFloat(account.remarketing_campaign.cost_per_conversion)
      : account.bing_remarketing_campaign && microsoftSelected
      ? parseFloat(account.bing_remarketing_campaign.cost_per_conversion)
      : 0
    : 0;
  const accountSpend = account
    ? account.remarketing_campaign &&
      account.bing_remarketing_campaign &&
      googleSelected &&
      microsoftSelected
      ? parseFloat(account.remarketing_campaign.spend) +
        parseFloat(account.bing_remarketing_campaign.spend)
      : account.remarketing_campaign && googleSelected
      ? parseFloat(account.remarketing_campaign.spend)
      : account.bing_remarketing_campaign && microsoftSelected
      ? parseFloat(account.bing_remarketing_campaign.spend)
      : 0
    : 0;
  const accountConversions = account
    ? account.remarketing_campaign &&
      account.bing_remarketing_campaign &&
      googleSelected &&
      microsoftSelected
      ? parseFloat(account.remarketing_campaign.conversions) +
        parseFloat(account.bing_remarketing_campaign.conversions)
      : account.remarketing_campaign && googleSelected
      ? parseFloat(account.remarketing_campaign.conversions)
      : account.bing_remarketing_campaign && microsoftSelected
      ? parseFloat(account.bing_remarketing_campaign.conversions)
      : 0
    : 0;

  const accountLastMonthSpend = accountLastMonth
    ? accountLastMonth.remarketing_campaign &&
      accountLastMonth.bing_remarketing_campaign &&
      googleSelected &&
      microsoftSelected
      ? parseFloat(accountLastMonth.remarketing_campaign.spend) +
        parseFloat(accountLastMonth.bing_remarketing_campaign.spend)
      : accountLastMonth.remarketing_campaign && googleSelected
      ? parseFloat(accountLastMonth.remarketing_campaign.spend)
      : accountLastMonth.bing_remarketing_campaign && microsoftSelected
      ? parseFloat(accountLastMonth.bing_remarketing_campaign.spend)
      : 0
    : 0;
  const accountLastMonthConversions = accountLastMonth
    ? accountLastMonth.remarketing_campaign &&
      accountLastMonth.bing_remarketing_campaign &&
      googleSelected &&
      microsoftSelected
      ? parseFloat(accountLastMonth.remarketing_campaign.conversions) +
        parseFloat(accountLastMonth.bing_remarketing_campaign.conversions)
      : accountLastMonth.remarketing_campaign && googleSelected
      ? parseFloat(accountLastMonth.remarketing_campaign.conversions)
      : accountLastMonth.bing_remarketing_campaign && microsoftSelected
      ? parseFloat(accountLastMonth.bing_remarketing_campaign.conversions)
      : 0
    : 0;
  const account2MonthsAgoSpend = account2MonthsAgo
    ? account2MonthsAgo.remarketing_campaign &&
      account2MonthsAgo.bing_remarketing_campaign &&
      googleSelected &&
      microsoftSelected
      ? parseFloat(account2MonthsAgo.remarketing_campaign.spend) +
        parseFloat(account2MonthsAgo.bing_remarketing_campaign.spend)
      : account2MonthsAgo.remarketing_campaign && googleSelected
      ? parseFloat(account2MonthsAgo.remarketing_campaign.spend)
      : account2MonthsAgo.bing_remarketing_campaign && microsoftSelected
      ? parseFloat(account2MonthsAgo.bing_remarketing_campaign.spend)
      : 0
    : 0;
  const account2MonthsAgoConversions = account2MonthsAgo
    ? account2MonthsAgo.remarketing_campaign &&
      account2MonthsAgo.bing_remarketing_campaign &&
      googleSelected &&
      microsoftSelected
      ? parseFloat(account2MonthsAgo.remarketing_campaign.conversions) +
        parseFloat(account2MonthsAgo.bing_remarketing_campaign.conversions)
      : account2MonthsAgo.remarketing_campaign && googleSelected
      ? parseFloat(account2MonthsAgo.remarketing_campaign.conversions)
      : account2MonthsAgo.bing_remarketing_campaign && microsoftSelected
      ? parseFloat(account2MonthsAgo.bing_remarketing_campaign.conversions)
      : 0
    : 0;
  const account3MonthsAgoSpend = account3MonthsAgo
    ? account3MonthsAgo.remarketing_campaign &&
      account3MonthsAgo.bing_remarketing_campaign &&
      googleSelected &&
      microsoftSelected
      ? parseFloat(account3MonthsAgo.remarketing_campaign.spend) +
        parseFloat(account3MonthsAgo.bing_remarketing_campaign.spend)
      : account3MonthsAgo.remarketing_campaign && googleSelected
      ? parseFloat(account3MonthsAgo.remarketing_campaign.spend)
      : account3MonthsAgo.bing_remarketing_campaign && microsoftSelected
      ? parseFloat(account3MonthsAgo.bing_remarketing_campaign.spend)
      : 0
    : 0;
  const account3MonthsAgoConversions = account3MonthsAgo
    ? account3MonthsAgo.remarketing_campaign &&
      account3MonthsAgo.bing_remarketing_campaign &&
      googleSelected &&
      microsoftSelected
      ? parseFloat(account3MonthsAgo.remarketing_campaign.conversions) +
        parseFloat(account3MonthsAgo.bing_remarketing_campaign.conversions)
      : account3MonthsAgo.remarketing_campaign && googleSelected
      ? parseFloat(account3MonthsAgo.remarketing_campaign.conversions)
      : account3MonthsAgo.bing_remarketing_campaign && microsoftSelected
      ? parseFloat(account3MonthsAgo.bing_remarketing_campaign.conversions)
      : 0
    : 0;
  const account30DaysAgoSpend = account30DaysAgo
    ? account30DaysAgo.remarketing_campaign &&
      account30DaysAgo.bing_remarketing_campaign &&
      googleSelected &&
      microsoftSelected
      ? parseFloat(account30DaysAgo.remarketing_campaign.spend) +
        parseFloat(account30DaysAgo.bing_remarketing_campaign.spend)
      : account30DaysAgo.remarketing_campaign && googleSelected
      ? parseFloat(account30DaysAgo.remarketing_campaign.spend)
      : account30DaysAgo.bing_remarketing_campaign && microsoftSelected
      ? parseFloat(account30DaysAgo.bing_remarketing_campaign.spend)
      : 0
    : 0;
  const account30DaysAgoConversions = account30DaysAgo
    ? account30DaysAgo.remarketing_campaign &&
      account30DaysAgo.bing_remarketing_campaign &&
      googleSelected &&
      microsoftSelected
      ? parseFloat(account30DaysAgo.remarketing_campaign.conversions) +
        parseFloat(account30DaysAgo.bing_remarketing_campaign.conversions)
      : account30DaysAgo.remarketing_campaign && googleSelected
      ? parseFloat(account30DaysAgo.remarketing_campaign.conversions)
      : account30DaysAgo.bing_remarketing_campaign && microsoftSelected
      ? parseFloat(account30DaysAgo.bing_remarketing_campaign.conversions)
      : 0
    : 0;

  return (
    <div>
      <div className='sm:flex sm:gap-x-3 items-center'>
        <ReportsBoxTemplate
          label='Cost Per Lead'
          data={'$' + accountCPL.toFixed(2)}
        />
        <ReportsBoxTemplate
          label='Costs'
          data={'$' + accountSpend.toFixed(2)}
        />
        <ReportsBoxTemplate
          label='Conversions'
          data={accountConversions.toFixed(2)}
        />
      </div>
      <div>
        <LineGraph
          title='Remarketing Leads Graph'
          data1={accountLastMonthConversions}
          data2={account2MonthsAgoConversions}
          data3={account3MonthsAgoConversions}
          data4={account30DaysAgoConversions}
          backgroundColor={'rgba(235, 122, 150, 0.3)'}
          borderColor={'rgb(252, 53, 102)'}
          pointBorderColor={'rgb(252, 53, 102)'}
        />
        <LineGraph
          title='Remarketing Budget Graph'
          data1={accountLastMonthSpend}
          data2={account2MonthsAgoSpend}
          data3={account3MonthsAgoSpend}
          data4={account30DaysAgoSpend}
          backgroundColor={'rgba(99, 96, 224, 0.3)'}
          borderColor={'rgb(24, 20, 245)'}
          pointBorderColor={'rgb(24, 20, 245)'}
        />
      </div>
    </div>
  );
};

export default Remarketing;
