import React, { useState } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { Commaed } from '../../helpers/utils';
import {
  lightModeState,
  pageState,
  isDataFormOpenState,
  dataFormDataState,
  dataCoverageState,
  platformOptionState,
  activeColumnState,
  thisMoActiveColumnsState,
  thisMonthAccountsState,
} from '../../recoil/atoms';
import { HiOutlinePencilAlt } from 'react-icons/hi';
import { GoPrimitiveDot } from 'react-icons/go';
import { BsPauseFill } from 'react-icons/bs';
import Popover from '../info-boxes/Popover';
import { Account } from '../../helpers/types';
import EachDataPlatfrom from '../info-boxes-templates/EachDataPlatform';
import { RiSplitCellsHorizontal, RiMergeCellsHorizontal } from 'react-icons/ri';

type Props = {
  item: Account;
};

const DataPageTableData = ({ item }: Props) => {
  const page = useRecoilValue(pageState);
  const lightMode = useRecoilValue(lightModeState);
  const platformOptions = useRecoilValue(platformOptionState);
  const setIsDataFormOpen = useSetRecoilState(isDataFormOpenState);
  const setDataFormData = useSetRecoilState(dataFormDataState);
  const dataCoverage = useRecoilValue(dataCoverageState);
  const accountThisMonth = useRecoilValue(thisMonthAccountsState).filter(
    (acc) =>
      acc.google_cid
        ? acc.google_cid === item.google_cid
        : acc.microsoft_cid === item.microsoft_cid
  )[0];
  const activeColumns = useRecoilValue(
    dataCoverage === 'This Mo' ? thisMoActiveColumnsState : activeColumnState
  );
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);
  const [separateCost, setSeparateCost] = useState(false);
  const [separateCPL, setSeparateCPL] = useState(false);
  const [separateConv, setSeparateConv] = useState(false);
  const [separateImp, setSeparateImp] = useState(false);
  const [separateClicks, setSeparateClicks] = useState(false);
  const [separateCTR, setSeparateCTR] = useState(false);
  const [isHoverCost, setIsHoverCost] = useState(false);
  const [isHoverCPL, setIsHoverCPL] = useState(false);
  const [isHoverConv, setIsHoverConv] = useState(false);
  const [isHoverImp, setIsHoverImp] = useState(false);
  const [isHoverClicks, setIsHoverClicks] = useState(false);
  const [isHoverCTR, setIsHoverCTR] = useState(false);
  const gItem = platformOptions['Google'] ? item.google_account : null;
  const mItem = platformOptions['Microsoft'] ? item.microsoft_account : null;
  const aDItem = platformOptions['Manually Added']
    ? item.additional_data
    : null;

  const budget =
    accountThisMonth && accountThisMonth.budget ? accountThisMonth.budget : '0';
  const date = new Date(
    new Date().toLocaleString('en-US', { timeZone: 'America/New_York' })
  );
  const month = date.getMonth() + 1;
  const year = date.getFullYear();
  const daysThisMonth = new Date(year, month, 0).getDate();
  const dayToday = date.getDate();

  let cost =
    gItem && mItem
      ? (parseFloat(gItem.spend) + parseFloat(mItem.spend)).toString()
      : gItem
      ? gItem.spend
      : mItem
      ? mItem.spend
      : '0';

  let conversions =
    gItem && mItem
      ? (
          parseFloat(gItem.conversions) + parseFloat(mItem.conversions)
        ).toString()
      : gItem
      ? gItem.conversions
      : mItem
      ? mItem.conversions
      : '0';

  cost =
    aDItem && aDItem.spend
      ? aDItem.spend_operation === '+'
        ? (parseFloat(aDItem.spend) + parseFloat(cost)).toString()
        : (parseFloat(cost) - parseFloat(aDItem.spend)).toString()
      : cost;

  conversions =
    aDItem && aDItem.conversions
      ? aDItem.conv_operation === '+'
        ? (parseFloat(aDItem.conversions) + parseFloat(conversions)).toString()
        : (parseFloat(conversions) - parseFloat(aDItem.conversions)).toString()
      : conversions;

  const costOffsetAD =
    aDItem && aDItem.spend
      ? aDItem.spend_operation === '+'
        ? (parseFloat(cost) - parseFloat(aDItem.spend)).toString()
        : (parseFloat(cost) + parseFloat(aDItem.spend)).toString()
      : cost;
  const convOffsetAD =
    aDItem && aDItem.conversions
      ? aDItem.conv_operation === '+'
        ? (parseFloat(conversions) - parseFloat(aDItem.conversions)).toString()
        : (parseFloat(conversions) + parseFloat(aDItem.conversions)).toString()
      : conversions;

  let pace =
    (parseFloat(cost) / parseFloat(budget) - dayToday / daysThisMonth) * 100;
  let remainingBudget = parseFloat(budget) - parseFloat(cost);
  let cpl = (parseFloat(cost) / parseFloat(conversions)).toString();

  if (!isFinite(parseFloat(cpl)) || isNaN(parseFloat(cpl))) cpl = '0';
  if (!isFinite(pace) || isNaN(pace)) pace = 0;

  let impressions =
    gItem && mItem
      ? (
          parseFloat(gItem.impressions) + parseFloat(mItem.impressions)
        ).toString()
      : gItem
      ? gItem.impressions
      : mItem
      ? mItem.impressions
      : '0';

  let clicks =
    gItem && mItem
      ? (parseFloat(gItem.clicks) + parseFloat(mItem.clicks)).toString()
      : gItem
      ? gItem.clicks
      : mItem
      ? mItem.clicks
      : '0';

  let ctr =
    gItem && mItem
      ? ((parseFloat(clicks) / parseFloat(impressions)) * 100).toString()
      : gItem
      ? gItem.ctr
      : mItem
      ? mItem.ctr
      : '0';

  if (!isFinite(parseFloat(cpl)) || isNaN(parseFloat(cpl))) cpl = '0';
  if (!isFinite(pace) || isNaN(pace)) pace = 0;
  if (!isFinite(parseFloat(ctr)) || isNaN(parseFloat(ctr))) ctr = '0';

  const handleDataFormOpen = () => {
    setIsDataFormOpen(true);
    setDataFormData({
      id: item ? item.id : '',
      name: item ? item.name : '',
      spend: parseFloat(costOffsetAD).toFixed(2),
      conversions: parseFloat(convOffsetAD).toFixed(2),
    });
  };

  const handleNameHover = (
    e: React.MouseEvent<HTMLParagraphElement, MouseEvent>
  ) => {
    setAnchorEl(e.currentTarget);
  };

  const handleNameUnhover = () => {
    setAnchorEl(null);
  };

  const id = `popover-${item.id}`;

  return (
    <tr
      className={`border-b ${
        lightMode ? 'border-gray-400' : 'border-gray-700'
      } ${page === 'data' ? 'text-sm' : ''}`}
    >
      {dataCoverage === 'This Mo' ? (
        <td
          className={`${
            item.is_active
              ? lightMode
                ? 'text-green-600'
                : 'text-green-500'
              : lightMode
              ? 'text-gray-900'
              : 'text-gray-100'
          } pl-2`}
        >
          {item.is_active ? <GoPrimitiveDot /> : <BsPauseFill />}
        </td>
      ) : null}
      <td
        className={`${
          lightMode ? 'text-black bg-gray-100' : 'text-white bg-gray-900'
        } sticky left-0 px-4 py-3 font-semibold w-max`}
      >
        <span className='pt-2 flex items-center justify-between gap-x-5 w-40 sm:w-max'>
          <p
            className={`${
              item.note && item.note.note ? 'cursor-pointer underline' : ''
            }`}
            onClick={handleNameHover}
          >
            {' '}
            {item.name}
          </p>{' '}
          {item.note && item.note.note ? (
            <Popover
              anchorEl={anchorEl}
              handleClose={handleNameUnhover}
              note={item.note.note}
              id={id}
            />
          ) : null}
          {dataCoverage === 'This Mo' ? (
            <p
              className={`rounded  text-sm cursor-pointer transition duration-150 ${
                lightMode
                  ? 'text-black hover:text-indigo-700'
                  : 'text-green-200 hover:text-gray-50'
              }`}
              onClick={handleDataFormOpen}
            >
              <HiOutlinePencilAlt />
            </p>
          ) : null}
        </span>
      </td>
      {activeColumns.includes('Email') ? (
        <td className={`px-4 ${lightMode && 'text-gray-800'}`}>
          {item.email === 'nan' ||
          item.email === 'your@email.com' ||
          !item.email
            ? 'TBD'
            : item.email}
        </td>
      ) : null}
      {activeColumns.includes('Budget') ? (
        <td className={`px-4 ${lightMode && 'text-gray-800'}`}>
          ${Commaed(parseFloat(budget).toFixed(2))}
        </td>
      ) : null}

      {activeColumns.includes('Cost') ? (
        <td
          className={`${separateCost ? 'px-1' : 'px-4'} cursor-pointer ${
            lightMode && 'text-gray-800'
          }`}
          onClick={() => setSeparateCost(!separateCost)}
          onMouseEnter={() => setIsHoverCost(true)}
          onMouseLeave={() => setIsHoverCost(false)}
        >
          <div className='flex items-center gap-x-1 '>
            {separateCost ? (
              <EachDataPlatfrom
                google={
                  item.google_account && platformOptions['Google']
                    ? item.google_account.spend
                    : '0.00'
                }
                microsoft={
                  item.microsoft_account && platformOptions['Microsoft']
                    ? item.microsoft_account.spend
                    : '0.00'
                }
                manual={
                  item.additional_data &&
                  item.additional_data.spend &&
                  platformOptions['Manually Added']
                    ? item.additional_data.spend
                    : '0.00'
                }
              />
            ) : (
              '$' + Commaed(parseFloat(cost).toFixed(2))
            )}
            {isHoverCost ? (
              separateCost ? (
                <RiMergeCellsHorizontal className='mx-auto text-center font-semibold' />
              ) : (
                <RiSplitCellsHorizontal className='mx-auto text-center font-semibold' />
              )
            ) : (
              ''
            )}
          </div>
        </td>
      ) : null}
      {activeColumns.includes('Remaining Budget') ? (
        <td className={`px-4 ${lightMode && 'text-gray-800'}`}>
          ${Commaed(remainingBudget.toFixed(2))}
        </td>
      ) : null}
      {activeColumns.includes('Pace') ? (
        <td
          className={`${
            pace <= -25
              ? 'bg-indigo-400 text-gray-900'
              : pace < 0
              ? 'bg-indigo-500 text-gray-900'
              : pace > 0 && pace < 25
              ? 'bg-indigo-600 text-white'
              : 'bg-indigo-900 text-white'
          } px-4 ${lightMode && 'text-gray-800'}`}
        >
          {Commaed(pace.toFixed(2))}%
        </td>
      ) : null}
      {activeColumns.includes('CPL') ? (
        <td
          className={`${
            separateCPL ? 'px-1' : 'px-4'
          } cursor-pointer transition duration-300  ${
            lightMode && 'text-gray-800'
          }`}
          onClick={() => setSeparateCPL(!separateCPL)}
          onMouseEnter={() => setIsHoverCPL(true)}
          onMouseLeave={() => setIsHoverCPL(false)}
        >
          <div className='flex items-center gap-x-1 '>
            {separateCPL ? (
              <EachDataPlatfrom
                google={
                  item.google_account && platformOptions['Google']
                    ? item.google_account.cost_per_conversion
                    : '0.00'
                }
                microsoft={
                  item.microsoft_account && platformOptions['Microsoft']
                    ? item.microsoft_account.cost_per_conversion
                    : '0.00'
                }
                manual={
                  item.additional_data &&
                  item.additional_data.conversions &&
                  item.additional_data.spend &&
                  platformOptions['Manually Added']
                    ? (
                        parseFloat(item.additional_data.spend) /
                        parseFloat(item.additional_data.conversions)
                      ).toString()
                    : '0.00'
                }
              />
            ) : (
              '$' + Commaed(parseFloat(cpl).toFixed(2))
            )}
            {isHoverCPL ? (
              separateCPL ? (
                <RiMergeCellsHorizontal className='mx-auto text-center font-semibold' />
              ) : (
                <RiSplitCellsHorizontal className='mx-auto text-center font-semibold' />
              )
            ) : (
              ''
            )}
          </div>
        </td>
      ) : null}
      {activeColumns.includes('Conv.') ? (
        <td
          className={`${separateConv ? 'px-1' : 'px-4'} cursor-pointer ${
            lightMode && 'text-gray-800'
          }`}
          onClick={() => setSeparateConv(!separateConv)}
          onMouseEnter={() => setIsHoverConv(true)}
          onMouseLeave={() => setIsHoverConv(false)}
        >
          <div className='flex items-center gap-x-1 '>
            {separateConv ? (
              <EachDataPlatfrom
                google={
                  item.google_account && platformOptions['Google']
                    ? item.google_account.conversions
                    : '0.00'
                }
                microsoft={
                  item.microsoft_account && platformOptions['Microsoft']
                    ? item.microsoft_account.conversions
                    : '0.00'
                }
                manual={
                  item.additional_data &&
                  item.additional_data.conversions &&
                  platformOptions['Manually Added']
                    ? item.additional_data.conversions
                    : '0.00'
                }
              />
            ) : (
              Commaed(parseFloat(conversions).toFixed(2))
            )}

            {isHoverConv ? (
              separateConv ? (
                <RiMergeCellsHorizontal className='mx-auto text-center font-semibold' />
              ) : (
                <RiSplitCellsHorizontal className='mx-auto text-center font-semibold' />
              )
            ) : (
              ''
            )}
          </div>
        </td>
      ) : null}
      {activeColumns.includes('Impressions') ? (
        <td
          className={`${separateImp ? 'px-1' : 'px-4'} cursor-pointer ${
            lightMode && 'text-gray-800'
          }`}
          onClick={() => setSeparateImp(!separateImp)}
          onMouseEnter={() => setIsHoverImp(true)}
          onMouseLeave={() => setIsHoverImp(false)}
        >
          <div className='flex items-center gap-x-1 '>
            {separateImp ? (
              <EachDataPlatfrom
                google={
                  item.google_account && platformOptions['Google']
                    ? item.google_account.impressions
                    : '0.00'
                }
                microsoft={
                  item.microsoft_account && platformOptions['Microsoft']
                    ? item.microsoft_account.impressions
                    : '0.00'
                }
                manual={'0.00'}
              />
            ) : (
              Commaed(parseFloat(impressions).toFixed(2))
            )}

            {isHoverImp ? (
              separateImp ? (
                <RiMergeCellsHorizontal className='mx-auto text-center font-semibold' />
              ) : (
                <RiSplitCellsHorizontal className='mx-auto text-center font-semibold' />
              )
            ) : (
              ''
            )}
          </div>
        </td>
      ) : null}
      {activeColumns.includes('Clicks') ? (
        <td
          className={`${separateClicks ? 'px-1' : 'px-4'} cursor-pointer ${
            lightMode && 'text-gray-800'
          }`}
          onClick={() => setSeparateClicks(!separateClicks)}
          onMouseEnter={() => setIsHoverClicks(true)}
          onMouseLeave={() => setIsHoverClicks(false)}
        >
          <div className='flex items-center gap-x-1 '>
            {separateClicks ? (
              <EachDataPlatfrom
                google={
                  item.google_account && platformOptions['Google']
                    ? item.google_account.clicks
                    : '0.00'
                }
                microsoft={
                  item.microsoft_account && platformOptions['Microsoft']
                    ? item.microsoft_account.clicks
                    : '0.00'
                }
                manual={'0.00'}
              />
            ) : (
              Commaed(parseFloat(clicks).toFixed(2))
            )}

            {isHoverClicks ? (
              isHoverClicks ? (
                <RiMergeCellsHorizontal className='mx-auto text-center font-semibold' />
              ) : (
                <RiSplitCellsHorizontal className='mx-auto text-center font-semibold' />
              )
            ) : (
              ''
            )}
          </div>
        </td>
      ) : null}
      {activeColumns.includes('CTR') ? (
        <td
          className={`${separateCTR ? 'px-1' : 'px-4'} cursor-pointer ${
            lightMode && 'text-gray-800'
          }`}
          onClick={() => setSeparateCTR(!separateCTR)}
          onMouseEnter={() => setIsHoverCTR(true)}
          onMouseLeave={() => setIsHoverCTR(false)}
        >
          <div className='flex items-center gap-x-1 '>
            {separateCTR ? (
              <EachDataPlatfrom
                google={
                  item.google_account && platformOptions['Google']
                    ? item.google_account.ctr
                    : '0.00'
                }
                microsoft={
                  item.microsoft_account && platformOptions['Microsoft']
                    ? item.microsoft_account.ctr
                    : '0.00'
                }
                manual={'0.00'}
              />
            ) : (
              Commaed(parseFloat(ctr).toFixed(2)) + '%'
            )}

            {isHoverCTR ? (
              isHoverCTR ? (
                <RiMergeCellsHorizontal className='mx-auto text-center font-semibold' />
              ) : (
                <RiSplitCellsHorizontal className='mx-auto text-center font-semibold' />
              )
            ) : (
              ''
            )}
          </div>
        </td>
      ) : null}
      {activeColumns.includes('Search Lost IS Rank (Google)') ? (
        <td className={`px-4 ${lightMode && 'text-gray-800'}`}>
          {Commaed(
            parseFloat(
              item.google_account?.search_rank_lost_impression_share || '0'
            ).toFixed(2)
          )}
          %
        </td>
      ) : null}
      {activeColumns.includes('Search Lost IS Budget (Google)') ? (
        <td className={`px-4 ${lightMode && 'text-gray-800'}`}>
          {Commaed(
            parseFloat(
              item.google_account?.search_budget_lost_impression_share || '0'
            ).toFixed(2)
          )}
          %
        </td>
      ) : null}
      {activeColumns.includes('Search Lost IS Rank (Microsoft)') ? (
        <td className={`px-4 ${lightMode && 'text-gray-800'}`}>
          {Commaed(
            parseFloat(
              item.microsoft_account?.search_rank_lost_impression_share || '0'
            ).toFixed(2)
          )}
          %
        </td>
      ) : null}
      {activeColumns.includes('Search Lost IS Budget (Microsoft)') ? (
        <td className={`px-4 ${lightMode && 'text-gray-800'}`}>
          {Commaed(
            parseFloat(
              item.microsoft_account?.search_budget_lost_impression_share || '0'
            ).toFixed(2)
          )}
          %
        </td>
      ) : null}

      {activeColumns.includes('Ads Paid By') ? (
        <td className={`px-4 ${lightMode && 'text-gray-800'}`}>
          {item.ad_spend}
        </td>
      ) : null}

      {activeColumns.includes('State') ? (
        <td className={`px-4 ${lightMode && 'text-gray-800'}`}>
          {item.state_country}
        </td>
      ) : null}
      {activeColumns.includes('Account Manager') ? (
        <td className={`px-4 ${lightMode && 'text-gray-800'}`}>
          {item.account_manager}
        </td>
      ) : null}
      {activeColumns.includes('PPC Manager') ? (
        <td className={`px-4 ${lightMode && 'text-gray-800'}`}>
          {item.ppc_specialist}
        </td>
      ) : null}
      {activeColumns.includes('IDX') ? (
        <td className={`px-4 ${lightMode && 'text-gray-800'}`}>{item.idx}</td>
      ) : null}
    </tr>
  );
};

export default DataPageTableData;
