import React from 'react';
import { Link } from 'react-router-dom';
import Layout from '../components/layout/Layout';
import { lightModeState } from '../recoil/atoms';
import { useRecoilValue } from 'recoil';

const ErrorPage = () => {
  const lightMode = useRecoilValue(lightModeState);
  return (
    <Layout>
      <div
        className={
          lightMode ? 'bg-gray-50 text-gray-800' : 'bg-gray-900 text-white'
        }
      >
        <div className='w-9/12 m-auto py-16 min-h-screen flex items-center justify-center'>
          <div className='overflow-hidden sm:rounded-lg pb-8'>
            <div className='text-center pt-8'>
              <h1 className='text-9xl font-bold'>404</h1>
              <p className='text-2xl pb-8 px-12 font-medium'>
                Oops! The page you are looking for does not exist. It might have
                been moved or deleted.
              </p>
              <Link
                className={`${
                  lightMode
                    ? 'bg-yellow-400 hover:bg-yellow-500'
                    : 'bg-yellow-600 hover:bg-yellow-700'
                } text-white font-semibold px-6 py-3 rounded-md mr-6 hover:no-underline`}
                to='/'
              >
                Back To Home
              </Link>
              <a
                className={`${
                  lightMode
                    ? 'bg-red-500 hover:bg-red-600 '
                    : 'bg-red-600 hover:bg-red-700 '
                } text-white font-semibold px-6 py-3 rounded-md hover:no-underline`}
                href='mailto:analytics@asteriskmarketing.co'
              >
                Contact Support
              </a>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default ErrorPage;
