import React from 'react';
import { useRecoilValue, useRecoilState } from 'recoil';
import {
  searchTermFilterState,
  userState,
  accountState,
  searchTerm30DaysAgoState,
  bingSearchTerm30DaysAgoState,
  searchTermAprState,
  searchTermAugState,
  searchTermDecState,
  searchTermFebState,
  searchTermJanState,
  searchTermJulState,
  searchTermJunState,
  searchTermMarState,
  searchTermMayState,
  searchTermNovState,
  searchTermOctState,
  searchTermSeptState,
  bingSearchTermAprState,
  bingSearchTermAugState,
  bingSearchTermDecState,
  bingSearchTermFebState,
  bingSearchTermJanState,
  bingSearchTermJulState,
  bingSearchTermJunState,
  bingSearchTermMarState,
  bingSearchTermMayState,
  bingSearchTermNovState,
  bingSearchTermOctState,
  bingSearchTermSeptState,
  monNameState,
  accounts30DaysAgoState,
} from '../../recoil/atoms';
import { month } from '../../helpers/dateUtils';
import ReportsCompFilterDropdown from '../filter-templates/ReportsCompFilterDropdown';

type Props = {
  showFilter: boolean;
  setShowFilter: React.Dispatch<React.SetStateAction<boolean>>;
};

const SearchTermDropdown = ({ showFilter, setShowFilter }: Props) => {
  const searchTermMonths = [
    searchTermJanState,
    searchTermFebState,
    searchTermMarState,
    searchTermAprState,
    searchTermMayState,
    searchTermJunState,
    searchTermJulState,
    searchTermAugState,
    searchTermSeptState,
    searchTermOctState,
    searchTermNovState,
    searchTermDecState,
  ];
  const bingSearchTermMonths = [
    bingSearchTermJanState,
    bingSearchTermFebState,
    bingSearchTermMarState,
    bingSearchTermAprState,
    bingSearchTermMayState,
    bingSearchTermJunState,
    bingSearchTermJulState,
    bingSearchTermAugState,
    bingSearchTermSeptState,
    bingSearchTermOctState,
    bingSearchTermNovState,
    bingSearchTermDecState,
  ];
  const [searchTermFilter, setSearchTermFitler] = useRecoilState(
    searchTermFilterState
  );
  const monName = useRecoilValue(monNameState);
  const user = useRecoilValue(userState);
  const adminAccount = useRecoilValue(accounts30DaysAgoState)[0];
  const superadminAccount = useRecoilValue(accountState);
  const account =
    user?.role === 'superadmin' ? superadminAccount : adminAccount;
  const searchTerm = useRecoilValue(
    monName === '30-Day'
      ? searchTerm30DaysAgoState
      : searchTermMonths[month.indexOf(monName)]
  ).filter((st) =>
    account && account.google_cid ? st.account === account.google_cid : st
  );
  const bingSearchTerm = useRecoilValue(
    monName === '30-Day'
      ? bingSearchTerm30DaysAgoState
      : bingSearchTermMonths[month.indexOf(monName)]
  ).filter((st) =>
    account && account.microsoft_cid ? st.account === account.microsoft_cid : st
  );

  const handleSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value: v } = e.target;
    if (v === 'Google' || v === 'Microsoft') setSearchTermFitler(v);
    setShowFilter(false);
  };

  return (
    <ReportsCompFilterDropdown
      showFilter={showFilter}
      handleSelect={handleSelect}
      filter={searchTermFilter}
      name='st-platform'
      isSearchTerm
      searchTerm={searchTerm}
      bingSearchTerm={bingSearchTerm}
    />
  );
};

export default SearchTermDropdown;
