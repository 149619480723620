import Layout from '../components/layout/Layout';
import { useSetRecoilState } from 'recoil';
import { pageState } from '../recoil/atoms';
import { useEffect } from 'react';
import Title from '../components/universal-decorations/Title';
import {
  BsEye,
  BsPercent,
  BsCurrencyDollar,
  BsFillBasket2Fill,
  BsReceiptCutoff,
} from 'react-icons/bs';
import { HiOutlineCursorClick } from 'react-icons/hi';
import { RiHandCoinLine } from 'react-icons/ri';
import GlossaryinfoTemplate from '../components/info-boxes-templates/GlossaryInfoTemplate';

const Glossary = () => {
  const setPage = useSetRecoilState(pageState);
  const data = [
    {
      Icon: <BsEye />,
      title: 'Impressions',
      description:
        'An impression is counted each time your ad is served on the SERP (search engine results page). Impressions help you understand how often your ad is being seen.',
    },
    {
      Icon: <HiOutlineCursorClick />,
      title: 'Clicks',
      description: "When someone clicks your ad, it's counted here.",
    },
    {
      Icon: <BsPercent />,
      title: 'CTR',
      description:
        "Clickthrough rate (CTR) measures how often people click on your ad after it's shown to them, which can help you understand the effectiveness of your ad.",
    },
    {
      Icon: <RiHandCoinLine />,
      title: 'CPC',
      description:
        "Average cost-per-click (CPC) is the amount you've paid for your ad divided by its total clicks. If your ad receives 2 clicks, one costing $0.20 and one costing $0.40, your average CPC for those clicks is $0.30.",
    },
    {
      Icon: <BsCurrencyDollar />,
      title: 'Ad Investment/Cost',
      description:
        'Ad Investment is the sum of your ad spend during this period.',
    },
    {
      Icon: <BsFillBasket2Fill />,
      title: 'Conversions',
      description:
        'Conversions show the number of leads you received after someone clicks on your ad.',
    },
    {
      Icon: <BsReceiptCutoff />,
      title: 'CPL',
      description:
        "Cost per lead shows the average cost of a conversion (lead). It's your cost divided by your conversions.",
    },
  ];

  useEffect(() => {
    setPage('glossary');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Layout>
      <div>
        <Title title='Glossary' />
        <div className='sm:grid sm:grid-cols-3 justify-items-center gap-x-7 px-10 gap-y-7'>
          {data.map((d) => (
            <GlossaryinfoTemplate
              key={d.title}
              title={d.title}
              description={d.description}
              Icon={d.Icon}
            />
          ))}
        </div>
      </div>
    </Layout>
  );
};

export default Glossary;
