import React from 'react';
import {
  lightModeState,
  accountState,
  isAccountModalState,
} from '../../recoil/atoms';
import { useRecoilValue, useSetRecoilState } from 'recoil';

const SelectAccountButton = () => {
  const lightMode = useRecoilValue(lightModeState);
  const account = useRecoilValue(accountState);
  const setIsAccountModalOpen = useSetRecoilState(isAccountModalState);
  const environment = process.env.NODE_ENV;
  return (
    <button
      className={`flex items-center justify-center gap-x-1 text-2xs sm:text-sm rounded my-auto w-8/12 font-semibold ml-34 shadow-md h-1/6 sm:h-full ${
        environment === 'production' && 'py-3'
      } ${
        lightMode
          ? 'bg-gray-200 hover:bg-gray-300 text-black'
          : 'bg-gray-600 hover:bg-gray-700 text-white'
      }`}
      onClick={() => setIsAccountModalOpen(true)}
    >
      <p
        className={`${
          environment === 'development' ? 'mt-3' : ''
        } hidden sm:inline`}
      >
        Selected Account:
      </p>{' '}
      <p className={environment === 'development' ? 'mt-3' : ''}>
        {account.name}
      </p>
    </button>
  );
};

export default SelectAccountButton;
