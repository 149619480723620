import React, { useEffect, useState } from 'react';
import Title from '../components/universal-decorations/Title';
import ReportsGraph from '../components/parent-comps/ReportsGraphs';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import {
  pageState,
  lightModeState,
  isLoadingDataState,
  isUpdateFormOpenState,
  isAddAccountOpenState,
  isAccountModalState,
  userState,
  accountState,
  reportBoxOneState,
  reportBoxTwoState,
  reportBoxThreeState,
} from '../recoil/atoms';
import SearchTerm from '../components/parent-comps/SearchTerm';
import Layout from '../components/layout/Layout';
import SelectAccountButton from '../components/buttons/SelectAccountButton';
import SelectAccountModal from '../components/forms/modal/SelectAccountModal';
import Coverage from '../components/date-related/Coverage';
import PlatformDropdown from '../components/filters/PlatformDropdown';
import LoadAnimation from '../components/universal-decorations/LoadAnimation';
import UpdateAccountForm from '../components/forms/modal/UpdateAccountForm';
import AddAccountForm from '../components/forms/modal/AddAccountForm';
import UserLocation from '../components/parent-comps/UserLocation';
import DeviceCharts from '../components/parent-comps/DeviceCharts';
import CoverageGraphs from '../components/parent-comps/CoverageGraphs';
import ReportsCompNav from '../components/navigation/ReportsCompNav';
import DeviceLineGraphs from '../components/parent-comps/DeviceLineGraphs';
import Remarketing from '../components/parent-comps/Remarketing';
import { MdArrowDropDown, MdArrowDropUp } from 'react-icons/md';
import MainReportsBox from '../components/info-boxes/MainReportsBox';
import Print from '../components/parent-comps/Print';

const Reports = () => {
  const setPage = useSetRecoilState(pageState);

  const lightMode = useRecoilValue(lightModeState);
  const showModal = useRecoilValue(isAccountModalState);
  const isUpdateFormOpen = useRecoilValue(isUpdateFormOpenState);
  const isAddAccountOpen = useRecoilValue(isAddAccountOpenState);
  const isLoadingData = useRecoilValue(isLoadingDataState);
  const [reportBoxOne, setReportBoxOne] = useRecoilState(reportBoxOneState);
  const [reportBoxTwo, setReportBoxTwo] = useRecoilState(reportBoxTwoState);
  const [reportBoxThree, setReportBoxThree] =
    useRecoilState(reportBoxThreeState);

  const [reportsComponent, setReportsComponent] = useState('Platforms');
  const [isNavOpen, setIsNavOpen] = useState(false);
  const [isEachOpen1, setIsEachOpen1] = useState(false);
  const [isEachOpen2, setIsEachOpen2] = useState(false);
  const [isEachOpen3, setIsEachOpen3] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    setPage('reports');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const account = useRecoilValue(accountState);

  const user = useRecoilValue(userState);

  return (
    <Layout>
      <div>
        <div className=' grid grid-cols-3 justify-items-center'>
          <div className='my-auto sm:ml-40 w-full'>
            <Coverage setReportsComponent={setReportsComponent} />
          </div>
          <Title
            title={
              user?.role !== 'superadmin'
                ? user
                  ? user.displayName
                  : ''
                : account.name === 'None'
                ? 'Asterisk Marketing'
                : account.name
            }
          />

          {user?.role === 'superadmin' ? (
            <SelectAccountButton />
          ) : (
            <PlatformDropdown />
          )}
        </div>
        {user?.role === 'superadmin' && (
          <div
            className={`mx-20
       ${lightMode ? 'text-gray-900' : 'text-white'}`}
          >
            <PlatformDropdown />
          </div>
        )}
        {isLoadingData ? (
          <div className='mt-24'>
            <LoadAnimation />
          </div>
        ) : (
          <div className='md:px-16'>
            <div className='sm:grid sm:grid-cols-3 md:py-5 justify-center'>
              <MainReportsBox
                reportBox={reportBoxOne}
                setReportBox={setReportBoxOne}
                isEachOpen={isEachOpen1}
                setIsEachOpen={setIsEachOpen1}
                isOtherOpen1={isEachOpen2}
                isOtherOpen2={isEachOpen3}
              />
              <MainReportsBox
                reportBox={reportBoxTwo}
                setReportBox={setReportBoxTwo}
                isEachOpen={isEachOpen2}
                setIsEachOpen={setIsEachOpen2}
                isOtherOpen1={isEachOpen1}
                isOtherOpen2={isEachOpen3}
              />
              <MainReportsBox
                reportBox={reportBoxThree}
                setReportBox={setReportBoxThree}
                isEachOpen={isEachOpen3}
                setIsEachOpen={setIsEachOpen3}
                isOtherOpen1={isEachOpen1}
                isOtherOpen2={isEachOpen2}
              />
            </div>
            <div className='overflow-x-scroll'>
              <div
                className={`my-14 sm:border-b ${
                  lightMode ? 'sm:border-gray-900' : 'sm:border-white'
                }`}
              >
                <div
                  className={` rounded mb-4 border-b border-t border-l border-r ${
                    lightMode
                      ? 'border-black text-black'
                      : 'border-white text-white'
                  } w-1/4 items-center gap-x-2 px-2.5 py-1 flex mx-auto font-semibold inline sm:hidden`}
                  onClick={() => setIsNavOpen(!isNavOpen)}
                >
                  Menu{' '}
                  {isNavOpen ? (
                    <MdArrowDropUp className='text-xl' />
                  ) : (
                    <MdArrowDropDown className='text-xl' />
                  )}
                </div>
                <div className={`${isNavOpen ? 'inline' : 'hidden'} sm:inline`}>
                  <ReportsCompNav
                    reportsComponent={reportsComponent}
                    setReportsComponent={setReportsComponent}
                    isNavOpen={isNavOpen}
                    setIsNavOpen={setIsNavOpen}
                  />
                </div>
              </div>
            </div>

            {reportsComponent === 'Platforms' ? (
              <ReportsGraph />
            ) : reportsComponent === 'SearchTerms' ? (
              <SearchTerm />
            ) : reportsComponent === 'Devices' ? (
              <DeviceCharts />
            ) : reportsComponent === 'UserLocation' ? (
              <UserLocation />
            ) : reportsComponent === 'Coverages' ? (
              <CoverageGraphs />
            ) : reportsComponent === 'DeviceGraphs' ? (
              <DeviceLineGraphs />
            ) : reportsComponent === 'Remarketing' ? (
              <Remarketing />
            ) : (
              <Print />
            )}
          </div>
        )}
        {showModal && (
          <SelectAccountModal setReportsComponent={setReportsComponent} />
        )}
        {isUpdateFormOpen && <UpdateAccountForm />}
        {isAddAccountOpen && <AddAccountForm />}
      </div>
    </Layout>
  );
};

export default Reports;
