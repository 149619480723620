import { selector } from 'recoil';
import axios from 'axios';
import { isAuthenticatedState, jwtState, userState } from '../atoms/authAtoms';
import {
  accountsAprState,
  accountsAugState,
  accountsDecState,
  accountsFebState,
  accountsJanState,
  accountsJulState,
  accountsJunState,
  accountsMarState,
  accountsMayState,
  accountsNovState,
  accountsOctState,
  accountsSeptState,
  accounts30DaysAgoState,
  accountState,
  googleReportsSelectedState,
  microsoftReportsSelectedState,
  lsaReportsSelectedState,
  adReportsSelectedState,
  monNameState,
  accountsAllTimeState,
} from '../atoms';
import { month } from '../../helpers/dateUtils';
import { Account } from '../../helpers/types';

export const headerState = selector({
  key: 'headerState',
  get: ({ get }) => {
    let idToken = get(jwtState);
    idToken = 'JWT ' + idToken;

    const headers = {
      'Content-Type': 'application/json',
      Authorization: idToken,
    };
    return headers;
  },
});

export const getAccountDataState = selector<Account[]>({
  key: 'getAccountsState',
  get: async ({ get }) => {
    let accounts: Account[] = [];
    const isAuthentcated = get(isAuthenticatedState);

    if (isAuthentcated) {
      let user = get(userState);

      const headers = get(headerState);

      const environment = process.env.NODE_ENV;

      const role = user?.role;
      await axios({
        method: 'get',
        url: `${
          environment === 'production'
            ? 'https://apimakeforth.herokuapp.com'
            : ''
        }/api/account_info/${
          role === 'superadmin'
            ? 'this-mo/'
            : `30-days/${
                user?.googleCID ? user?.googleCID : user?.microsoftCID
              }/`
        }`,
        headers: headers,
      })
        .then((res) => {
          if (role !== 'superadmin') accounts = [res.data];
          if (role === 'superadmin') accounts = res.data;
        })
        .catch((e) => console.log(e));
    }

    return accounts;
  },
});

export const adminAccountState = selector({
  key: 'adminAccountState',
  get: ({ get }) => {
    const accountMonths = [
      accountsJanState,
      accountsFebState,
      accountsMarState,
      accountsAprState,
      accountsMayState,
      accountsJunState,
      accountsJulState,
      accountsAugState,
      accountsSeptState,
      accountsOctState,
      accountsNovState,
      accountsDecState,
    ];
    const monName = get(monNameState);
    const accounts = get(
      monName === '30-Day'
        ? accounts30DaysAgoState
        : monName === 'All Time'
        ? accountsAllTimeState
        : accountMonths[month.indexOf(monName)]
    );
    const account = accounts[0];
    return account;
  },
});

export const getAccountState = selector({
  key: 'getAccountState',
  get: ({ get }) => {
    const superadminAccount = get(accountState);
    const adminAccount = get(adminAccountState);

    const googleState = get(googleReportsSelectedState);
    const microsoftState = get(microsoftReportsSelectedState);
    const lsaState = get(lsaReportsSelectedState);
    const adState = get(adReportsSelectedState);
    const user = get(userState);
    const account =
      user?.role === 'superadmin' ? superadminAccount : adminAccount;
    const gAccount = googleState ? account?.google_account : null;
    const mAccount = microsoftState ? account?.microsoft_account : null;
    const lAccount = lsaState ? account?.lsa_account : null;
    const adAccount = adState ? account?.additional_data : null;
    const mobile = account?.mobile;
    const tablet = account?.tablet;
    const desktop = account?.desktop;
    const bing_mobile = account?.bing_mobile;
    const bing_desktop = account?.bing_desktop;
    return {
      gAccount,
      mAccount,
      lAccount,
      adAccount,
      mobile,
      tablet,
      desktop,
      bing_mobile,
      bing_desktop,
    };
  },
});
