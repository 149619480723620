import React from 'react';
import { useRecoilValue } from 'recoil';
import {
  accountState,
  lightModeState,
  monNameState,
  userState,
} from '../../recoil/atoms';
import { adminAccountState } from '../../recoil/selectors/dataSelectors';
import { month } from '../../helpers/dateUtils';

type Props = {
  reportsComponent: string;
  setReportsComponent: React.Dispatch<React.SetStateAction<string>>;
  isNavOpen: boolean;
  setIsNavOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const ReportsCompNav = ({
  reportsComponent,
  setReportsComponent,
  isNavOpen,
  setIsNavOpen,
}: Props) => {
  const lightMode = useRecoilValue(lightModeState);
  const user = useRecoilValue(userState);
  const monName = useRecoilValue(monNameState);
  const account = useRecoilValue(
    user?.role === 'superadmin' ? accountState : adminAccountState
  );

  const onNav = (component: string) => {
    setReportsComponent(component);
    setIsNavOpen(false);
  };

  return (
    <div
      className={`flex flex-col sm:flex-row gap-y-3 align-items-center justify-center ${
        lightMode ? 'text-black' : 'text-white'
      }`}
    >
      <div
        className={`px-2 ${
          reportsComponent === 'Platforms'
            ? `border-b-4 ${lightMode ? 'border-gray-900' : 'border-gray-200'}`
            : `${
                lightMode
                  ? 'border-gray-600 text-gray-600'
                  : 'border-gray-500 text-gray-500'
              }`
        }`}
      >
        <div
          className={`transition duration-300 cursor-pointer font-semibold px-4 pb-3 text-xs `}
          onClick={() => onNav('Platforms')}
        >
          Platform Charts
        </div>
      </div>
      <div
        className={`px-2 ${
          reportsComponent === 'SearchTerms'
            ? `border-b-4 ${lightMode ? 'border-gray-900' : 'border-gray-200'}`
            : `${
                monName === 'All Time'
                  ? lightMode
                    ? 'border-gray-600 text-gray-300'
                    : 'border-gray-500 text-gray-800'
                  : lightMode
                  ? 'border-gray-600 text-gray-600'
                  : 'border-gray-500 text-gray-500'
              }`
        }`}
      >
        <div
          className={`transition duration-300 cursor-pointer font-semibold px-4 pb-3 text-xs `}
          onClick={() => monName !== 'All Time' && onNav('SearchTerms')}
        >
          Keywords
        </div>
      </div>
      <div
        className={`px-2 ${
          reportsComponent === 'Devices'
            ? `border-b-4 ${lightMode ? 'border-gray-900' : 'border-gray-200'}`
            : `${
                lightMode
                  ? 'border-gray-600 text-gray-600'
                  : 'border-gray-500 text-gray-500'
              }`
        }`}
      >
        <div
          className={`transition duration-300 cursor-pointer font-semibold  px-4 pb-3 text-xs `}
          onClick={() => onNav('Devices')}
        >
          Device Charts
        </div>
      </div>
      <div
        className={`px-2 ${
          reportsComponent === 'UserLocation'
            ? `border-b-4 ${lightMode ? 'border-gray-900' : 'border-gray-200'}`
            : `${
                monName === 'All Time'
                  ? lightMode
                    ? 'border-gray-600 text-gray-300'
                    : 'border-gray-500 text-gray-800'
                  : lightMode
                  ? 'border-gray-600 text-gray-600'
                  : 'border-gray-500 text-gray-500'
              }`
        }`}
      >
        <div
          className={`transition duration-300 cursor-pointer font-semibold px-4 pb-3 text-xs `}
          onClick={() => monName !== 'All Time' && onNav('UserLocation')}
        >
          User Location
        </div>
      </div>
      <div
        className={`px-2 ${
          reportsComponent === 'Coverages'
            ? `border-b-4 ${lightMode ? 'border-gray-900' : 'border-gray-200'}`
            : `${
                lightMode
                  ? 'border-gray-600 text-gray-600'
                  : 'border-gray-500 text-gray-500'
              }`
        }`}
      >
        <div
          className={`transition duration-300 cursor-pointer font-semibold  px-4 pb-3 text-xs `}
          onClick={() => onNav('Coverages')}
        >
          Coverage Graphs
        </div>
      </div>
      <div
        className={`px-2 ${
          reportsComponent === 'DeviceGraphs'
            ? `border-b-4 ${lightMode ? 'border-gray-900' : 'border-gray-200'}`
            : `${
                lightMode
                  ? 'border-gray-600 text-gray-600'
                  : 'border-gray-500 text-gray-500'
              }`
        }`}
      >
        <div
          className={`transition duration-300 cursor-pointer font-semibold px-4 pb-3 text-xs `}
          onClick={() => onNav('DeviceGraphs')}
        >
          Device Graphs
        </div>
      </div>
      {account &&
        (account.remarketing_campaign || account.bing_remarketing_campaign) && (
          <div
            className={`px-2 ${
              reportsComponent === 'Remarketing'
                ? `border-b-4 ${
                    lightMode ? 'border-gray-900' : 'border-gray-200'
                  }`
                : `${
                    lightMode
                      ? 'border-gray-600 text-gray-600'
                      : 'border-gray-500 text-gray-500'
                  }`
            }`}
          >
            <div
              className={`transition duration-300 cursor-pointer font-semibold px-4 pb-3 text-xs `}
              onClick={() => onNav('Remarketing')}
            >
              Remarketing
            </div>
          </div>
        )}
      {month.includes(monName) && (
        <div
          className={`px-2 ${
            reportsComponent === 'Print'
              ? `border-b-4 ${
                  lightMode ? 'border-gray-900' : 'border-gray-200'
                }`
              : `${
                  lightMode
                    ? 'border-gray-600 text-gray-600'
                    : 'border-gray-500 text-gray-500'
                }`
          } hidden sm:inline`}
        >
          <div
            className={`transition duration-300 cursor-pointer font-semibold px-4 pb-3 text-xs `}
            onClick={() => onNav('Print')}
          >
            Print
          </div>
        </div>
      )}
    </div>
  );
};

export default ReportsCompNav;
