import React from 'react';
import { useRecoilValue } from 'recoil';
import { isLineDataLoadingState } from '../../recoil/atoms';
// import LeadsLineGraph from '../graphs/LeadsLineGraph';
// import BudgetLineGraph from '../graphs/BudgetLineGraph';
import CoverageFirstLineGraph from '../graphs/CoverageFirstLineGraph';
import CoverageSecondLineGraph from '../graphs/CoverageSecondLineGraph';
import LoadAnimation from '../universal-decorations/LoadAnimation';

const CoverageGraphs = () => {
  const isLineDataLoading = useRecoilValue(isLineDataLoadingState);

  return (
    <div>
      <div className='animate__animated  animate__backInRight'>
        {isLineDataLoading ? (
          <div className='py-5'>
            <LoadAnimation />
          </div>
        ) : (
          <div>
            <CoverageFirstLineGraph />
            <CoverageSecondLineGraph />
          </div>
        )}
      </div>
    </div>
  );
};

export default CoverageGraphs;
