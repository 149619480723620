import React from 'react';
import { lightModeState } from '../../recoil/atoms';
import { useRecoilValue } from 'recoil';

interface options {
  name: string;
}

type Props = {
  title: string;
  options: string[] | options[];
  account?: boolean;
  handlePick?: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  name?: string;
  defaultValue?: string;
  placeholder?: string;
};

const FormDropdown = ({
  title,
  options,
  account,
  handlePick,
  name,
  defaultValue,
  placeholder,
}: Props) => {
  const lightMode = useRecoilValue(lightModeState);
  return (
    <div className='mt-1'>
      <h6
        className={`font-semibold ml-1 mb-2 ${
          !lightMode ? 'text-white' : 'text-gray-800'
        }`}
      >
        {title}:
      </h6>
      <select
        className={`block w-full h-1/2 shadow-sm py-1.5 pl-1 mb-2.5 rounded-md border-l border-r border-t border-b cursor-pointer ${
          !lightMode
            ? 'bg-gray-800 text-gray-200 border-gray-700'
            : 'bg-white text-gray-800 border-gray-300'
        } font-semibold`}
        name={name}
        onChange={handlePick}
        disabled={account}
        defaultValue={defaultValue ? defaultValue : placeholder}
      >
        {placeholder && <option disabled>{placeholder}</option>}
        {options.map((option, index) => (
          <option
            key={index}
            value={typeof option !== 'string' ? option.name : option}
            className={`font-semibold ${!lightMode && 'bg-gray-900'}`}
          >
            {typeof option !== 'string' ? option.name : option}
          </option>
        ))}
      </select>
    </div>
  );
};

export default FormDropdown;
