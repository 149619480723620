import React, { useState } from 'react';
import { useRecoilValue } from 'recoil';
import { lightModeState, pageState } from '../../recoil/atoms';
import NavOptions from '../navigation/NavOptions';
import { RiAsterisk } from 'react-icons/ri';
import { VscClose } from 'react-icons/vsc';
import { Link } from 'react-router-dom';
import MobileNavFooter from '../nav-comp/MobileNavFooter';

type Props = {
  handleToggle: () => void;
  isOpen?: boolean;
};

const MobileNavMenu = ({ handleToggle, isOpen }: Props) => {
  const page = useRecoilValue(pageState);
  const lightMode = useRecoilValue(lightModeState);
  const [isGoingOut, setIsGoingOut] = useState(false);
  const handleClose = () => {
    setIsGoingOut(true);
    handleToggle();
    setIsGoingOut(false);
  };

  return (
    <div className='overflow-hidden'>
      <div
        className={`p-3 min-h-screen w-screen z-50 ${
          isOpen ? 'fixed' : 'hidden'
        } ${lightMode ? 'bg-white text-gray-900' : 'bg-gray-900 text-white'} ${
          isGoingOut ? 'animate__animated animate__slideOutRight' : ''
        } animate__animated animate__slideInRight inline sm:hidden flex flex-col gap-y-10 items-start`}
      >
        <div className='flex justify-between items-start w-full'>
          <Link to='/' onClick={handleClose}>
            <RiAsterisk
              className={`${
                lightMode ? 'text-gray-700' : 'text-gray-50'
              } text-8xl`}
            />
          </Link>
          <div className='text-2xl'>
            <VscClose onClick={handleClose} />
          </div>
        </div>
        <div className='flex flex-col justify-center ml-3'>
          <div className='flex flex-col justify-center'>
            {page !== 'sign-in' ? (
              <NavOptions mobile handleClick={handleToggle} />
            ) : (
              ''
            )}
          </div>
        </div>
        <div className='fixed bottom-0 left-0 w-full'>
          <MobileNavFooter handleClose={handleClose} />
        </div>
      </div>
    </div>
  );
};

export default MobileNavMenu;
