import { TiPrinter } from 'react-icons/ti';

type Props = {
  onPrint: () => void;
};

const PrintButton = ({ onPrint }: Props) => {
  return (
    <button
      onClick={onPrint}
      className='rounded pl-3 pr-4 py-2 bg-blue-500 transition duration-300 hover:bg-blue-600 text-white text-xl mb-36 font-semibold ml-5 flex items-center gap-x-2'
    >
      <TiPrinter className='font-normal' /> Print Reports
    </button>
  );
};

export default PrintButton;
