import React, { useState } from 'react';
import { lightModeState, firebaseUserState } from '../../../recoil/atoms';
import { useRecoilValue } from 'recoil';
import { changePassword, reauthProcess } from '../../../helpers/auth';
import swal from 'sweetalert';

const PasswordComponent = () => {
  const lightMode = useRecoilValue(lightModeState);
  const firebaseUser = useRecoilValue(firebaseUserState);
  const [showPassword, setShowPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [credentials, setCredentials] = useState({
    email: '',
    password: '',
    newPassword: '',
  });

  const updatePassword = () => {
    if (firebaseUser) {
      changePassword(firebaseUser, credentials.newPassword)
        .then(() =>
          swal(
            'Succefully Changed Password',
            'Your password has been updated',
            'success',
            {
              timer: 1000,
              buttons: [false],
            }
          )
        )
        .catch((err) =>
          swal('Unable to change password', err.message, 'error')
        );
    }
  };

  const changePasswordProcess = () => {
    const { email, password } = credentials;
    if (firebaseUser) {
      reauthProcess(firebaseUser, { email, password })
        .then(() => updatePassword())
        .catch((err) =>
          swal('Unable to change password', err.message, 'error')
        );
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setCredentials({ ...credentials, [name]: value });
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    changePasswordProcess();
  };

  return (
    <div
      className={`w-screen sm:w-full flex flex-col ${
        lightMode ? 'bg-gray-100' : 'bg-gray-900'
      } h-full`}
    >
      <div className='my-auto md:pt-0'>
        <form className='mx-4 sm:mx-0 flex flex-col' onSubmit={handleSubmit}>
          <div className='flex flex-col pt-4'>
            <label
              htmlFor='email'
              className={`text-lg ${lightMode ? 'text-black' : 'text-white'} `}
            >
              Email
            </label>

            <input
              type='email'
              id='email'
              placeholder='your@email.com'
              name='email'
              className={`appearance-none rounded py-2.5 px-3 text-gray-700 mt-1 leading-tight focus:outline-none focus:shadow-outline w-full ${
                !lightMode
                  ? 'bg-gray-700 text-white shadow'
                  : 'border-r border-l border-t border-b border-gray-400 shadow-md bg-white'
              }`}
              onChange={handleChange}
            />
          </div>

          <div className='flex flex-col pt-4'>
            <label
              htmlFor='password'
              className={`text-lg ${lightMode ? 'text-black' : 'text-white'} `}
            >
              Password
            </label>
            <input
              type={showPassword ? 'text' : 'password'}
              id='password'
              placeholder='Password'
              name='password'
              className={`appearance-none rounded py-2.5 px-3 text-gray-700 mt-1 leading-tight focus:outline-none focus:shadow-outline w-full ${
                !lightMode
                  ? 'bg-gray-700 text-white shadow'
                  : 'border-r border-l border-t border-b border-gray-400 shadow-md bg-white'
              }`}
              onChange={handleChange}
            />
          </div>
          <label className='text-sm text-white mt-3 cursor-pointer'>
            <input
              type='checkbox'
              checked={showPassword}
              onChange={() => setShowPassword(!showPassword)}
              className={`cursor-pointer rounded ${
                lightMode
                  ? 'border-l border-r border-b border-t border-gray-300'
                  : 'border-l border-r border-b border-t border-gray-700'
              }`}
            />
            <span
              className={`ml-2.5 text-2xs sm:text-base ${
                lightMode ? 'text-gray-700' : 'text-gray-200'
              }`}
            >
              Show Password
            </span>
          </label>
          <div className='flex flex-col pt-4'>
            <label
              htmlFor='newPassword'
              className={`text-lg ${lightMode ? 'text-black' : 'text-white'} `}
            >
              New Password
            </label>
            <input
              type={showNewPassword ? 'text' : 'password'}
              id='newPassword'
              placeholder='Password'
              name='newPassword'
              className={`appearance-none rounded py-2.5 px-3 text-gray-700 mt-1 leading-tight focus:outline-none focus:shadow-outline w-full ${
                !lightMode
                  ? 'bg-gray-700 text-white shadow'
                  : 'border-r border-l border-t border-b border-gray-400 shadow-md bg-white'
              }`}
              onChange={handleChange}
            />
          </div>
          <label className='text-sm text-white mt-3 cursor-pointer'>
            <input
              type='checkbox'
              checked={showNewPassword}
              onChange={() => setShowNewPassword(!showNewPassword)}
              className={`cursor-pointer rounded ${
                lightMode
                  ? 'border-l border-r border-b border-t border-gray-300'
                  : 'border-l border-r border-b border-t border-gray-700'
              }`}
            />
            <span
              className={`ml-2.5 text-2xs sm:text-base ${
                lightMode ? 'text-gray-700' : 'text-gray-200'
              }`}
            >
              Show New Password
            </span>
          </label>
          <button
            type='submit'
            className={`${
              lightMode
                ? 'bg-gray-800 hover:bg-black '
                : 'bg-red-800 hover:bg-red-600'
            }  text-white font-semibold text-lg p-2 mt-9 rounded w-full`}
          >
            Change Password
          </button>
        </form>
        <div className='text-center pt-12 pb-12'></div>
      </div>
    </div>
  );
};

export default PasswordComponent;
