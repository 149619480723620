import React from 'react';
import DoughnutGraph from '../graph-templates/DoughnutGraph';
import { accountState, userState } from '../../recoil/atoms';
import {
  adminAccountState,
  getAccountState,
} from '../../recoil/selectors/dataSelectors';
import { useRecoilValue } from 'recoil';

type Props = { isLabel?: boolean; isPrint?: boolean };

const BudgetDeviceBingDoughnutChart = ({ isLabel, isPrint }: Props) => {
  const user = useRecoilValue(userState);
  const adminAccount = useRecoilValue(adminAccountState);
  const superadminAccount = useRecoilValue(accountState);
  const account =
    user?.role === 'superadmin' ? superadminAccount : adminAccount;

  const { bing_desktop, bing_mobile } = useRecoilValue(getAccountState);
  const mSpend = bing_mobile ? bing_mobile.spend : '';
  const dSpend = bing_desktop ? bing_desktop.spend : '';

  let other =
    account && account.microsoft_account
      ? parseFloat(account.microsoft_account.spend) -
        (parseFloat(mSpend) + parseFloat(dSpend))
      : 0;

  const otherString = Math.sign(other) > 0 ? other.toString() : '';

  return (
    <DoughnutGraph
      data1={mSpend}
      data2={dSpend}
      data3={otherString}
      label1='Mobile'
      label2='Desktop'
      label3='Other'
      title='Budget Per Device (Microsoft)'
      color1='#16C79A'
      color2='#6A5495'
      color3='#EB5E0B'
      hovercolor1='#CFFFDC'
      hovercolor2='#D5D5D5'
      hovercolor3='#FCECDD'
      isLabel={isLabel}
      isPrint={isPrint}
    />
  );
};

export default BudgetDeviceBingDoughnutChart;
