import {
  auth,
  FirebaseUser,
  firestore,
  getCrendetials,
} from '../firebase/firebase';

// This authenticates users
export const signIn = async (email: string, password: string) => {
  const authUser = await auth.signInWithEmailAndPassword(email, password);
  return authUser;
};

// This gets you the user's data
export const snapshot = async (user: FirebaseUser) => {
  const userRef = await firestore.doc(`users/${user.uid}`).get();
  const users = userRef.data();

  return users;
};

// This removes user's authentication
export const signOut = () => {
  return auth.signOut();
};

// This change's user's password
export const changePassword = async (
  user: FirebaseUser,
  newPassword: string
) => {
  const changePass = await user.updatePassword(newPassword);
  return changePass;
};

// This reauthenticate users during password change
export const reauthProcess = async (
  user: FirebaseUser,
  { email, password }: { email: string; password: string }
) => {
  const credential = getCrendetials(email, password);
  const reauth = await user.reauthenticateWithCredential(credential);
  return reauth;
};
