import PrintULKWTemplate from '../info-boxes-templates/PrintULKWTemplate';
import {
  userLocationJanState,
  userLocationFebState,
  userLocationMarState,
  userLocationAprState,
  userLocationMayState,
  userLocationJunState,
  userLocationJulState,
  userLocationAugState,
  userLocationSeptState,
  userLocationOctState,
  userLocationNovState,
  userLocationDecState,
  bingUserLocationJanState,
  bingUserLocationFebState,
  bingUserLocationMarState,
  bingUserLocationAprState,
  bingUserLocationMayState,
  bingUserLocationJunState,
  bingUserLocationJulState,
  bingUserLocationAugState,
  bingUserLocationSeptState,
  bingUserLocationOctState,
  bingUserLocationNovState,
  bingUserLocationDecState,
  monNameState,
  userState,
  accountState,
  isLoadingUserLocationState,
  ulPrintComponentState,
} from '../../recoil/atoms';
import { adminAccountState } from '../../recoil/selectors/dataSelectors';
import { useRecoilValue } from 'recoil';
import { month } from '../../helpers/dateUtils';

const PrintUL = () => {
  const user = useRecoilValue(userState);
  const account = useRecoilValue(
    user?.role === 'superadmin' ? accountState : adminAccountState
  );
  const monName = useRecoilValue(monNameState);
  const isLoading = useRecoilValue(isLoadingUserLocationState);
  const ulPrintComponent = useRecoilValue(ulPrintComponentState);
  const googleULs = [
    userLocationJanState,
    userLocationFebState,
    userLocationMarState,
    userLocationAprState,
    userLocationMayState,
    userLocationJunState,
    userLocationJulState,
    userLocationAugState,
    userLocationSeptState,
    userLocationOctState,
    userLocationNovState,
    userLocationDecState,
  ];
  const bingULs = [
    bingUserLocationJanState,
    bingUserLocationFebState,
    bingUserLocationMarState,
    bingUserLocationAprState,
    bingUserLocationMayState,
    bingUserLocationJunState,
    bingUserLocationJulState,
    bingUserLocationAugState,
    bingUserLocationSeptState,
    bingUserLocationOctState,
    bingUserLocationNovState,
    bingUserLocationDecState,
  ];
  const googleULsFiltered = useRecoilValue(googleULs[month.indexOf(monName)])
    .filter((ul) => ul.account === account.google_cid)
    .filter((item) =>
      ulPrintComponent === 'Least Spent' || ulPrintComponent === 'Lowest CPL'
        ? item.conversions > 0
        : item
    );
  const bingULsFiltered = useRecoilValue(bingULs[month.indexOf(monName)])
    .filter((ul) => ul.account === account.microsoft_cid)
    .filter((item) =>
      ulPrintComponent === 'Least Spent' || ulPrintComponent === 'Lowest CPL'
        ? item.conversions > 0
        : item
    );
  googleULsFiltered.sort((a, b) =>
    ulPrintComponent === 'Most Converting'
      ? b.conversions - a.conversions
      : ulPrintComponent === 'Most Clicks'
      ? b.clicks - a.clicks
      : ulPrintComponent === 'Most Impressions'
      ? b.impressions - a.impressions
      : ulPrintComponent === 'Least Spent'
      ? a.spend - b.spend
      : ulPrintComponent === 'Highest CTR'
      ? b.ctr - a.ctr
      : a.cost_per_conversion - b.cost_per_conversion
  );
  bingULsFiltered.sort((a, b) =>
    ulPrintComponent === 'Most Converting'
      ? b.conversions - a.conversions
      : ulPrintComponent === 'Most Clicks'
      ? b.clicks - a.clicks
      : ulPrintComponent === 'Most Impressions'
      ? b.impressions - a.impressions
      : ulPrintComponent === 'Least Spent'
      ? a.spend - b.spend
      : ulPrintComponent === 'Highest CTR'
      ? b.ctr - a.ctr
      : a.cost_per_conversion - b.cost_per_conversion
  );

  return (
    <PrintULKWTemplate
      label={`${ulPrintComponent} User Location`}
      google={
        googleULsFiltered.length > 0
          ? googleULsFiltered[0].city + ', ' + googleULsFiltered[0].state
          : isLoading
          ? 'User Locations Loading'
          : 'No User Locaitons Available'
      }
      microsoft={
        bingULsFiltered.length > 0
          ? bingULsFiltered[0].city + ', ' + bingULsFiltered[0].state
          : isLoading
          ? 'User Locations Loading'
          : 'No User Locaitons Available'
      }
    />
  );
};

export default PrintUL;
