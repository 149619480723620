import React, { useState } from 'react';
import { BsCheck } from 'react-icons/bs';
import { HiPencilAlt } from 'react-icons/hi';
import { lightModeState, pageState } from '../../recoil/atoms';
import { useRecoilValue } from 'recoil';

type Props = {
  title: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  value: string | number | null;
  name: string;
  defaultValue?: string;
};

const FormInput = ({ title, onChange, value, name, defaultValue }: Props) => {
  const [isEditing, setIsEditing] = useState(false);
  const lightMode = useRecoilValue(lightModeState);
  const page = useRecoilValue(pageState);
  return (
    <div className='mt-1'>
      <h6
        className={`font-semibold ml-1 ${
          !lightMode ? 'text-white' : 'text-gray-800'
        } mb-2 `}
      >
        {title}:
      </h6>
      <div className='flex'>
        <input
          className={`rounded border-l border-r border-t border-b px-2 mb-2.5 focus:outline-none w-full  shadow-sm ${
            !lightMode
              ? isEditing
                ? 'font-semibold bg-gray-900 text-white border-gray-600'
                : 'font-semibold bg-gray-800 text-gray-300 border-gray-600'
              : isEditing
              ? 'bg-white text-black border-gray-300'
              : 'bg-gray-200 text-gray-700 border-gray-300'
          } ${page === 'data' ? 'py-1.5' : 'py-1'}`}
          disabled={!isEditing}
          onChange={onChange}
          value={value ? value : defaultValue}
          name={name}
        />
        {!isEditing ? (
          <HiPencilAlt
            className={`${
              !lightMode ? 'text-gray-200' : 'text-gray-700'
            } h-1/4 w-1/12 mt-2 ml-2 cursor-pointer text-xl`}
            onClick={() => setIsEditing(true)}
          />
        ) : (
          <BsCheck
            className='text-green-500  text-xl h-1/5 w-1/12 mt-1 ml-1 cursor-pointer'
            onClick={() => setIsEditing(false)}
          />
        )}
      </div>
    </div>
  );
};

export default FormInput;
