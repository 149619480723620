import React from 'react';
import {
  dataPageFormCompState,
  dataFormDataState,
  lightModeState,
  isDataFormOpenState,
} from '../../recoil/atoms';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import ModalBase from '../forms-comps/ModalBase';
import {
  MdOutlineArrowBackIos,
  MdOutlineArrowForwardIos,
} from 'react-icons/md';
import { GoPrimitiveDot } from 'react-icons/go';
import AddDataForm from '../forms/modal/AddDataForm';
import NoteTextArea from '../forms/modal/NoteTextArea';
import ChangeAttForm from '../forms/modal/ChangeAttForm';

const DataPModalComp = () => {
  const [dataPageFormComp, setDataPageFormComp] = useRecoilState(
    dataPageFormCompState
  );
  const dataFormData = useRecoilValue(dataFormDataState);
  const lightMode = useRecoilValue(lightModeState);
  const setIsDataFormOpen = useSetRecoilState(isDataFormOpenState);

  const handleClose = () => {
    setIsDataFormOpen(false);
    setDataPageFormComp('AddData');
  };

  return (
    <ModalBase handleClose={handleClose}>
      <h4
        className={`${
          lightMode ? 'text-gray-900' : 'text-gray-50'
        } font-semibold p-2 border-l border-r border-b border-t border-gray-600 rounded text-center my-2 text-xl sm:text-2xl`}
      >
        {dataFormData.name}
      </h4>
      <div className='grid grid-cols-3 place-content-center'>
        <div></div>
        <div
          className={`${
            lightMode ? 'text-gray-400' : 'text-gray-600'
          } flex text-sm justify-center gap-x-1 items-center `}
        >
          <MdOutlineArrowBackIos
            className={`transition duration-75 text-2xs cursor-pointer ${
              lightMode
                ? 'text-gray-400 hover:text-gray-700'
                : 'text-gray-700 hover:text-gray-400'
            } mr-1`}
            onClick={() =>
              setDataPageFormComp((comp) =>
                comp === 'AddData'
                  ? 'ChangeAtt'
                  : comp === 'Note'
                  ? 'AddData'
                  : 'Note'
              )
            }
          />
          <GoPrimitiveDot
            className={`transition duration-75 cursor-pointer ${
              lightMode
                ? dataPageFormComp === 'AddData'
                  ? 'text-gray-600'
                  : 'hover:text-gray-500'
                : dataPageFormComp === 'AddData'
                ? 'text-gray-400'
                : 'hover:text-gray-500 '
            }`}
            onClick={() => setDataPageFormComp('AddData')}
          />
          <GoPrimitiveDot
            className={`transition duration-75 cursor-pointer ${
              lightMode
                ? dataPageFormComp === 'Note'
                  ? 'text-gray-600'
                  : 'hover:text-gray-500'
                : dataPageFormComp === 'Note'
                ? 'text-gray-400'
                : 'hover:text-gray-500 '
            }`}
            onClick={() => setDataPageFormComp('Note')}
          />
          <GoPrimitiveDot
            className={`transition duration-75 cursor-pointer ${
              lightMode
                ? dataPageFormComp === 'ChangeAtt'
                  ? 'text-gray-600'
                  : 'hover:text-gray-500'
                : dataPageFormComp === 'ChangeAtt'
                ? 'text-gray-400'
                : 'hover:text-gray-500 '
            }`}
            onClick={() => setDataPageFormComp('ChangeAtt')}
          />
          <MdOutlineArrowForwardIos
            className={`transition duration-75 text-2xs cursor-pointer ${
              lightMode
                ? 'text-gray-400 hover:text-gray-700'
                : 'text-gray-700 hover:text-gray-400'
            } mr-1`}
            onClick={() =>
              setDataPageFormComp((comp) =>
                comp === 'AddData'
                  ? 'Note'
                  : comp === 'Note'
                  ? 'ChangeAtt'
                  : 'AddData'
              )
            }
          />
        </div>
        <div className='flex gap-x-1 justify-self-end text-white'></div>
      </div>
      {dataPageFormComp === 'AddData' ? (
        <AddDataForm />
      ) : dataPageFormComp === 'Note' ? (
        <NoteTextArea />
      ) : (
        <ChangeAttForm />
      )}
    </ModalBase>
  );
};

export default DataPModalComp;
