import React from 'react';
import { useRecoilValue } from 'recoil';
import { lightModeState } from '../../recoil/atoms';

type Props = {
  onClose: () => void;
  onClear: () => void;
  onSave: () => void;
};

const FilterBtns = ({ onClose, onClear, onSave }: Props) => {
  const lightMode = useRecoilValue(lightModeState);
  return (
    <div
      className={`sticky bottom-0 shadow flex justify-evenly relative z-10 w-full py-2 pt-1 gap-x-1 pr-1 ${
        lightMode ? 'bg-gray-50' : 'bg-gray-800'
      }`}
    >
      <button
        className={`font-semibold w-2/3 border-b border-t border-l border-r items-center hover:no-underline py-1 px-2 ml-2 focus:outline-none transition duration-300 ${
          lightMode
            ? 'text-red-400 border-red-400 hover:text-white hover:bg-red-400'
            : 'text-indigo-100 border-indigo-300 hover:text-white hover:bg-indigo-600 hover:border-indigo-600'
        } rounded text-base md:mt-0 `}
        onClick={onClose}
      >
        Close
      </button>
      <button
        className={`font-semibold w-2/3 border-b border-t border-l border-r items-center hover:no-underline py-1 px-2 focus:outline-none transition duration-300 ${
          lightMode
            ? 'border-red-400 text-white bg-red-300 hover:bg-red-400'
            : 'border-indigo-300 text-white bg-indigo-900 hover:bg-indigo-600 hover:border-indigo-600'
        } rounded text-base md:mt-0 `}
        onClick={onClear}
      >
        Clear
      </button>
      <button
        className={`font-semibold w-2/3 border-b border-t border-l border-r items-center hover:no-underline py-1 px-2 focus:outline-none transition duration-300 ${
          lightMode
            ? 'border-yellow-400 text-white bg-yellow-300 hover:bg-yellow-400'
            : 'border-red-400 text-white bg-red-600 hover:bg-red-500 hover:border-red-500'
        } rounded text-base md:mt-0 `}
        onClick={onSave}
      >
        Save
      </button>
    </div>
  );
};

export default FilterBtns;
