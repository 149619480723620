import React, { useState } from 'react';
import { Commaed } from '../../helpers/utils';
import { HiPencilAlt } from 'react-icons/hi';
import { BsCheck } from 'react-icons/bs';
import { useRecoilValue } from 'recoil';
import { lightModeState, pageState } from '../../recoil/atoms';

type Props = {
  title: string;
  data: string | number;
  value: string | number | null;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  name: string;
  operation: string;
  handleSetOperation: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  placeholder?: string;
};

const AddDataInput = ({
  title,
  data,
  value,
  onChange,
  name,
  operation,
  handleSetOperation,
  placeholder,
}: Props) => {
  const [isEditing, setIsEditing] = useState(false);
  const lightMode = useRecoilValue(lightModeState);
  const page = useRecoilValue(pageState);
  return (
    <div className='mt-1'>
      <h6
        className={`font-semibold ml-1 ${
          lightMode ? 'text-gray-800' : 'text-white'
        } mb-2 `}
      >
        {title}:
      </h6>
      <div className='flex mb-2'>
        <span
          className={`h-5/6 w-1/4 text-center ${
            lightMode
              ? 'bg-gray-200 text-gray-700 border-gray-300'
              : 'bg-gray-800 text-gray-200 border-gray-600'
          } rounded-l  py-1.5 px-2 font-semibold border-r border-t border-b border-l shadow-sm`}
        >
          {Commaed(data)}
        </span>
        <select
          className={`h-4/5 font-semibold w-max ${
            lightMode
              ? 'bg-gray-300 text-gray-700 border-gray-300'
              : 'bg-gray-700 text-gray-200 border-gray-700'
          } py-1.5 px-1 border-r border-t-2 border-b shadow-sm focus:outline-none cursor-pointer`}
          value={operation}
          onChange={handleSetOperation}
        >
          <option
            className={`font-semibold ${
              lightMode ? 'bg-gray-100' : 'bg-gray-900'
            }`}
          >
            +
          </option>
          <option
            className={`font-semibold ${
              lightMode ? 'bg-gray-100' : 'bg-gray-900'
            }`}
          >
            -
          </option>
        </select>
        <input
          className={`rounded-r border-r border-t border-b-2 pb-1.5 pt-2 h-2/3 px-2 mb-2.5 focus:outline-none w-full font-semibold shadow-sm number-remove-arrows ${
            lightMode
              ? isEditing
                ? 'bg-gray-white text-black border-gray-300'
                : 'bg-gray-200 text-gray-700 border-gray-300'
              : isEditing
              ? 'bg-gray-900 text-white border-gray-700'
              : 'bg-gray-800 text-gray-300 border-gray-700'
          }`}
          type='number'
          disabled={!isEditing}
          onChange={onChange}
          value={value ? value : undefined}
          name={name}
          autoComplete='off'
          placeholder={placeholder}
        />
        {!isEditing ? (
          <HiPencilAlt
            className={`${
              lightMode ? 'text-gray-700' : 'text-gray-200'
            } h-1/6 w-1/6 ${
              page === 'data' ? 'p-2' : 'p-1.5'
            } mt-0.5 cursor-pointer text-4xl`}
            onClick={() => setIsEditing(true)}
          />
        ) : (
          <BsCheck
            className='text-green-500 h-1/6 w-1/6 p-1.5 mt-0.5 cursor-pointer text-4xl'
            onClick={() => setIsEditing(false)}
          />
        )}
      </div>
    </div>
  );
};

export default AddDataInput;
