import React from 'react';
import ReportsBoxTemplate from '../info-boxes-templates/ReportsBoxTemplate';
import { getAccountState } from '../../recoil/selectors/dataSelectors';
import { Commaed } from '../../helpers/utils';
import { useRecoilValue, type SetterOrUpdater } from 'recoil';

type Props = {
  reportBox: string;
  setReportBox: SetterOrUpdater<string>;
  isEachOpen: boolean;
  setIsEachOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isOtherOpen1: boolean;
  isOtherOpen2: boolean;
};

const MainReportsBox = ({
  reportBox,
  setReportBox,
  isEachOpen,
  setIsEachOpen,
  isOtherOpen1,
  isOtherOpen2,
}: Props) => {
  const { gAccount, mAccount, lAccount, adAccount } =
    useRecoilValue(getAccountState);

  const gData =
    gAccount?.spend &&
    (reportBox === 'Ad Investment' || reportBox === 'Cost Per Lead')
      ? gAccount.spend
      : gAccount?.conversions && reportBox === 'Conversions'
      ? gAccount.conversions
      : gAccount?.impressions && reportBox === 'Impressions'
      ? gAccount.impressions
      : gAccount?.clicks && (reportBox === 'Clicks' || reportBox === 'CTR')
      ? gAccount.clicks
      : '0';
  const mData =
    mAccount?.spend &&
    (reportBox === 'Ad Investment' || reportBox === 'Cost Per Lead')
      ? mAccount.spend
      : mAccount?.conversions && reportBox === 'Conversions'
      ? mAccount.conversions
      : mAccount?.impressions && reportBox === 'Impressions'
      ? mAccount.impressions
      : mAccount?.clicks && (reportBox === 'Clicks' || reportBox === 'CTR')
      ? mAccount.clicks
      : '0';
  const lData =
    lAccount?.spend &&
    (reportBox === 'Ad Investment' || reportBox === 'Cost Per Lead')
      ? lAccount.spend
      : lAccount?.conversions && reportBox === 'Conversions'
      ? lAccount.conversions
      : '0';
  const adData =
    adAccount?.spend &&
    (reportBox === 'Ad Investment' || reportBox === 'Cost Per Lead')
      ? adAccount.spend
      : adAccount?.conversions && reportBox === 'Conversions'
      ? adAccount.conversions
      : '0';
  const dataTotal =
    parseFloat(gData) +
    parseFloat(mData) +
    parseFloat(lData) +
    parseFloat(adData);

  const gData2 =
    gAccount?.conversions && reportBox === 'Cost Per Lead'
      ? gAccount.conversions
      : gAccount?.clicks && reportBox === 'CTR'
      ? gAccount.impressions
      : '0';
  const mData2 =
    mAccount?.conversions && reportBox === 'Cost Per Lead'
      ? mAccount.conversions
      : mAccount?.clicks && reportBox === 'CTR'
      ? mAccount.impressions
      : '0';
  const lData2 =
    lAccount?.conversions && reportBox === 'Cost Per Lead'
      ? lAccount.conversions
      : '0';
  const adData2 =
    adAccount?.conversions && reportBox === 'Cost Per Lead'
      ? adAccount.conversions
      : '0';

  let data2Total =
    parseFloat(gData2) +
    parseFloat(mData2) +
    parseFloat(lData2) +
    parseFloat(adData2);

  if (data2Total === 0) data2Total = 1;

  const total =
    reportBox === 'Cost Per Lead'
      ? dataTotal / data2Total
      : reportBox === 'CTR'
      ? (dataTotal / data2Total) * 100
      : dataTotal;

  return (
    <ReportsBoxTemplate
      label={reportBox}
      data={`${
        reportBox === 'Ad Investment' || reportBox === 'Cost Per Lead'
          ? '$'
          : ''
      }${Commaed(total.toFixed(2))}${reportBox === 'CTR' ? '%' : ''}`}
      setReportBox={setReportBox}
      google={
        reportBox === 'Cost Per Lead'
          ? gAccount?.cost_per_conversion
          : reportBox === 'CTR'
          ? gAccount?.ctr
          : gData
      }
      microsoft={
        reportBox === 'Cost Per Lead'
          ? mAccount?.cost_per_conversion
          : reportBox === 'CTR'
          ? mAccount?.ctr
          : mData
      }
      lsa={
        reportBox === 'Cost Per Lead'
          ? (
              parseFloat(lData) /
              (parseFloat(lData2) > 0 ? parseFloat(lData2) : 1)
            ).toFixed(2)
          : lData
      }
      manual={
        adAccount && reportBox !== 'Cost Per Lead' && reportBox !== 'CTR'
          ? adData
          : undefined
      }
      isEachOpen={isEachOpen}
      setIsEachOpen={setIsEachOpen}
      isOtherOpen1={isOtherOpen1}
      isOtherOpen2={isOtherOpen2}
    />
  );
};

export default MainReportsBox;
