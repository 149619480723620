import React, { useState } from 'react';
import {
  lightModeState,
  dataFormDataState,
  thisMonthAccountsState,
  isLoadingDataState,
  dataPageFormCompState,
  isDataFormOpenState,
} from '../../../recoil/atoms';
import { useRecoilValue, useRecoilState, useSetRecoilState } from 'recoil';
import FormLayoutBase from '../../forms-comps/FormLayoutBase';
import { FaTrash } from 'react-icons/fa';
import axios from 'axios';
import { headerState } from '../../../recoil/selectors/dataSelectors';
import swal from 'sweetalert';

const NoteTextArea = () => {
  const environment = process.env.NODE_ENV;
  const lightMode = useRecoilValue(lightModeState);
  const dataFormData = useRecoilValue(dataFormDataState);
  const headers = useRecoilValue(headerState);
  const [thisMonthAccounts, setThisMonthAccounts] = useRecoilState(
    thisMonthAccountsState
  );
  const setIsLoadingData = useSetRecoilState(isLoadingDataState);
  const setDataPageFormComp = useSetRecoilState(dataPageFormCompState);
  const setIsDataFormOpen = useSetRecoilState(isDataFormOpenState);
  const thisMonthAccount = thisMonthAccounts.filter(
    (acc) => acc.id === dataFormData.id
  )[0];
  const accountNote = thisMonthAccount ? thisMonthAccount.note : null;
  const [note, setNote] = useState(accountNote ? accountNote.note : '');

  const getThisMoData = async () => {
    setIsLoadingData(true);
    await axios({
      method: 'get',
      headers: headers,
      url: `${
        environment === 'production' ? 'https://apimakeforth.herokuapp.com' : ''
      }/api/account_info/this-mo/`,
    })
      .then((res) => {
        setThisMonthAccounts(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
    setIsLoadingData(false);
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!accountNote) {
      await axios({
        method: 'post',
        headers: headers,
        url: `${
          environment === 'production'
            ? 'https://apimakeforth.herokuapp.com'
            : ''
        }/api/note/this-mo/`,
        data: {
          id: dataFormData.id,
          name: dataFormData.name,
          note: note,
        },
      })
        .then(() => {
          setIsDataFormOpen(false);
          setDataPageFormComp('AddData');

          swal(
            'Note Submitted',
            `Submitted Note for ${dataFormData.name}`,
            'success',
            {
              timer: 1000,
              buttons: [false],
            }
          );
          getThisMoData();
        })
        .catch((e) => {
          setIsLoadingData(false);
          swal('An Error Occured!', e.message, 'error');
          console.log(e);
        });
      return;
    }

    await axios({
      method: 'put',
      headers: headers,
      url: `${
        environment === 'production' ? 'https://apimakeforth.herokuapp.com' : ''
      }/api/note/this-mo/${dataFormData.id}/`,
      data: {
        id: dataFormData.id,
        name: dataFormData.name,
        note: note,
      },
    })
      .then(() => {
        setIsDataFormOpen(false);
        setDataPageFormComp('AddData');

        swal(
          'Note Submitted',
          `Submitted Note for ${dataFormData.name}`,
          'success',
          {
            timer: 1000,
            buttons: [false],
          }
        );
        getThisMoData();
      })
      .catch((e) => {
        setIsLoadingData(false);
        swal('An Error Occured!', e.message, 'error');
        console.log(e);
      });
  };

  const handleDeleteNote = async () => {
    if (!accountNote) return;
    await axios({
      method: 'delete',
      headers: headers,
      url: `${
        environment === 'production' ? 'https://apimakeforth.herokuapp.com' : ''
      }/api/note/this-mo/${dataFormData.id}/`,
    })
      .then(() => {
        setIsDataFormOpen(false);
        setDataPageFormComp('AddData');

        swal(
          'Note Deleted',
          `Deleted Note for ${dataFormData.name}`,
          'success',
          {
            timer: 1000,
            buttons: [false],
          }
        );
        getThisMoData();
      })
      .catch((e) => {
        setIsLoadingData(false);
        swal('An Error Occured!', e.message, 'error');
        console.log(e);
      });
  };

  return (
    <FormLayoutBase
      btnLabel='Submit Note'
      btn2Label={<FaTrash />}
      handleSubmit={handleSubmit}
      handleBtn2Click={handleDeleteNote}
      disableButton={!note}
      disableButton2={!accountNote}
    >
      <h6
        className={`font-semibold ml-1 ${
          lightMode ? 'text-gray-900' : 'text-white'
        } mb-2t text-lg`}
      >
        Note :{' '}
      </h6>
      <textarea
        value={note}
        onChange={(e) => setNote(e.target.value)}
        rows={5}
        className={`resize-none shadow-md w-full text-sm sm:text-base ${
          lightMode
            ? 'bg-gray-100 text-black border-gray-400 focus:ring-red-300'
            : 'bg-gray-900 text-white border-gray-600   focus:ring-indigo-500'
        } border-r border-l border-b border-t   rounded p-2 focus:outline-none focus:ring-2`}
      />
    </FormLayoutBase>
  );
};

export default NoteTextArea;
