import React, { useState } from 'react';
import { useRecoilValue } from 'recoil';
import { isLineDataLoadingState } from '../../recoil/atoms';
import LeadsMobileLineGraph from '../graphs/LeadsMobileLineGraph';
import BudgetMobileLineGraph from '../graphs/BudgetMobileLineGraph';
import LeadsDesktopLineGraph from '../graphs/LeadsDesktopLineGraph';
import BudgetDesktopLineGraph from '../graphs/BudgetDesktopLineGraph';
import LeadsTabletLineGraph from '../graphs/LeadsTabletLineGraph';
import BudgetTabletLineGraph from '../graphs/BudgetTabletLineGraph';
import LoadAnimation from '../universal-decorations/LoadAnimation';
import ReportsCompFilter from '../filter-comps/ReportsCompFilter';
import DeviceGraphsFilter from '../filters/DeviceGraphsFilter';

const DeviceLineGraphs = () => {
  const isLineDataLoading = useRecoilValue(isLineDataLoadingState);
  const [showFilter, setShowFilter] = useState(false);
  const [filter, setFilter] = useState('Mobile');

  const handleSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFilter(e.target.value);
  };

  return (
    <div>
      <div className='animate__animated  animate__backInRight'>
        <div>
          <div className='w-1/2 sm:w-1/6'>
            <ReportsCompFilter
              showFilter={showFilter}
              onClick={() => setShowFilter(!showFilter)}
              title='Device Graphs'
            />
            <DeviceGraphsFilter
              showFilter={showFilter}
              handleSelect={handleSelect}
              filter={filter}
            />
          </div>
        </div>
        {isLineDataLoading ? (
          <div className='py-5'>
            <LoadAnimation />
          </div>
        ) : (
          <div>
            {filter === 'Mobile' ? (
              <>
                <LeadsMobileLineGraph />
                <BudgetMobileLineGraph />
              </>
            ) : filter === 'Desktop' ? (
              <>
                <LeadsDesktopLineGraph />
                <BudgetDesktopLineGraph />
              </>
            ) : (
              <>
                <LeadsTabletLineGraph />
                <BudgetTabletLineGraph />
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default DeviceLineGraphs;
