import PopoverMUI from '@material-ui/core/Popover';

type Props = {
  anchorEl: Element | ((element: Element) => Element) | null | undefined;
  handleClose: () => void;
  note: string;
  id: string;
};

const Popover = ({ anchorEl, handleClose, note, id }: Props) => {
  return (
    <PopoverMUI
      id={id}
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      onClose={handleClose}
      disableRestoreFocus
      className='w-1/6'
    >
      <h6 className='pl-2 pt-2 font-semibold w-1/2'>Note: </h6>
      <p className='w-full px-3 py-2 text-sm'>{note}</p>
    </PopoverMUI>
  );
};

export default Popover;
