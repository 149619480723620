import {
  lightModeState,
  thisMonthAccountsState,
  isOpenNotifBoxState,
  searchState,
  statusFilterState,
  statusOptionState,
  userState,
} from '../../recoil/atoms';
import { useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';
import type { Account } from '../../helpers/types';
import { IoMdClose } from 'react-icons/io';
import { VscWarning } from 'react-icons/vsc';
import { Link } from 'react-router-dom';

const NotificationBox = () => {
  const lightMode = useRecoilValue(lightModeState);
  const user = useRecoilValue(userState);
  const thisMonthsAccounts = useRecoilValue(thisMonthAccountsState);
  const setIsOpenNotifBox = useSetRecoilState(isOpenNotifBoxState);
  const setSearch = useSetRecoilState(searchState);
  const resetStatusFilter = useResetRecoilState(statusFilterState);
  const resetStatusOptions = useResetRecoilState(statusOptionState);
  const accounts = thisMonthsAccounts
    .filter((account) => account.is_active === false)
    .filter(
      (account) =>
        parseFloat(account.google_account?.impressions || '0') > 0 ||
        parseFloat(account.microsoft_account?.impressions || '0') > 0
    )
    .filter((account) => account.ppc_specialist === user?.displayName);

  const getImpressions = (account: Account) => {
    return (
      parseFloat(account.google_account?.impressions || '0') +
      parseFloat(account.microsoft_account?.impressions || '0')
    );
  };

  const onClickView = (name: string) => {
    setSearch(name);
    setIsOpenNotifBox(false);
    resetStatusFilter();
    resetStatusOptions();
  };

  return (
    <div
      className={`pb-8 px-2 hidden sm:inline fixed left-12 bottom-12 mb-1 z-50 w-1/4 max-h-2/5 overflow-y-auto ${
        lightMode ? 'bg-gray-200 text-black' : 'bg-gray-900 text-white'
      } rounded`}
    >
      <div
        className={`flex items-center justify-between font-semibold sticky top-0 py-2 text-lg ${
          lightMode ? 'bg-gray-200' : 'bg-gray-900'
        }`}
      >
        Notifications
        <IoMdClose
          className='cursor-pointer'
          onClick={() => setIsOpenNotifBox(false)}
        />
      </div>
      {accounts.length > 0
        ? accounts.map((account) => (
            <div
              className={`flex items-start gap-x-3 ${
                lightMode ? 'bg-white' : 'bg-gray-700'
              } mb-2 p-2`}
            >
              <VscWarning
                className={`text-6xl ${
                  lightMode ? 'text-yellow-700' : 'text-yellow-500'
                }`}
              />
              <div
                key={account.name}
                className={`flex flex-col items-start gap-y-2`}
              >
                <p className='text-base font-semibold'>
                  {account.name} is paused but has gotten{' '}
                  {getImpressions(account)} impressions.
                </p>
                <p
                  className={`text-sm ${
                    lightMode ? 'text-gray-700' : 'text-gray-200'
                  }`}
                >
                  Please check your Google and Microsoft Ads to make sure the
                  account is paused or change the status in the BMS.
                </p>
                <Link
                  to='BMS'
                  className={`hover:no-underline ${
                    lightMode
                      ? 'text-blue-600 hover:text-blue-800'
                      : 'text-blue-500 hover:text-blue-600'
                  }`}
                  onClick={() => onClickView(account.name)}
                >
                  View in BMS
                </Link>
              </div>
            </div>
          ))
        : null}
    </div>
  );
};

export default NotificationBox;
