import React, { useState } from 'react';
import BudgetDeviceDoughnutChart from '../graphs/BudgetDeviceDoughnutChart';
import LeadsDeviceDoughnutChart from '../graphs/LeadsDeviceDoughnutChart';
import BudgetDeviceBingDoughnutChart from '../graphs/BudgetDeviceBingDoughnutChart';
import LeadsDeviceBingDoughnutChart from '../graphs/LeadsDeviceBingDoughnutChart';
import { useRecoilValue } from 'recoil';
import { deviceChartFilterState } from '../../recoil/atoms';
import ReportsCompFilter from '../filter-comps/ReportsCompFilter';
import DeviceChartsDropdown from '../filters/DeviceChartsDropdown';

const DeviceCharts = () => {
  const deviceChartFilter = useRecoilValue(deviceChartFilterState);
  const [showFilter, setShowFilter] = useState(false);

  const handleShowFilter = () => {
    setShowFilter(!showFilter);
  };
  return (
    <div>
      <div>
        <div className='w-1/2  sm:w-1/6'>
          <ReportsCompFilter
            showFilter={showFilter}
            onClick={handleShowFilter}
            title='Device Platform'
          />
          <DeviceChartsDropdown
            showFilter={showFilter}
            setShowFilter={setShowFilter}
          />
        </div>
      </div>
      <div className='animate__animated animate__backInRight sm:flex sm:justify-evenly'>
        {deviceChartFilter === 'Google' ? (
          <LeadsDeviceDoughnutChart isLabel />
        ) : (
          <LeadsDeviceBingDoughnutChart isLabel />
        )}
        {deviceChartFilter === 'Google' ? (
          <BudgetDeviceDoughnutChart isLabel />
        ) : (
          <BudgetDeviceBingDoughnutChart isLabel />
        )}
      </div>
    </div>
  );
};

export default DeviceCharts;
