import React from 'react';
import { type SetterOrUpdater, useRecoilValue } from 'recoil';
import { lightModeState } from '../../recoil/atoms';
import EachReportsPlatfrom from './EachReportsPlatform';
import {
  RiMergeCellsVertical,
  RiSplitCellsVertical,
  RiArrowDownSFill,
} from 'react-icons/ri';

type Props = {
  label: string;
  data: string;
  setReportBox?: SetterOrUpdater<string>;
  google?: string;
  microsoft?: string;
  lsa?: string;
  manual?: string;
  isEachOpen?: boolean;
  setIsEachOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  isOtherOpen1?: boolean;
  isOtherOpen2?: boolean;
};

const ReportsBoxTemplate = ({
  label,
  data,
  setReportBox,
  google,
  microsoft,
  lsa,
  manual,
  isEachOpen,
  setIsEachOpen,
  isOtherOpen1,
  isOtherOpen2,
}: Props) => {
  const lightMode = useRecoilValue(lightModeState);
  const reportsBoxOptions = [
    'Ad Investment',
    'Conversions',
    'Cost Per Lead',
    'Impressions',
    'Clicks',
    'CTR',
  ];
  return (
    <div
      className={`text-center ${
        lightMode ? 'text-gray-900 bg-gray-300 ' : 'bg-gray-700 text-white '
      } shadow-md m-2 sm:pt-2 sm:flex-1 rounded-md ${
        isOtherOpen1 || isOtherOpen2 ? 'max-h-1/2' : 'max-h-full'
      }`}
    >
      <div className='relative'>
        <h3 className='mb-2 text-xl sm:text-3xl'>{data}</h3>
        <div
          className={
            (google || microsoft || lsa) &&
            'dropdown dropdown-hover dropdown-right group z-10'
          }
        >
          <p
            className={`mb-2 text-xs sm:text-base cursor-pointer rounded ${
              (google || microsoft || lsa) && 'flex px-2 py-1'
            } items-center gap-x-2 ${
              lightMode && (google || microsoft || lsa)
                ? 'group-hover:bg-gray-200'
                : 'group-hover:bg-gray-600'
            }`}
            tabIndex={0}
          >
            {label} {(google || microsoft || lsa) && <RiArrowDownSFill />}
          </p>
          {(google || microsoft || lsa) && (
            <ul
              tabIndex={0}
              className={`dropdown-content menu w-44 border-t border-b border-l border-r ${
                lightMode
                  ? 'bg-gray-300 border-gray-400 '
                  : 'bg-gray-700 border-gray-500'
              } text-left menu  rounded text-2xs sm:text-xs font-semibold uppercase `}
            >
              {reportsBoxOptions.map((option) => (
                <li
                  key={option}
                  className={`py-2 px-3 cursor-pointer ${
                    lightMode ? 'hover:bg-gray-200' : 'hover:bg-gray-600'
                  } ${
                    label.includes(option) &&
                    (lightMode ? 'bg-gray-200' : 'bg-gray-600')
                  }`}
                  onClick={() => setReportBox && setReportBox(option)}
                >
                  {option}
                </li>
              ))}
            </ul>
          )}
        </div>
        {(google || microsoft || lsa) &&
          (isEachOpen ? (
            <RiMergeCellsVertical
              className='absolute right-2 top-5 sm:top-6 text-sm sm:text-lg cursor-pointer'
              title='Show Data for Each Platform'
              onClick={() => setIsEachOpen && setIsEachOpen(!isEachOpen)}
            />
          ) : (
            <RiSplitCellsVertical
              className='absolute right-2 top-5 sm:top-6 text-sm sm:text-lg cursor-pointer'
              title='Show Data for Each Platform'
              onClick={() => setIsEachOpen && setIsEachOpen(!isEachOpen)}
            />
          ))}
        {(google || microsoft || lsa) && isEachOpen && (
          <EachReportsPlatfrom
            google={google ? google : '0'}
            microsoft={microsoft ? microsoft : '0'}
            lsa={lsa ? lsa : '0'}
            manual={manual}
          />
        )}
      </div>
    </div>
  );
};

export default ReportsBoxTemplate;
