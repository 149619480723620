import React, { useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import DataPageComponent from '../components/parent-comps/DataPageComponent';
import Layout from '../components/layout/Layout';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import {
  thisMonthAccountsState,
  bmsAccount30DaysState,
  accounts90DaysAgoState,
  accounts3DaysAgoState,
  accountsYesterdayState,
  pageState,
  idxPlatformsFilterState,
  ppcSpecialistsFilterState,
  accountManagersFilterState,
  statesFilterState,
  statusFilterState,
  notesFilterState,
  isDataFormOpenState,
  budgetFilterState,
  costFilterState,
  remainingBudgetFilterState,
  paceFilterState,
  cplFilterState,
  convFilterState,
  budgetComparatorState,
  costComparatorState,
  remainingBudgetComparatorState,
  paceComparatorState,
  cplComparatorState,
  convComparatorState,
  dataCoverageState,
  bmsAccountJanState,
  bmsAccountFebState,
  bmsAccountMarState,
  bmsAccountAprState,
  bmsAccountMayState,
  bmsAccountJunState,
  bmsAccountJulstate,
  bmsAccountAugState,
  bmsAccountSeptState,
  bmsAccountOctState,
  bmsAccountNovState,
  bmsAccountDecState,
  bmsAccountAllTimeState,
  platformOptionState,
  adsPaidByFilterState,
} from '../recoil/atoms';
import DataPModalComp from '../components/parent-comps/DataPModalComp';
import { getValue, getPace } from '../helpers/utils';
import { month } from '../helpers/dateUtils';
import { Account } from '../helpers/types';

const Data = () => {
  const dataCoverage = useRecoilValue(dataCoverageState);
  const platformOptions = useRecoilValue(platformOptionState);

  const numberFilter = (
    item: number,
    defaultItem: Account,
    filter: string,
    comparator: '=' | '>' | '<' | '>=' | '<='
  ) => {
    return filter
      ? comparator === '='
        ? parseFloat(filter) === +item.toFixed(2)
        : comparator === '>'
        ? parseFloat(filter) > +item.toFixed(2)
        : comparator === '<'
        ? parseFloat(filter) < +item.toFixed(2)
        : comparator === '>='
        ? parseFloat(filter) >= +item.toFixed(2)
        : parseFloat(filter) <= +item.toFixed(2)
      : defaultItem;
  };

  const getVal = (
    parent: Account,
    value:
      | 'spend'
      | 'conversions'
      | 'cost_per_conversion'
      | 'impressions'
      | 'clicks'
      | 'ctr'
  ) =>
    getValue(
      parent,
      value,
      platformOptions['Google'],
      platformOptions['Microsoft'],
      platformOptions['Manually Added']
    );

  const getPacing = (account: Account) => getPace(account, getVal);

  const idxPlatformsFilter = useRecoilValue(idxPlatformsFilterState);
  const ppcSpecialistsFilter = useRecoilValue(ppcSpecialistsFilterState);
  const accountManagersFilter = useRecoilValue(accountManagersFilterState);
  const statesFilter = useRecoilValue(statesFilterState);
  const statusFilter = useRecoilValue(statusFilterState);
  const notesFilter = useRecoilValue(notesFilterState);
  const adsPaidByFilter = useRecoilValue(adsPaidByFilterState);
  const budgetFilter = useRecoilValue(budgetFilterState);
  const costFilter = useRecoilValue(costFilterState);
  const remainingBudgetFilter = useRecoilValue(remainingBudgetFilterState);
  const paceFilter = useRecoilValue(paceFilterState);
  const cplFilter = useRecoilValue(cplFilterState);
  const convFilter = useRecoilValue(convFilterState);
  const budgetComparator = useRecoilValue(budgetComparatorState);
  const costComparator = useRecoilValue(costComparatorState);
  const remainingBudgetComparator = useRecoilValue(
    remainingBudgetComparatorState
  );
  const paceComparator = useRecoilValue(paceComparatorState);
  const cplComparator = useRecoilValue(cplComparatorState);
  const convComparator = useRecoilValue(convComparatorState);
  const accounts = useRecoilValue(
    dataCoverage === 'This Mo'
      ? thisMonthAccountsState
      : dataCoverage === '30-Day'
      ? bmsAccount30DaysState
      : dataCoverage === '90-Day'
      ? accounts90DaysAgoState
      : dataCoverage === '3-Day'
      ? accounts3DaysAgoState
      : dataCoverage === 'Yesterday'
      ? accountsYesterdayState
      : dataCoverage === 'All Time'
      ? bmsAccountAllTimeState
      : dataCoverage === month[0]
      ? bmsAccountJanState
      : dataCoverage === month[1]
      ? bmsAccountFebState
      : dataCoverage === month[2]
      ? bmsAccountMarState
      : dataCoverage === month[3]
      ? bmsAccountAprState
      : dataCoverage === month[4]
      ? bmsAccountMayState
      : dataCoverage === month[5]
      ? bmsAccountJunState
      : dataCoverage === month[6]
      ? bmsAccountJulstate
      : dataCoverage === month[7]
      ? bmsAccountAugState
      : dataCoverage === month[8]
      ? bmsAccountSeptState
      : dataCoverage === month[9]
      ? bmsAccountOctState
      : dataCoverage === month[10]
      ? bmsAccountNovState
      : bmsAccountDecState
  )
    .filter((item) =>
      idxPlatformsFilter.length > 0
        ? idxPlatformsFilter.includes(item.idx)
        : item
    )
    .filter((item) =>
      ppcSpecialistsFilter.length > 0
        ? ppcSpecialistsFilter.includes(item.ppc_specialist)
        : item
    )
    .filter((item) =>
      accountManagersFilter.length > 0
        ? accountManagersFilter.includes(item.account_manager)
        : item
    )
    .filter((item) =>
      statesFilter.length > 0 ? statesFilter.includes(item.state_country) : item
    )
    .filter((item) =>
      statusFilter.length > 0 && typeof item.is_active !== 'undefined'
        ? statusFilter.includes(item.is_active)
        : item
    )
    .filter((item) =>
      notesFilter.length > 0
        ? notesFilter.includes('With Note') &&
          notesFilter.includes('Without Note')
          ? item
          : notesFilter.includes('With Note')
          ? item.note
          : !item.note
        : item
    )
    .filter((item) =>
      adsPaidByFilter.length > 0
        ? adsPaidByFilter.includes('Asterisk') &&
          adsPaidByFilter.includes('Client')
          ? item
          : adsPaidByFilter.includes('Asterisk')
          ? item.ad_spend === 'Asterisk'
          : item.ad_spend === 'Client'
        : item
    )
    .filter((item) =>
      numberFilter(
        parseFloat(item.budget ? item.budget : ''),
        item,
        budgetFilter,
        budgetComparator
      )
    )
    .filter((item) =>
      numberFilter(getVal(item, 'spend'), item, costFilter, costComparator)
    )
    .filter((item) =>
      numberFilter(
        getVal(item, 'conversions'),
        item,
        convFilter,
        convComparator
      )
    )
    .filter((item) =>
      numberFilter(
        getVal(item, 'cost_per_conversion'),
        item,
        cplFilter,
        cplComparator
      )
    )
    .filter((item) =>
      numberFilter(
        parseFloat(item.budget ? item.budget : '') - getVal(item, 'spend'),
        item,
        remainingBudgetFilter,
        remainingBudgetComparator
      )
    )
    .filter((item) =>
      numberFilter(getPacing(item), item, paceFilter, paceComparator)
    );
  const setPage = useSetRecoilState(pageState);
  const isDataFormOpen = useRecoilValue(isDataFormOpenState);

  useEffect(() => {
    setPage('data');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Layout>
      <div>
        <DataPageComponent items={accounts} />
        {isDataFormOpen && <DataPModalComp />}
      </div>
    </Layout>
  );
};

export default Data;
