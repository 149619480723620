import React from 'react';

type Props = {
  data: string;
  label: string;
};

const PrintBoxTemplate = ({ data, label }: Props) => {
  return (
    <div className='px-4 py-2 bg-gray-100 text-black border-t border-b border-l border-r border-black text-center'>
      <h1 className='font-semibold text-xl'>{data}</h1>
      <p>{label}</p>
    </div>
  );
};

export default PrintBoxTemplate;
