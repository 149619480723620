import React from 'react';

type Props = {
  label: string;
  google: string;
  microsoft: string;
};

const PrintULKWTemplate = ({ label, google, microsoft }: Props) => {
  return (
    <div className='border-2 border-gray-900 p-4 w-96 bg-gray-100 rounded-lg'>
      <p className='italic tracking-wide uppercase'>{label}</p>
      <div className='mt-2'>
        <p className=''>
          <span className='font-semibold'>Google: </span>
          {google}
        </p>
        <p className=''>
          <span className='font-semibold'>Microsoft: </span>
          {microsoft}
        </p>
      </div>
    </div>
  );
};

export default PrintULKWTemplate;
