import React, { useState } from 'react';
import { useRecoilValue, useSetRecoilState, useRecoilState } from 'recoil';
import {
  selectedAccountState,
  accountsState,
  accountState,
  accounts2MonthsAgoState,
  accounts3MonthsAgoState,
  accounts30DaysAgoState,
  searchTerm30DaysAgoState,
  bingSearchTerm30DaysAgoState,
  userLocation30DaysAgoState,
  accountsAprState,
  accountsAugState,
  accountsDecState,
  accountsFebState,
  accountsJanState,
  accountsJulState,
  accountsJunState,
  accountsMarState,
  accountsMayState,
  accountsNovState,
  accountsOctState,
  accountsSeptState,
  searchTermAprState,
  searchTermAugState,
  searchTermDecState,
  searchTermFebState,
  searchTermJanState,
  searchTermJulState,
  searchTermJunState,
  searchTermMarState,
  searchTermMayState,
  searchTermNovState,
  searchTermOctState,
  searchTermSeptState,
  bingSearchTermAprState,
  bingSearchTermAugState,
  bingSearchTermDecState,
  bingSearchTermFebState,
  bingSearchTermJanState,
  bingSearchTermJulState,
  bingSearchTermJunState,
  bingSearchTermMarState,
  bingSearchTermMayState,
  bingSearchTermNovState,
  bingSearchTermOctState,
  bingSearchTermSeptState,
  userLocationAprState,
  userLocationAugState,
  userLocationDecState,
  userLocationFebState,
  userLocationJanState,
  userLocationJulState,
  userLocationJunState,
  userLocationMarState,
  userLocationMayState,
  userLocationNovState,
  userLocationOctState,
  userLocationSeptState,
  dataCoverageState,
  bmsAccount30DaysState,
  bmsAccountJanState,
  bmsAccountFebState,
  bmsAccountMarState,
  bmsAccountAprState,
  bmsAccountMayState,
  bmsAccountJunState,
  bmsAccountJulstate,
  bmsAccountAugState,
  bmsAccountSeptState,
  bmsAccountOctState,
  bmsAccountNovState,
  bmsAccountDecState,
  bmsAccountAllTimeState,
  isLoadingDataState,
  isUpdateFormOpenState,
  monNameState,
  isAccountModalState,
  accountsAllTimeState,
} from '../../../recoil/atoms';
import { headerState } from '../../../recoil/selectors/dataSelectors';
import ModalBase from '../../forms-comps/ModalBase';
import FormLayoutBase from '../../forms-comps/FormLayoutBase';
import FormDropdown from '../../forms-comps/FormDropdown';
import AddDataInput from '../../forms-comps/AddDataInput';
import axios from 'axios';
import swal from 'sweetalert';
import { month } from '../../../helpers/dateUtils';

const UpdateAccountForm = () => {
  const env = process.env.NODE_ENV;

  const setIssUpdateFormOpen = useSetRecoilState(isUpdateFormOpenState);
  const setAccounts = useSetRecoilState(accountsState);
  const setAccounts2MonthsAgo = useSetRecoilState(accounts2MonthsAgoState);
  const setAccounts3MonthsAgo = useSetRecoilState(accounts3MonthsAgoState);
  const setAccountsJan = useSetRecoilState(accountsJanState);
  const setAccountsFeb = useSetRecoilState(accountsFebState);
  const setAccountsMar = useSetRecoilState(accountsMarState);
  const setAccountsApr = useSetRecoilState(accountsAprState);
  const setAccountsMay = useSetRecoilState(accountsMayState);
  const setAccountsJun = useSetRecoilState(accountsJunState);
  const setAccountsJul = useSetRecoilState(accountsJulState);
  const setAccountsAug = useSetRecoilState(accountsAugState);
  const setAccountsSep = useSetRecoilState(accountsSeptState);
  const setAccountsOct = useSetRecoilState(accountsOctState);
  const setAccountsNov = useSetRecoilState(accountsNovState);
  const setAccountsDec = useSetRecoilState(accountsDecState);
  const setAccounts30DaysAgo = useSetRecoilState(accounts30DaysAgoState);
  const setAccountAllTime = useSetRecoilState(accountsAllTimeState);
  const setBMSAccountAllTime = useSetRecoilState(bmsAccountAllTimeState);
  const setBMSAccountsJan = useSetRecoilState(bmsAccountJanState);
  const setBMSAccountsFeb = useSetRecoilState(bmsAccountFebState);
  const setBMSAccountsMar = useSetRecoilState(bmsAccountMarState);
  const setBMSAccountsApr = useSetRecoilState(bmsAccountAprState);
  const setBMSAccountsMay = useSetRecoilState(bmsAccountMayState);
  const setBMSAccountsJun = useSetRecoilState(bmsAccountJunState);
  const setBMSAccountsJul = useSetRecoilState(bmsAccountJulstate);
  const setBMSAccountsAug = useSetRecoilState(bmsAccountAugState);
  const setBMSAccountsSep = useSetRecoilState(bmsAccountSeptState);
  const setBMSAccountsOct = useSetRecoilState(bmsAccountOctState);
  const setBMSAccountsNov = useSetRecoilState(bmsAccountNovState);
  const setBMSAccountsDec = useSetRecoilState(bmsAccountDecState);
  const setBMSAccounts30DaysAgo = useSetRecoilState(bmsAccount30DaysState);
  const setDataCoverage = useSetRecoilState(dataCoverageState);
  const setSearchTerms30DaysAgo = useSetRecoilState(searchTerm30DaysAgoState);
  const setBingSearchTerms30DaysAgo = useSetRecoilState(
    bingSearchTerm30DaysAgoState
  );
  const setUserLocation30DaysAgo = useSetRecoilState(
    userLocation30DaysAgoState
  );
  const setSearchTermsJan = useSetRecoilState(searchTermJanState);
  const setSearchTermsFeb = useSetRecoilState(searchTermFebState);
  const setSearchTermsMar = useSetRecoilState(searchTermMarState);
  const setSearchTermsApr = useSetRecoilState(searchTermAprState);
  const setSearchTermsMay = useSetRecoilState(searchTermMayState);
  const setSearchTermsJun = useSetRecoilState(searchTermJunState);
  const setSearchTermsJul = useSetRecoilState(searchTermJulState);
  const setSearchTermsAug = useSetRecoilState(searchTermAugState);
  const setSearchTermsSep = useSetRecoilState(searchTermSeptState);
  const setSearchTermsOct = useSetRecoilState(searchTermOctState);
  const setSearchTermsNov = useSetRecoilState(searchTermNovState);
  const setSearchTermsDec = useSetRecoilState(searchTermDecState);
  const setBingSearchTermsJan = useSetRecoilState(bingSearchTermJanState);
  const setBingSearchTermsFeb = useSetRecoilState(bingSearchTermFebState);
  const setBingSearchTermsMar = useSetRecoilState(bingSearchTermMarState);
  const setBingSearchTermsApr = useSetRecoilState(bingSearchTermAprState);
  const setBingSearchTermsMay = useSetRecoilState(bingSearchTermMayState);
  const setBingSearchTermsJun = useSetRecoilState(bingSearchTermJunState);
  const setBingSearchTermsJul = useSetRecoilState(bingSearchTermJulState);
  const setBingSearchTermsAug = useSetRecoilState(bingSearchTermAugState);
  const setBingSearchTermsSep = useSetRecoilState(bingSearchTermSeptState);
  const setBingSearchTermsOct = useSetRecoilState(bingSearchTermOctState);
  const setBingSearchTermsNov = useSetRecoilState(bingSearchTermNovState);
  const setBingSearchTermsDec = useSetRecoilState(bingSearchTermDecState);
  const setUserLocationJan = useSetRecoilState(userLocationJanState);
  const setUserLocationFeb = useSetRecoilState(userLocationFebState);
  const setUserLocationMar = useSetRecoilState(userLocationMarState);
  const setUserLocationApr = useSetRecoilState(userLocationAprState);
  const setUserLocationMay = useSetRecoilState(userLocationMayState);
  const setUserLocationJun = useSetRecoilState(userLocationJunState);
  const setUserLocationJul = useSetRecoilState(userLocationJulState);
  const setUserLocationAug = useSetRecoilState(userLocationAugState);
  const setUserLocationSep = useSetRecoilState(userLocationSeptState);
  const setUserLocationOct = useSetRecoilState(userLocationOctState);
  const setUserLocationNov = useSetRecoilState(userLocationNovState);
  const setUserLocationDec = useSetRecoilState(userLocationDecState);

  const arraySetters = [
    setAccounts,
    setAccounts2MonthsAgo,
    setAccounts3MonthsAgo,
    setAccountsJan,
    setAccountsFeb,
    setAccountsMar,
    setAccountsApr,
    setAccountsMay,
    setAccountsJun,
    setAccountsJul,
    setAccountsAug,
    setAccountsSep,
    setAccountsOct,
    setAccountsNov,
    setAccountsDec,
    setAccounts30DaysAgo,
    setAccountAllTime,
    setSearchTerms30DaysAgo,
    setBingSearchTerms30DaysAgo,
    setUserLocation30DaysAgo,
    setSearchTermsJan,
    setSearchTermsFeb,
    setSearchTermsMar,
    setSearchTermsApr,
    setSearchTermsMay,
    setSearchTermsJun,
    setSearchTermsJul,
    setSearchTermsAug,
    setSearchTermsSep,
    setSearchTermsOct,
    setSearchTermsNov,
    setSearchTermsDec,
    setBingSearchTermsJan,
    setBingSearchTermsFeb,
    setBingSearchTermsMar,
    setBingSearchTermsApr,
    setBingSearchTermsMay,
    setBingSearchTermsJun,
    setBingSearchTermsJul,
    setBingSearchTermsAug,
    setBingSearchTermsSep,
    setBingSearchTermsOct,
    setBingSearchTermsNov,
    setBingSearchTermsDec,
    setUserLocationJan,
    setUserLocationFeb,
    setUserLocationMar,
    setUserLocationApr,
    setUserLocationMay,
    setUserLocationJun,
    setUserLocationJul,
    setUserLocationAug,
    setUserLocationSep,
    setUserLocationOct,
    setUserLocationNov,
    setUserLocationDec,
    setBMSAccountsJan,
    setBMSAccountsFeb,
    setBMSAccountsMar,
    setBMSAccountsApr,
    setBMSAccountsMay,
    setBMSAccountsJun,
    setBMSAccountsJul,
    setBMSAccountsAug,
    setBMSAccountsSep,
    setBMSAccountsOct,
    setBMSAccountsNov,
    setBMSAccountsDec,
    setBMSAccounts30DaysAgo,
    setBMSAccountAllTime,
  ];

  const [acct, setAccount] = useRecoilState(accountState);
  const setIsLoadingData = useSetRecoilState(isLoadingDataState);
  const headers = useRecoilValue(headerState);
  const setSelectedAccount = useSetRecoilState(selectedAccountState);
  const [monName, setMonName] = useRecoilState(monNameState);
  const setAccountModal = useSetRecoilState(isAccountModalState);

  const gSpend = acct?.google_account ? acct.google_account.spend : '0';
  const mSpend = acct?.microsoft_account ? acct.microsoft_account.spend : '0';
  const lSpend = acct?.lsa_account ? acct.lsa_account.spend : '0';

  const gConv = acct?.google_account ? acct.google_account.conversions : '0';
  const mConv = acct?.microsoft_account
    ? acct.microsoft_account.conversions
    : '0';
  const lConv = acct?.lsa_account ? acct.lsa_account.conversions : '0';

  const dSpend = parseFloat(gSpend) + parseFloat(mSpend) + parseFloat(lSpend);

  const dConversions =
    parseFloat(gConv) + parseFloat(mConv) + parseFloat(lConv);

  const [input, setInput] = useState({
    spend: acct?.additional_data?.spend,
    conversions: acct?.additional_data?.conversions,
    conv_operation: '+',
    spend_operation: '+',
  });

  const { conv_operation, spend_operation, spend, conversions } = input;

  const requestUrl = `${
    env === 'production' ? 'https://apimakeforth.herokuapp.com' : ''
  }/api/additional_data/${
    month.includes(monName) ? monName.toLowerCase() : 'all-time'
  }/${acct?.additional_data ? acct.id + '/' : ''}`;

  const requestJSON = {
    name: acct.name,
    id: acct.id,
    conv_operation,
    spend_operation,
    spend,
    conversions,
  };

  const handleRefresh = () => {
    setIsLoadingData(true);
    arraySetters.forEach((set) => set([]));
    setAccount({
      id: '',
      name: 'None',
      email: 'your@email.com',
      microsoft_cid: '',
      account_manager: '',
      ppc_specialist: '',
      state_country: '',
      city: '',
      idx: '',
      ad_var: '',
      google_account: {
        id: 0,
        name: '',
        spend: '',
        conversions: '',
        cost_per_conversion: '',
        impressions: '',
        clicks: '',
        ctr: '',
      },
      microsoft_account: {
        id: '',
        name: '',
        spend: '',
        conversions: '',
        cost_per_conversion: '',
        impressions: '',
        clicks: '',
        ctr: '',
      },
    });
    setMonName('30-Day');
    setDataCoverage('This Mo');
    setAccountModal(false);
    setSelectedAccount('');
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value, name } = e.target;
    setInput({ ...input, [name]: value });
  };

  const handleClose = () => setIssUpdateFormOpen(false);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (requestJSON && monName !== '30-Day') {
      await axios({
        method: acct?.additional_data ? 'put' : 'post',
        url: requestUrl,
        headers: headers,
        data: requestJSON,
      })
        .then(() => {
          handleClose();
          handleRefresh();
          swal(
            'Successfully Posted!',
            `Changed Data for ${acct.name}`,
            'success',
            {
              timer: 1000,
              buttons: [false],
            }
          );
        })
        .then(() => setIsLoadingData(false))
        .catch((err) => {
          let message = '';
          if (err.message.includes('404'))
            message = 'Unauthorized. Please refresh page.';
          if (err.message.includes('400'))
            message = 'Bad Request. Data should only contain numeric value.';
          swal('An Error Occured!', message, 'error');
        });
    }
  };

  return (
    <ModalBase handleClose={handleClose}>
      <FormLayoutBase
        handleSubmit={handleSubmit}
        btnLabel='Submit Update'
        addPadding
      >
        <FormDropdown
          title='Account Name'
          options={[{ name: acct.name }]}
          account
        />
        <FormDropdown title='Coverage' options={[{ name: monName }]} account />
        <AddDataInput
          title='Spend'
          data={dSpend ? dSpend.toFixed(2) : 'null'}
          value={spend ? spend : null}
          onChange={handleChange}
          name='spend'
          operation={input.spend_operation}
          handleSetOperation={(e) =>
            setInput((inp) => ({ ...inp, spend_operation: e.target.value }))
          }
        />
        <AddDataInput
          title='Conversions'
          data={dConversions ? dConversions.toFixed(2) : 'null'}
          value={conversions ? conversions : null}
          onChange={handleChange}
          name='conversions'
          operation={input.conv_operation}
          handleSetOperation={(e) =>
            setInput((inp) => ({ ...inp, conv_operation: e.target.value }))
          }
        />
      </FormLayoutBase>
    </ModalBase>
  );
};

export default UpdateAccountForm;
