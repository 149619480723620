import React from 'react';
import { Spinner } from 'react-bootstrap';

const LoadAnimation = () => {
  return (
    <div className='flex justify-center gap-x-5 right-1/4 top-1/2 left-1/4'>
      <Spinner animation='grow' variant='secondary' />
      <Spinner animation='grow' variant='secondary' />
      <Spinner animation='grow' variant='secondary' />
    </div>
  );
};

export default LoadAnimation;
