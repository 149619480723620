import React, { useState } from 'react';
import { useRecoilValue, useRecoilState } from 'recoil';
import { FiFilter } from 'react-icons/fi';
import { BsSearch } from 'react-icons/bs';
import { HiViewBoards } from 'react-icons/hi';
import {
  isEditingColumsState,
  lightModeState,
  showFilterModalState,
} from '../../recoil/atoms';
import FilterModal from '../filters/FilterModal';
import SearchModal from './SearchModal';

type Props = {
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  tablePagination: JSX.Element;
};

const ToolbeltBar = ({ handleChange, tablePagination }: Props) => {
  const [showSearchBar, setShowSearchBar] = useState(false);
  const lightMode = useRecoilValue(lightModeState);
  const [isEditingColumn, setIsEditingColumn] =
    useRecoilState(isEditingColumsState);
  const [showFilterModal, setShowFilterModal] =
    useRecoilState(showFilterModalState);

  return (
    <div
      className={`flex sm:flex-row flex-col justify-between items-center w-full ${
        lightMode ? 'bg-gray-200' : 'bg-gray-700'
      }`}
    >
      <div
        className={`flex items-center font-semibold text-sm sm:text-base ${
          lightMode ? 'text-gray-900' : 'text-white'
        }`}
      >
        <div>
          <span
            className={`transition duration-300 cursor-pointer flex items-center gap-x-2 py-3 px-4 ${
              lightMode
                ? showFilterModal
                  ? 'bg-gray-50'
                  : 'bg-gray-200 hover:bg-gray-50'
                : showFilterModal
                ? 'bg-gray-800'
                : 'bg-gray-700 hover:bg-gray-800'
            }`}
            onClick={() => {
              setShowFilterModal((filter) => !filter);
              setShowSearchBar(false);
              setIsEditingColumn(false);
            }}
          >
            <FiFilter /> Filter
          </span>
          <FilterModal />
        </div>
        <div>
          <span
            className={`transition duration-300 cursor-pointer flex items-center gap-x-2 py-3 px-4 ${
              lightMode
                ? showSearchBar
                  ? 'bg-gray-50'
                  : 'bg-gray-200 hover:bg-gray-50'
                : showSearchBar
                ? 'bg-gray-800'
                : 'bg-gray-700 hover:bg-gray-800'
            }`}
            onClick={() => {
              setShowSearchBar((search) => !search);
              setShowFilterModal(false);
              setIsEditingColumn(false);
            }}
          >
            <BsSearch /> Search
          </span>
          {showSearchBar && (
            <SearchModal
              setShowSearch={setShowSearchBar}
              handleChange={handleChange}
            />
          )}
        </div>
        <div
          className={`transition duration-300 flex items-center gap-x-2 cursor-pointer h-full py-3 px-4 ${
            lightMode
              ? isEditingColumn
                ? 'bg-gray-50'
                : 'bg-gray-200 hover:bg-gray-50'
              : isEditingColumn
              ? 'bg-gray-800'
              : 'bg-gray-700 hover:bg-gray-800'
          }`}
          onClick={() => {
            setIsEditingColumn((col) => !col);
            setShowSearchBar(false);
            setShowFilterModal(false);
          }}
        >
          <HiViewBoards /> Columns
        </div>
      </div>
      <div>{tablePagination}</div>
    </div>
  );
};

export default ToolbeltBar;
