import React from 'react';
import { useRecoilValue } from 'recoil';
import { lightModeState, isMenuOpenState } from '../../recoil/atoms';
import SearchTermTableData from '../tbody-rows/SearchTermTableData';
import { RiArrowDownSFill, RiArrowUpSFill } from 'react-icons/ri';
import { SearchTerm } from '../../helpers/types';

interface notSortOptions {
  isName?: boolean;
  isCPC?: boolean;
  isCPL?: boolean;
  isCTR?: boolean;
  isClicks?: boolean;
  isConversions?: boolean;
  isImpressions?: boolean;
  isCost?: boolean;
}

type Props = {
  items: SearchTerm[];
  handleSort: (
    setMainSort: React.Dispatch<React.SetStateAction<number>>,
    mainSort: number
  ) => void;
  handleNotSort: (arr: React.Dispatch<React.SetStateAction<number>>[]) => void;
  notSortList: (
    options: notSortOptions
  ) => React.Dispatch<React.SetStateAction<number>>[];
  setters: React.Dispatch<React.SetStateAction<number>>[];
  isSortArr: number[];
};

const SearchTermTable = ({
  items,
  handleSort,
  handleNotSort,
  notSortList,
  setters,
  isSortArr,
}: Props) => {
  const lightMode = useRecoilValue(lightModeState);
  const isMenuOpen = useRecoilValue(isMenuOpenState);

  const handleClick = (
    setterIndex: number,
    notSortFunc: React.Dispatch<React.SetStateAction<number>>[]
  ) => {
    handleSort(setters[setterIndex], isSortArr[setterIndex]);
    handleNotSort(notSortFunc);
  };

  return (
    <table
      className={`text-sm table-auto w-1/2 sm:w-full text-left whitespace-no-wrap ${
        isMenuOpen && 'hidden'
      }`}
    >
      <thead className='h-10 sm:h-20'>
        <tr>
          <th
            className={`pr-5 pl-4 py-3 title-font tracking-wider font-medium ${
              lightMode ? 'text-black bg-gray-200' : 'text-white bg-gray-800'
            } text-2xs sm:text-sm  rounded-tl rounded-bl`}
          >
            <span
              className='cursor-pointer flex items-center'
              onClick={() => {
                handleClick(0, notSortList({ isName: true }));
              }}
            >
              Keywords
              {isSortArr[0] === 2 ? (
                <RiArrowDownSFill />
              ) : (
                isSortArr[0] === 3 && <RiArrowUpSFill />
              )}
            </span>
          </th>
          <th
            className={`px-4 py-3 title-font tracking-wider font-medium ${
              lightMode ? 'text-black bg-gray-200' : 'text-white bg-gray-800'
            } text-2xs sm:text-sm`}
          >
            <span
              className='cursor-pointer flex items-center'
              onClick={() => {
                handleClick(1, notSortList({ isImpressions: true }));
              }}
            >
              Imps.
              {isSortArr[1] === 2 ? (
                <RiArrowDownSFill />
              ) : (
                isSortArr[1] === 3 && <RiArrowUpSFill />
              )}
            </span>
          </th>
          <th
            className={`px-4 py-3 title-font tracking-wider font-medium ${
              lightMode ? 'text-black bg-gray-200' : 'text-white bg-gray-800'
            } text-2xs sm:text-sm`}
          >
            <span
              className='cursor-pointer flex items-center'
              onClick={() => {
                handleClick(2, notSortList({ isClicks: true }));
              }}
            >
              Clicks
              {isSortArr[2] === 2 ? (
                <RiArrowDownSFill />
              ) : (
                isSortArr[2] === 3 && <RiArrowUpSFill />
              )}
            </span>
          </th>
          <th
            className={`px-4 py-3 title-font tracking-wider font-medium ${
              lightMode ? 'text-black bg-gray-200' : 'text-white bg-gray-800'
            } text-2xs sm:text-sm`}
          >
            <span
              className='cursor-pointer flex items-center'
              onClick={() => {
                handleClick(3, notSortList({ isCTR: true }));
              }}
            >
              CTR{' '}
              {isSortArr[3] === 2 ? (
                <RiArrowDownSFill />
              ) : (
                isSortArr[3] === 3 && <RiArrowUpSFill />
              )}
            </span>
          </th>
          <th
            className={`px-4 py-3 title-font tracking-wider font-medium ${
              lightMode ? 'text-black bg-gray-200' : 'text-white bg-gray-800'
            } text-2xs sm:text-sm`}
          >
            <span
              className='cursor-pointer flex items-center'
              onClick={() => {
                handleClick(4, notSortList({ isCPC: true }));
              }}
            >
              CPC{' '}
              {isSortArr[4] === 2 ? (
                <RiArrowDownSFill />
              ) : (
                isSortArr[4] === 3 && <RiArrowUpSFill />
              )}
            </span>
          </th>
          <th
            className={`px-4 py-3 title-font tracking-wider font-medium ${
              lightMode ? 'text-black bg-gray-200' : 'text-white bg-gray-800'
            } text-2xs sm:text-sm`}
          >
            <span
              className='cursor-pointer flex items-center'
              onClick={() => {
                handleClick(5, notSortList({ isCost: true }));
              }}
            >
              Cost{' '}
              {isSortArr[5] === 2 ? (
                <RiArrowDownSFill />
              ) : (
                isSortArr[5] === 3 && <RiArrowUpSFill />
              )}
            </span>
          </th>
          <th
            className={`px-4 py-3 title-font tracking-wider font-medium ${
              lightMode ? 'text-black bg-gray-200' : 'text-white bg-gray-800'
            } text-2xs sm:text-sm`}
          >
            <span
              className='cursor-pointer flex items-center'
              onClick={() => {
                handleClick(6, notSortList({ isConversions: true }));
              }}
            >
              Conv.
              {isSortArr[6] === 2 ? (
                <RiArrowDownSFill />
              ) : (
                isSortArr[6] === 3 && <RiArrowUpSFill />
              )}
            </span>
          </th>
          <th
            className={`pl-4 py-3 title-font tracking-wider font-medium ${
              lightMode ? 'text-black bg-gray-200' : 'text-white bg-gray-800'
            } text-2xs sm:text-sm  rounded-tr rounded-br`}
          >
            <span
              className='cursor-pointer flex items-center'
              onClick={() => {
                handleClick(7, notSortList({ isCPL: true }));
              }}
            >
              CPL{' '}
              {isSortArr[7] === 2 ? (
                <RiArrowDownSFill />
              ) : (
                isSortArr[7] === 3 && <RiArrowUpSFill />
              )}
            </span>
          </th>
        </tr>
      </thead>
      <tbody>
        {items.map((item, index) => (
          <SearchTermTableData item={item} key={index} />
        ))}
      </tbody>
    </table>
  );
};

export default SearchTermTable;
