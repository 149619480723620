import React from 'react';
import { useRecoilValue } from 'recoil';
import {
  lightModeState,
  pageState,
  isMenuOpenState,
  dataCoverageState,
  thisMoActiveColumnsState,
  activeColumnState,
} from '../../recoil/atoms';
import DataPageTableData from '../tbody-rows/DataPageTableData';
import { RiArrowDownSFill, RiArrowUpSFill } from 'react-icons/ri';
import { Account } from '../../helpers/types';

interface notSortOptions {
  isCPL?: boolean;
  isStratType?: boolean;
  isName?: boolean;
  isEmail?: boolean;
  isPace?: boolean;
  isCPC?: boolean;
  isRemainingBudget?: boolean;
  isConversions?: boolean;
  isBudget?: boolean;
  isCost?: boolean;
  isAdSpend?: boolean;
  isStateCountry?: boolean;
  isAccountManager?: boolean;
  isPPC?: boolean;
  isAdVar?: boolean;
  isIdx?: boolean;
  isImpressions?: boolean;
  isClicks?: boolean;
  isCTR?: boolean;
  isSILRankG?: boolean;
  isSILBudgetG?: boolean;
  isSILRankM?: boolean;
  isSILBudgetM?: boolean;
}

type Props = {
  items: Account[];
  handleSort: (
    setMainSort: React.Dispatch<React.SetStateAction<number>>,
    mainSort: number
  ) => void;
  handleNotSort: (arr: React.Dispatch<React.SetStateAction<number>>[]) => void;
  notSortList: (
    options: notSortOptions
  ) => React.Dispatch<React.SetStateAction<number>>[];
  setters: React.Dispatch<React.SetStateAction<number>>[];
  isSortsArr: number[];
};

const DataPageTable = ({
  items,
  handleSort,
  handleNotSort,
  notSortList,
  setters,
  isSortsArr,
}: Props) => {
  const page = useRecoilValue(pageState);
  const lightMode = useRecoilValue(lightModeState);
  const isMenuOpen = useRecoilValue(isMenuOpenState);
  const dataCoverage = useRecoilValue(dataCoverageState);
  const activeColumns = useRecoilValue(
    dataCoverage === 'This Mo' ? thisMoActiveColumnsState : activeColumnState
  );

  const handleClick = (
    index: number,
    notSortFunc: React.Dispatch<React.SetStateAction<number>>[]
  ) => {
    handleSort(setters[index], isSortsArr[index]);
    handleNotSort(notSortFunc);
  };
  return (
    <table
      className={`w-full text-left whitespace-no-wrap pt-2 m-0 overflow-x-scroll relative ${
        isMenuOpen && 'hidden'
      }`}
    >
      <thead className='mb-5'>
        <tr>
          {dataCoverage === 'This Mo' ? (
            <th
              className={`px-2 ${
                dataCoverage === 'This Mo' ? 'py-3' : 'py-4'
              } title-font tracking-wider font-medium text-sm ${
                lightMode
                  ? 'text-green-600 bg-gray-300 border-black'
                  : 'text-green-500 bg-gray-800 border-gray-300'
              } sticky top-0`}
            ></th>
          ) : null}
          <th
            className={`px-4 sticky left-0 z-20  ${
              dataCoverage === 'This Mo' ? 'py-3' : 'py-4'
            } title-font tracking-wider font-medium text-sm ${
              lightMode
                ? 'text-gray-900 bg-gray-300 border-black'
                : 'text-white bg-gray-800  border-gray-300'
            } top-0`}
          >
            <span
              className={`w-max px-2 py-1 left-10 rounded-full cursor-pointer flex items-center `}
              onClick={() => {
                handleClick(0, notSortList({ isName: true }));
              }}
            >
              Account Name
              {isSortsArr[0] === 2 ? (
                <RiArrowDownSFill className='text-xl' />
              ) : (
                isSortsArr[0] === 3 && <RiArrowUpSFill className='text-xl' />
              )}
            </span>
          </th>
          {activeColumns.includes('Email') ? (
            <th
              className={`px-4  ${
                dataCoverage === 'This Mo' ? 'py-3' : 'py-4'
              } title-font tracking-wider font-medium text-sm ${
                lightMode
                  ? 'text-gray-900 bg-gray-300 border-black'
                  : 'text-white bg-gray-800  border-gray-300'
              } sticky top-0`}
            >
              <span
                className={`w-max px-2 py-1 rounded-full cursor-pointer flex items-center `}
                onClick={() => {
                  handleClick(1, notSortList({ isEmail: true }));
                }}
              >
                Email
                {isSortsArr[1] === 2 ? (
                  <RiArrowDownSFill />
                ) : (
                  isSortsArr[1] === 3 && <RiArrowUpSFill />
                )}
              </span>
            </th>
          ) : null}
          {activeColumns.includes('Budget') ? (
            <th
              className={`px-4  ${
                dataCoverage === 'This Mo' ? 'py-3' : 'py-4'
              } title-font tracking-wider font-medium text-sm ${
                lightMode
                  ? 'text-gray-900 bg-gray-300 border-black'
                  : 'text-white bg-gray-800  border-gray-300'
              } sticky top-0`}
            >
              <span
                className={`w-max px-2 py-1 rounded-full cursor-pointer flex items-center `}
                onClick={() => {
                  handleClick(2, notSortList({ isBudget: true }));
                }}
              >
                Budget
                {isSortsArr[2] === 2 ? (
                  <RiArrowDownSFill />
                ) : (
                  isSortsArr[2] === 3 && <RiArrowUpSFill />
                )}
              </span>
            </th>
          ) : null}
          {activeColumns.includes('Cost') ? (
            <th
              className={`px-4  ${
                dataCoverage === 'This Mo' ? 'py-3' : 'py-4'
              } title-font tracking-wider font-medium text-sm ${
                lightMode
                  ? 'text-gray-900 bg-gray-300 border-black'
                  : 'text-white bg-gray-800  border-gray-300'
              } sticky top-0`}
            >
              <span
                className={`w-max px-2 py-1 rounded-full cursor-pointer flex items-center `}
                onClick={() => {
                  handleClick(3, notSortList({ isCost: true }));
                }}
              >
                Cost
                {isSortsArr[3] === 2 ? (
                  <RiArrowDownSFill />
                ) : (
                  isSortsArr[3] === 3 && <RiArrowUpSFill />
                )}
              </span>
            </th>
          ) : null}
          {activeColumns.includes('Remaining Budget') ? (
            <th
              className={`px-4  ${
                dataCoverage === 'This Mo' ? 'py-3' : 'py-4'
              } title-font tracking-wider font-medium text-sm ${
                lightMode
                  ? 'text-gray-900 bg-gray-300 border-black'
                  : 'text-white bg-gray-800  border-gray-300'
              } sticky top-0`}
            >
              <span
                className={`w-max px-2 py-1 rounded-full cursor-pointer flex items-center`}
                onClick={() => {
                  handleClick(4, notSortList({ isRemainingBudget: true }));
                }}
              >
                Remaining Budget
                {isSortsArr[4] === 2 ? (
                  <RiArrowDownSFill className='text-xl' />
                ) : (
                  isSortsArr[4] === 3 && <RiArrowUpSFill className='text-xl' />
                )}
              </span>
            </th>
          ) : null}
          {activeColumns.includes('Pace') ? (
            <th
              className={`px-4  ${
                dataCoverage === 'This Mo' ? 'py-3' : 'py-4'
              } title-font tracking-wider font-medium text-sm ${
                lightMode
                  ? 'text-gray-900 bg-gray-300 border-black'
                  : 'text-white bg-gray-800  border-gray-300'
              } sticky top-0`}
            >
              <span
                className={`w-max px-2 py-1 rounded-full cursor-pointer flex items-center `}
                onClick={() => {
                  handleClick(5, notSortList({ isPace: true }));
                }}
              >
                Pace
                {isSortsArr[5] === 2 ? (
                  <RiArrowDownSFill />
                ) : (
                  isSortsArr[5] === 3 && <RiArrowUpSFill />
                )}
              </span>
            </th>
          ) : null}
          {activeColumns.includes('CPL') ? (
            <th
              className={`px-4  ${
                dataCoverage === 'This Mo' ? 'py-3' : 'py-4'
              } title-font tracking-wider font-medium text-sm ${
                lightMode
                  ? 'text-gray-900 bg-gray-300 border-black'
                  : 'text-white bg-gray-800  border-gray-300'
              } sticky top-0`}
            >
              <span
                className={`w-max px-2 py-1 rounded-full cursor-pointer flex items-center `}
                onClick={() => {
                  handleClick(6, notSortList({ isCPL: true }));
                }}
              >
                CPL
                {isSortsArr[6] === 2 ? (
                  <RiArrowDownSFill />
                ) : (
                  isSortsArr[6] === 3 && <RiArrowUpSFill />
                )}
              </span>
            </th>
          ) : null}
          {activeColumns.includes('Conv.') ? (
            <th
              className={`px-4  ${
                dataCoverage === 'This Mo' ? 'py-3' : 'py-4'
              } title-font tracking-wider font-medium text-sm ${
                lightMode
                  ? 'text-gray-900 bg-gray-300 border-black'
                  : 'text-white bg-gray-800 border-gray-300'
              } ${page === 'data' && 'ml-6'} sticky top-0`}
            >
              <span
                className={`w-max px-2 py-1 rounded-full cursor-pointer flex items-center `}
                onClick={() => {
                  handleClick(7, notSortList({ isConversions: true }));
                }}
              >
                Conv.
                {isSortsArr[7] === 2 ? (
                  <RiArrowDownSFill />
                ) : (
                  isSortsArr[7] === 3 && <RiArrowUpSFill />
                )}
              </span>
            </th>
          ) : null}
          {activeColumns.includes('Impressions') ? (
            <th
              className={`px-4  ${
                dataCoverage === 'This Mo' ? 'py-3' : 'py-4'
              } title-font tracking-wider font-medium text-sm ${
                lightMode
                  ? 'text-gray-900 bg-gray-300 border-black'
                  : 'text-white bg-gray-800 border-gray-300'
              } ${page === 'data' && 'ml-6'} sticky top-0`}
            >
              <span
                className={`w-max px-2 py-1 rounded-full cursor-pointer flex items-center `}
                onClick={() => {
                  handleClick(13, notSortList({ isImpressions: true }));
                }}
              >
                Impressions
                {isSortsArr[13] === 2 ? (
                  <RiArrowDownSFill />
                ) : (
                  isSortsArr[13] === 3 && <RiArrowUpSFill />
                )}
              </span>
            </th>
          ) : null}
          {activeColumns.includes('Clicks') ? (
            <th
              className={`px-4  ${
                dataCoverage === 'This Mo' ? 'py-3' : 'py-4'
              } title-font tracking-wider font-medium text-sm ${
                lightMode
                  ? 'text-gray-900 bg-gray-300 border-black'
                  : 'text-white bg-gray-800 border-gray-300'
              } ${page === 'data' && 'ml-6'} sticky top-0`}
            >
              <span
                className={`w-max px-2 py-1 rounded-full cursor-pointer flex items-center `}
                onClick={() => {
                  handleClick(14, notSortList({ isClicks: true }));
                }}
              >
                Clicks
                {isSortsArr[14] === 2 ? (
                  <RiArrowDownSFill />
                ) : (
                  isSortsArr[14] === 3 && <RiArrowUpSFill />
                )}
              </span>
            </th>
          ) : null}
          {activeColumns.includes('CTR') ? (
            <th
              className={`px-4  ${
                dataCoverage === 'This Mo' ? 'py-3' : 'py-4'
              } title-font tracking-wider font-medium text-sm ${
                lightMode
                  ? 'text-gray-900 bg-gray-300 border-black'
                  : 'text-white bg-gray-800 border-gray-300'
              } ${page === 'data' && 'ml-6'} sticky top-0`}
            >
              <span
                className={`w-max px-2 py-1 rounded-full cursor-pointer flex items-center `}
                onClick={() => {
                  handleClick(15, notSortList({ isCTR: true }));
                }}
              >
                CTR
                {isSortsArr[15] === 2 ? (
                  <RiArrowDownSFill />
                ) : (
                  isSortsArr[15] === 3 && <RiArrowUpSFill />
                )}
              </span>
            </th>
          ) : null}
          {activeColumns.includes('Search Lost IS Rank (Google)') ? (
            <th
              className={`px-4 ${
                dataCoverage === 'This Mo' ? 'py-3' : 'py-4'
              } title-font tracking-wider font-medium text-sm ${
                lightMode
                  ? 'text-gray-900 bg-gray-300 border-black'
                  : 'text-white bg-gray-800 border-gray-300'
              } ${page === 'data' && 'ml-6'} sticky top-0`}
            >
              <span
                className={`w-max px-2 py-1 rounded-full cursor-pointer flex items-center `}
                onClick={() => {
                  handleClick(16, notSortList({ isSILRankG: true }));
                }}
              >
                Lost IS Rank (Google)
                {isSortsArr[16] === 2 ? (
                  <RiArrowDownSFill />
                ) : (
                  isSortsArr[16] === 3 && <RiArrowUpSFill />
                )}
              </span>
            </th>
          ) : null}
          {activeColumns.includes('Search Lost IS Budget (Google)') ? (
            <th
              className={`px-4  ${
                dataCoverage === 'This Mo' ? 'py-3' : 'py-4'
              } title-font tracking-wider font-medium text-sm ${
                lightMode
                  ? 'text-gray-900 bg-gray-300 border-black'
                  : 'text-white bg-gray-800 border-gray-300'
              } ${page === 'data' && 'ml-6'} sticky top-0`}
            >
              <span
                className={`w-max px-2 py-1 rounded-full cursor-pointer flex items-center `}
                onClick={() => {
                  handleClick(17, notSortList({ isSILBudgetG: true }));
                }}
              >
                Lost IS Budget (Google)
                {isSortsArr[17] === 2 ? (
                  <RiArrowDownSFill />
                ) : (
                  isSortsArr[17] === 3 && <RiArrowUpSFill />
                )}
              </span>
            </th>
          ) : null}
          {activeColumns.includes('Search Lost IS Rank (Microsoft)') ? (
            <th
              className={`px-4  ${
                dataCoverage === 'This Mo' ? 'py-3' : 'py-4'
              } title-font tracking-wider font-medium text-sm ${
                lightMode
                  ? 'text-gray-900 bg-gray-300 border-black'
                  : 'text-white bg-gray-800 border-gray-300'
              } ${page === 'data' && 'ml-6'} sticky top-0`}
            >
              <span
                className={`w-max px-2 py-1 rounded-full cursor-pointer flex items-center `}
                onClick={() => {
                  handleClick(18, notSortList({ isSILRankM: true }));
                }}
              >
                Lost IS Rank (Microsoft)
                {isSortsArr[18] === 2 ? (
                  <RiArrowDownSFill />
                ) : (
                  isSortsArr[18] === 3 && <RiArrowUpSFill />
                )}
              </span>
            </th>
          ) : null}
          {activeColumns.includes('Search Lost IS Budget (Microsoft)') ? (
            <th
              className={`px-4  ${
                dataCoverage === 'This Mo' ? 'py-3' : 'py-4'
              } title-font tracking-wider font-medium text-sm ${
                lightMode
                  ? 'text-gray-900 bg-gray-300 border-black'
                  : 'text-white bg-gray-800 border-gray-300'
              } ${page === 'data' && 'ml-6'} sticky top-0`}
            >
              <span
                className={`w-max px-2 py-1 rounded-full cursor-pointer flex items-center `}
                onClick={() => {
                  handleClick(19, notSortList({ isSILBudgetM: true }));
                }}
              >
                Lost IS Budget (Microsoft)
                {isSortsArr[19] === 2 ? (
                  <RiArrowDownSFill />
                ) : (
                  isSortsArr[19] === 3 && <RiArrowUpSFill />
                )}
              </span>
            </th>
          ) : null}
          {activeColumns.includes('Ads Paid By') ? (
            <th
              className={`px-4  ${
                dataCoverage === 'This Mo' ? 'py-3' : 'py-4'
              } title-font tracking-wider font-medium text-sm ${
                lightMode
                  ? 'text-gray-900 bg-gray-300 border-black'
                  : 'text-white bg-gray-800  border-gray-300'
              } sticky top-0`}
            >
              <span
                className={`w-max px-2 py-1 rounded-full cursor-pointer flex items-center `}
                onClick={() => {
                  handleClick(8, notSortList({ isAdSpend: true }));
                }}
              >
                Ads Paid By
                {isSortsArr[8] === 2 ? (
                  <RiArrowDownSFill />
                ) : (
                  isSortsArr[8] === 3 && <RiArrowUpSFill />
                )}
              </span>
            </th>
          ) : null}
          {activeColumns.includes('State') ? (
            <th
              className={`px-4  ${
                dataCoverage === 'This Mo' ? 'py-3' : 'py-4'
              } title-font tracking-wider font-medium text-sm ${
                lightMode
                  ? 'text-gray-900 bg-gray-300 border-black'
                  : 'text-white bg-gray-800  border-gray-300'
              } sticky top-0`}
            >
              <span
                className={`w-max px-2 py-1 rounded-full cursor-pointer flex items-center `}
                onClick={() => {
                  handleClick(9, notSortList({ isStateCountry: true }));
                }}
              >
                State
                {isSortsArr[9] === 2 ? (
                  <RiArrowDownSFill />
                ) : (
                  isSortsArr[9] === 3 && <RiArrowUpSFill />
                )}
              </span>
            </th>
          ) : null}
          {activeColumns.includes('Account Manager') ? (
            <th
              className={`px-4  ${
                dataCoverage === 'This Mo' ? 'py-3' : 'py-4'
              } title-font tracking-wider font-medium text-sm ${
                lightMode
                  ? 'text-gray-900 bg-gray-300 border-black'
                  : 'text-white bg-gray-800  border-gray-300'
              } sticky top-0`}
            >
              <span
                className={`w-max px-2 py-1 rounded-full cursor-pointer flex items-center `}
                onClick={() => {
                  handleClick(10, notSortList({ isAccountManager: true }));
                }}
              >
                Account Manager
                {isSortsArr[10] === 2 ? (
                  <RiArrowDownSFill className='text-xl' />
                ) : (
                  isSortsArr[10] === 3 && <RiArrowUpSFill className='text-xl' />
                )}
              </span>
            </th>
          ) : null}
          {activeColumns.includes('PPC Manager') ? (
            <th
              className={`px-4  ${
                dataCoverage === 'This Mo' ? 'py-3' : 'py-4'
              } title-font tracking-wider font-medium text-sm ${
                lightMode
                  ? 'text-gray-900 bg-gray-300 border-black'
                  : 'text-white bg-gray-800  border-gray-300'
              } sticky top-0`}
            >
              <span
                className={`w-max px-2 py-1 rounded-full cursor-pointer flex items-center `}
                onClick={() => {
                  handleClick(11, notSortList({ isPPC: true }));
                }}
              >
                PPC Manager
                {isSortsArr[11] === 2 ? (
                  <RiArrowDownSFill className='text-xl' />
                ) : (
                  isSortsArr[11] === 3 && <RiArrowUpSFill className='text-xl' />
                )}
              </span>
            </th>
          ) : null}
          {activeColumns.includes('IDX') ? (
            <th
              className={`px-4  ${
                dataCoverage === 'This Mo' ? 'py-3' : 'py-4'
              } title-font tracking-wider font-medium text-sm ${
                lightMode
                  ? 'text-gray-900 bg-gray-300 border-black'
                  : 'text-white bg-gray-800  border-gray-300'
              } sticky top-0`}
            >
              <span
                className={`w-max px-2 py-1 rounded-full cursor-pointer flex items-center `}
                onClick={() => {
                  handleClick(12, notSortList({ isIdx: true }));
                }}
              >
                IDX
                {isSortsArr[12] === 2 ? (
                  <RiArrowDownSFill />
                ) : (
                  isSortsArr[12] === 3 && <RiArrowUpSFill />
                )}
              </span>
            </th>
          ) : null}
        </tr>
      </thead>
      <tbody>
        {items.map((item) => (
          <DataPageTableData key={item.id} item={item} />
        ))}
      </tbody>
    </table>
  );
};

export default DataPageTable;
