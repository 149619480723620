import LineGraph from '../graph-templates/LineGraph';
import {
  lightModeState,
  accountState,
  accountsState,
  accounts2MonthsAgoState,
  accounts3MonthsAgoState,
  accounts30DaysAgoState,
  userState,
  googleReportsSelectedState,
  microsoftReportsSelectedState,
} from '../../recoil/atoms';
import { adminAccountState } from '../../recoil/selectors/dataSelectors';
import { useRecoilValue } from 'recoil';
import { Account } from '../../helpers/types';

const LeadsDesktopLineGraph = () => {
  const lightMode = useRecoilValue(lightModeState);
  const user = useRecoilValue(userState);
  const account = useRecoilValue(
    user?.role === 'superadmin' ? accountState : adminAccountState
  );
  const accountsLastMonth = useRecoilValue(accountsState);
  const accounts2MonthsAgo = useRecoilValue(accounts2MonthsAgoState);
  const accounts3MonthsAgo = useRecoilValue(accounts3MonthsAgoState);
  const accounts30DaysAgo = useRecoilValue(accounts30DaysAgoState);
  const googleSelected = useRecoilValue(googleReportsSelectedState);
  const microsoftSelect = useRecoilValue(microsoftReportsSelectedState);

  const filterAccount = (accs: Account[]) =>
    accs.filter((acc) => acc.name === account.name);

  const accountLastMonth = filterAccount(accountsLastMonth)[0];
  const account2MonthsAgo = filterAccount(accounts2MonthsAgo)[0];
  const account3MonthsAgo = filterAccount(accounts3MonthsAgo)[0];
  const account30DaysAgo = filterAccount(accounts30DaysAgo)[0];

  const leadsLastMonth = accountLastMonth
    ? (accountLastMonth.desktop && googleSelected
        ? parseFloat(accountLastMonth.desktop.conversions)
        : 0) +
      (accountLastMonth.bing_desktop && microsoftSelect
        ? parseFloat(accountLastMonth.bing_desktop.conversions)
        : 0)
    : 0;
  const leads2MonthsAgo = account2MonthsAgo
    ? (account2MonthsAgo.desktop && googleSelected
        ? parseFloat(account2MonthsAgo.desktop.conversions)
        : 0) +
      (account2MonthsAgo.bing_desktop && microsoftSelect
        ? parseFloat(account2MonthsAgo.bing_desktop.conversions)
        : 0)
    : 0;
  const leads3MonthsAgo = account3MonthsAgo
    ? (account3MonthsAgo.desktop && googleSelected
        ? parseFloat(account3MonthsAgo.desktop.conversions)
        : 0) +
      (account3MonthsAgo.bing_desktop && microsoftSelect
        ? parseFloat(account3MonthsAgo.bing_desktop.conversions)
        : 0)
    : 0;
  const leads30DaysAgo = account30DaysAgo
    ? (account30DaysAgo.desktop && googleSelected
        ? parseFloat(account30DaysAgo.desktop.conversions)
        : 0) +
      (account30DaysAgo.bing_desktop && microsoftSelect
        ? parseFloat(account30DaysAgo.bing_desktop.conversions)
        : 0)
    : 0;

  return (
    <LineGraph
      title='Desktop Leads Graph'
      data1={leadsLastMonth}
      data2={leads2MonthsAgo}
      data3={leads3MonthsAgo}
      data4={leads30DaysAgo}
      backgroundColor={
        lightMode ? 'rgba(255, 161, 140, 0.3)' : 'rgba(59, 13, 2, 0.1)'
      }
      borderColor='rgb(219, 60, 24)'
      pointBorderColor='rgb(219, 60, 24)'
    />
  );
};

export default LeadsDesktopLineGraph;
