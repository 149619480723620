import { AiFillHome } from 'react-icons/ai';
import { Link } from 'react-router-dom';
import { BiPlus, BiSun, BiMoon, BiBookAlt } from 'react-icons/bi';
import { BsPersonCircle } from 'react-icons/bs';
import { RiAsterisk } from 'react-icons/ri';
import { FaChartPie, FaTable } from 'react-icons/fa';
import {
  pageState,
  lightModeState,
  isOpenProfModalState,
  isAuthenticatedState,
  userState,
  isMenuOpenState,
  isOpenNotifBoxState,
  thisMonthAccountsState,
  isOpenNotifPopupState,
} from '../../recoil/atoms';
import {
  useRecoilValue,
  useRecoilState,
  useSetRecoilState,
  type SetterOrUpdater,
} from 'recoil';
import { GiHamburgerMenu } from 'react-icons/gi';
import { VscBellDot, VscBell } from 'react-icons/vsc';

const Navbar = () => {
  const page = useRecoilValue(pageState);
  const [lightMode, setLightMode] = useRecoilState(lightModeState);
  const [isOpenProfModal, setIsOpenProfModal] =
    useRecoilState(isOpenProfModalState);
  const isAuthenticated = useRecoilValue(isAuthenticatedState);
  const [isOpenNotifBox, setIsOpenNotifBox] =
    useRecoilState(isOpenNotifBoxState);
  const [isOpenNotifPopup, setIsOpenNotifPopup] = useRecoilState(
    isOpenNotifPopupState
  );
  const user = useRecoilValue(userState);
  const setIsMenuOpen = useSetRecoilState(isMenuOpenState);
  const thisMonthsAccounts = useRecoilValue(thisMonthAccountsState);
  const accounts = thisMonthsAccounts
    .filter((account) => account.is_active === false)
    .filter(
      (account) =>
        parseFloat(account.google_account?.impressions || '0') > 0 ||
        parseFloat(account.microsoft_account?.impressions || '0') > 0
    )
    .filter((account) => account.ppc_specialist === user?.displayName);

  const toggleModal = (
    isOpen: boolean,
    setIsOpenPrimary: SetterOrUpdater<boolean>,
    setIsOpenSecondary: SetterOrUpdater<boolean>
  ) => {
    if (!isOpen) setIsOpenSecondary(false);
    setIsOpenPrimary(!isOpen);
    setIsOpenNotifPopup(false);
  };
  return (
    <div
      className={`sm:min-h-screen fixed left-0 flex sm:flex-col justify-between items-center sm:border-r-2 ${
        lightMode
          ? 'bg-gray-100 sm:bg-white border-gray-200'
          : 'bg-gray-900 sm:bg-gray-800 border-gray-700'
      } py-3 px-3 z-50 navb`}
    >
      <div className='text-5xl'>
        <Link to='/'>
          <RiAsterisk
            className={`${lightMode ? 'text-gray-700' : 'text-gray-50'}`}
          />
        </Link>
      </div>
      <div
        className={`${
          isAuthenticated ? 'hidden sm:flex' : 'hidden'
        } flex-col justify-between items-center gap-y-6 text-2xl `}
      >
        <Link
          to='/'
          className={`text-center group ${
            lightMode ? 'hover:bg-red-400' : 'hover:bg-red-600'
          } p-2 transition duration-300 rounded-sm ${
            page === 'home' ? (lightMode ? 'bg-red-400' : 'bg-red-600') : ''
          }`}
          title='Home'
        >
          <AiFillHome
            className={`group-hover:text-white ${
              page === 'home'
                ? 'text-white'
                : lightMode
                ? 'text-red-400'
                : 'text-red-600'
            } transition duration-300`}
          />
        </Link>
        <Link
          to='/bms'
          className={`text-center group ${
            lightMode ? 'hover:bg-red-400' : 'hover:bg-red-600'
          } p-2 transition duration-300 rounded-sm ${
            page === 'data' ? (lightMode ? 'bg-red-400' : 'bg-red-600') : ''
          } ${user?.role === 'admin' ? 'hidden' : ''}`}
          title='BMS'
        >
          <FaTable
            className={`group-hover:text-white ${
              page === 'data'
                ? 'text-white'
                : lightMode
                ? 'text-red-400'
                : 'text-red-600'
            } transition duration-300`}
          />
        </Link>
        <Link
          to='/reports'
          className={`text-center group ${
            lightMode ? 'hover:bg-red-400' : 'hover:bg-red-600'
          } p-2 transition duration-300 rounded-sm ${
            page === 'reports' ? (lightMode ? 'bg-red-400' : 'bg-red-600') : ''
          }`}
          title='Reports'
        >
          <FaChartPie
            className={`group-hover:text-white ${
              page === 'reports'
                ? 'text-white'
                : lightMode
                ? 'text-red-400'
                : 'text-red-600'
            } transition duration-300`}
          />
        </Link>
        <Link
          to='/glossary'
          className={`text-center group ${
            lightMode ? 'hover:bg-red-400' : 'hover:bg-red-600'
          } p-2 transition duration-300 rounded-sm ${
            page === 'glossary' ? (lightMode ? 'bg-red-400' : 'bg-red-600') : ''
          }`}
          title='Glossary'
        >
          <BiBookAlt
            className={`group-hover:text-white ${
              page === 'glossary'
                ? 'text-white'
                : lightMode
                ? 'text-red-400'
                : 'text-red-600'
            } transition duration-300`}
          />
        </Link>
      </div>
      <div className='sm:flex hidden flex-col items-center'>
        {lightMode ? (
          <BiMoon
            className={`${
              lightMode ? 'text-gray-800' : 'text-gray-300'
            } cursor-pointer ${isAuthenticated ? 'mb-2' : ''}`}
            onClick={() => setLightMode(false)}
            title='Dark Mode'
          />
        ) : (
          <BiSun
            className={`${
              lightMode ? 'text-gray-800' : 'text-gray-300'
            } cursor-pointer ${isAuthenticated ? 'mb-2' : ''}`}
            onClick={() => setLightMode(true)}
            title='Light Mode'
          />
        )}
        <Link
          to='/adduser'
          className={`text-center p-2 ${isAuthenticated ? '' : 'hidden'}`}
        >
          <BiPlus
            className={`${lightMode ? 'text-gray-800' : 'text-gray-300'}`}
          />
        </Link>
        <div
          className={`p-2.5 rounded ${
            isOpenNotifBox || (isOpenNotifPopup && accounts.length > 0)
              ? lightMode
                ? 'bg-gray-300'
                : 'bg-gray-900'
              : ''
          } ${isAuthenticated && user?.role === 'superadmin' ? '' : 'hidden'}`}
          onClick={() =>
            toggleModal(isOpenNotifBox, setIsOpenNotifBox, setIsOpenProfModal)
          }
        >
          {accounts.length > 0 ? (
            <VscBellDot
              className={`${
                lightMode ? 'text-red-600' : 'text-yellow-600'
              } cursor-pointer
            `}
            />
          ) : (
            <VscBell
              className={`${
                lightMode ? 'text-gray-800' : 'text-gray-300'
              } cursor-pointer
            `}
            />
          )}
        </div>
        <div
          className={`p-2.5 rounded ${
            isOpenProfModal ? (lightMode ? 'bg-gray-300' : 'bg-gray-900') : ''
          } ${isAuthenticated ? '' : 'hidden'}`}
        >
          <BsPersonCircle
            className={`${
              lightMode ? 'text-gray-800' : 'text-gray-300'
            } cursor-pointer
            `}
            onClick={() =>
              toggleModal(
                isOpenProfModal,
                setIsOpenProfModal,
                setIsOpenNotifBox
              )
            }
          />
        </div>
      </div>
      {isAuthenticated ? (
        <div
          className={`sm:hidden ${
            lightMode ? 'text-gray-700' : 'text-gray-50'
          } text-3xl`}
          onClick={() => setIsMenuOpen(true)}
        >
          <GiHamburgerMenu />
        </div>
      ) : lightMode ? (
        <BiMoon
          className={`${
            lightMode ? 'text-gray-800' : 'text-gray-300'
          } cursor-pointer ${isAuthenticated ? 'mb-2' : ''} sm:hidden`}
          onClick={() => setLightMode(false)}
          title='Dark Mode'
        />
      ) : (
        <BiSun
          className={`${
            lightMode ? 'text-gray-800' : 'text-gray-300'
          } cursor-pointer ${isAuthenticated ? 'mb-2' : ''} sm:hidden`}
          onClick={() => setLightMode(true)}
          title='Light Mode'
        />
      )}
    </div>
  );
};

export default Navbar;
