import React, { useState } from 'react';
import Title from '../universal-decorations/Title';
import DataPageTable from '../tables/DataPageTable';
import { useRecoilValue, useRecoilState } from 'recoil';
import {
  isLoadingDataState,
  isEditingColumsState,
  platformOptionState,
  searchState,
} from '../../recoil/atoms';
import TablePagination from '../table-comps/TablePagination';
import LoadAnimation from '../universal-decorations/LoadAnimation';
import { getValue, getPace } from '../../helpers/utils';
import BMSCoverage from '../date-related/BMSCoverage';
import { Account } from '../../helpers/types';
import CustomColumnSelect from '../table-comps/CustomColumnSelect';
import ToolbeltBar from '../table-comps/ToolbeltBar';

type Props = {
  items: Account[];
};

const DataPageComponent = ({ items }: Props) => {
  const platformOptions = useRecoilValue(platformOptionState);

  const isLoadingData = useRecoilValue(isLoadingDataState);
  const isEditingColumn = useRecoilValue(isEditingColumsState);
  const [pageNum, setPageNum] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [search, setSearch] = useRecoilState(searchState);

  const [isSortName, setIsSortName] = useState(1);
  const [isSortEmail, setIsSortEmail] = useState(1);
  const [isSortBudget, setIsSortBudget] = useState(1);
  const [isSortRemainingBudget, setIsSortRemainingBudget] = useState(1);
  const [isSortPace, setIsSortPace] = useState(1);
  const [isSortCost, setIsSortCost] = useState(1);
  const [isSortConversions, setIsSortConversions] = useState(1);
  const [isSortCPL, setIsSortCPL] = useState(1);
  const [isSortAdSpend, setIsSortAdSpend] = useState(1);
  const [isSortStateCountry, setIsSortStateCountry] = useState(1);
  const [isSortAccountManager, setIsSortAccountManager] = useState(1);
  const [isSortPPCSpecialist, setIsSortPPCSpecialist] = useState(1);
  const [isSortAdVar] = useState(1);
  const [isSortIdx, setIsSortIdx] = useState(1);
  const [isSortImpressions, setIsSortImpressions] = useState(1);
  const [isSortClicks, setIsSortClicks] = useState(1);
  const [isSortCTR, setIsSortCTR] = useState(1);
  const [isSortSILRankG, setIsSortSILRankG] = useState(1);
  const [isSortSILBudgetG, setIsSortSILBudgetG] = useState(1);
  const [isSortSILRankM, setIsSortSILRankM] = useState(1);
  const [isSortSILBudgetM, setIsSortSILBudgetM] = useState(1);

  const setters = [
    setIsSortName,
    setIsSortEmail,
    setIsSortBudget,
    setIsSortCost,
    setIsSortRemainingBudget,
    setIsSortPace,
    setIsSortCPL,
    setIsSortConversions,
    setIsSortAdSpend,
    setIsSortStateCountry,
    setIsSortAccountManager,
    setIsSortPPCSpecialist,
    setIsSortIdx,
    setIsSortImpressions,
    setIsSortClicks,
    setIsSortCTR,
    setIsSortSILRankG,
    setIsSortSILBudgetG,
    setIsSortSILRankM,
    setIsSortSILBudgetM,
  ];

  const isValues = [
    isSortName,
    isSortEmail,
    isSortBudget,
    isSortCost,
    isSortRemainingBudget,
    isSortPace,
    isSortCPL,
    isSortConversions,
    isSortAdSpend,
    isSortStateCountry,
    isSortAccountManager,
    isSortPPCSpecialist,
    isSortIdx,
    isSortImpressions,
    isSortClicks,
    isSortCTR,
    isSortSILRankG,
    isSortSILBudgetG,
    isSortSILRankM,
    isSortSILBudgetM,
  ];

  let filteredItems = items.filter(
    (item) =>
      item.name.toLowerCase().includes(search.toLowerCase()) ||
      item.ppc_specialist.toLowerCase().includes(search.toLowerCase()) ||
      item.account_manager.toLowerCase().includes(search.toLowerCase()) ||
      item.email?.toLowerCase().includes(search.toLowerCase())
  );

  const getVal = (
    parent: Account,
    value:
      | 'spend'
      | 'conversions'
      | 'cost_per_conversion'
      | 'impressions'
      | 'clicks'
      | 'ctr'
  ) =>
    getValue(
      parent,
      value,
      platformOptions['Google'],
      platformOptions['Microsoft'],
      platformOptions['Manually Added']
    );

  const getPacing = (account: Account) => getPace(account, getVal);

  const isStringSorter = (isSort: number) => {
    isSort === 2
      ? filteredItems.sort((a, b) => {
          const aSort =
            isSort === isSortName
              ? a.google_account
                ? a.google_account.name
                : a.name
              : isSort === isSortEmail
              ? a.email === 'nan' || a.email === 'your@email.com'
                ? 'TBD'
                : a.email
              : isSort === isSortAdSpend
              ? a.ad_spend
              : isSort === isSortStateCountry
              ? a.state_country
              : isSort === isSortAccountManager
              ? a.account_manager
              : isSort === isSortAdVar
              ? a.ad_var
              : isSort === isSortPPCSpecialist
              ? a.ppc_specialist
              : a.idx;

          const bSort =
            isSort === isSortName
              ? b.google_account
                ? b.google_account.name
                : b.name
              : isSort === isSortEmail
              ? b.email === 'nan' || b.email === 'your@email.com'
                ? 'TBD'
                : b.email
              : isSort === isSortAdSpend
              ? b.ad_spend
              : isSort === isSortStateCountry
              ? b.state_country
              : isSort === isSortAccountManager
              ? b.account_manager
              : isSort === isSortAdVar
              ? b.ad_var
              : isSort === isSortPPCSpecialist
              ? b.ppc_specialist
              : b.idx;

          if (aSort && bSort) {
            if (aSort.toLowerCase() > bSort.toLowerCase()) return -1;
            if (aSort.toLowerCase() < bSort.toLowerCase()) return 1;
          }
          return 0;
        })
      : isSort === 3 &&
        filteredItems.sort((a, b) => {
          const aSort =
            isSort === isSortName
              ? a.google_account
                ? a.google_account.name
                : a.name
              : isSort === isSortEmail
              ? a.email === 'nan' || a.email === 'your@email.com'
                ? 'TBD'
                : a.email
              : isSort === isSortAdSpend
              ? a.ad_spend
              : isSort === isSortStateCountry
              ? a.state_country
              : isSort === isSortAccountManager
              ? a.account_manager
              : isSort === isSortAdVar
              ? a.ad_var
              : isSort === isSortPPCSpecialist
              ? a.ppc_specialist
              : a.idx;

          const bSort =
            isSort === isSortName
              ? b.google_account
                ? b.google_account.name
                : b.name
              : isSort === isSortEmail
              ? b.email === 'nan' || b.email === 'your@email.com'
                ? 'TBD'
                : b.email
              : isSort === isSortAdSpend
              ? b.ad_spend
              : isSort === isSortStateCountry
              ? b.state_country
              : isSort === isSortAccountManager
              ? b.account_manager
              : isSort === isSortAdVar
              ? b.ad_var
              : isSort === isSortPPCSpecialist
              ? b.ppc_specialist
              : b.idx;

          if (aSort && bSort) {
            if (aSort.toLowerCase() > bSort.toLowerCase()) return 1;
            if (aSort.toLowerCase() < bSort.toLowerCase()) return -1;
          }
          return 0;
        });
  };

  const isFloatSorter = (isSort: number) => {
    isSort === 2
      ? filteredItems.sort((a, b) => {
          const aSort =
            isSort === isSortBudget
              ? parseFloat(a.budget ? a.budget : '')
              : isSort === isSortRemainingBudget
              ? parseFloat(a.budget ? a.budget : '') - getVal(a, 'spend')
              : isSort === isSortPace
              ? getPacing(a)
              : isSort === isSortCost
              ? getVal(a, 'spend')
              : isSort === isSortConversions
              ? getVal(a, 'conversions')
              : isSort === isSortCPL
              ? getVal(a, 'cost_per_conversion')
              : isSort === isSortImpressions
              ? getVal(a, 'impressions')
              : isSort === isSortClicks
              ? getVal(a, 'clicks')
              : isSort === isSortCTR
              ? getVal(a, 'ctr')
              : isSort === isSortSILRankG
              ? parseFloat(
                  a.google_account?.search_rank_lost_impression_share || '0'
                )
              : isSort === isSortSILBudgetG
              ? parseFloat(
                  a.google_account?.search_budget_lost_impression_share || '0'
                )
              : isSort === isSortSILRankM
              ? parseFloat(
                  a.microsoft_account?.search_rank_lost_impression_share || '0'
                )
              : parseFloat(
                  a.microsoft_account?.search_budget_lost_impression_share ||
                    '0'
                );

          const bSort =
            isSort === isSortBudget
              ? parseFloat(b.budget ? b.budget : '')
              : isSort === isSortRemainingBudget
              ? parseFloat(b.budget ? b.budget : '') - getVal(b, 'spend')
              : isSort === isSortPace
              ? getPacing(b)
              : isSort === isSortCost
              ? getVal(b, 'spend')
              : isSort === isSortConversions
              ? getVal(b, 'conversions')
              : isSort === isSortCPL
              ? getVal(b, 'cost_per_conversion')
              : isSort === isSortImpressions
              ? getVal(b, 'impressions')
              : isSort === isSortClicks
              ? getVal(b, 'clicks')
              : isSort === isSortCTR
              ? getVal(b, 'ctr')
              : isSort === isSortSILRankG
              ? parseFloat(
                  b.google_account?.search_rank_lost_impression_share || '0'
                )
              : isSort === isSortSILBudgetG
              ? parseFloat(
                  b.google_account?.search_budget_lost_impression_share || '0'
                )
              : isSort === isSortSILRankM
              ? parseFloat(
                  b.microsoft_account?.search_rank_lost_impression_share || '0'
                )
              : parseFloat(
                  b.microsoft_account?.search_budget_lost_impression_share ||
                    '0'
                );

          return bSort - aSort;
        })
      : isSort === 3 &&
        filteredItems.sort((a, b) => {
          const aSort =
            isSort === isSortBudget
              ? parseFloat(a.budget ? a.budget : '')
              : isSort === isSortRemainingBudget
              ? parseFloat(a.budget ? a.budget : '') - getVal(a, 'spend')
              : isSort === isSortPace
              ? getPacing(a)
              : isSort === isSortCost
              ? getVal(a, 'spend')
              : isSort === isSortConversions
              ? getVal(a, 'conversions')
              : isSort === isSortCPL
              ? getVal(a, 'cost_per_conversion')
              : isSort === isSortImpressions
              ? getVal(a, 'impressions')
              : isSort === isSortClicks
              ? getVal(a, 'clicks')
              : isSort === isSortCTR
              ? getVal(a, 'ctr')
              : isSort === isSortSILRankG
              ? parseFloat(
                  a.google_account?.search_rank_lost_impression_share || '0'
                )
              : isSort === isSortSILBudgetG
              ? parseFloat(
                  a.google_account?.search_budget_lost_impression_share || '0'
                )
              : isSort === isSortSILRankM
              ? parseFloat(
                  a.microsoft_account?.search_rank_lost_impression_share || '0'
                )
              : parseFloat(
                  a.microsoft_account?.search_budget_lost_impression_share ||
                    '0'
                );

          const bSort =
            isSort === isSortBudget
              ? parseFloat(b.budget ? b.budget : '')
              : isSort === isSortRemainingBudget
              ? parseFloat(b.budget ? b.budget : '') - getVal(b, 'spend')
              : isSort === isSortPace
              ? getPacing(b)
              : isSort === isSortCost
              ? getVal(b, 'spend')
              : isSort === isSortConversions
              ? getVal(b, 'conversions')
              : isSort === isSortCPL
              ? getVal(b, 'cost_per_conversion')
              : isSort === isSortImpressions
              ? getVal(b, 'impressions')
              : isSort === isSortClicks
              ? getVal(b, 'clicks')
              : isSort === isSortCTR
              ? getVal(b, 'ctr')
              : isSort === isSortSILRankG
              ? parseFloat(
                  b.google_account?.search_rank_lost_impression_share || '0'
                )
              : isSort === isSortSILBudgetG
              ? parseFloat(
                  b.google_account?.search_budget_lost_impression_share || '0'
                )
              : isSort === isSortSILRankM
              ? parseFloat(
                  b.microsoft_account?.search_rank_lost_impression_share || '0'
                )
              : parseFloat(
                  b.microsoft_account?.search_budget_lost_impression_share ||
                    '0'
                );

          return aSort - bSort;
        });
  };
  [...isValues.slice(0, 2), ...isValues.slice(8, 13)].forEach((isSort) =>
    isStringSorter(isSort)
  );

  [...isValues.slice(2, 8), ...isValues.slice(13)].forEach((isSort) =>
    isFloatSorter(isSort)
  );

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number
  ) => {
    setPageNum(newPage);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPageNum(0);
    setSearch(e.target.value);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPageNum(0);
  };

  interface notSortOptions {
    isCPL?: boolean;
    isName?: boolean;
    isEmail?: boolean;
    isPace?: boolean;
    isRemainingBudget?: boolean;
    isConversions?: boolean;
    isBudget?: boolean;
    isCost?: boolean;
    isAdSpend?: boolean;
    isStateCountry?: boolean;
    isAccountManager?: boolean;
    isPPC?: boolean;
    isIdx?: boolean;
    isImpressions?: boolean;
    isClicks?: boolean;
    isCTR?: boolean;
    isSILRankG?: boolean;
    isSILBudgetG?: boolean;
    isSILRankM?: boolean;
    isSILBudgetM?: boolean;
  }

  const notSortList = (options: notSortOptions) => {
    const list = [];
    if (!options.isCPL) list.push(setIsSortCPL);
    if (!options.isName) list.push(setIsSortName);
    if (!options.isEmail) list.push(setIsSortEmail);
    if (!options.isPace) list.push(setIsSortPace);
    if (!options.isRemainingBudget) list.push(setIsSortRemainingBudget);
    if (!options.isConversions) list.push(setIsSortConversions);
    if (!options.isBudget) list.push(setIsSortBudget);
    if (!options.isCost) list.push(setIsSortCost);
    if (!options.isAdSpend) list.push(setIsSortAdSpend);
    if (!options.isStateCountry) list.push(setIsSortStateCountry);
    if (!options.isAccountManager) list.push(setIsSortAccountManager);
    if (!options.isPPC) list.push(setIsSortPPCSpecialist);
    if (!options.isIdx) list.push(setIsSortIdx);
    if (!options.isImpressions) list.push(setIsSortImpressions);
    if (!options.isClicks) list.push(setIsSortClicks);
    if (!options.isCTR) list.push(setIsSortCTR);
    if (!options.isSILRankG) list.push(setIsSortSILRankG);
    if (!options.isSILBudgetG) list.push(setIsSortSILBudgetG);
    if (!options.isSILRankM) list.push(setIsSortSILRankM);
    if (!options.isSILBudgetM) list.push(setIsSortSILBudgetM);
    return list;
  };

  const handleNotSort = ([
    setNotSort1,
    setNotSort2,
    setNotSort3,
    setNotSort4,
    setNotSort5,
    setNotSort6,
    setNotSort7,
    setNotSort8,
    setNotSort9,
    setNotSort10,
    setNotSort11,
    setNotSort12,
    setNotSort13,
    setNotSort14,
    setNotSort15,
    setNotSort16,
    setNotSort17,
    setNotSort18,
    setNotSort19,
  ]: React.Dispatch<React.SetStateAction<number>>[]) => {
    setNotSort1(1);
    setNotSort2(1);
    setNotSort3(1);
    setNotSort4(1);
    setNotSort5(1);
    setNotSort6(1);
    setNotSort7(1);
    setNotSort8(1);
    setNotSort9(1);
    setNotSort10(1);
    setNotSort11(1);
    setNotSort12(1);
    setNotSort13(1);
    setNotSort14(1);
    setNotSort15(1);
    setNotSort16(1);
    setNotSort17(1);
    setNotSort18(1);
    setNotSort19(1);
  };

  const handleSort = (
    setMainSort: React.Dispatch<React.SetStateAction<number>>,
    mainSort: number
  ) => {
    setMainSort(mainSort < 3 ? mainSort + 1 : 1);
    setPageNum(0);
  };

  return (
    <section className='text-gray-400 body-font h-full'>
      <div className={` mx-auto`}>
        <div className='flex flex-col text-center w-full'>
          <div className='grid grid-cols-3 content-center'>
            <div className=' my-auto sm:ml-20 min-w-full'>
              <BMSCoverage />
            </div>
            <Title title={'Budget Monitoring Sheet'} />
            {/* <CoverageTemplate_ coverage='30-Day' /> */}
          </div>
        </div>
        {isEditingColumn && <CustomColumnSelect />}
        <div>
          <div className='sticky top-0'>
            <ToolbeltBar
              handleChange={handleChange}
              tablePagination={
                <TablePagination
                  items={filteredItems}
                  rowsPerPage={rowsPerPage}
                  page={pageNum}
                  handleChangePage={handleChangePage}
                  handleChangeRowsPerPage={handleChangeRowsPerPage}
                />
              }
            />

            <div className='flex flex-col w-full h-screen-7.5/10 top-96'>
              <div
                className={`overflow-x-scroll h-3/4 overflow-y-overlay ${
                  !isLoadingData && 'bms-table'
                }`}
              >
                {isLoadingData ? (
                  <div className='mt-32'>
                    <LoadAnimation />
                  </div>
                ) : (
                  <DataPageTable
                    items={filteredItems.slice(
                      pageNum * rowsPerPage,
                      pageNum * rowsPerPage + rowsPerPage
                    )}
                    handleSort={handleSort}
                    handleNotSort={handleNotSort}
                    notSortList={notSortList}
                    setters={setters}
                    isSortsArr={isValues}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default DataPageComponent;
