import LineGraph from '../graph-templates/LineGraph';
import {
  lightModeState,
  accountState,
  accountsState,
  accounts2MonthsAgoState,
  accounts3MonthsAgoState,
  accounts30DaysAgoState,
  userState,
  googleReportsSelectedState,
  microsoftReportsSelectedState,
} from '../../recoil/atoms';
import { adminAccountState } from '../../recoil/selectors/dataSelectors';
import { useRecoilValue } from 'recoil';
import { Account } from '../../helpers/types';

const LeadsTabletLineGraph = () => {
  const lightMode = useRecoilValue(lightModeState);
  const user = useRecoilValue(userState);
  const account = useRecoilValue(
    user?.role === 'superadmin' ? accountState : adminAccountState
  );
  const accountsLastMonth = useRecoilValue(accountsState);
  const accounts2MonthsAgo = useRecoilValue(accounts2MonthsAgoState);
  const accounts3MonthsAgo = useRecoilValue(accounts3MonthsAgoState);
  const accounts30DaysAgo = useRecoilValue(accounts30DaysAgoState);
  const googleSelected = useRecoilValue(googleReportsSelectedState);
  const microsoftSelect = useRecoilValue(microsoftReportsSelectedState);

  const filterAccount = (accs: Account[]) =>
    accs.filter((acc) => acc.name === account.name);

  const accountLastMonth = filterAccount(accountsLastMonth)[0];
  const account2MonthsAgo = filterAccount(accounts2MonthsAgo)[0];
  const account3MonthsAgo = filterAccount(accounts3MonthsAgo)[0];
  const account30DaysAgo = filterAccount(accounts30DaysAgo)[0];

  const bingLeadsLastMonth = accountLastMonth
    ? (accountLastMonth.microsoft_account && microsoftSelect
        ? parseFloat(accountLastMonth.microsoft_account.conversions)
        : 0) -
      ((accountLastMonth.bing_mobile && microsoftSelect
        ? parseFloat(accountLastMonth.bing_mobile.conversions)
        : 0) +
        (accountLastMonth.bing_desktop && microsoftSelect
          ? parseFloat(accountLastMonth.bing_desktop.conversions)
          : 0))
    : 0;
  const bingLeads2MonthsAgo = account2MonthsAgo
    ? (account2MonthsAgo.microsoft_account && microsoftSelect
        ? parseFloat(account2MonthsAgo.microsoft_account.conversions)
        : 0) -
      ((account2MonthsAgo.bing_mobile && microsoftSelect
        ? parseFloat(account2MonthsAgo.bing_mobile.conversions)
        : 0) +
        (account2MonthsAgo.bing_desktop && microsoftSelect
          ? parseFloat(account2MonthsAgo.bing_desktop.conversions)
          : 0))
    : 0;
  const bingLeads3MonthsAgo = account3MonthsAgo
    ? (account3MonthsAgo.microsoft_account && microsoftSelect
        ? parseFloat(account3MonthsAgo.microsoft_account.conversions)
        : 0) -
      ((account3MonthsAgo.bing_mobile && microsoftSelect
        ? parseFloat(account3MonthsAgo.bing_mobile.conversions)
        : 0) +
        (account3MonthsAgo.bing_desktop && microsoftSelect
          ? parseFloat(account3MonthsAgo.bing_desktop.conversions)
          : 0))
    : 0;

  const bingLeads30DaysAgo = account30DaysAgo
    ? (account30DaysAgo.microsoft_account && microsoftSelect
        ? parseFloat(account30DaysAgo.microsoft_account.conversions)
        : 0) -
      ((account30DaysAgo.bing_mobile && microsoftSelect
        ? parseFloat(account30DaysAgo.bing_mobile.conversions)
        : 0) +
        (account30DaysAgo.bing_desktop && microsoftSelect
          ? parseFloat(account30DaysAgo.bing_desktop.conversions)
          : 0))
    : 0;

  const leadsLastMonth = accountLastMonth
    ? (accountLastMonth.tablet && googleSelected
        ? parseFloat(accountLastMonth.tablet.conversions)
        : 0) +
      (microsoftSelect && bingLeadsLastMonth > 0 ? bingLeadsLastMonth : 0)
    : 0;
  const leads2MonthsAgo = account2MonthsAgo
    ? (account2MonthsAgo.tablet && googleSelected
        ? parseFloat(account2MonthsAgo.tablet.conversions)
        : 0) +
      (microsoftSelect && bingLeads2MonthsAgo > 0 ? bingLeads2MonthsAgo : 0)
    : 0;
  const leads3MonthsAgo = account3MonthsAgo
    ? (account3MonthsAgo.tablet && googleSelected
        ? parseFloat(account3MonthsAgo.tablet.conversions)
        : 0) +
      (microsoftSelect && bingLeads3MonthsAgo > 0 ? bingLeads3MonthsAgo : 0)
    : 0;
  const leads30DaysAgo = account30DaysAgo
    ? (account30DaysAgo.mobile && googleSelected
        ? parseFloat(account30DaysAgo.mobile.conversions)
        : 0) +
      (microsoftSelect && bingLeads30DaysAgo > 0 ? bingLeads30DaysAgo : 0)
    : 0;

  return (
    <LineGraph
      title='Tablet Leads Graph'
      data1={leadsLastMonth}
      data2={leads2MonthsAgo}
      data3={leads3MonthsAgo}
      data4={leads30DaysAgo}
      backgroundColor={
        lightMode ? 'rgba(172, 204, 171, 0.3)' : 'rgba(4, 51, 3, 0.1)'
      }
      borderColor='rgb(18, 153, 15)'
      pointBorderColor='rgb(18, 153, 15)'
    />
  );
};

export default LeadsTabletLineGraph;
