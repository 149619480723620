import Layout from '../components/layout/Layout';
import { pageState } from '../recoil/atoms';
import { useSetRecoilState } from 'recoil';
import FeedbackForm from '../components/forms/non-modal/FeedbackForm';
import Title from '../components/universal-decorations/Title';
import { useEffect } from 'react';

const Feedback = () => {
  const setPage = useSetRecoilState(pageState);

  useEffect(() => {
    setPage('feedback');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Layout>
      <div className='mt-10'>
        <Title title='Send Feedback' />
        <FeedbackForm />
      </div>
    </Layout>
  );
};

export default Feedback;
