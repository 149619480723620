import React, { useState } from 'react';
import { GoPlus } from 'react-icons/go';
import { TiMinus } from 'react-icons/ti';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import {
  activeColumnState,
  dataCoverageState,
  isEditingColumsState,
  lightModeState,
  thisMoActiveColumnsState,
  thisMoInactiveColumnState,
  inactiveColumnState,
} from '../../recoil/atoms';

const CustomColumnSelect = () => {
  const lightMode = useRecoilValue(lightModeState);
  const setIsEditingColumn = useSetRecoilState(isEditingColumsState);
  const dataCoverage = useRecoilValue(dataCoverageState);
  const [activeColumns, setActiveColumns] = useRecoilState(
    dataCoverage === 'This Mo' ? thisMoActiveColumnsState : activeColumnState
  );
  const [inactiveColumns, setInactiveColumns] = useRecoilState(
    dataCoverage === 'This Mo' ? thisMoInactiveColumnState : inactiveColumnState
  );
  const [activeCols, setActiveCols] = useState(activeColumns);
  const [inactiveCols, setInactiveCols] = useState(inactiveColumns);

  const addSubCols = (
    col: string,
    setCols: React.Dispatch<React.SetStateAction<string[]>>,
    setSubCols: React.Dispatch<React.SetStateAction<string[]>>
  ) => {
    setCols((c) => c.filter((colm) => colm !== col));
    setSubCols((c) => [...c, col]);
  };

  const applyCols = () => {
    setActiveColumns(activeCols);
    setInactiveColumns(inactiveCols);
    setIsEditingColumn((isEditing) => !isEditing);
  };

  return (
    <div
      className={`w-full sm:w-5/12 font-semibold rounded relative sm:ml-5 ${
        lightMode
          ? 'border-r border-l border-t border-b border-gray-200 bg-gray-50 text-gray-900'
          : 'bg-gray-800 text-white'
      }`}
    >
      <div className={`grid grid-cols-2 relative bottom-0   px-2 `}>
        <div className='px-5 py-10'>
          <h1 className='font-semibold text-2xl mb-4'>Modify Columns</h1>
          <ul>
            {inactiveCols.map((col) => (
              <li
                key={col}
                className='flex items-center gap-x-5 mb-3 text-xs sm:text-base'
                onClick={() => addSubCols(col, setInactiveCols, setActiveCols)}
              >
                <GoPlus
                  className={`${
                    lightMode
                      ? 'text-blue-500 hover:text-blue-700'
                      : 'text-blue-500 hover:text-blue-400'
                  } transition duration-300 cursor-pointer font-bold`}
                />{' '}
                {col}
              </li>
            ))}
          </ul>
        </div>
        <div className='px-5 py-10'>
          <h1 className='font-semibold text-2xl mb-4'>Your Columns</h1>

          <ul>
            {activeCols.map((col) => (
              <li
                key={col}
                className='flex items-center gap-x-5 mb-3 text-xs sm:text-base'
                onClick={() => addSubCols(col, setActiveCols, setInactiveCols)}
              >
                <TiMinus
                  className={`${
                    lightMode
                      ? 'text-blue-500 hover:text-blue-700'
                      : 'text-blue-500 hover:text-blue-400'
                  } transition duration-300 cursor-pointer font-bold`}
                />{' '}
                {col}
              </li>
            ))}
          </ul>
        </div>
      </div>
      <div
        className={`py-4 flex items-center gap-x-5 border-t-2 mx-auto ${
          lightMode ? 'border-gray-300' : 'border-gray-700'
        }`}
      >
        <button
          className={`ml-3 px-3 py-2 rounded transition duration-300 text-white  border-l border-r border-t border-b ${
            lightMode
              ? 'bg-indigo-400 hover:bg-indigo-500 border-indigo-400 hover:border-indigo-500'
              : 'bg-red-600 hover:bg-red-500 border-red-600 hover:border-red-500'
          } font-semibold`}
          onClick={applyCols}
        >
          Apply
        </button>
        <button
          className={`px-3 py-2 rounded border-l border-r border-t border-b transition duration-300 font-semibold ${
            lightMode
              ? 'border-gray-700 hover:bg-gray-300'
              : 'border-white hover:bg-gray-700'
          }`}
          onClick={() => setIsEditingColumn((isEditing) => !isEditing)}
        >
          Cancel
        </button>
      </div>
    </div>
  );
};

export default CustomColumnSelect;
