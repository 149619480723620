import { Account } from './types';

// This converts any thousand or more number into a commaed version of the number
export const Commaed = (x: number | string) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

// This checks whether the string is numeric
export const isNumeric = (n: string) => {
  return !isNaN(parseFloat(n)) && isFinite(parseFloat(n));
};

// This helps you compute the combined total of all your non-cpl numeric data
const getNonCPLval = (
  parent: Account,
  value:
    | 'spend'
    | 'conversions'
    | 'cost_per_conversion'
    | 'impressions'
    | 'clicks'
    | 'ctr',
  googleSelected: boolean,
  microsoftSelected: boolean,
  adSelected?: boolean
) => {
  let nonCPLval =
    parent.google_account &&
    parent.microsoft_account &&
    googleSelected &&
    microsoftSelected
      ? parseFloat(parent.google_account[value]) +
        parseFloat(parent.microsoft_account[value])
      : parent.google_account && googleSelected
      ? parseFloat(parent.google_account[value])
      : parent.microsoft_account && microsoftSelected
      ? parseFloat(parent.microsoft_account[value])
      : 0;
  const adVal =
    parent.additional_data &&
    (value === 'spend' || value === 'conversions') &&
    parent.additional_data[value];
  return adSelected &&
    parent.additional_data &&
    (value === 'spend' || value === 'conversions')
    ? nonCPLval + (adVal ? parseFloat(adVal) : 0)
    : nonCPLval;
};

// This helps you compute all the main values found in bms user location and search terms/keywords
export const getValue = (
  parent: Account,
  value:
    | 'spend'
    | 'conversions'
    | 'cost_per_conversion'
    | 'impressions'
    | 'clicks'
    | 'ctr',
  googleSelected: boolean,
  microsoftSelected: boolean,
  adSelected?: boolean
) => {
  let cost = getNonCPLval(
    parent,
    'spend',
    googleSelected,
    microsoftSelected,
    adSelected
  );
  let conversions = getNonCPLval(
    parent,
    'conversions',
    googleSelected,
    microsoftSelected,
    adSelected
  );
  let clicks = getNonCPLval(
    parent,
    'clicks',
    googleSelected,
    microsoftSelected,
    adSelected
  );
  let impressions = getNonCPLval(
    parent,
    'impressions',
    googleSelected,
    microsoftSelected,
    adSelected
  );

  if (value === 'spend') return cost;
  if (value === 'conversions') return conversions;

  if (value === 'clicks') return clicks;
  if (value === 'impressions') return impressions;

  if (impressions === 0 && value === 'ctr') return 0;
  if (value === 'ctr') return (clicks / impressions) * 100;

  if (conversions === 0) return 0;
  return cost / conversions;
};

// This computes the pacing of the accounts (This Month) in the BMS
export const getPace = (
  account: Account,
  getVal: (
    parent: Account,
    val:
      | 'spend'
      | 'conversions'
      | 'cost_per_conversion'
      | 'impressions'
      | 'clicks'
      | 'ctr'
  ) => number
) => {
  const date = new Date();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();
  const daysThisMonth = new Date(year, month, 0).getDate();
  const dayToday = date.getDate();
  const budget = parseFloat(account.budget ? account.budget : '0');
  let cost = getVal(account, 'spend');

  return (cost / budget - dayToday / daysThisMonth) * 100;
};
