import React from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { firstPlatformChartState } from '../../recoil/atoms';
import { getAccountState } from '../../recoil/selectors/dataSelectors';
import DoughnutGraph from '../graph-templates/DoughnutGraph';

type Props = {
  isLabel?: boolean;
  isPrint?: boolean;
};

const PlatformFirstDoughnutChart = ({ isLabel, isPrint }: Props) => {
  const { gAccount, mAccount, lAccount, adAccount } =
    useRecoilValue(getAccountState);
  const [platformChart, setPlatformChart] = useRecoilState(
    firstPlatformChartState
  );

  const gData = gAccount
    ? platformChart === 'Budget'
      ? gAccount?.spend
      : platformChart === 'Leads'
      ? gAccount?.conversions
      : platformChart === 'Impressions'
      ? gAccount?.impressions
      : gAccount?.clicks
    : '';
  const mData = mAccount
    ? platformChart === 'Budget'
      ? mAccount?.spend
      : platformChart === 'Leads'
      ? mAccount?.conversions
      : platformChart === 'Impressions'
      ? mAccount?.impressions
      : mAccount?.clicks
    : '';
  const lsaData = lAccount
    ? platformChart === 'Budget'
      ? lAccount?.spend
      : platformChart === 'Leads'
      ? lAccount?.conversions
      : ''
    : '';
  const adData =
    platformChart === 'Budget'
      ? adAccount?.spend
      : platformChart === 'Leads'
      ? adAccount?.conversions
      : '';

  return (
    <DoughnutGraph
      label1='Microsoft'
      label2='Google'
      label3='LSA'
      label4='Manually Added'
      data1={mData}
      data2={gData}
      data3={lsaData}
      data4={adData}
      title={`${platformChart} Per Platform`}
      setTitle={setPlatformChart}
      color1='#001E6C'
      color2='#EC0101'
      color3='#FFB319'
      color4='#F14A16'
      hovercolor1='#035397'
      hovercolor2='#FF6767'
      hovercolor3='#FFE194'
      hovercolor4='#FFC069'
      isLabel={isLabel}
      isPrint={isPrint}
    />
  );
};

export default PlatformFirstDoughnutChart;
