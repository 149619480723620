import React from 'react';
import { lightModeState } from '../../recoil/atoms';
import { SetterOrUpdater, useRecoilValue } from 'recoil';

type Props = {
  label: string;
  setComparator: SetterOrUpdater<'=' | '>' | '<' | '<=' | '>='>;
  comparator: '=' | '>' | '<' | '<=' | '>=';
  setFilter: SetterOrUpdater<string>;
  filter: string;
};

const FilterSelectNumber = ({
  label,
  setComparator,
  comparator,
  setFilter,
  filter,
}: Props) => {
  const lightMode = useRecoilValue(lightModeState);
  const comparators = ['=', '>', '<', '<=', '>='];

  const onSelectComparator = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { value: v } = e.target;
    if (v === '=' || v === '>' || v === '<' || v === '<=' || v === '>=') {
      setComparator(v);
    }
  };

  return (
    <div className={`mt-0 mb-0 ${lightMode ? 'text-black' : 'text-white'}`}>
      <h1 className='font-semibold text-sm sm:text-lg sm:px-4 pt-3 pb-2'>
        {label}
      </h1>
      <div className='flex gap-x-3 items-center justify-center mt-2'>
        <input
          type='number'
          className={`w-5/12 number-remove-arrows rounded focus:ring  px-2 py-1 ${
            lightMode
              ? 'bg-gray-50 border-t border-b border-r border-l border-gray-400 focus:ring-red-300'
              : 'bg-gray-700 border-t border-b border-r border-l border-gray-400 focus:ring-indigo-500'
          }`}
          value={filter ? filter : ''}
          onChange={(e) => setFilter(e.target.value)}
        />
        <select
          onChange={onSelectComparator}
          className={`${
            lightMode ? 'bg-gray-200' : 'bg-gray-600'
          } focus:outline-none py-2 pl-2 pr-3 cursor-pointer rounded text-sm font-semibold`}
          value={comparator}
        >
          {comparators.map((item, index) => (
            <option key={index} className='pb-2 font-semibold'>
              {item}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};

export default FilterSelectNumber;
