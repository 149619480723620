import React, { useState } from 'react';
import FormDropdown from '../../forms-comps/FormDropdown';
import AddDataInput from '../../forms-comps/AddDataInput';
import {
  isLoadingDataState,
  lightModeState,
  dataFormDataState,
  thisMonthAccountsState,
  isDataFormOpenState,
} from '../../../recoil/atoms';
import { useRecoilValue, useRecoilState, useSetRecoilState } from 'recoil';
import FormLayoutBase from '../../forms-comps/FormLayoutBase';
import { headerState } from '../../../recoil/selectors/dataSelectors';
import { isNumeric } from '../../../helpers/utils';
import axios from 'axios';
import swal from 'sweetalert';

const AddDataForm = () => {
  const environment = process.env.NODE_ENV;
  const lightMode = useRecoilValue(lightModeState);
  const setIsLoadingData = useSetRecoilState(isLoadingDataState);
  const setIsDataFormOpen = useSetRecoilState(isDataFormOpenState);
  const dataFormData = useRecoilValue(dataFormDataState);
  const headers = useRecoilValue(headerState);
  const [thisMonthAccounts, setThisMonthAccounts] = useRecoilState(
    thisMonthAccountsState
  );
  const thisMonthAccount = thisMonthAccounts.filter(
    (acc) => acc.id === dataFormData.id
  )[0];
  const additionalData =
    thisMonthAccount && thisMonthAccount.additional_data
      ? thisMonthAccount.additional_data
      : null;
  const [input, setInput] = useState({
    spend: additionalData && additionalData.spend ? additionalData.spend : null,
    conversions:
      additionalData && additionalData.conversions
        ? additionalData.conversions
        : null,
    conv_operation:
      additionalData && additionalData.conv_operation
        ? additionalData.conv_operation
        : '+',
    spend_operation:
      additionalData && additionalData.spend_operation
        ? additionalData.spend_operation
        : '+',
    is_active: thisMonthAccount.is_active,
    ad_spend: thisMonthAccount.ad_spend,
  });
  const dSpend = dataFormData ? dataFormData.spend : 0;
  const dConversions = dataFormData ? dataFormData.conversions : 0;
  const iSpend = input.spend ? input.spend : '';
  const iConversions = input.conversions ? input.conversions : '';
  const isValidData =
    (!input.spend || isNumeric(input.spend)) &&
    (!input.conversions || isNumeric(input.conversions));

  const handleChange = (
    e: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>
  ) => {
    let { name, value } = e.target;
    let v: string | boolean = value;

    if (name === 'is_active') {
      value === 'Active' ? (v = true) : (v = false);
    }
    setInput((inp) => ({ ...inp, [name]: v }));
  };

  const getThisMoData = async () => {
    await axios({
      method: 'get',
      headers: headers,
      url: `${
        environment === 'production' ? 'https://apimakeforth.herokuapp.com' : ''
      }/api/account_info/this-mo/`,
    })
      .then((res) => {
        setThisMonthAccounts(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
    setIsLoadingData(false);
  };

  const patchStatus = async () => {
    setIsDataFormOpen(false);
    setIsLoadingData(true);

    await axios({
      method: 'patch',
      headers: headers,
      url: `${
        environment === 'production' ? 'https://apimakeforth.herokuapp.com' : ''
      }/api/account_info/this-mo/${
        thisMonthAccount && thisMonthAccount.google_cid
          ? thisMonthAccount.google_cid
          : thisMonthAccount.microsoft_cid
      }/`,
      data: { is_active: input.is_active, ad_spend: input.ad_spend },
    }).catch((e) => console.log(e));
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!additionalData) {
      await axios({
        method: 'post',
        headers: headers,
        url: `${
          environment === 'production'
            ? 'https://apimakeforth.herokuapp.com'
            : ''
        }/api/additional_data/this-mo/`,
        data: {
          ...input,
          id: dataFormData.id,
          name: dataFormData.name,
        },
      })
        .then(() => patchStatus())
        .then(() => {
          swal(
            'Data Updated',
            `Updated Data for ${dataFormData.name}`,
            'success',
            {
              timer: 1000,
              buttons: [false],
            }
          );
          getThisMoData();
        })
        .catch((err) => {
          setIsLoadingData(false);
          swal('An Error Occured!', err.message, 'error');
          console.log(err);
        });
      return;
    }
    await axios({
      method: 'put',
      headers: headers,
      url: `${
        environment === 'production' ? 'https://apimakeforth.herokuapp.com' : ''
      }/api/additional_data/this-mo/${dataFormData.id}/`,
      data: {
        ...input,
        id: dataFormData.id,
        name: dataFormData.name,
      },
    })
      .then(() => patchStatus())
      .then(() => {
        swal(
          'Data Updated',
          `Updated Data for ${dataFormData.name}`,
          'success',
          {
            timer: 1000,
            buttons: [false],
          }
        );
        getThisMoData();
      })
      .catch((err) => {
        setIsLoadingData(false);
        swal('An Error Occured!', err.message, 'error');
        console.log(err);
      });
  };

  return (
    <FormLayoutBase
      btnLabel='Update Data'
      handleSubmit={handleSubmit}
      disableButton={!isValidData}
    >
      <AddDataInput
        title='Spend'
        data={dSpend}
        name='spend'
        value={iSpend}
        onChange={handleChange}
        operation={input.spend_operation}
        handleSetOperation={(e) =>
          setInput({ ...input, spend_operation: e.target.value })
        }
      />
      <AddDataInput
        title='Conversions'
        data={dConversions}
        name='conversions'
        value={iConversions}
        onChange={handleChange}
        operation={input.conv_operation}
        handleSetOperation={(e) =>
          setInput({ ...input, conv_operation: e.target.value })
        }
      />
      <p
        className={`${isValidData ? 'hidden' : 'inline'} text-sm ${
          lightMode ? 'text-red-600' : 'text-red-400'
        } font-thin ml-2`}
      >
        Please enter numerical value
      </p>
      <FormDropdown
        title='Status'
        options={['Active', 'Pause']}
        handlePick={handleChange}
        name='is_active'
        defaultValue={input.is_active ? 'Active' : 'Pause'}
      />
      <FormDropdown
        title='Ads Paid By'
        options={['Client', 'Asterisk']}
        handlePick={handleChange}
        name='ad_spend'
        defaultValue={input.ad_spend}
      />
    </FormLayoutBase>
  );
};

export default AddDataForm;
