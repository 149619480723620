import './App.css';
import React, { useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Switch, Route, Redirect } from 'react-router-dom';
import Home from './pages/Home';
import Data from './pages/Data';
import SignIn from './pages/SIgnIn';
import Reports from './pages/Reports';
import Glossary from './pages/Glossary';
import Password from './pages/Password';
import Feedback from './pages/Feedback';
import ErrorPage from './pages/404';
import LoadAnimation from './components/universal-decorations/LoadAnimation';
import {
  useSetRecoilState,
  useRecoilValueLoadable,
  useRecoilValue,
  type SetterOrUpdater,
  useRecoilState,
} from 'recoil';
import {
  accounts30DaysAgoState,
  accountsState,
  accounts2MonthsAgoState,
  accounts3MonthsAgoState,
  bingSearchTerm30DaysAgoState,
  searchTerm30DaysAgoState,
  thisMonthAccountsState,
  isAuthenticatedState,
  userState,
  jwtState,
  isLoadingSearchTermState,
  isLineDataLoadingState,
  isLoadingUserLocationState,
  userLocation30DaysAgoState,
  firebaseUserState,
  bingUserLocation30DaysAgoState,
  searchTermFilterState,
  userLocationFilterState,
  deviceChartFilterState,
  idxPlatformsFilterState,
  ppcSpecialistsFilterState,
  accountManagersFilterState,
  statesFilterState,
  statusFilterState,
  notesFilterState,
  adsPaidByFilterState,
  idxFilterOptionsState,
  ppcFilterOptionState,
  accManagerOptionState,
  statesOptionState,
  statusOptionState,
  notesOptionState,
  adsPaidByOptionState,
  budgetComparatorState,
  costComparatorState,
  remainingBudgetComparatorState,
  paceComparatorState,
  cplComparatorState,
  convComparatorState,
  budgetFilterState,
  costFilterState,
  remainingBudgetFilterState,
  paceFilterState,
  cplFilterState,
  convFilterState,
  ppcFilterIDState,
} from './recoil/atoms';
import {
  getAccountDataState,
  headerState,
} from './recoil/selectors/dataSelectors';
import { auth } from './firebase/firebase';
import { month } from './helpers/dateUtils';
import 'animate.css';
import axios from 'axios';
import NavBar from './components/layout/Navbar';
import { Account, CheckboxOptions } from './helpers/types';
import AddUser from './pages/AddUser';

const App = () => {
  const environment = process.env.NODE_ENV;
  const user = useRecoilValue(userState);
  const setAccs = useSetRecoilState(
    user?.role === 'superadmin'
      ? thisMonthAccountsState
      : accounts30DaysAgoState
  );
  const header = useRecoilValue(headerState);
  const date = new Date();
  const lastMonthIndex = date.getMonth() - 1;
  const twoMonthsAgoIndex = date.getMonth() - 2;
  const threeMonthsAgoIndex = date.getMonth() - 3;
  const setST = useSetRecoilState(searchTerm30DaysAgoState);
  const setBST = useSetRecoilState(bingSearchTerm30DaysAgoState);
  const setUL = useSetRecoilState(userLocation30DaysAgoState);
  const setBUL = useSetRecoilState(bingUserLocation30DaysAgoState);
  const setPPCSpecialistsFilter = useSetRecoilState(ppcSpecialistsFilterState);
  const setPPCOptionFilter = useSetRecoilState(ppcFilterOptionState);
  const setStatusOptions = useSetRecoilState(statusOptionState);
  const setStatusFilter = useSetRecoilState(statusFilterState);
  const setIDXFilter = useSetRecoilState(idxPlatformsFilterState);
  const setIDXOptions = useSetRecoilState(idxFilterOptionsState);
  const setAccountManagerFilter = useSetRecoilState(accountManagersFilterState);
  const setAccountManagerOptions = useSetRecoilState(accManagerOptionState);
  const setStatesFilter = useSetRecoilState(statesFilterState);
  const setStatesOptions = useSetRecoilState(statesOptionState);
  const setNotesFilter = useSetRecoilState(notesFilterState);
  const setNotesOptions = useSetRecoilState(notesOptionState);
  const setAdsPaidByFilter = useSetRecoilState(adsPaidByFilterState);
  const setAdsPaidByOptions = useSetRecoilState(adsPaidByOptionState);
  const setBudgetComparator = useSetRecoilState(budgetComparatorState);
  const setBudgetFilter = useSetRecoilState(budgetFilterState);
  const setCostComparator = useSetRecoilState(costComparatorState);
  const setCostFilter = useSetRecoilState(costFilterState);
  const setRemainingBudgetComparator = useSetRecoilState(
    remainingBudgetComparatorState
  );
  const setRemainingBudgetFilter = useSetRecoilState(
    remainingBudgetFilterState
  );
  const setPaceComparator = useSetRecoilState(paceComparatorState);
  const setPaceFilter = useSetRecoilState(paceFilterState);
  const setCPLComparator = useSetRecoilState(cplComparatorState);
  const setCPLFilter = useSetRecoilState(cplFilterState);
  const setConvComparator = useSetRecoilState(convComparatorState);
  const setConvFilter = useSetRecoilState(convFilterState);
  const setPPCFilterID = useSetRecoilState(ppcFilterIDState);
  const setIsLoadingST = useSetRecoilState(isLoadingSearchTermState);
  const setIsLoadingUL = useSetRecoilState(isLoadingUserLocationState);
  const [firebaseUser, setFirebaseUser] = useRecoilState(firebaseUserState);
  const setIsLineDataLoading = useSetRecoilState(isLineDataLoadingState);
  const setAccountLastMonth = useSetRecoilState(accountsState);
  const setAccounts2MonthsAgo = useSetRecoilState(accounts2MonthsAgoState);
  const setAccounts3MonthsAgo = useSetRecoilState(accounts3MonthsAgoState);
  const setSearchTermFilter = useSetRecoilState(searchTermFilterState);
  const setUserLocationFilter = useSetRecoilState(userLocationFilterState);
  const setDeviceChartFilter = useSetRecoilState(deviceChartFilterState);

  const getAccountData = useRecoilValueLoadable(getAccountDataState);

  const isAuthenticated = useRecoilValue(isAuthenticatedState);
  const setJwtState = useSetRecoilState(jwtState);

  const getPostiveMonthIndex = (index: number) => {
    return index < 0 ? index + 12 : index;
  };

  const accountSetter = (
    setter: SetterOrUpdater<Account[]>,
    account: Account[]
  ) => {
    setter(account);
  };

  useEffect(() => {
    const resubscribe = auth.onIdTokenChanged(async (user) => {
      if (user) {
        const token = await user.getIdToken();
        setJwtState(token);
        setFirebaseUser(user);
      } else {
        return '';
      }
    });
    return () => {
      resubscribe();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  switch (getAccountData.state) {
    case 'hasValue':
      const accounts = getAccountData.contents;
      const role = user?.role;

      if (accounts.length > 0) {
        accountSetter(setAccs, accounts);

        const sortFilter = (
          array: string[] | boolean[],
          setFilter: SetterOrUpdater<(string | boolean)[]>,
          setOptions: SetterOrUpdater<CheckboxOptions>
        ) => {
          setFilter(array);
          array.forEach((key) => {
            const k: string =
              typeof key === 'boolean' ? (key ? 'Active' : 'Paused') : key;
            setOptions((options) => ({ ...options, [k]: true }));
          });
        };

        if (role === 'admin') {
          setIsLineDataLoading(true);
          setIsLoadingST(true);
          setIsLoadingUL(true);

          const account = accounts[0];

          if (!account.mobile) setDeviceChartFilter('Microsoft');
          if (!account.bing_mobile) setDeviceChartFilter('Google');

          const handleGetAccountReq = async (
            timeFrame: string,
            setter: SetterOrUpdater<Account[]>
          ) => {
            await axios({
              method: 'get',
              url: `${
                environment === 'development'
                  ? ''
                  : 'https://apimakeforth.herokuapp.com'
              }/api/account_info/${timeFrame}/${
                account.google_cid ? account.google_cid : account.microsoft_cid
              }/`,
              headers: header,
            })
              .then((res) => setter((accounts) => [...accounts, res.data]))
              .catch((err) => console.error(err));
          };

          axios({
            method: 'get',
            url: `${
              environment === 'development'
                ? ''
                : 'https://apimakeforth.herokuapp.com'
            }/api/account_info/${month[
              getPostiveMonthIndex(lastMonthIndex)
            ].toLowerCase()}/${
              account.google_cid ? account.google_cid : account.microsoft_cid
            }/`,
            headers: header,
          })
            .then((res) =>
              setAccountLastMonth((account) => [...account, res.data])
            )
            .then(() =>
              handleGetAccountReq(
                month[getPostiveMonthIndex(twoMonthsAgoIndex)].toLowerCase(),
                setAccounts2MonthsAgo
              )
            )
            .then(() => {
              handleGetAccountReq(
                month[getPostiveMonthIndex(threeMonthsAgoIndex)].toLowerCase(),
                setAccounts3MonthsAgo
              );
              setIsLineDataLoading(false);
            })
            .catch((err) => {
              console.error(err);
              setIsLineDataLoading(false);
            });

          axios({
            method: 'get',
            url: `${
              environment === 'production'
                ? 'https://apimakeforth.herokuapp.com'
                : ''
            }/api/search_term/30-days/filter/${user?.googleCID}/`,
            headers: header,
          })
            .then((res) => {
              setST(res.data);
              if (res.data.length < 1) {
                setSearchTermFilter('Microsoft');
              }
            })
            .catch((err) => console.log(err));

          axios({
            method: 'get',
            url: `${
              environment === 'production'
                ? 'https://apimakeforth.herokuapp.com'
                : ''
            }/api/bing_searchterm/30-days/filter/${user?.microsoftCID}/`,
            headers: header,
          })
            .then((res) => {
              setBST(res.data);
              setIsLoadingST(false);
              if (res.data.length < 1) {
                setSearchTermFilter('Google');
              }
            })
            .catch((err) => {
              console.log(err);
              setIsLoadingST(false);
            });

          axios({
            method: 'get',
            url: `${
              environment === 'production'
                ? 'https://apimakeforth.herokuapp.com'
                : ''
            }/api/user_location/30-days/filter/${user?.googleCID}/`,
            headers: header,
          })
            .then((res) => {
              setUL(res.data);
              setIsLoadingUL(false);
              if (res.data.length < 1) {
                setUserLocationFilter('Microsoft');
              }
            })
            .catch((err) => {
              console.log(err);
              setIsLoadingUL(false);
            });

          axios({
            method: 'get',
            url: `${
              environment === 'production'
                ? 'https://apimakeforth.herokuapp.com'
                : ''
            }/api/bing_user_location/30-days/filter/${user?.microsoftCID}/`,
            headers: header,
          })
            .then((res) => {
              setBUL(res.data);
              setIsLoadingUL(false);
              if (res.data.length < 1) {
                setUserLocationFilter('Google');
              }
            })
            .catch((err) => {
              console.log(err);
              setIsLoadingUL(false);
            });
        } else {
          axios({
            method: 'get',
            url: `${
              environment === 'development'
                ? ''
                : 'https://apimakeforth.herokuapp.com'
            }/api/bms_filter/${firebaseUser?.uid}/`,
            headers: header,
          })
            .then((res) => {
              sortFilter(
                res.data.accountManagers,
                setAccountManagerFilter,
                setAccountManagerOptions
              );
              sortFilter(
                res.data.accountNotes,
                setNotesFilter,
                setNotesOptions
              );
              sortFilter(
                res.data.accountStatus,
                setStatusFilter,
                setStatusOptions
              );
              sortFilter(
                res.data.adsPaidBy,
                setAdsPaidByFilter,
                setAdsPaidByOptions
              );
              sortFilter(res.data.idxPlatforms, setIDXFilter, setIDXOptions);
              sortFilter(
                res.data.ppcSpecialists,
                setPPCSpecialistsFilter,
                setPPCOptionFilter
              );
              sortFilter(res.data.states, setStatesFilter, setStatesOptions);
              setBudgetFilter(res.data.budget);
              setBudgetComparator(res.data.budgetOp);
              setConvFilter(res.data.conversions);
              setConvComparator(res.data.conversionsOp);
              setCostFilter(res.data.cost);
              setCostComparator(res.data.costOp);
              setCPLFilter(res.data.cpl);
              setCPLComparator(res.data.cplOp);
              setPaceFilter(res.data.pace);
              setPaceComparator(res.data.paceOp);
              setRemainingBudgetFilter(res.data.remainingBudget);
              setRemainingBudgetComparator(res.data.remainingBudgetOp);
              setPPCFilterID(res.data.id);
            })
            .catch((err) => console.error(err));
        }
      }

      if (user?.isPPCManager) {
        setPPCSpecialistsFilter((filters) =>
          !filters.includes(user?.displayName)
            ? [...filters, user?.displayName]
            : [...filters]
        );
        setPPCOptionFilter((options) => ({
          ...options,
          [user.displayName]: true,
        }));
      }

      return (
        <div>
          <Switch>
            <Route
              exact
              path='/'
              render={() =>
                isAuthenticated ? <Home /> : <Redirect to='/sign-in' />
              }
            />
            <Route
              exact
              path='/bms'
              render={() =>
                isAuthenticated && role === 'superadmin' ? (
                  <Data />
                ) : (
                  <Redirect to='/sign-in' />
                )
              }
            />
            <Route
              exact
              path='/reports'
              render={() =>
                isAuthenticated ? <Reports /> : <Redirect to='/sign-in' />
              }
            />
            <Route
              exact
              path='/glossary'
              render={() =>
                isAuthenticated ? <Glossary /> : <Redirect to='/sign-in' />
              }
            />
            <Route
              exact
              path='/sign-in'
              render={() =>
                isAuthenticated ? <Redirect to='/' /> : <SignIn />
              }
            />
            <Route
              exact
              path='/password'
              render={() =>
                isAuthenticated ? <Password /> : <Redirect to='/sign-in' />
              }
            />
            <Route
              exact
              path='/adduser'
              render={() =>
                isAuthenticated ? <AddUser /> : <Redirect to='/sign-in' />
              }
            />
            <Route
              exact
              path='/feedback'
              render={() =>
                isAuthenticated ? <Feedback /> : <Redirect to='/sign-in' />
              }
            />
            <Route
              render={() =>
                isAuthenticated ? <ErrorPage /> : <Redirect to='/sign-in' />
              }
            />
          </Switch>
        </div>
      );
    case 'loading':
      return (
        <div>
          <NavBar />
          <div className='pt-60 min-h-screen bg-white'>
            <LoadAnimation />
          </div>
        </div>
      );
    case 'hasError':
      throw getAccountData.contents;
    default:
      return <div>No Content...</div>;
  }
};

export default App;
