import React, { useState } from 'react';
import axios from 'axios';
import {
  useRecoilValue,
  useSetRecoilState,
  useRecoilState,
  SetterOrUpdater,
} from 'recoil';
import {
  accountState,
  accounts30DaysAgoState,
  accounts2MonthsAgoState,
  accounts3MonthsAgoState,
  accountsState,
  userLocation30DaysAgoState,
  bingSearchTerm30DaysAgoState,
  searchTerm30DaysAgoState,
  thisMonthAccountsState,
  selectedAccountState,
  monNameState,
  isLoadingDataState,
  isLoadingSearchTermState,
  isLoadingUserLocationState,
  isUpdateFormOpenState,
  isAddAccountOpenState,
  isAccountModalState,
  isLineDataLoadingState,
  searchTermFilterState,
  bingUserLocation30DaysAgoState,
  userLocationFilterState,
  deviceChartFilterState,
  lightModeState,
} from '../../../recoil/atoms';
import { headerState } from '../../../recoil/selectors/dataSelectors';
import { month } from '../../../helpers/dateUtils';
import { Account } from '../../../helpers/types';

type Props = {
  setReportsComponent?: React.Dispatch<React.SetStateAction<string>>;
};

const SelectAccountModal = ({ setReportsComponent }: Props) => {
  interface SelectMouseTarget extends EventTarget {
    value: string;
  }

  interface SelectMouseEvent extends React.MouseEvent<HTMLSelectElement> {
    target: SelectMouseTarget;
  }

  const filterAccounts = (accounts: Account[]) => {
    return accounts.filter((item) => item.name === selectedAccount);
  };
  const dateToday = new Date();
  const [search, setSearch] = useState('');
  const [monName, setMonName] = useRecoilState(monNameState);
  const thisMonthAccounts = useRecoilValue(thisMonthAccountsState);
  const lightMode = useRecoilValue(lightModeState);
  const filteredAccounts = thisMonthAccounts?.filter((account) =>
    account.name.toLowerCase().includes(search.toLowerCase())
  );
  const [selectedAccount, setSelectedAccount] =
    useRecoilState(selectedAccountState);
  const setSearchTermFilter = useSetRecoilState(searchTermFilterState);
  const setUserLocationFilter = useSetRecoilState(userLocationFilterState);
  const setDeviceChartFilter = useSetRecoilState(deviceChartFilterState);

  const [accounts30DaysAgo, setAccounts30DaysAgo] = useRecoilState(
    accounts30DaysAgoState
  );
  const isMonNameMonth = monName !== '30-Day';
  const thisMonthAccount = filterAccounts(thisMonthAccounts)[0];
  const cachedAccount = filterAccounts(accounts30DaysAgo)[0];
  const [account, setAccount] = useRecoilState(accountState);
  const setAccountLastMonth = useSetRecoilState(accountsState);
  const setAccounts2MonthsAgo = useSetRecoilState(accounts2MonthsAgoState);
  const setAccounts3MonthsAgo = useSetRecoilState(accounts3MonthsAgoState);

  const [searchTerms30DaysAgo, setSearchTerm30DaysAgo] = useRecoilState(
    searchTerm30DaysAgoState
  );
  const setBingSearchTerm30DaysAgo = useSetRecoilState(
    bingSearchTerm30DaysAgoState
  );

  const setUserLocation30DaysAgo = useSetRecoilState(
    userLocation30DaysAgoState
  );
  const setBingUserLocation30DaysAgo = useSetRecoilState(
    bingUserLocation30DaysAgoState
  );

  const setIsLoading = useSetRecoilState(isLoadingDataState);
  const setIsLoadingST = useSetRecoilState(isLoadingSearchTermState);
  const setIsLineDataLoading = useSetRecoilState(isLineDataLoadingState);
  const setIsLoadingUL = useSetRecoilState(isLoadingUserLocationState);
  const headers = useRecoilValue(headerState);

  const setIsUpdateFormOpen = useSetRecoilState(isUpdateFormOpenState);
  const setIsAddAccountOpen = useSetRecoilState(isAddAccountOpenState);
  const setIsAccountModalOpen = useSetRecoilState(isAccountModalState);

  const environment = process.env.NODE_ENV;

  const handlePick = (
    e: React.ChangeEvent<HTMLSelectElement> | SelectMouseEvent
  ) => {
    setSelectedAccount(e.target.value);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  const handleGetAccountReq = async (
    model: string,
    timeFrame: string,
    setter: SetterOrUpdater<Account[]>,
    account_id: string | number | undefined
  ) => {
    await axios({
      method: 'get',
      url: `${
        environment === 'development'
          ? ''
          : 'https://apimakeforth.herokuapp.com'
      }/api/${model}/${timeFrame}${account_id}/`,
      headers: headers,
    })
      .then((res) => setter((d) => [...d, res.data]))
      .catch((err) => console.error(err));
  };

  const aid =
    thisMonthAccount &&
    (thisMonthAccount.google_cid
      ? thisMonthAccount.google_cid
      : thisMonthAccount.microsoft_cid);

  const handleRequest = async () => {
    let indexOfLastMonth = dateToday.getMonth() - 1;
    let indexOfTwoMonthsAgo = dateToday.getMonth() - 2;
    let indexOfThreeMonthsAgo = dateToday.getMonth() - 3;

    indexOfLastMonth = indexOfLastMonth === -1 ? 11 : indexOfLastMonth;
    indexOfTwoMonthsAgo =
      indexOfTwoMonthsAgo < 0 ? indexOfTwoMonthsAgo + 12 : indexOfTwoMonthsAgo;
    indexOfThreeMonthsAgo =
      indexOfThreeMonthsAgo < 0
        ? indexOfThreeMonthsAgo + 12
        : indexOfThreeMonthsAgo;

    await axios({
      method: 'get',
      url: `${
        environment === 'production' ? 'https://apimakeforth.herokuapp.com' : ''
      }/api/account_info/30-days/${
        thisMonthAccount.google_cid
          ? thisMonthAccount.google_cid
          : thisMonthAccount.microsoft_cid
      }/`,
      headers: headers,
    })
      .then((res) => {
        setAccount(res.data);
        setAccounts30DaysAgo((accounts) => [...accounts, res.data]);
        setIsLoading(false);
        if (!res.data.mobile) setDeviceChartFilter('Microsoft');
        if (!res.data.bing_mobile) setDeviceChartFilter('Google');
      })
      .catch((e) => console.log(e));

    await axios({
      method: 'get',
      url: `${
        environment === 'production' ? 'https://apimakeforth.herokuapp.com' : ''
      }/api/search_term/30-days/filter/${thisMonthAccount.google_cid}/`,
      headers: headers,
    })
      .then((res) => {
        setSearchTerm30DaysAgo((searchTerm) => [...searchTerm, ...res.data]);
        if (res.data.length === 0) {
          setSearchTermFilter('Microsoft');
        }
      })
      .catch((e) => console.log(e));

    await axios({
      method: 'get',
      url: `${
        environment === 'production' ? 'https://apimakeforth.herokuapp.com' : ''
      }/api/bing_searchterm/30-days/filter/${thisMonthAccount.microsoft_cid}/`,
      headers: headers,
    })
      .then((res) => {
        setBingSearchTerm30DaysAgo((bingSearchTerm) => [
          ...bingSearchTerm,
          ...res.data,
        ]);
        if (res.data.length === 0) {
          setSearchTermFilter('Google');
        }
        setIsLoadingST(false);
      })
      .catch((e) => {
        console.log(e);
        setIsLoadingST(false);
      });

    await axios({
      method: 'get',
      headers: headers,
      url: `${
        environment === 'production' ? 'https://apimakeforth.herokuapp.com' : ''
      }/api/user_location/30-days/filter/${thisMonthAccount.google_cid}/`,
    })
      .then((res) => {
        setUserLocation30DaysAgo((userLocs) => [...userLocs, ...res.data]);
        setIsLoadingUL(false);
        if (res.data.length < 1) {
          setUserLocationFilter('Microsoft');
        }
      })
      .catch((err) => {
        console.log(err);
        setIsLoadingUL(false);
      });

    await axios({
      method: 'get',
      headers: headers,
      url: `${
        environment === 'production' ? 'https://apimakeforth.herokuapp.com' : ''
      }/api/bing_user_location/30-days/filter/${
        thisMonthAccount.microsoft_cid
      }/`,
    })
      .then((res) => {
        setBingUserLocation30DaysAgo((userLocs) => [...userLocs, ...res.data]);
        setIsLoadingUL(false);
        if (res.data.length < 1) {
          setUserLocationFilter('Google');
        }
      })
      .catch((err) => {
        console.log(err);
        setIsLoadingUL(false);
      });

    await axios({
      method: 'get',
      url: `${
        environment === 'development'
          ? ''
          : 'https://apimakeforth.herokuapp.com'
      }/api/account_info/${month[indexOfLastMonth].toLowerCase()}/${aid}/`,
      headers: headers,
    })
      .then((res) => setAccountLastMonth((account) => [...account, res.data]))
      .then(() =>
        handleGetAccountReq(
          'account_info',
          `${month[indexOfTwoMonthsAgo].toLowerCase()}/`,
          setAccounts2MonthsAgo,
          aid
        )
      )
      .then(() => {
        handleGetAccountReq(
          'account_info',
          `${month[indexOfThreeMonthsAgo].toLowerCase()}/`,
          setAccounts3MonthsAgo,
          aid
        );
        setIsLineDataLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setIsLineDataLoading(false);
      });
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setReportsComponent && setReportsComponent('Platforms');
    setMonName('30-Day');
    setIsLoading(true);
    setIsLoadingST(true);
    setIsLineDataLoading(true);
    setIsLoadingUL(true);
    setSelectedAccount('');
    setSearchTermFilter('Google');
    setIsAccountModalOpen(false);
    const searchTerms = searchTerms30DaysAgo.filter(
      (item) => item.account === thisMonthAccount.google_cid
    );
    if (searchTerms.length === 0) {
      handleRequest();
    } else {
      setAccount(cachedAccount);
      setIsLoading(false);
      setIsLoadingST(false);
      setIsLineDataLoading(false);
    }
  };

  return (
    <div
      className='fixed z-50 inset-0 overflow-y-auto'
      aria-labelledby='modal-title'
      role='dialog'
      aria-modal='true'
    >
      <div className='flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0'>
        <div
          className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity'
          aria-hidden='true'
        ></div>
        <div className='inline-block align-bottom rounded-lg text-left overflow-hidden transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full h-1/2'>
          <div
            className={`shadow-md	${
              !lightMode ? 'bg-gray-900' : 'bg-white'
            } rounded-lg p-4 flex flex-col md:ml-auto w-full mt-48`}
          >
            <div className='flex justify-end w-full'>
              <button
                type='button'
                className={`${
                  !lightMode ? 'bg-gray-900' : 'bg-white hover:bg-gray-100'
                } rounded-md p-1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500`}
                onClick={() => {
                  setIsAccountModalOpen(false);
                  setSelectedAccount('');
                }}
              >
                <span className='sr-only'>Close menu</span>
                <svg
                  className='h-4 w-4'
                  xmlns='http://www.w3.org/2000/svg'
                  fill='none'
                  viewBox='0 0 24 24'
                  stroke='currentColor'
                  aria-hidden='true'
                >
                  <path
                    stroke-linecap='round'
                    stroke-linejoin='round'
                    stroke-width='2'
                    d='M6 18L18 6M6 6l12 12'
                  />
                </svg>
              </button>
            </div>
            <input
              type='search'
              name='search'
              value={search}
              placeholder='🔍 Search'
              onChange={handleChange}
              className={`rounded-md border-l border-r border-t border-b  form-control-md py-1 px-1.5 mb-2.5 shadow-sm focus:outline-none ${
                lightMode
                  ? 'bg-white text-black border-gray-300 shadow-sm'
                  : 'bg-gray-900 text-white border-gray-700 shadow'
              }`}
              autoComplete='off'
            />
            <form onSubmit={handleSubmit}>
              <select
                className={`block w-full mt-1 py-2 px-1 mb-1 rounded-sm font-medium border-l border-r border-t border-b ${
                  lightMode
                    ? 'bg-white text-black border-gray-300 shadow-sm'
                    : 'bg-gray-900 text-white border-gray-700 shadow'
                } cursor-pointer focus:outline-none`}
                name='selectedAccount'
                onClick={(e: SelectMouseEvent) => handlePick(e)}
                onChange={handlePick}
                defaultValue={
                  account.name !== 'None'
                    ? account.name
                    : filteredAccounts[0]?.name
                }
              >
                {filteredAccounts.map((account, index) => (
                  <option
                    key={index}
                    value={account.name}
                    className={`py-2 border cursor-pointer ${
                      lightMode
                        ? 'bg-white text-black hover:bg-gray-200'
                        : 'bg-gray-900 text-white hover:bg-gray-800'
                    }`}
                  >
                    {account.name}
                  </option>
                ))}
              </select>
              <div
                className={`grid ${
                  account.name === 'None' || !isMonNameMonth
                    ? 'sm:grid-cols-2'
                    : 'sm:grid-cols-3'
                } sm:gap-x-2`}
              >
                <input
                  className={`px-2 py-1 mt-2 text-white font-semibold ${
                    selectedAccount === ''
                      ? lightMode
                        ? 'bg-gray-300'
                        : 'bg-gray-400'
                      : 'transition duration-300 ease-out transform bg-indigo-500 hover:bg-indigo-600'
                  } rounded`}
                  type='submit'
                  value='Select Account'
                  disabled={selectedAccount === ''}
                />

                <button
                  className={`px-2 py-1 mt-2 text-white font-semibold ${
                    account.name === 'None' || !isMonNameMonth
                      ? 'hidden'
                      : 'transition duration-300 ease-out transform bg-indigo-500 hover:bg-indigo-600'
                  } rounded`}
                  type='button'
                  onClick={() => setIsUpdateFormOpen(true)}
                  disabled={account.name === 'None' || !isMonNameMonth}
                >
                  Update Data
                </button>
                <button
                  className='px-2 py-1 mt-2 text-white font-semibold transition ease-out duration-300 transform bg-indigo-500 hover:bg-indigo-600 rounded'
                  type='button'
                  onClick={() => setIsAddAccountOpen(true)}
                >
                  Add Account
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SelectAccountModal;
