import React from 'react';
import DoughnutGraph from '../graph-templates/DoughnutGraph';
import { accountState, userState } from '../../recoil/atoms';
import {
  adminAccountState,
  getAccountState,
} from '../../recoil/selectors/dataSelectors';
import { useRecoilValue } from 'recoil';

type Props = { isLabel?: boolean; isPrint?: boolean };

const BudgetDeviceDoughnutChart = ({ isLabel, isPrint }: Props) => {
  const user = useRecoilValue(userState);
  const adminAccount = useRecoilValue(adminAccountState);
  const superadminAccount = useRecoilValue(accountState);
  const account =
    user?.role === 'superadmin' ? superadminAccount : adminAccount;

  const { mobile, tablet, desktop } = useRecoilValue(getAccountState);

  const mSpend = mobile ? mobile.spend : '';
  const tSpend = tablet ? tablet.spend : '';
  const dSpend = desktop ? desktop.spend : '';

  const other =
    account && account.google_account
      ? parseFloat(account.google_account.spend) -
        (parseFloat(mSpend) + parseFloat(tSpend) + parseFloat(dSpend))
      : 0;

  const otherString = other > 0 ? other.toString() : '';

  return (
    <DoughnutGraph
      label1='Mobile'
      label2='Tablet'
      label3='Desktop'
      label4='Other'
      data1={mSpend}
      data2={tSpend}
      data3={dSpend}
      data4={otherString}
      title='Budget Per Device (Google)'
      color1='#1572A1'
      color2='#DE8971'
      color3='#FFCA03'
      color4='#655D8A'
      hovercolor1='#DEEDF0'
      hovercolor2='#F8EDED'
      hovercolor3='#F5FDB0'
      hovercolor4='#F3F1F5'
      isLabel={isLabel}
      isPrint={isPrint}
    />
  );
};

export default BudgetDeviceDoughnutChart;
