import React from 'react';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import { lightModeState } from '../../recoil/atoms';
import { useRecoilValue } from 'recoil';

type Props = {
  onClick: () => void;
  showFilter: boolean;
  title: string;
};

const ReportsCompFilter = ({ onClick, showFilter, title }: Props) => {
  const lightMode = useRecoilValue(lightModeState);

  return (
    <div
      className={`w-full h-5/6 gap-x-8 flex justify-evenly items-center ${
        lightMode ? 'bg-gray-200 text-black' : 'bg-gray-800 text-white'
      } rounded cursor-pointer text-center font-semibold  text-xs ${
        title === 'Device Platform' ? 'sm:text-sm' : 'sm:text-base'
      } ${title.includes('Device') ? 'px-2.5 py-2.5' : 'py-2'}`}
      onClick={onClick}
    >
      {title} {showFilter ? <IoIosArrowUp /> : <IoIosArrowDown />}
    </div>
  );
};

export default ReportsCompFilter;
