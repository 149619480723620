import { isOpenNotifPopupState, lightModeState } from '../../recoil/atoms';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { IoMdClose } from 'react-icons/io';
import { BiErrorCircle } from 'react-icons/bi';
import React from 'react';

const NotificationPopup = () => {
  const lightMode = useRecoilValue(lightModeState);
  const setIsOpenPopup = useSetRecoilState(isOpenNotifPopupState);

  return (
    <div
      className={`py-4  px-3 hidden sm:inline fixed left-12 bottom-12 mb-1 z-50 w-1/4 max-h-2/5 overflow-y-auto border-l border-r border-t border-b ${
        lightMode
          ? 'bg-white text-black border-gray-300'
          : 'bg-gray-900 text-white border-gray-800'
      } rounded`}
    >
      <IoMdClose
        className='top-3 right-3 absolute animate__animated animate__zoomIn cursor-pointer'
        onClick={() => setIsOpenPopup(false)}
      />
      <div className='text-xl flex items-center gap-x-2 font-semibold mb-2'>
        <BiErrorCircle className='text-2xl text-yellow-500' />
        Check Your Notifications
      </div>
      <p className={`text-sm ${lightMode ? 'text-gray-700' : 'text-gray-200'}`}>
        Some of the accounts you handle might have some discrepancies with
        Google and/or Microsoft Ads.
      </p>
    </div>
  );
};

export default NotificationPopup;
