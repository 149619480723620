import PrintULKWTemplate from '../info-boxes-templates/PrintULKWTemplate';
import {
  searchTermJanState,
  searchTermFebState,
  searchTermMarState,
  searchTermAprState,
  searchTermMayState,
  searchTermJunState,
  searchTermJulState,
  searchTermAugState,
  searchTermSeptState,
  searchTermOctState,
  searchTermNovState,
  searchTermDecState,
  bingSearchTermJanState,
  bingSearchTermFebState,
  bingSearchTermMarState,
  bingSearchTermAprState,
  bingSearchTermMayState,
  bingSearchTermJunState,
  bingSearchTermJulState,
  bingSearchTermAugState,
  bingSearchTermSeptState,
  bingSearchTermOctState,
  bingSearchTermNovState,
  bingSearchTermDecState,
  monNameState,
  userState,
  accountState,
  isLoadingSearchTermState,
  kwPrintComponentState,
} from '../../recoil/atoms';
import { adminAccountState } from '../../recoil/selectors/dataSelectors';
import { useRecoilValue } from 'recoil';
import { month } from '../../helpers/dateUtils';

const PrintKW = () => {
  const user = useRecoilValue(userState);
  const account = useRecoilValue(
    user?.role === 'superadmin' ? accountState : adminAccountState
  );
  const monName = useRecoilValue(monNameState);
  const isLoading = useRecoilValue(isLoadingSearchTermState);
  const kwPrintComponent = useRecoilValue(kwPrintComponentState);
  const googleKWs = [
    searchTermJanState,
    searchTermFebState,
    searchTermMarState,
    searchTermAprState,
    searchTermMayState,
    searchTermJunState,
    searchTermJulState,
    searchTermAugState,
    searchTermSeptState,
    searchTermOctState,
    searchTermNovState,
    searchTermDecState,
  ];
  const bingKWs = [
    bingSearchTermJanState,
    bingSearchTermFebState,
    bingSearchTermMarState,
    bingSearchTermAprState,
    bingSearchTermMayState,
    bingSearchTermJunState,
    bingSearchTermJulState,
    bingSearchTermAugState,
    bingSearchTermSeptState,
    bingSearchTermOctState,
    bingSearchTermNovState,
    bingSearchTermDecState,
  ];
  const googleKWsFiltered = useRecoilValue(googleKWs[month.indexOf(monName)])
    .filter((kw) => kw.account === account.google_cid)
    .filter((item) =>
      kwPrintComponent === 'Least Spent' || kwPrintComponent === 'Lowest CPL'
        ? item.conversions > 0
        : item
    );
  const bingKWsFiltered = useRecoilValue(bingKWs[month.indexOf(monName)])
    .filter((kw) => kw.account === account.microsoft_cid)
    .filter((item) =>
      kwPrintComponent === 'Least Spent' || kwPrintComponent === 'Lowest CPL'
        ? item.conversions > 0
        : item
    );
  googleKWsFiltered.sort((a, b) =>
    kwPrintComponent === 'Most Converting'
      ? b.conversions - a.conversions
      : kwPrintComponent === 'Most Clicks'
      ? b.clicks - a.clicks
      : kwPrintComponent === 'Most Impressions'
      ? b.impressions - a.impressions
      : kwPrintComponent === 'Least Spent'
      ? a.spend - b.spend
      : kwPrintComponent === 'Highest CTR'
      ? b.ctr - a.ctr
      : a.cost_per_conversion - b.cost_per_conversion
  );
  bingKWsFiltered.sort((a, b) =>
    kwPrintComponent === 'Most Converting'
      ? b.conversions - a.conversions
      : kwPrintComponent === 'Most Clicks'
      ? b.clicks - a.clicks
      : kwPrintComponent === 'Most Impressions'
      ? b.impressions - a.impressions
      : kwPrintComponent === 'Least Spent'
      ? a.spend - b.spend
      : kwPrintComponent === 'Highest CTR'
      ? b.ctr - a.ctr
      : a.cost_per_conversion - b.cost_per_conversion
  );

  return (
    <PrintULKWTemplate
      label={`${kwPrintComponent} Keywords`}
      google={
        googleKWsFiltered.length > 0
          ? googleKWsFiltered[0].name
          : isLoading
          ? ' Keywords Loading'
          : 'No Keywords Available'
      }
      microsoft={
        bingKWsFiltered.length > 0
          ? bingKWsFiltered[0].name
          : isLoading
          ? 'Keywords Loading'
          : 'No Keywords Available'
      }
    />
  );
};

export default PrintKW;
