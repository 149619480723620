import React from 'react';
import { useRecoilValue, useRecoilState } from 'recoil';
import {
  userLocationFilterState,
  userState,
  accountState,
  userLocation30DaysAgoState,
  bingUserLocation30DaysAgoState,
  userLocationAprState,
  userLocationAugState,
  userLocationDecState,
  userLocationFebState,
  userLocationJanState,
  userLocationJulState,
  userLocationJunState,
  userLocationMarState,
  userLocationMayState,
  userLocationNovState,
  userLocationOctState,
  userLocationSeptState,
  bingUserLocationAprState,
  bingUserLocationAugState,
  bingUserLocationDecState,
  bingUserLocationFebState,
  bingUserLocationJanState,
  bingUserLocationJulState,
  bingUserLocationJunState,
  bingUserLocationMarState,
  bingUserLocationMayState,
  bingUserLocationNovState,
  bingUserLocationOctState,
  bingUserLocationSeptState,
  monNameState,
  accounts30DaysAgoState,
} from '../../recoil/atoms';
import { month } from '../../helpers/dateUtils';
import ReportsCompFilterDropdown from '../filter-templates/ReportsCompFilterDropdown';

type Props = {
  showFilter: boolean;
  setShowFilter: React.Dispatch<React.SetStateAction<boolean>>;
};

const UserLocationDropdown = ({ showFilter, setShowFilter }: Props) => {
  const userLocationMonths = [
    userLocationJanState,
    userLocationFebState,
    userLocationMarState,
    userLocationAprState,
    userLocationMayState,
    userLocationJunState,
    userLocationJulState,
    userLocationAugState,
    userLocationSeptState,
    userLocationOctState,
    userLocationNovState,
    userLocationDecState,
  ];
  const bingUserLocationMonths = [
    bingUserLocationJanState,
    bingUserLocationFebState,
    bingUserLocationMarState,
    bingUserLocationAprState,
    bingUserLocationMayState,
    bingUserLocationJunState,
    bingUserLocationJulState,
    bingUserLocationAugState,
    bingUserLocationSeptState,
    bingUserLocationOctState,
    bingUserLocationNovState,
    bingUserLocationDecState,
  ];
  const [userLocationFilter, setUserLocationFitler] = useRecoilState(
    userLocationFilterState
  );
  const monName = useRecoilValue(monNameState);
  const user = useRecoilValue(userState);
  const adminAccount = useRecoilValue(accounts30DaysAgoState)[0];
  const superadminAccount = useRecoilValue(accountState);
  const account =
    user?.role === 'superadmin' ? superadminAccount : adminAccount;
  const userLocation = useRecoilValue(
    monName === '30-Day'
      ? userLocation30DaysAgoState
      : userLocationMonths[month.indexOf(monName)]
  ).filter((ul) =>
    account && account.google_cid ? ul.account === account.google_cid : ul
  );
  const bingUserLocation = useRecoilValue(
    monName === '30-Day'
      ? bingUserLocation30DaysAgoState
      : bingUserLocationMonths[month.indexOf(monName)]
  ).filter((ul) =>
    account && account.microsoft_cid ? ul.account === account.microsoft_cid : ul
  );

  const handleSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value: v } = e.target;
    if (v === 'Google' || v === 'Microsoft') setUserLocationFitler(v);
    setShowFilter(false);
  };

  return (
    <ReportsCompFilterDropdown
      showFilter={showFilter}
      handleSelect={handleSelect}
      filter={userLocationFilter}
      name='ul-platform'
      isSearchTerm
      searchTerm={userLocation}
      bingSearchTerm={bingUserLocation}
    />
  );
};

export default UserLocationDropdown;
