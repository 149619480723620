import React, { useState } from 'react';
import FormLayoutBase from '../../forms-comps/FormLayoutBase';
import ModalBase from '../../forms-comps/ModalBase';
import FormInput from '../../forms-comps/FormInput';
import FormDropdown from '../../forms-comps/FormDropdown';
import { useSetRecoilState, useRecoilValue } from 'recoil';
import { headerState } from '../../../recoil/selectors/dataSelectors';
import {
  thisMonthAccountsState,
  accountsState,
  accountState,
  accounts2MonthsAgoState,
  accounts3MonthsAgoState,
  accounts30DaysAgoState,
  isAddAccountOpenState,
  isLoadingDataState,
  monNameState,
  isAccountModalState,
  accounts90DaysAgoState,
  dataCoverageState,
} from '../../../recoil/atoms';
import axios from 'axios';
import swal from 'sweetalert';

const AddAccountForm = () => {
  const setIsAddAccountOpen = useSetRecoilState(isAddAccountOpenState);
  const headers = useRecoilValue(headerState);

  const setDataCoverage = useSetRecoilState(dataCoverageState);
  const setAccountsThisMonth = useSetRecoilState(thisMonthAccountsState);
  const setAccounts = useSetRecoilState(accountsState);
  const setAccounts2MonthsAgo = useSetRecoilState(accounts2MonthsAgoState);
  const setAccounts3MonthsAgo = useSetRecoilState(accounts3MonthsAgoState);
  const setAccounts30DaysAgo = useSetRecoilState(accounts30DaysAgoState);
  const setAccounts90DaysAgo = useSetRecoilState(accounts90DaysAgoState);
  const setAccount = useSetRecoilState(accountState);
  const setIsLoadingData = useSetRecoilState(isLoadingDataState);
  const setMonName = useSetRecoilState(monNameState);
  const setAccountModal = useSetRecoilState(isAccountModalState);

  const environment = process.env.NODE_ENV;

  const ppcSpecialists = [
    'Gwen',
    'Jose Ramirez',
    'Joshua Simpson',
    'Joyce Raypan',
    'Mich',
    'Red Quijano',
    'Mike More',
  ];

  const accountManagers = [
    'Allyson Hunt',
    'Mark Lorentz',
    'Philip Pasma',
    'Valeria Angel',
    'Vanessa Iles',
    'Amanda Cattle',
  ];

  const [input, setInput] = useState({
    name: '',
    email: '',
    google_cid: null,
    microsoft_cid: null,
    microsoft_aid: null,
    lsa_cid: null,
    account_manager: 'Allyson Hunt',
    ppc_specialist: 'Gwen',
    state_country: '',
    idx: '',
    ad_var: 'default',
    budget: '',
  });

  const handleChange = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setInput({ ...input, [name]: value });
  };

  const handleClose = () => setIsAddAccountOpen(false);

  const handleRefresh = async () => {
    setAccountsThisMonth([]);
    setAccounts([]);
    setAccounts2MonthsAgo([]);
    setAccounts3MonthsAgo([]);
    setAccounts30DaysAgo([]);
    setAccounts90DaysAgo([]);
    setAccount({
      id: '',
      name: 'None',
      email: 'your@email.com',
      microsoft_cid: '',
      account_manager: '',
      ppc_specialist: '',
      state_country: '',
      city: '',
      idx: '',
      ad_var: '',
      google_account: {
        id: 0,
        name: '',
        spend: '',
        conversions: '',
        cost_per_conversion: '',
        impressions: '',
        clicks: '',
        ctr: '',
      },
      microsoft_account: {
        id: '',
        name: '',
        spend: '',
        conversions: '',
        cost_per_conversion: '',
        impressions: '',
        clicks: '',
        ctr: '',
      },
    });
    setMonName('30-Day');
    setDataCoverage('This Mo');
    setAccountModal(false);

    await axios({
      method: 'get',
      url: `${
        environment === 'production' ? 'https://apimakeforth.herokuapp.com' : ''
      }/api/account_info/this-mo/`,
      headers: headers,
    })
      .then((res) => {
        setAccountsThisMonth(res.data);
        setIsLoadingData(false);
      })
      .catch((err) => {
        swal('An Error Occured!', err.message, 'error');
        setIsLoadingData(false);
      });
  };

  const handlePostAccountInfo = async (time_frame: string) => {
    await axios({
      method: 'post',
      url: `${
        environment === 'production' ? 'https://apimakeforth.herokuapp.com' : ''
      }/api/account_info/${time_frame}/`,
      headers: headers,
      data: {
        ...input,
        lsa_cid: input.lsa_cid || null,
        google_cid: input.google_cid || null,
        microsoft_cid: input.microsoft_cid || null,
        microsoft_aid: input.microsoft_aid || null,
      },
    }).catch((err) => console.log(err));
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (
      input.name &&
      input.account_manager &&
      input.ppc_specialist &&
      input.state_country &&
      input.idx &&
      input.budget
    ) {
      handleClose();
      setAccountModal(false);
      setIsLoadingData(true);
    }

    await axios({
      method: 'post',
      url: `${
        environment === 'production' ? 'https://apimakeforth.herokuapp.com' : ''
      }/api/account_info/jan/`,
      headers: headers,
      data: {
        ...input,
        lsa_cid: input.lsa_cid || null,
        google_cid: input.google_cid || null,
        microsoft_cid: input.microsoft_cid || null,
        microsoft_aid: input.microsoft_aid || null,
      },
    })
      .then(() => handlePostAccountInfo('feb'))
      .then(() => handlePostAccountInfo('mar'))
      .then(() => handlePostAccountInfo('apr'))
      .then(() => handlePostAccountInfo('may'))
      .then(() => handlePostAccountInfo('jun'))
      .then(() => handlePostAccountInfo('jul'))
      .then(() => handlePostAccountInfo('aug'))
      .then(() => handlePostAccountInfo('sept'))
      .then(() => handlePostAccountInfo('oct'))
      .then(() => handlePostAccountInfo('nov'))
      .then(() => handlePostAccountInfo('dec'))
      .then(() => handlePostAccountInfo('90-days'))
      .then(() => handlePostAccountInfo('30-days'))
      .then(() => handlePostAccountInfo('this-mo'))
      .then(() => handlePostAccountInfo('3-days'))
      .then(() => handlePostAccountInfo('yesterday'))
      .then(() => handlePostAccountInfo('all-time'))
      .then(() => handleRefresh())
      .catch((err) => {
        swal('An Error Occured!', err.message, 'error');
        setIsLoadingData(false);
      });
  };
  return (
    <ModalBase handleClose={handleClose}>
      <FormLayoutBase
        btnLabel='Add Account'
        handleSubmit={handleSubmit}
        addPadding
      >
        <FormInput
          title='Account Name'
          name='name'
          value={input.name}
          onChange={handleChange}
        />
        <FormInput
          title='Email Address (Optional)'
          name='email'
          value={input.email}
          onChange={handleChange}
        />
        <FormInput
          title='Google CID (Optional)'
          name='google_cid'
          value={input.google_cid}
          onChange={handleChange}
        />
        <FormInput
          title='Microsoft CID (Optional)'
          name='microsoft_cid'
          value={input.microsoft_cid}
          onChange={handleChange}
        />
        <FormInput
          title='Microsoft AID (Fill out if above is filled out)'
          name='microsoft_aid'
          value={input.microsoft_aid}
          onChange={handleChange}
        />
        <FormInput
          title='LSA CID (Optional)'
          name='lsa_cid'
          value={input.lsa_cid}
          onChange={handleChange}
        />

        <FormDropdown
          title='Account Manager'
          name='account_manager'
          handlePick={handleChange}
          options={accountManagers}
        />
        <FormDropdown
          title='PPC Specialist'
          name='ppc_specialist'
          options={ppcSpecialists}
          handlePick={handleChange}
        />
        <FormInput
          title='State And Country (Format: NY, US)'
          name='state_country'
          value={input.state_country}
          onChange={handleChange}
        />
        <FormInput
          title='IDX'
          name='idx'
          value={input.idx}
          onChange={handleChange}
        />
        <FormInput
          title='Budget'
          name='budget'
          value={input.budget}
          onChange={handleChange}
        />
      </FormLayoutBase>
    </ModalBase>
  );
};

export default AddAccountForm;
