import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';

export type FirebaseUser = firebase.User;

const config = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJ_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
};

firebase.initializeApp(config);

export const auth = firebase.auth();
export const firestore = firebase.firestore();

export const getCrendetials = (email: string, password: string) => {
  return firebase.auth.EmailAuthProvider.credential(email, password);
};

// Secondary app was created to avoid reauthentication every time you add another user
const secondaryApp = firebase.initializeApp(config, 'Secondary');
export const auth2 = secondaryApp.auth();
