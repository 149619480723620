import React, { useState } from 'react';
import {
  SetterOrUpdater,
  useRecoilState,
  useRecoilValue,
  useSetRecoilState,
} from 'recoil';
import { month } from '../../helpers/dateUtils';
import {
  monNameState,
  isLoadingDataState,
  isLoadingSearchTermState,
  isLoadingUserLocationState,
  userState,
  accountState,
  searchTerm30DaysAgoState,
  bingSearchTerm30DaysAgoState,
  accounts30DaysAgoState,
  userLocation30DaysAgoState,
  searchTermFilterState,
  accountsAprState,
  accountsAugState,
  accountsDecState,
  accountsFebState,
  accountsJanState,
  accountsJulState,
  accountsJunState,
  accountsMarState,
  accountsMayState,
  accountsNovState,
  accountsOctState,
  accountsSeptState,
  searchTermAprState,
  searchTermAugState,
  searchTermDecState,
  searchTermFebState,
  searchTermJanState,
  searchTermJulState,
  searchTermJunState,
  searchTermMarState,
  searchTermMayState,
  searchTermNovState,
  searchTermOctState,
  searchTermSeptState,
  bingSearchTermAprState,
  bingSearchTermAugState,
  bingSearchTermDecState,
  bingSearchTermFebState,
  bingSearchTermJanState,
  bingSearchTermJulState,
  bingSearchTermJunState,
  bingSearchTermMarState,
  bingSearchTermMayState,
  bingSearchTermNovState,
  bingSearchTermOctState,
  bingSearchTermSeptState,
  userLocationAprState,
  userLocationAugState,
  userLocationDecState,
  userLocationFebState,
  userLocationJanState,
  userLocationJulState,
  userLocationJunState,
  userLocationMarState,
  userLocationMayState,
  userLocationNovState,
  userLocationOctState,
  userLocationSeptState,
  bingUserLocation30DaysAgoState,
  bingUserLocationAprState,
  bingUserLocationAugState,
  bingUserLocationDecState,
  bingUserLocationFebState,
  bingUserLocationJanState,
  bingUserLocationJulState,
  bingUserLocationJunState,
  bingUserLocationMarState,
  bingUserLocationMayState,
  bingUserLocationNovState,
  bingUserLocationOctState,
  bingUserLocationSeptState,
  userLocationFilterState,
  accountsAllTimeState,
  deviceChartFilterState,
} from '../../recoil/atoms';
import {
  adminAccountState,
  headerState,
} from '../../recoil/selectors/dataSelectors';
import axios from 'axios';
import CoverageTemplate from '../date-related-templates/CoverageTemplate';
import { Account, SearchTerm, UserLocation } from '../../helpers/types';

type Props = {
  setReportsComponent?: React.Dispatch<React.SetStateAction<string>>;
};

const Coverage = ({ setReportsComponent }: Props) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const [monName, setMonName] = useRecoilState(monNameState);

  const user = useRecoilValue(userState);

  const [superadminAccount, setSuperadminAccount] =
    useRecoilState(accountState);
  const adminAccount = useRecoilValue(adminAccountState);

  const environment = process.env.NODE_ENV;

  let account = user?.role === 'superadmin' ? superadminAccount : adminAccount;

  const headers = useRecoilValue(headerState);
  const setIsLoadingData = useSetRecoilState(isLoadingDataState);
  const setIsLoadingST = useSetRecoilState(isLoadingSearchTermState);
  const setIsLoadingUL = useSetRecoilState(isLoadingUserLocationState);
  const setSearchTerm30DaysAgo = useSetRecoilState(searchTerm30DaysAgoState);
  const setSearchTermJan = useSetRecoilState(searchTermJanState);
  const setSearchTermFeb = useSetRecoilState(searchTermFebState);
  const setSearchTermMar = useSetRecoilState(searchTermMarState);
  const setSearchTermApr = useSetRecoilState(searchTermAprState);
  const setSearchTermMay = useSetRecoilState(searchTermMayState);
  const setSearchTermJun = useSetRecoilState(searchTermJunState);
  const setSearchTermJul = useSetRecoilState(searchTermJulState);
  const setSearchTermAug = useSetRecoilState(searchTermAugState);
  const setSearchTermSept = useSetRecoilState(searchTermSeptState);
  const setSearchTermOct = useSetRecoilState(searchTermOctState);
  const setSearchTermNov = useSetRecoilState(searchTermNovState);
  const setSearchTermDec = useSetRecoilState(searchTermDecState);
  const [accountsAllTime, setAccountsAllTime] =
    useRecoilState(accountsAllTimeState);
  const [accounts30DaysAgo, setAccount30DaysAgo] = useRecoilState(
    accounts30DaysAgoState
  );
  const [accountsJan, setAccountsJan] = useRecoilState(accountsJanState);
  const [accountsFeb, setAccountsFeb] = useRecoilState(accountsFebState);
  const [accountsMar, setAccountsMar] = useRecoilState(accountsMarState);
  const [accountsApr, setAccountsApr] = useRecoilState(accountsAprState);
  const [accountsMay, setAccountsMay] = useRecoilState(accountsMayState);
  const [accountsJun, setAccountsJun] = useRecoilState(accountsJunState);
  const [accountsJul, setAccountsJul] = useRecoilState(accountsJulState);
  const [accountsAug, setAccountsAug] = useRecoilState(accountsAugState);
  const [accountsSept, setAccountsSept] = useRecoilState(accountsSeptState);
  const [accountsOct, setAccountsOct] = useRecoilState(accountsOctState);
  const [accountsNov, setAccountsNov] = useRecoilState(accountsNovState);
  const [accountsDec, setAccountsDec] = useRecoilState(accountsDecState);
  const setBingSearchTerm30DaysAgo = useSetRecoilState(
    bingSearchTerm30DaysAgoState
  );
  const setBingSearchTermsJan = useSetRecoilState(bingSearchTermJanState);
  const setBingSearchTermsFeb = useSetRecoilState(bingSearchTermFebState);
  const setBingSearchTermsMar = useSetRecoilState(bingSearchTermMarState);
  const setBingSearchTermsApr = useSetRecoilState(bingSearchTermAprState);
  const setBingSearchTermsMay = useSetRecoilState(bingSearchTermMayState);
  const setBingSearchTermsJun = useSetRecoilState(bingSearchTermJunState);
  const setBingSearchTermsJul = useSetRecoilState(bingSearchTermJulState);
  const setBingSearchTermsAug = useSetRecoilState(bingSearchTermAugState);
  const setBingSearchTermsSept = useSetRecoilState(bingSearchTermSeptState);
  const setBingSearchTermsOct = useSetRecoilState(bingSearchTermOctState);
  const setBingSearchTermsNov = useSetRecoilState(bingSearchTermNovState);
  const setBingSearchTermsDec = useSetRecoilState(bingSearchTermDecState);
  const setUserLocation30DaysAgo = useSetRecoilState(
    userLocation30DaysAgoState
  );
  const setUserLocationJan = useSetRecoilState(userLocationJanState);
  const setUserLocationFeb = useSetRecoilState(userLocationFebState);
  const setUserLocationMar = useSetRecoilState(userLocationMarState);
  const setUserLocationApr = useSetRecoilState(userLocationAprState);
  const setUserLocationMay = useSetRecoilState(userLocationMayState);
  const setUserLocationJun = useSetRecoilState(userLocationJunState);
  const setUserLocationJul = useSetRecoilState(userLocationJulState);
  const setUserLocationAug = useSetRecoilState(userLocationAugState);
  const setUserLocationSept = useSetRecoilState(userLocationSeptState);
  const setUserLocationOct = useSetRecoilState(userLocationOctState);
  const setUserLocationNov = useSetRecoilState(userLocationNovState);
  const setUserLocationDec = useSetRecoilState(userLocationDecState);
  const setBingUserLocation30DaysAgo = useSetRecoilState(
    bingUserLocation30DaysAgoState
  );
  const setBingUserLocationJan = useSetRecoilState(bingUserLocationJanState);
  const setBingUserLocationFeb = useSetRecoilState(bingUserLocationFebState);
  const setBingUserLocationMar = useSetRecoilState(bingUserLocationMarState);
  const setBingUserLocationApr = useSetRecoilState(bingUserLocationAprState);
  const setBingUserLocationMay = useSetRecoilState(bingUserLocationMayState);
  const setBingUserLocationJun = useSetRecoilState(bingUserLocationJunState);
  const setBingUserLocationJul = useSetRecoilState(bingUserLocationJulState);
  const setBingUserLocationAug = useSetRecoilState(bingUserLocationAugState);
  const setBingUserLocationSept = useSetRecoilState(bingUserLocationSeptState);
  const setBingUserLocationOct = useSetRecoilState(bingUserLocationOctState);
  const setBingUserLocationNov = useSetRecoilState(bingUserLocationNovState);
  const setBingUserLocationDec = useSetRecoilState(bingUserLocationDecState);
  const setSearchTermFilter = useSetRecoilState(searchTermFilterState);
  const setUserLocationFilter = useSetRecoilState(userLocationFilterState);
  const setDeviceChartFilter = useSetRecoilState(deviceChartFilterState);

  const accountMonths = [
    accountsJan,
    accountsFeb,
    accountsMar,
    accountsApr,
    accountsMay,
    accountsJun,
    accountsJul,
    accountsAug,
    accountsSept,
    accountsOct,
    accountsNov,
    accountsDec,
  ];

  const getSuperadminAccount = (accounts: Account[]) => {
    return accounts.filter((item) => item.name === superadminAccount.name)[0];
  };

  const handleGetRequest = async (
    timeFrame: string,
    setSearchTerms: SetterOrUpdater<SearchTerm[]> | null,
    setBingSearchTerms: SetterOrUpdater<SearchTerm[]> | null,
    setUserLocation: SetterOrUpdater<UserLocation[]> | null,
    setBingUserLocation: SetterOrUpdater<UserLocation[]> | null,
    setAccounts: SetterOrUpdater<Account[]>
  ) => {
    await axios({
      method: 'get',
      headers: headers,
      url: `${
        environment === 'production' ? 'https://apimakeforth.herokuapp.com' : ''
      }/api/account_info/${timeFrame}${
        account.google_cid ? account.google_cid : account.microsoft_cid
      }/`,
    })
      .then((res) => {
        if (user?.role === 'superadmin') {
          setAccounts((accounts) => [...accounts, res.data]);
          setSuperadminAccount(res.data);
        }
        if (user?.role === 'admin') setAccounts([res.data]);
        setIsLoadingData(false);
        if (!res.data.mobile) setDeviceChartFilter('Microsoft');
        if (!res.data.bing_mobile) setDeviceChartFilter('Google');
      })
      .catch((err) => {
        console.log(err);
        setIsLoadingData(false);
      });

    if (setSearchTerms) {
      await axios({
        method: 'get',
        headers: headers,
        url: `${
          environment === 'production'
            ? 'https://apimakeforth.herokuapp.com'
            : ''
        }/api/search_term/${timeFrame}filter/${account.google_cid}/`,
      })
        .then((res) => {
          setSearchTerms((searchTerms) => [...searchTerms, ...res.data]);
          if (res.data.length < 1) {
            setSearchTermFilter('Microsoft');
          }
        })
        .catch((err) => console.log(err));
    }

    if (setBingSearchTerms) {
      await axios({
        method: 'get',
        headers: headers,
        url: `${
          environment === 'production'
            ? 'https://apimakeforth.herokuapp.com'
            : ''
        }/api/bing_searchterm/${timeFrame}filter/${account.microsoft_cid}/`,
      })
        .then((res) => {
          setBingSearchTerms((searchTerms) => [...searchTerms, ...res.data]);
          if (res.data.length < 1) {
            setSearchTermFilter('Google');
            setUserLocationFilter('Google');
          }
          setIsLoadingST(false);
        })
        .catch((err) => {
          console.log(err);
          setIsLoadingST(false);
        });
    }

    if (setUserLocation) {
      await axios({
        method: 'get',
        headers: headers,
        url: `${
          environment === 'production'
            ? 'https://apimakeforth.herokuapp.com'
            : ''
        }/api/user_location/${timeFrame}filter/${account.google_cid}/`,
      })
        .then((res) => {
          setUserLocation((userLocs) => [...userLocs, ...res.data]);
          setIsLoadingUL(false);
          if (res.data.length < 1) {
            setUserLocationFilter('Microsoft');
          }
        })
        .catch((err) => {
          console.log(err);
          setIsLoadingUL(false);
        });
    }

    if (setBingUserLocation) {
      await axios({
        method: 'get',
        headers: headers,
        url: `${
          environment === 'production'
            ? 'https://apimakeforth.herokuapp.com'
            : ''
        }/api/bing_user_location/${timeFrame}filter/${account.microsoft_cid}/`,
      })
        .then((res) => {
          setBingUserLocation((userLocs) => [...userLocs, ...res.data]);
          setIsLoadingUL(false);
          if (res.data.length < 1) {
            setUserLocationFilter('Google');
          }
        })
        .catch((err) => {
          console.log(err);
          setIsLoadingUL(false);
        });
    }
  };

  const handleClickOption = (
    index: number,
    accounts: Account[],
    setSearchTerms: SetterOrUpdater<SearchTerm[]> | null,
    setBingSearchTerms: SetterOrUpdater<SearchTerm[]> | null,
    setUserLocation: SetterOrUpdater<UserLocation[]> | null,
    setBingUserLocation: SetterOrUpdater<UserLocation[]> | null,
    setAccounts: SetterOrUpdater<Account[]>
  ) => {
    setIsMenuOpen(false);
    setSearchTermFilter('Google');
    setReportsComponent && setReportsComponent('Platforms');

    if (account?.name === 'None') return;

    const filteredAccount = accounts.filter(
      (item) => item.name === account.name
    )[0];
    setMonName(
      index === -1 ? '30-Day' : index === 12 ? 'All Time' : month[index]
    );

    if (!filteredAccount) {
      setIsLoadingData(true);
      setIsLoadingST(true);
      setIsLoadingUL(true);
      handleGetRequest(
        index === -1
          ? '30-days/'
          : index === 12
          ? 'all-time/'
          : `${month[index].toLowerCase()}/`,
        setSearchTerms,
        setBingSearchTerms,
        setUserLocation,
        setBingUserLocation,
        setAccounts
      );
    } else if (user?.role === 'superadmin') {
      setSuperadminAccount(
        index === -1
          ? getSuperadminAccount(accounts30DaysAgo)
          : index === 12
          ? getSuperadminAccount(accountsAllTime)
          : getSuperadminAccount(accountMonths[index])
      );
    }
  };

  return (
    <CoverageTemplate
      setIsMenuOpen={setIsMenuOpen}
      isMenuOpen={isMenuOpen}
      coverage={monName}
      customOptions={[
        {
          option: 'Last 30 Days',
          onClickOption: () =>
            handleClickOption(
              -1,
              accounts30DaysAgo,
              setSearchTerm30DaysAgo,
              setBingSearchTerm30DaysAgo,
              setUserLocation30DaysAgo,
              setBingUserLocation30DaysAgo,
              setAccount30DaysAgo
            ),
        },
      ]}
      account={account}
      onClickJan={() =>
        handleClickOption(
          0,
          accountsJan,
          setSearchTermJan,
          setBingSearchTermsJan,
          setUserLocationJan,
          setBingUserLocationJan,
          setAccountsJan
        )
      }
      onClickFeb={() =>
        handleClickOption(
          1,
          accountsFeb,
          setSearchTermFeb,
          setBingSearchTermsFeb,
          setUserLocationFeb,
          setBingUserLocationFeb,
          setAccountsFeb
        )
      }
      onClickMar={() =>
        handleClickOption(
          2,
          accountsMar,
          setSearchTermMar,
          setBingSearchTermsMar,
          setUserLocationMar,
          setBingUserLocationMar,
          setAccountsMar
        )
      }
      onClickApr={() =>
        handleClickOption(
          3,
          accountsApr,
          setSearchTermApr,
          setBingSearchTermsApr,
          setUserLocationApr,
          setBingUserLocationApr,
          setAccountsApr
        )
      }
      onClickMay={() =>
        handleClickOption(
          4,
          accountsMay,
          setSearchTermMay,
          setBingSearchTermsMay,
          setUserLocationMay,
          setBingUserLocationMay,
          setAccountsMay
        )
      }
      onClickJun={() =>
        handleClickOption(
          5,
          accountsJun,
          setSearchTermJun,
          setBingSearchTermsJun,
          setUserLocationJun,
          setBingUserLocationJun,
          setAccountsJun
        )
      }
      onClickJul={() =>
        handleClickOption(
          6,
          accountsJul,
          setSearchTermJul,
          setBingSearchTermsJul,
          setUserLocationJul,
          setBingUserLocationJul,
          setAccountsJul
        )
      }
      onClickAug={() =>
        handleClickOption(
          7,
          accountsAug,
          setSearchTermAug,
          setBingSearchTermsAug,
          setUserLocationAug,
          setBingUserLocationAug,
          setAccountsAug
        )
      }
      onClickSept={() =>
        handleClickOption(
          8,
          accountsSept,
          setSearchTermSept,
          setBingSearchTermsSept,
          setUserLocationSept,
          setBingUserLocationSept,
          setAccountsSept
        )
      }
      onClickOct={() =>
        handleClickOption(
          9,
          accountsOct,
          setSearchTermOct,
          setBingSearchTermsOct,
          setUserLocationOct,
          setBingUserLocationOct,
          setAccountsOct
        )
      }
      onClickNov={() =>
        handleClickOption(
          10,
          accountsNov,
          setSearchTermNov,
          setBingSearchTermsNov,
          setUserLocationNov,
          setBingUserLocationNov,
          setAccountsNov
        )
      }
      onClickDec={() =>
        handleClickOption(
          11,
          accountsDec,
          setSearchTermDec,
          setBingSearchTermsDec,
          setUserLocationDec,
          setBingUserLocationDec,
          setAccountsDec
        )
      }
      onClickAllTime={() =>
        handleClickOption(
          12,
          accountsAllTime,
          null,
          null,
          null,
          null,
          setAccountsAllTime
        )
      }
    />
  );
};

export default Coverage;
