import React from 'react';
import { useRecoilValue } from 'recoil';
import { lightModeState } from '../../recoil/atoms';
import { searchState } from '../../recoil/atoms';

type Props = {
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  setShowSearch: React.Dispatch<React.SetStateAction<boolean>>;
};

const SearchModal = ({ handleChange, setShowSearch }: Props) => {
  const lightMode = useRecoilValue(lightModeState);
  const search = useRecoilValue(searchState);
  return (
    <div
      className={`px-3 pb-2 pt-3 ${
        lightMode ? 'bg-gray-50' : 'bg-gray-800'
      } absolute  sm:left-24 z-50 sm:ml-2.5 sm:w-1/5 shadow-sm`}
    >
      <input
        type='search'
        name='search'
        placeholder='🔍 Search'
        onChange={handleChange}
        onKeyDown={(e) => {
          if (e.key === 'Escape') setShowSearch(false);
        }}
        className={`${
          lightMode ? 'bg-gray-100 text-gray-900' : 'bg-black text-white'
        } rounded-md border-gray-500 border-l border-r border-t border-b focus:outline-none form-control-md w-full mx-1 px-2 mb-0.5 py-1.5 h-1 text-xs sm:text-base`}
        autoComplete='off'
        value={search}
      />
    </div>
  );
};

export default SearchModal;
