import { default as TablePaginationMUI } from '@material-ui/core/TablePagination';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { useRecoilValue } from 'recoil';
import { lightModeState, pageState } from '../../recoil/atoms';
import { Account, SearchTerm, UserLocation } from '../../helpers/types';

type Props = {
  items: Account[] | SearchTerm[] | UserLocation[];
  rowsPerPage: number;
  page: number;
  handleChangePage: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    page: number
  ) => void;
  handleChangeRowsPerPage: (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => void;
};

const TablePagination = ({
  items,
  rowsPerPage,
  page,
  handleChangePage,
  handleChangeRowsPerPage,
}: Props) => {
  const lightMode = useRecoilValue(lightModeState);
  const appPage = useRecoilValue(pageState);
  const tablePaginationStyle = {
    color: lightMode ? 'rgb(17 24 39)' : 'rgb(255 255 255)',
    fontSize: appPage !== 'reports' ? '.57rem' : '.71rem',
    '@media (min-width: 640px)': {
      fontSize: '1rem',
    },
    '@media (max-width: 640px)': {
      marginTop: '5px',
    },
  };

  const useStyles = makeStyles({
    caption: {
      ...tablePaginationStyle,
      '@media (max-width: 640px)': {
        marginRight: '.75rem',
      },
    },
    toolbar: tablePaginationStyle,
  });

  return (
    <TablePaginationMUI
      rowsPerPageOptions={[10, 20, 50, 100]}
      component='div'
      count={items.length}
      rowsPerPage={rowsPerPage}
      page={page}
      onPageChange={handleChangePage}
      onRowsPerPageChange={handleChangeRowsPerPage}
      color='secondary'
      classes={{
        caption: useStyles().caption,
        toolbar: useStyles().toolbar,
      }}
    />
  );
};

export default TablePagination;
