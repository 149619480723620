import React from 'react';
import LineGraph from '../graph-templates/LineGraph';
import {
  lightModeState,
  userState,
  accountState,
  accountsState,
  accounts2MonthsAgoState,
  accounts3MonthsAgoState,
  accounts30DaysAgoState,
  googleReportsSelectedState,
  microsoftReportsSelectedState,
  lsaReportsSelectedState,
  adDataSelectedState,
  secondCoverageGraphState,
} from '../../recoil/atoms';
import { adminAccountState } from '../../recoil/selectors/dataSelectors';
import { useRecoilValue, useRecoilState } from 'recoil';
import { Account } from '../../helpers/types';

type Props = {
  isPrint?: boolean;
};

const CoverageSecondLineGraph = ({ isPrint }: Props) => {
  const lightMode = useRecoilValue(lightModeState);
  const googleSelected = useRecoilValue(googleReportsSelectedState);
  const microsoftSelected = useRecoilValue(microsoftReportsSelectedState);
  const lsaSelected = useRecoilValue(lsaReportsSelectedState);
  const adSelected = useRecoilValue(adDataSelectedState);
  const user = useRecoilValue(userState);
  const adminAccount = useRecoilValue(adminAccountState);
  const [coverageGraph, setCoverageGraph] = useRecoilState(
    secondCoverageGraphState
  );
  const superadminAccount = useRecoilValue(accountState);
  const account =
    user?.role === 'superadmin' ? superadminAccount : adminAccount;

  const filterAccount = (accs: Account[]) => {
    return accs.filter((acc) => acc.name === account.name);
  };

  const accounts = useRecoilValue(accountsState);
  const accounts2MonthsAgo = useRecoilValue(accounts2MonthsAgoState);
  const accounts3MonthsAgo = useRecoilValue(accounts3MonthsAgoState);
  const accounts30DaysAgo = useRecoilValue(accounts30DaysAgoState);

  const accountLastMonth = filterAccount(accounts)[0];
  const account2MonthsAgo = filterAccount(accounts2MonthsAgo)[0];
  const account3MonthsAgo = filterAccount(accounts3MonthsAgo)[0];
  const account30DaysAgo = filterAccount(accounts30DaysAgo)[0];

  const google3MonthsAgo =
    account3MonthsAgo && account3MonthsAgo.google_account && googleSelected
      ? coverageGraph === 'Leads'
        ? parseFloat(account3MonthsAgo.google_account.conversions)
        : coverageGraph === 'Budget' || coverageGraph === 'CPL'
        ? parseFloat(account3MonthsAgo.google_account.spend)
        : coverageGraph === 'Impressions'
        ? parseFloat(account3MonthsAgo.google_account.impressions)
        : parseFloat(account3MonthsAgo.google_account.clicks)
      : 0;

  const microsoft3MonthsAgo =
    account3MonthsAgo && account3MonthsAgo.microsoft_account && googleSelected
      ? coverageGraph === 'Leads'
        ? parseFloat(account3MonthsAgo.microsoft_account.conversions)
        : coverageGraph === 'Budget' || coverageGraph === 'CPL'
        ? parseFloat(account3MonthsAgo.microsoft_account.spend)
        : coverageGraph === 'Impressions'
        ? parseFloat(account3MonthsAgo.microsoft_account.impressions)
        : parseFloat(account3MonthsAgo.microsoft_account.clicks)
      : 0;

  const lsa3MonthsAgo =
    account3MonthsAgo && account3MonthsAgo.lsa_account && lsaSelected
      ? coverageGraph === 'Leads'
        ? parseFloat(account3MonthsAgo.lsa_account.conversions)
        : parseFloat(account3MonthsAgo.lsa_account.spend)
      : 0;

  const ad3MonthsAgo =
    account3MonthsAgo && account3MonthsAgo.additional_data && adSelected
      ? coverageGraph === 'Leads'
        ? parseFloat(account3MonthsAgo.additional_data.conversions || '0')
        : parseFloat(account3MonthsAgo.additional_data.spend || '0')
      : 0;

  const google2MonthsAgo =
    account2MonthsAgo && account2MonthsAgo.google_account && googleSelected
      ? coverageGraph === 'Leads'
        ? parseFloat(account2MonthsAgo.google_account.conversions)
        : coverageGraph === 'Budget' || coverageGraph === 'CPL'
        ? parseFloat(account2MonthsAgo.google_account.spend)
        : coverageGraph === 'Impressions'
        ? parseFloat(account2MonthsAgo.google_account.impressions)
        : parseFloat(account2MonthsAgo.google_account.clicks)
      : 0;

  const microsoft2MonthsAgo =
    account2MonthsAgo &&
    account2MonthsAgo.microsoft_account &&
    microsoftSelected
      ? coverageGraph === 'Leads'
        ? parseFloat(account2MonthsAgo.microsoft_account.conversions)
        : coverageGraph === 'Budget' || coverageGraph === 'CPL'
        ? parseFloat(account2MonthsAgo.microsoft_account.spend)
        : coverageGraph === 'Impressions'
        ? parseFloat(account2MonthsAgo.microsoft_account.impressions)
        : parseFloat(account2MonthsAgo.microsoft_account.clicks)
      : 0;

  const lsa2MonthsAgo =
    account2MonthsAgo && account2MonthsAgo.lsa_account && lsaSelected
      ? coverageGraph === 'Leads'
        ? parseFloat(account2MonthsAgo.lsa_account.conversions)
        : parseFloat(account2MonthsAgo.lsa_account.spend)
      : 0;

  const ad2MonthsAgo =
    account2MonthsAgo && account2MonthsAgo.additional_data && adSelected
      ? coverageGraph === 'Leads'
        ? parseFloat(account2MonthsAgo.additional_data.conversions || '0')
        : parseFloat(account2MonthsAgo.additional_data.spend || '0')
      : 0;

  const googleLastMonth =
    accountLastMonth && accountLastMonth.google_account && googleSelected
      ? coverageGraph === 'Leads'
        ? parseFloat(accountLastMonth.google_account.conversions)
        : coverageGraph === 'Budget' || coverageGraph === 'CPL'
        ? parseFloat(accountLastMonth.google_account.spend)
        : coverageGraph === 'Impressions'
        ? parseFloat(accountLastMonth.google_account.impressions)
        : parseFloat(accountLastMonth.google_account.clicks)
      : 0;

  const microsoftLastMonth =
    accountLastMonth && accountLastMonth.microsoft_account && microsoftSelected
      ? coverageGraph === 'Leads'
        ? parseFloat(accountLastMonth.microsoft_account.conversions)
        : coverageGraph === 'Budget' || coverageGraph === 'CPL'
        ? parseFloat(accountLastMonth.microsoft_account.spend)
        : coverageGraph === 'Impressions'
        ? parseFloat(accountLastMonth.microsoft_account.impressions)
        : parseFloat(accountLastMonth.microsoft_account.clicks)
      : 0;

  const lsaLastMonth =
    accountLastMonth && accountLastMonth.lsa_account && lsaSelected
      ? coverageGraph === 'Leads'
        ? parseFloat(accountLastMonth.lsa_account.conversions)
        : parseFloat(accountLastMonth.lsa_account.spend)
      : 0;

  const adLastMonth =
    accountLastMonth && accountLastMonth.additional_data && adSelected
      ? coverageGraph === 'Leads'
        ? parseFloat(accountLastMonth.additional_data.conversions || '0')
        : parseFloat(accountLastMonth.additional_data.spend || '0')
      : 0;

  const google30DaysAgo =
    account30DaysAgo && account30DaysAgo.google_account && googleSelected
      ? coverageGraph === 'Leads'
        ? parseFloat(account30DaysAgo.google_account.conversions)
        : coverageGraph === 'Budget' || coverageGraph === 'CPL'
        ? parseFloat(account30DaysAgo.google_account.spend)
        : coverageGraph === 'Impressions'
        ? parseFloat(account30DaysAgo.google_account.impressions)
        : parseFloat(account30DaysAgo.google_account.clicks)
      : 0;

  const microsoft30DaysAgo =
    account30DaysAgo && account30DaysAgo.microsoft_account && microsoftSelected
      ? coverageGraph === 'Leads'
        ? parseFloat(account30DaysAgo.microsoft_account.conversions)
        : coverageGraph === 'Budget' || coverageGraph === 'CPL'
        ? parseFloat(account30DaysAgo.microsoft_account.spend)
        : coverageGraph === 'Impressions'
        ? parseFloat(account30DaysAgo.microsoft_account.impressions)
        : parseFloat(account30DaysAgo.microsoft_account.clicks)
      : 0;

  const lsa30DaysAgo =
    account30DaysAgo && account30DaysAgo.lsa_account && lsaSelected
      ? coverageGraph === 'Leads'
        ? parseFloat(account30DaysAgo.lsa_account?.conversions)
        : parseFloat(account30DaysAgo.lsa_account?.spend)
      : 0;

  const ad30DaysAgo =
    account30DaysAgo && account30DaysAgo.additional_data && adSelected
      ? coverageGraph === 'Leads'
        ? parseFloat(account30DaysAgo.additional_data.conversions || '0')
        : parseFloat(account30DaysAgo.additional_data.spend || '0')
      : 0;

  const data3MonthsAgo =
    google3MonthsAgo +
    microsoft3MonthsAgo +
    (coverageGraph === 'Leads' ||
    coverageGraph === 'Budget' ||
    coverageGraph === 'CPL'
      ? lsa3MonthsAgo
      : 0) +
    (coverageGraph === 'Leads' ||
    coverageGraph === 'Budget' ||
    coverageGraph === 'CPL'
      ? ad3MonthsAgo
      : 0);

  const data2MonthsAgo =
    google2MonthsAgo +
    microsoft2MonthsAgo +
    (coverageGraph === 'Leads' ||
    coverageGraph === 'Budget' ||
    coverageGraph === 'CPL'
      ? lsa2MonthsAgo
      : 0) +
    (coverageGraph === 'Leads' ||
    coverageGraph === 'Budget' ||
    coverageGraph === 'CPL'
      ? ad2MonthsAgo
      : 0);

  const dataLastMonth =
    googleLastMonth +
    microsoftLastMonth +
    (coverageGraph === 'Leads' ||
    coverageGraph === 'Budget' ||
    coverageGraph === 'CPL'
      ? lsaLastMonth
      : 0) +
    (coverageGraph === 'Leads' ||
    coverageGraph === 'Budget' ||
    coverageGraph === 'CPL'
      ? adLastMonth
      : 0);

  const data30DaysAgo =
    google30DaysAgo +
    microsoft30DaysAgo +
    (coverageGraph === 'Leads' ||
    coverageGraph === 'Budget' ||
    coverageGraph === 'CPL'
      ? lsa30DaysAgo
      : 0) +
    (coverageGraph === 'Leads' ||
    coverageGraph === 'Budget' ||
    coverageGraph === 'CPL'
      ? ad30DaysAgo
      : 0);

  const secondGoogle3MonthsAgo =
    account3MonthsAgo && account3MonthsAgo.google_account && googleSelected
      ? coverageGraph === 'CPL'
        ? parseFloat(account3MonthsAgo.google_account.conversions)
        : parseFloat(account3MonthsAgo.google_account.impressions)
      : 0;
  const secondMicrosoft3MonthsAgo =
    account3MonthsAgo &&
    account3MonthsAgo.microsoft_account &&
    microsoftSelected
      ? coverageGraph === 'CPL'
        ? parseFloat(account3MonthsAgo.microsoft_account.conversions)
        : parseFloat(account3MonthsAgo.microsoft_account.impressions)
      : 0;
  const secondLSA3MonthsAgo =
    account3MonthsAgo && account3MonthsAgo.lsa_account && lsaSelected
      ? parseFloat(account3MonthsAgo.lsa_account.conversions)
      : 0;
  const secondAD3MonthsAgo =
    account3MonthsAgo && account3MonthsAgo.additional_data && adSelected
      ? parseFloat(account3MonthsAgo.additional_data.conversions || '0')
      : 0;

  const secondGoogle2MonthsAgo =
    account2MonthsAgo && account2MonthsAgo.google_account && googleSelected
      ? coverageGraph === 'CPL'
        ? parseFloat(account2MonthsAgo.google_account.conversions)
        : parseFloat(account2MonthsAgo.google_account.impressions)
      : 0;
  const secondMicrosoft2MonthsAgo =
    account2MonthsAgo &&
    account2MonthsAgo.microsoft_account &&
    microsoftSelected
      ? coverageGraph === 'CPL'
        ? parseFloat(account2MonthsAgo.microsoft_account.conversions)
        : parseFloat(account2MonthsAgo.microsoft_account.impressions)
      : 0;
  const secondLSA2MonthsAgo =
    account2MonthsAgo && account2MonthsAgo.lsa_account && lsaSelected
      ? parseFloat(account2MonthsAgo.lsa_account.conversions)
      : 0;
  const secondAD2MonthsAgo =
    account2MonthsAgo && account2MonthsAgo.additional_data && adSelected
      ? parseFloat(account2MonthsAgo.additional_data.conversions || '0')
      : 0;

  const secondGoogleLastMonth =
    accountLastMonth && accountLastMonth.google_account && googleSelected
      ? coverageGraph === 'CPL'
        ? parseFloat(accountLastMonth.google_account.conversions)
        : parseFloat(accountLastMonth.google_account.impressions)
      : 0;
  const secondMicrosoftLastMonth =
    accountLastMonth && accountLastMonth.microsoft_account && microsoftSelected
      ? coverageGraph === 'CPL'
        ? parseFloat(accountLastMonth.microsoft_account.conversions)
        : parseFloat(accountLastMonth.microsoft_account.impressions)
      : 0;
  const secondLSALastMonth =
    accountLastMonth && accountLastMonth.lsa_account && lsaSelected
      ? parseFloat(accountLastMonth.lsa_account.conversions)
      : 0;
  const secondADLastMonth =
    accountLastMonth && accountLastMonth.additional_data && adSelected
      ? parseFloat(accountLastMonth.additional_data.conversions || '0')
      : 0;

  const secondGoogle30DaysAgo =
    account30DaysAgo && account30DaysAgo.google_account && googleSelected
      ? coverageGraph === 'CPL'
        ? parseFloat(account30DaysAgo.google_account.conversions)
        : parseFloat(account30DaysAgo.google_account.impressions)
      : 0;
  const secondMicrosoft30DaysAgo =
    account30DaysAgo && account30DaysAgo.microsoft_account && microsoftSelected
      ? coverageGraph === 'CPL'
        ? parseFloat(account30DaysAgo.microsoft_account.conversions)
        : parseFloat(account30DaysAgo.microsoft_account.impressions)
      : 0;
  const secondLSA30DaysAgo =
    account30DaysAgo && account30DaysAgo.lsa_account && lsaSelected
      ? parseFloat(account30DaysAgo.lsa_account.conversions)
      : 0;
  const secondAD30DaysAgo =
    account30DaysAgo && account30DaysAgo.additional_data && adSelected
      ? parseFloat(account30DaysAgo.additional_data.conversions || '0')
      : 0;

  const secondData3MonthsAgo =
    secondGoogle3MonthsAgo +
    secondMicrosoft3MonthsAgo +
    (coverageGraph === 'CPL' ? secondLSA3MonthsAgo : 0) +
    (coverageGraph === 'CPL' ? secondAD3MonthsAgo : 0);
  const secondData2MonthsAgo =
    secondGoogle2MonthsAgo +
    secondMicrosoft2MonthsAgo +
    (coverageGraph === 'CPL' ? secondLSA2MonthsAgo : 0) +
    (coverageGraph === 'CPL' ? secondAD2MonthsAgo : 0);
  const secondDataLastMonth =
    secondGoogleLastMonth +
    secondMicrosoftLastMonth +
    (coverageGraph === 'CPL' ? secondLSALastMonth : 0) +
    (coverageGraph === 'CPL' ? secondADLastMonth : 0);
  const secondData30DaysAgo =
    secondGoogle30DaysAgo +
    secondMicrosoft30DaysAgo +
    (coverageGraph === 'CPL' ? secondLSA30DaysAgo : 0) +
    (coverageGraph === 'CPL' ? secondAD30DaysAgo : 0);

  return (
    <LineGraph
      title={`${coverageGraph} Per Coverage`}
      data1={parseFloat(
        (coverageGraph === 'CPL'
          ? data3MonthsAgo /
            (secondData3MonthsAgo > 0 ? secondData3MonthsAgo : 1)
          : coverageGraph === 'CTR'
          ? (data3MonthsAgo /
              (secondData3MonthsAgo > 0 ? secondData3MonthsAgo : 1)) *
            100
          : data3MonthsAgo
        ).toFixed(2)
      )}
      data2={parseFloat(
        (coverageGraph === 'CPL'
          ? data2MonthsAgo /
            (secondData2MonthsAgo > 0 ? secondData2MonthsAgo : 1)
          : coverageGraph === 'CTR'
          ? (data2MonthsAgo /
              (secondData2MonthsAgo > 0 ? secondData2MonthsAgo : 1)) *
            100
          : data2MonthsAgo
        ).toFixed(2)
      )}
      data3={parseFloat(
        (coverageGraph === 'CPL'
          ? dataLastMonth / (secondDataLastMonth > 0 ? secondDataLastMonth : 1)
          : coverageGraph === 'CTR'
          ? (dataLastMonth /
              (secondDataLastMonth > 0 ? secondDataLastMonth : 1)) *
            100
          : dataLastMonth
        ).toFixed(2)
      )}
      data4={parseFloat(
        (coverageGraph === 'CPL'
          ? data30DaysAgo / (secondData30DaysAgo > 0 ? secondData30DaysAgo : 1)
          : coverageGraph === 'CTR'
          ? (data30DaysAgo /
              (secondData30DaysAgo > 0 ? secondData30DaysAgo : 1)) *
            100
          : data30DaysAgo
        ).toFixed(2)
      )}
      backgroundColor={
        lightMode ? 'rgba(184, 185, 210, .3)' : 'rgba(21, 29, 59, .3)'
      }
      borderColor='rgb(35, 26, 136)'
      pointBorderColor='rgb(35, 26, 136)'
      isPrint={isPrint}
      setTitle={setCoverageGraph}
    />
  );
};

export default CoverageSecondLineGraph;
