import React from 'react';
import { useRecoilValue } from 'recoil';
import { lightModeState } from '../../recoil/atoms';

type Props = {
  title: string;
};

const Title = ({ title }: Props) => {
  const lightMode = useRecoilValue(lightModeState);
  return (
    <h1
      className={`sm:text-4xl xs:text-3xl text-2xl font-medium title-font my-10 ${
        lightMode ? 'text-black' : 'text-white'
      } text-center`}
    >
      {title}
    </h1>
  );
};

export default Title;
