import Layout from '../components/layout/Layout';
import {
  lightModeState,
  isLoadingDataState,
  pageState,
  userState,
} from '../recoil/atoms';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import AddUserComponent from '../components/forms/non-modal/AddUserComponent';
import LoadAnimation from '../components/universal-decorations/LoadAnimation';
import AddUserInternal from '../components/forms/non-modal/AddUserInternal';
import { useEffect } from 'react';

const AddUser = () => {
  const lightMode = useRecoilValue(lightModeState);
  const isLoading = useRecoilValue(isLoadingDataState);
  const setPage = useSetRecoilState(pageState);
  const user = useRecoilValue(userState);

  useEffect(() => {
    setPage('adduser');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Layout>
      <div>
        <p
          className={`text-4xl font-semibold mt-16 text-center ${
            lightMode ? 'text-gray-900' : 'text-white'
          }`}
        >
          {user?.role === 'admin' ? 'Add An Admin' : 'Add A User'}
        </p>
        <div className={`w-full h-full md:grid  md:grid-cols-3`}>
          <div className={lightMode ? 'bg-gray-100' : 'bg-gray-900'}></div>
          <div
            className={`rounded h-full pt-1 flex items-center justify-center ${
              isLoading && 'mt-10'
            }`}
          >
            {isLoading ? (
              <LoadAnimation />
            ) : user?.role === 'admin' ? (
              <AddUserComponent />
            ) : (
              <AddUserInternal />
            )}
          </div>

          <div className={lightMode ? 'bg-gray-100' : 'bg-gray-900'}></div>
        </div>
      </div>
    </Layout>
  );
};

export default AddUser;
