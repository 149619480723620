import React from 'react';
import Navbar from './Navbar';
import Footer from './Footer';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
  isMenuOpenState,
  isOpenNotifBoxState,
  isOpenNotifPopupState,
  isOpenProfModalState,
  lightModeState,
  pageState,
  thisMonthAccountsState,
  userState,
} from '../../recoil/atoms';
import MobileNavMenu from './MobileNavMenu';
import Helmet from 'react-helmet';
import Profile from '../nav-comp/Profile';
import NotificationBox from '../nav-comp/NotificationBox';
import NotificationPopup from '../nav-comp/NotificationPopup';

type Props = {
  children: JSX.Element;
};

const Layout = ({ children }: Props) => {
  const page = useRecoilValue(pageState);
  const lightMode = useRecoilValue(lightModeState);
  const user = useRecoilValue(userState);
  const isOpenProfModal = useRecoilValue(isOpenProfModalState);
  const isOpenNotifBox = useRecoilValue(isOpenNotifBoxState);
  const [isMenuOpen, setIsMenuOpen] = useRecoilState(isMenuOpenState);
  const isOpenPopup = useRecoilValue(isOpenNotifPopupState);
  const thisMonthsAccounts = useRecoilValue(thisMonthAccountsState);
  const accounts = thisMonthsAccounts
    .filter((account) => account.is_active === false)
    .filter(
      (account) =>
        parseFloat(account.google_account?.impressions || '0') > 0 ||
        parseFloat(account.microsoft_account?.impressions || '0') > 0
    )
    .filter((account) => account.ppc_specialist === user?.displayName);

  const handleToggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <div className={`${isMenuOpen ? 'overflow-hidden' : ''}`}>
      <Helmet>
        <title style={{ textTransform: 'capitalize' }}>
          {page === 'data'
            ? 'BMS'
            : page === 'adduser'
            ? 'Add User'
            : page[0].toUpperCase() + page.substring(1)}{' '}
          - Asterisk Client Portal
        </title>
      </Helmet>
      <Navbar />
      <div
        className={`layout absolute right-0 flex flex-col min-h-screen  ${
          isMenuOpen ? 'absolute overflow-hidden' : ''
        } ${
          lightMode
            ? `${
                page === 'sign-in'
                  ? 'bg-gradient-to-r from-gray-900 to-gray-800'
                  : 'bg-gray-100'
              }`
            : `${
                page === 'sign-in'
                  ? 'bg-gradient-to-r from-red-800 to-red-900'
                  : 'bg-gray-900'
              }`
        }`}
      >
        {isOpenNotifBox && <NotificationBox />}
        {isOpenProfModal && <Profile />}
        {accounts.length > 0 && isOpenPopup && <NotificationPopup />}
        <main
          className={`flex-grow overflow-x-hidden ${
            page !== 'sign-in' && 'mb-10'
          } ${isMenuOpen ? 'overflow-y-hidden' : ''} width`}
        >
          {children}
        </main>
        <Footer />
      </div>
      <MobileNavMenu isOpen={isMenuOpen} handleToggle={handleToggleMenu} />
    </div>
  );
};

export default Layout;
