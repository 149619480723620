import {
  IoIosArrowDown,
  IoIosArrowForward,
  IoMdArrowDropdown,
} from 'react-icons/io';
import {
  lightModeState,
  platformChartsPrintState,
  deviceChartsPrintState,
  coverageGraphPrintState,
  remarketingPrintState,
  userState,
  accountState,
  kwPrintComponentState,
  ulPrintComponentState,
} from '../../recoil/atoms';
import { SetterOrUpdater, useRecoilState, useRecoilValue } from 'recoil';
import React, { useState } from 'react';
import { adminAccountState } from '../../recoil/selectors/dataSelectors';

type Props = {
  setPage: React.Dispatch<React.SetStateAction<number>>;
};

const PrintComponentCB = ({ setPage }: Props) => {
  const lightMode = useRecoilValue(lightModeState);
  const user = useRecoilValue(userState);
  const account = useRecoilValue(
    user?.role === 'superadmin' ? accountState : adminAccountState
  );
  const [isPlatformChartsOpen, setIsPlatformChartsOpen] = useState(false);
  const [isDeviceChartsOpen, setIsDeviceChartsOpen] = useState(false);
  const [isCoverageGraphOpen, setIsCoverageGraphOen] = useState(false);
  const [isRemarketingOpen, setIsRemarketingOpen] = useState(false);
  const [isULKWOpen, setISULKWOpen] = useState(false);
  const [platformCharts, setPlatformCharts] = useRecoilState(
    platformChartsPrintState
  );
  const [deviceCharts, setDeviceCharts] = useRecoilState(
    deviceChartsPrintState
  );
  const [coverageGraphs, setCoverageGraphs] = useRecoilState(
    coverageGraphPrintState
  );
  const [remarketing, setRemarketing] = useRecoilState(remarketingPrintState);
  const [kwPrintComponent, setKWPrintComponent] = useRecoilState(
    kwPrintComponentState
  );
  const [ulPrintComponent, setULPrintComponent] = useRecoilState(
    ulPrintComponentState
  );
  const onSelect = (
    e: React.MouseEvent<HTMLInputElement>,
    setter: SetterOrUpdater<{ [index: string]: boolean }>
  ) => {
    const { name } = e.currentTarget;
    setter((v) => ({ ...v, [name]: !v[name] }));
  };

  console.log(platformCharts);

  return (
    <div>
      <div className='mb-3'>
        <div
          className={`border-b ${
            lightMode
              ? 'border-gray-900 text-gray-900'
              : 'border-gray-200 text-white'
          } flex items-center justify-between w-full mb-3 gap-x-10 px-3 pb-2 text-base font-semibold`}
        >
          Platform Doughnut Charts{' '}
          {isPlatformChartsOpen ? (
            <IoIosArrowForward
              className='cursor-pointer'
              onClick={() => setIsPlatformChartsOpen(!isPlatformChartsOpen)}
            />
          ) : (
            <IoIosArrowDown
              className='cursor-pointer'
              onClick={() => setIsPlatformChartsOpen(!isPlatformChartsOpen)}
            />
          )}
        </div>
        {isPlatformChartsOpen && (
          <ul
            className={`${
              lightMode ? 'text-black' : 'text-white'
            } ml-4 mb-3 grid grid-cols-2`}
          >
            <li className='mb-1'>
              <label className='cursor-pointer form-check-label'>
                <input
                  type='checkbox'
                  className='form-check-input cursor-pointer rounded checkbox checkbox-xs border-l border-r border-b border-t border-gray-500'
                  checked={platformCharts['First Chart']}
                  name='First Chart'
                  onClick={(e) => onSelect(e, setPlatformCharts)}
                />
                <span className=' ml-2 text-2xs sm:text-base font-semibold'>
                  First Chart
                </span>
              </label>
            </li>
            <li>
              <label className='cursor-pointer form-check-label'>
                <input
                  type='checkbox'
                  className='form-check-input cursor-pointer rounded checkbox checkbox-xs border-l border-r border-b border-t border-gray-500'
                  checked={platformCharts['Second Chart']}
                  name='Second Chart'
                  onClick={(e) => onSelect(e, setPlatformCharts)}
                />
                <span className=' ml-2 text-2xs sm:text-base font-semibold'>
                  Second Chart
                </span>
              </label>
            </li>
          </ul>
        )}
      </div>
      <div className='mb-3'>
        <div
          className={`border-b ${
            lightMode
              ? 'border-gray-900 text-gray-900'
              : 'border-gray-200 text-white'
          } flex items-center justify-between w-full gap-x-10 mb-3 px-3 pb-2 text-base font-semibold`}
        >
          Device Doughnut Charts{' '}
          {isDeviceChartsOpen ? (
            <IoIosArrowForward
              className='cursor-pointer'
              onClick={() => setIsDeviceChartsOpen(!isDeviceChartsOpen)}
            />
          ) : (
            <IoIosArrowDown
              className='cursor-pointer'
              onClick={() => setIsDeviceChartsOpen(!isDeviceChartsOpen)}
            />
          )}
        </div>
        {isDeviceChartsOpen && (
          <ul
            className={`${lightMode ? 'text-black' : 'text-white'} ml-4 mb-3`}
          >
            <li className='mb-1'>
              <label className='cursor-pointer form-check-label'>
                <input
                  type='checkbox'
                  className='form-check-input cursor-pointer rounded checkbox checkbox-xs border-l border-r border-b border-t border-gray-500'
                  name='Leads Per Device (Google)'
                  checked={deviceCharts['Leads Per Device (Google)']}
                  onClick={(e) => {
                    onSelect(e, setDeviceCharts);
                    if (
                      deviceCharts['Leads Per Device (Google)'] &&
                      !deviceCharts['Budget Per Device (Google)'] &&
                      !deviceCharts['Leads Per Device (Microsoft)'] &&
                      !deviceCharts['Budget Per Device (Microsoft)'] &&
                      !remarketing['Remarketing']
                    )
                      setPage(1);
                  }}
                />
                <span className=' ml-2 text-2xs sm:text-base font-semibold'>
                  Leads Per Device (Google)
                </span>
              </label>
            </li>
            <li className='mb-1'>
              <label className='cursor-pointer form-check-label'>
                <input
                  type='checkbox'
                  className='form-check-input cursor-pointer rounded checkbox checkbox-xs border-l border-r border-b border-t border-gray-500'
                  name='Budget Per Device (Google)'
                  checked={deviceCharts['Budget Per Device (Google)']}
                  onClick={(e) => {
                    onSelect(e, setDeviceCharts);
                    if (
                      !deviceCharts['Leads Per Device (Google)'] &&
                      deviceCharts['Budget Per Device (Google)'] &&
                      !deviceCharts['Leads Per Device (Microsoft)'] &&
                      !deviceCharts['Budget Per Device (Microsoft)'] &&
                      !remarketing['Remarketing']
                    )
                      setPage(1);
                  }}
                />
                <span className=' ml-2 text-2xs sm:text-base font-semibold'>
                  Budget Per Device (Google)
                </span>
              </label>
            </li>
            <li className='mb-1'>
              <label className='cursor-pointer form-check-label'>
                <input
                  type='checkbox'
                  className='form-check-input cursor-pointer rounded checkbox checkbox-xs border-l border-r border-b border-t border-gray-500'
                  name='Leads Per Device (Microsoft)'
                  checked={deviceCharts['Leads Per Device (Microsoft)']}
                  onClick={(e) => {
                    onSelect(e, setDeviceCharts);
                    if (
                      !deviceCharts['Leads Per Device (Google)'] &&
                      !deviceCharts['Budget Per Device (Google)'] &&
                      deviceCharts['Leads Per Device (Microsoft)'] &&
                      !deviceCharts['Budget Per Device (Microsoft)'] &&
                      !remarketing['Remarketing']
                    )
                      setPage(1);
                  }}
                />
                <span className=' ml-2 text-2xs sm:text-base font-semibold'>
                  Leads Per Device (Microsoft)
                </span>
              </label>
            </li>
            <li>
              <label className='cursor-pointer form-check-label'>
                <input
                  type='checkbox'
                  className='form-check-input cursor-pointer rounded checkbox checkbox-xs border-l border-r border-b border-t border-gray-500'
                  name='Budget Per Device (Microsoft)'
                  checked={deviceCharts['Budget Per Device (Microsoft)']}
                  onClick={(e) => {
                    onSelect(e, setDeviceCharts);
                    if (
                      !deviceCharts['Leads Per Device (Google)'] &&
                      !deviceCharts['Budget Per Device (Google)'] &&
                      !deviceCharts['Leads Per Device (Microsoft)'] &&
                      deviceCharts['Budget Per Device (Microsoft)'] &&
                      !remarketing['Remarketing']
                    )
                      setPage(1);
                  }}
                />
                <span className=' ml-2 text-2xs sm:text-base font-semibold'>
                  Budget Per Device (Microsoft)
                </span>
              </label>
            </li>
          </ul>
        )}
      </div>
      <div className='mb-3'>
        <div
          className={`border-b ${
            lightMode
              ? 'border-gray-900 text-gray-900'
              : 'border-gray-200 text-white'
          } flex items-center justify-between w-full gap-x-10 mb-3 px-3 pb-2 text-base font-semibold`}
        >
          Coverage Line Graphs{' '}
          {isCoverageGraphOpen ? (
            <IoIosArrowForward
              className='cursor-pointer'
              onClick={() => setIsCoverageGraphOen(!isCoverageGraphOpen)}
            />
          ) : (
            <IoIosArrowDown
              className='cursor-pointer'
              onClick={() => setIsCoverageGraphOen(!isCoverageGraphOpen)}
            />
          )}
        </div>
        {isCoverageGraphOpen && (
          <ul
            className={`${
              lightMode ? 'text-black' : 'text-white'
            } ml-4 mb-3 grid grid-cols-2`}
          >
            <li className='mb-1'>
              <label className='cursor-pointer form-check-label'>
                <input
                  type='checkbox'
                  className='form-check-input cursor-pointer rounded checkbox checkbox-xs border-l border-r border-b border-t border-gray-500'
                  name='First Graph'
                  checked={coverageGraphs['First Graph']}
                  onClick={(e) => onSelect(e, setCoverageGraphs)}
                />
                <span className=' ml-2 text-2xs sm:text-base font-semibold'>
                  First Graph
                </span>
              </label>
            </li>
            <li>
              <label className='cursor-pointer form-check-label'>
                <input
                  type='checkbox'
                  className='form-check-input cursor-pointer rounded checkbox checkbox-xs border-l border-r border-b border-t border-gray-500'
                  name='Second Graph'
                  checked={coverageGraphs['Second Graph']}
                  onClick={(e) => onSelect(e, setCoverageGraphs)}
                />
                <span className=' ml-2 text-2xs sm:text-base font-semibold'>
                  Second Graph
                </span>
              </label>
            </li>
          </ul>
        )}
      </div>
      {(account.remarketing_campaign || account.bing_remarketing_campaign) && (
        <div className='mb-3'>
          <div
            className={`border-b ${
              lightMode
                ? 'border-gray-900 text-gray-900'
                : 'border-gray-200 text-white'
            } flex items-center justify-between w-full gap-x-10 mb-3 px-3 pb-2 text-base font-semibold`}
          >
            Remarketing
            {isRemarketingOpen ? (
              <IoIosArrowForward
                className='cursor-pointer'
                onClick={() => setIsRemarketingOpen(!isRemarketingOpen)}
              />
            ) : (
              <IoIosArrowDown
                className='cursor-pointer'
                onClick={() => setIsRemarketingOpen(!isRemarketingOpen)}
              />
            )}
          </div>
          {isRemarketingOpen && (
            <ul
              className={`${lightMode ? 'text-black' : 'text-white'} ml-4 mb-3`}
            >
              <li className='mb-1'>
                <label className='cursor-pointer form-check-label'>
                  <input
                    type='checkbox'
                    className='form-check-input cursor-pointer rounded checkbox checkbox-xs border-l border-r border-b border-t border-gray-500'
                    name='Remarketing'
                    checked={remarketing['Remarketing']}
                    onClick={(e) => {
                      onSelect(e, setRemarketing);
                      if (
                        !deviceCharts['Leads Per Device (Google)'] &&
                        !deviceCharts['Budget Per Device (Google)'] &&
                        !deviceCharts['Leads Per Device (Microsoft)'] &&
                        !deviceCharts['Budget Per Device (Microsoft)'] &&
                        remarketing['Remarketing']
                      )
                        setPage(1);
                    }}
                  />
                  <span className=' ml-2 text-2xs sm:text-base font-semibold'>
                    Remarketing
                  </span>
                </label>
              </li>
            </ul>
          )}
        </div>
      )}
      <div className='mb-3'>
        <div
          className={`border-b ${
            lightMode
              ? 'border-gray-900 text-gray-900'
              : 'border-gray-200 text-white'
          } flex items-center justify-between w-full mb-3 gap-x-10 px-3 pb-2 text-base font-semibold`}
        >
          User Locations / Keywords
          {isULKWOpen ? (
            <IoIosArrowForward
              className='cursor-pointer'
              onClick={() => setISULKWOpen(!isULKWOpen)}
            />
          ) : (
            <IoIosArrowDown
              className='cursor-pointer'
              onClick={() => setISULKWOpen(!isULKWOpen)}
            />
          )}
        </div>
        {isULKWOpen && (
          <div
            className={`${lightMode ? 'text-black' : 'text-white'} ml-4 mb-3`}
          >
            <div className='font-semibold flex items-start gap-x-5 mb-2'>
              UL :
              <div className='font-normal cursor-pointer  dropdown dropdown-hover group'>
                <p
                  className={`flex items-center justify-between px-2 py-1 border-l border-r border-t border-b ${
                    lightMode
                      ? 'bg-gray-200 border-gray-300 group-hover:border-gray-400'
                      : 'bg-gray-800 border-gray-800 group-hover:border-gray-600'
                  } w-44 rounded`}
                >
                  {ulPrintComponent} <IoMdArrowDropdown />
                </p>
                <ul
                  className={`dropdown-content ${
                    lightMode ? 'bg-gray-200' : 'bg-gray-800'
                  } w-full rounded`}
                  tabIndex={0}
                >
                  <li
                    className={`px-2 py-1 ${
                      lightMode ? 'hover:bg-gray-300' : 'hover:bg-gray-700'
                    } rounded-t ${
                      ulPrintComponent === 'Most Converting' &&
                      (lightMode ? 'bg-gray-300' : 'bg-gray-700')
                    }`}
                    onClick={() => setULPrintComponent('Most Converting')}
                  >
                    Most Converting
                  </li>
                  <li
                    className={`px-2 py-1 ${
                      lightMode ? 'hover:bg-gray-300' : 'hover:bg-gray-700'
                    } ${
                      ulPrintComponent === 'Least Spent' &&
                      (lightMode ? 'bg-gray-300' : 'bg-gray-700')
                    }`}
                    onClick={() => setULPrintComponent('Least Spent')}
                  >
                    Least Spent
                  </li>
                  <li
                    className={`px-2 py-1 ${
                      lightMode ? 'hover:bg-gray-300' : 'hover:bg-gray-700'
                    } ${
                      ulPrintComponent === 'Most Clicks' &&
                      (lightMode ? 'bg-gray-300' : 'bg-gray-700')
                    }`}
                    onClick={() => setULPrintComponent('Most Clicks')}
                  >
                    Most Clicks
                  </li>
                  <li
                    className={`px-2 py-1 ${
                      lightMode ? 'hover:bg-gray-300' : 'hover:bg-gray-700'
                    } ${
                      ulPrintComponent === 'Most Impressions' &&
                      (lightMode ? 'bg-gray-300' : 'bg-gray-700')
                    }`}
                    onClick={() => setULPrintComponent('Most Impressions')}
                  >
                    Most Impressions
                  </li>
                  <li
                    className={`px-2 py-1 ${
                      lightMode ? 'hover:bg-gray-300' : 'hover:bg-gray-700'
                    } ${
                      ulPrintComponent === 'Highest CTR' &&
                      (lightMode ? 'bg-gray-300' : 'bg-gray-700')
                    }`}
                    onClick={() => setULPrintComponent('Highest CTR')}
                  >
                    Highest CTR
                  </li>
                  <li
                    className={`px-2 py-1 ${
                      lightMode ? 'hover:bg-gray-300' : 'hover:bg-gray-700'
                    } rounded-b ${
                      ulPrintComponent === 'Lowest CPL' &&
                      (lightMode ? 'bg-gray-300' : 'bg-gray-700')
                    }`}
                    onClick={() => setULPrintComponent('Lowest CPL')}
                  >
                    Lowest CPL
                  </li>
                </ul>
              </div>
            </div>
            <div className='font-semibold flex items-start gap-x-5'>
              KW :
              <div className='font-normal cursor-pointer  dropdown dropdown-hover group'>
                <p
                  className={`flex items-center justify-between px-2 py-1  border-l border-r border-t border-b ${
                    lightMode
                      ? 'bg-gray-200 border-gray-300 group-hover:border-gray-400'
                      : 'bg-gray-800 border-gray-800 group-hover:border-gray-600'
                  } w-44 rounded`}
                >
                  {kwPrintComponent} <IoMdArrowDropdown />
                </p>
                <ul
                  className={`dropdown-content ${
                    lightMode ? 'bg-gray-200' : 'bg-gray-800'
                  } w-full rounded`}
                  tabIndex={0}
                >
                  <li
                    className={`px-2 py-1 ${
                      lightMode ? 'hover:bg-gray-300' : 'hover:bg-gray-700'
                    } ${
                      kwPrintComponent === 'Most Converting' &&
                      (lightMode ? 'bg-gray-300' : 'bg-gray-700')
                    }`}
                    onClick={() => setKWPrintComponent('Most Converting')}
                  >
                    Most Converting
                  </li>
                  <li
                    className={`px-2 py-1 ${
                      lightMode ? 'hover:bg-gray-300' : 'hover:bg-gray-700'
                    } ${
                      kwPrintComponent === 'Least Spent' &&
                      (lightMode ? 'bg-gray-300' : 'bg-gray-700')
                    }`}
                    onClick={() => setKWPrintComponent('Least Spent')}
                  >
                    Least Spent
                  </li>
                  <li
                    className={`px-2 py-1 ${
                      lightMode ? 'hover:bg-gray-300' : 'hover:bg-gray-700'
                    } ${
                      kwPrintComponent === 'Most Clicks' &&
                      (lightMode ? 'bg-gray-300' : 'bg-gray-700')
                    }`}
                    onClick={() => setKWPrintComponent('Most Clicks')}
                  >
                    Most Clicks
                  </li>
                  <li
                    className={`px-2 py-1 ${
                      lightMode ? 'hover:bg-gray-300' : 'hover:bg-gray-700'
                    } ${
                      kwPrintComponent === 'Most Impressions' &&
                      (lightMode ? 'bg-gray-300' : 'bg-gray-700')
                    }`}
                    onClick={() => setKWPrintComponent('Most Impressions')}
                  >
                    Most Impressions
                  </li>
                  <li
                    className={`px-2 py-1 ${
                      lightMode ? 'hover:bg-gray-300' : 'hover:bg-gray-700'
                    } ${
                      kwPrintComponent === 'Highest CTR' &&
                      (lightMode ? 'bg-gray-300' : 'bg-gray-700')
                    }`}
                    onClick={() => setKWPrintComponent('Highest CTR')}
                  >
                    Highest CTR
                  </li>
                  <li
                    className={`px-2 py-1 ${
                      lightMode ? 'hover:bg-gray-300' : 'hover:bg-gray-700'
                    } rounded-b ${
                      kwPrintComponent === 'Lowest CPL' &&
                      (lightMode ? 'bg-gray-300' : 'bg-gray-700')
                    }`}
                    onClick={() => setKWPrintComponent('Lowest CPL')}
                  >
                    Lowest CPL
                  </li>
                </ul>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default PrintComponentCB;
