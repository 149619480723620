import React, { useState } from 'react';
import FormInput from '../../forms-comps/FormInput';
import FormDropdown from '../../forms-comps/FormDropdown';
import FormLayoutBase from '../../forms-comps/FormLayoutBase';
import {
  selectedAccountState,
  isLoadingDataState,
  accountsState,
  accountState,
  accounts2MonthsAgoState,
  accounts3MonthsAgoState,
  accounts30DaysAgoState,
  thisMonthAccountsState,
  searchTerm30DaysAgoState,
  bingSearchTerm30DaysAgoState,
  userLocation30DaysAgoState,
  accountsYesterdayState,
  accounts3DaysAgoState,
  accounts90DaysAgoState,
  accountsAprState,
  accountsAugState,
  accountsDecState,
  accountsFebState,
  accountsJanState,
  accountsJulState,
  accountsJunState,
  accountsMarState,
  accountsMayState,
  accountsNovState,
  accountsOctState,
  accountsSeptState,
  searchTermAprState,
  searchTermAugState,
  searchTermDecState,
  searchTermFebState,
  searchTermJanState,
  searchTermJulState,
  searchTermJunState,
  searchTermMarState,
  searchTermMayState,
  searchTermNovState,
  searchTermOctState,
  searchTermSeptState,
  bingSearchTermAprState,
  bingSearchTermAugState,
  bingSearchTermDecState,
  bingSearchTermFebState,
  bingSearchTermJanState,
  bingSearchTermJulState,
  bingSearchTermJunState,
  bingSearchTermMarState,
  bingSearchTermMayState,
  bingSearchTermNovState,
  bingSearchTermOctState,
  bingSearchTermSeptState,
  userLocationAprState,
  userLocationAugState,
  userLocationDecState,
  userLocationFebState,
  userLocationJanState,
  userLocationJulState,
  userLocationJunState,
  userLocationMarState,
  userLocationMayState,
  userLocationNovState,
  userLocationOctState,
  userLocationSeptState,
  dataFormDataState,
  isDataFormOpenState,
  dataPageFormCompState,
} from '../../../recoil/atoms';
import { useRecoilValue, useSetRecoilState, useRecoilState } from 'recoil';
import axios from 'axios';
import { headerState } from '../../../recoil/selectors/dataSelectors';

const ChangeAttForm = () => {
  const environment = process.env.NODE_ENV;
  const headers = useRecoilValue(headerState);
  const setSelectedAccount = useSetRecoilState(selectedAccountState);
  const dataFormData = useRecoilValue(dataFormDataState);
  const [accountsThisMonth, setThisMonthsAccount] = useRecoilState(
    thisMonthAccountsState
  );
  const account = accountsThisMonth.filter(
    (acc) => acc.id === dataFormData.id
  )[0];
  const [input, setInput] = useState({
    attName: '',
    attEmail: '',
    attState: '',
    attPPC: '',
    attAM: '',
    attBudget: '',
  });
  const setIsLoadingData = useSetRecoilState(isLoadingDataState);
  const setIsDataFormOpen = useSetRecoilState(isDataFormOpenState);
  const setAccounts = useSetRecoilState(accountsState);
  const setAccount = useSetRecoilState(accountState);
  const setAccounts2MonthsAgo = useSetRecoilState(accounts2MonthsAgoState);
  const setAccounts3MonthsAgo = useSetRecoilState(accounts3MonthsAgoState);
  const setAccounts30DaysAgo = useSetRecoilState(accounts30DaysAgoState);
  const setThisMonthAccounts = useSetRecoilState(thisMonthAccountsState);
  const setSearchTerm30DaysAgo = useSetRecoilState(searchTerm30DaysAgoState);
  const setBingSearchTerm30DaysAgo = useSetRecoilState(
    bingSearchTerm30DaysAgoState
  );
  const setUserLocation30DaysAgo = useSetRecoilState(
    userLocation30DaysAgoState
  );
  const setAccountsYesterday = useSetRecoilState(accountsYesterdayState);
  const setAccounts3DaysAgo = useSetRecoilState(accounts3DaysAgoState);
  const setAccounts90DaysAgo = useSetRecoilState(accounts90DaysAgoState);
  const setAccountsJan = useSetRecoilState(accountsJanState);
  const setAccountsFeb = useSetRecoilState(accountsFebState);
  const setAccountsMar = useSetRecoilState(accountsMarState);
  const setAccountsApr = useSetRecoilState(accountsAprState);
  const setAccountsMay = useSetRecoilState(accountsMayState);
  const setAccountsJun = useSetRecoilState(accountsJunState);
  const setAccountsJul = useSetRecoilState(accountsJulState);
  const setAccountsAug = useSetRecoilState(accountsAugState);
  const setAccountsSep = useSetRecoilState(accountsSeptState);
  const setAccountsOct = useSetRecoilState(accountsOctState);
  const setAccountsNov = useSetRecoilState(accountsNovState);
  const setAccountsDec = useSetRecoilState(accountsDecState);
  const setSearchTermsJan = useSetRecoilState(searchTermJanState);
  const setSearchTermsFeb = useSetRecoilState(searchTermFebState);
  const setSearchTermsMar = useSetRecoilState(searchTermMarState);
  const setSearchTermsApr = useSetRecoilState(searchTermAprState);
  const setSearchTermsMay = useSetRecoilState(searchTermMayState);
  const setSearchTermsJun = useSetRecoilState(searchTermJunState);
  const setSearchTermsJul = useSetRecoilState(searchTermJulState);
  const setSearchTermsAug = useSetRecoilState(searchTermAugState);
  const setSearchTermsSep = useSetRecoilState(searchTermSeptState);
  const setSearchTermsOct = useSetRecoilState(searchTermOctState);
  const setSearchTermsNov = useSetRecoilState(searchTermNovState);
  const setSearchTermsDec = useSetRecoilState(searchTermDecState);
  const setBingSearchTermsJan = useSetRecoilState(bingSearchTermJanState);
  const setBingSearchTermsFeb = useSetRecoilState(bingSearchTermFebState);
  const setBingSearchTermsMar = useSetRecoilState(bingSearchTermMarState);
  const setBingSearchTermsApr = useSetRecoilState(bingSearchTermAprState);
  const setBingSearchTermsMay = useSetRecoilState(bingSearchTermMayState);
  const setBingSearchTermsJun = useSetRecoilState(bingSearchTermJunState);
  const setBingSearchTermsJul = useSetRecoilState(bingSearchTermJulState);
  const setBingSearchTermsAug = useSetRecoilState(bingSearchTermAugState);
  const setBingSearchTermsSep = useSetRecoilState(bingSearchTermSeptState);
  const setBingSearchTermsOct = useSetRecoilState(bingSearchTermOctState);
  const setBingSearchTermsNov = useSetRecoilState(bingSearchTermNovState);
  const setBingSearchTermsDec = useSetRecoilState(bingSearchTermDecState);
  const setUserLocationJan = useSetRecoilState(userLocationJanState);
  const setUserLocationFeb = useSetRecoilState(userLocationFebState);
  const setUserLocationMar = useSetRecoilState(userLocationMarState);
  const setUserLocationApr = useSetRecoilState(userLocationAprState);
  const setUserLocationMay = useSetRecoilState(userLocationMayState);
  const setUserLocationJun = useSetRecoilState(userLocationJunState);
  const setUserLocationJul = useSetRecoilState(userLocationJulState);
  const setUserLocationAug = useSetRecoilState(userLocationAugState);
  const setUserLocationSep = useSetRecoilState(userLocationSeptState);
  const setUserLocationOct = useSetRecoilState(userLocationOctState);
  const setUserLocationNov = useSetRecoilState(userLocationNovState);
  const setUserLocationDec = useSetRecoilState(userLocationDecState);
  const setDataPageFormComp = useSetRecoilState(dataPageFormCompState);

  const arraySetters = [
    setBingSearchTerm30DaysAgo,
    setUserLocation30DaysAgo,
    setSearchTerm30DaysAgo,
    setAccounts,
    setThisMonthAccounts,
    setAccounts2MonthsAgo,
    setAccounts3MonthsAgo,
    setAccounts30DaysAgo,
    setAccountsYesterday,
    setAccounts3DaysAgo,
    setAccounts90DaysAgo,
    setAccountsJan,
    setAccountsFeb,
    setAccountsMar,
    setAccountsApr,
    setAccountsMay,
    setAccountsJun,
    setAccountsJul,
    setAccountsAug,
    setAccountsSep,
    setAccountsOct,
    setAccountsNov,
    setAccountsDec,
    setSearchTermsJan,
    setSearchTermsFeb,
    setSearchTermsMar,
    setSearchTermsApr,
    setSearchTermsMay,
    setSearchTermsJun,
    setSearchTermsJul,
    setSearchTermsAug,
    setSearchTermsSep,
    setSearchTermsOct,
    setSearchTermsNov,
    setSearchTermsDec,
    setBingSearchTermsJan,
    setBingSearchTermsFeb,
    setBingSearchTermsMar,
    setBingSearchTermsApr,
    setBingSearchTermsMay,
    setBingSearchTermsJun,
    setBingSearchTermsJul,
    setBingSearchTermsAug,
    setBingSearchTermsSep,
    setBingSearchTermsOct,
    setBingSearchTermsNov,
    setBingSearchTermsDec,
    setUserLocationJan,
    setUserLocationFeb,
    setUserLocationMar,
    setUserLocationApr,
    setUserLocationMay,
    setUserLocationJun,
    setUserLocationJul,
    setUserLocationAug,
    setUserLocationSep,
    setUserLocationOct,
    setUserLocationNov,
    setUserLocationDec,
  ];

  const ppcSpecialists = [
    'Gwen',
    'Jose Ramirez',
    'Joshua Simpson',
    'Joyce Raypan',
    'Mich',
    'Red Quijano',
    'Mike More',
  ];

  const accountManagers = [
    'Allyson Hunt',
    'Mark Lorentz',
    'Philip Pasma',
    'Valeria Angel',
    'Vanessa Iles',
    'Amanda Cattle',
  ];

  interface ReqJSON {
    name?: string;
    email?: string;
    ppc_specialist?: string;
    account_manager?: string;
    city?: string;
    budget?: string;
    state_country?: string;
  }

  let requestJSON: ReqJSON = {};

  if (input.attName) requestJSON['name'] = input.attName;
  if (input.attEmail) requestJSON['email'] = input.attEmail;
  if (input.attPPC) requestJSON['ppc_specialist'] = input.attPPC;
  if (input.attAM) requestJSON['account_manager'] = input.attAM;
  if (input.attBudget) requestJSON['budget'] = input.attBudget;
  if (input.attState) requestJSON['state_country'] = input.attState;

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setInput({ ...input, [name]: value });
  };

  const handleRefresh = async () => {
    arraySetters.forEach((set) => set([]));
    setSelectedAccount('');
    setAccount({
      id: '',
      name: 'None',
      email: 'your@email.com',
      microsoft_cid: '',
      account_manager: '',
      ppc_specialist: '',
      state_country: '',
      city: '',
      idx: '',
      ad_var: '',
      google_account: {
        id: 0,
        name: '',
        spend: '',
        conversions: '',
        cost_per_conversion: '',
        impressions: '',
        clicks: '',
        ctr: '',
      },
      microsoft_account: {
        id: '',
        name: '',
        spend: '',
        conversions: '',
        cost_per_conversion: '',
        impressions: '',
        clicks: '',
        ctr: '',
      },
    });

    await axios({
      method: 'get',
      url: `${
        environment === 'production' ? 'https://apimakeforth.herokuapp.com' : ''
      }/api/account_info/this-mo/`,
      headers: headers,
    })
      .then((res) => {
        setIsLoadingData(false);
        setThisMonthsAccount(res.data);
      })
      .catch((err) => {
        console.log(err);
        setIsLoadingData(false);
      });
  };

  const patchRequest = async (
    time_frame: string,
    cid: number | string | undefined,
    table: string
  ) => {
    const url = `${
      environment === 'production' ? 'https://apimakeforth.herokuapp.com' : ''
    }/api/${table}/${time_frame}${cid}/`;
    await axios({
      method: 'patch',
      headers: headers,
      url: url,
      data:
        table === 'account_info' ? requestJSON : { name: requestJSON['name'] },
    }).catch((err) => console.log(err));
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (
      !requestJSON.name &&
      !requestJSON.email &&
      !requestJSON.city &&
      !requestJSON.ppc_specialist &&
      !requestJSON.account_manager &&
      !requestJSON.state_country
    ) {
      setIsLoadingData(true);
      await axios({
        method: 'patch',
        headers: headers,
        url: `${
          environment === 'production'
            ? 'https://apimakeforth.herokuapp.com'
            : ''
        }/api/account_info/this-mo/${
          account.google_cid ? account.google_cid : account.microsoft_cid
        }/`,
        data: requestJSON,
      })
        .then(() => {
          setIsDataFormOpen(false);
          setDataPageFormComp('AddData');
          handleRefresh();
        })
        .catch((err) => {
          setIsLoadingData(false);
          console.log(err);
        });
    } else {
      await axios({
        method: 'patch',
        headers: headers,
        url: `${
          environment === 'production'
            ? 'https://apimakeforth.herokuapp.com'
            : ''
        }/api/account_info/jan/${
          account.google_cid ? account.google_cid : account.microsoft_cid
        }/`,
        data: requestJSON,
      })
        .then(() => {
          setIsDataFormOpen(false);
          setIsLoadingData(true);
          setDataPageFormComp('AddData');
          patchRequest(
            'feb/',
            account.google_cid ? account.google_cid : account.microsoft_cid,
            'account_info'
          );
        })
        .then(() =>
          patchRequest(
            'mar/',
            account.google_cid ? account.google_cid : account.microsoft_cid,
            'account_info'
          )
        )
        .then(() =>
          patchRequest(
            'apr/',
            account.google_cid ? account.google_cid : account.microsoft_cid,
            'account_info'
          )
        )
        .then(() =>
          patchRequest(
            'may/',
            account.google_cid ? account.google_cid : account.microsoft_cid,
            'account_info'
          )
        )
        .then(() =>
          patchRequest(
            'jun/',
            account.google_cid ? account.google_cid : account.microsoft_cid,
            'account_info'
          )
        )
        .then(() =>
          patchRequest(
            'jul/',
            account.google_cid ? account.google_cid : account.microsoft_cid,
            'account_info'
          )
        )
        .then(() =>
          patchRequest(
            'aug/',
            account.google_cid ? account.google_cid : account.microsoft_cid,
            'account_info'
          )
        )
        .then(() =>
          patchRequest(
            'sept/',
            account.google_cid ? account.google_cid : account.microsoft_cid,
            'account_info'
          )
        )
        .then(() =>
          patchRequest(
            'oct/',
            account.google_cid ? account.google_cid : account.microsoft_cid,
            'account_info'
          )
        )
        .then(() =>
          patchRequest(
            'nov/',
            account.google_cid ? account.google_cid : account.microsoft_cid,
            'account_info'
          )
        )
        .then(() =>
          patchRequest(
            'dec/',
            account.google_cid ? account.google_cid : account.microsoft_cid,
            'account_info'
          )
        )
        .then(() =>
          patchRequest(
            '30-days/',
            account.google_cid ? account.google_cid : account.microsoft_cid,
            'account_info'
          )
        )
        .then(() =>
          patchRequest(
            '90-days/',
            account.google_cid ? account.google_cid : account.microsoft_cid,
            'account_info'
          )
        )
        .then(() =>
          patchRequest(
            'this-mo/',
            account.google_cid ? account.google_cid : account.microsoft_cid,
            'account_info'
          )
        )
        .then(() =>
          patchRequest(
            '3-days/',
            account.google_cid ? account.google_cid : account.microsoft_cid,
            'account_info'
          )
        )
        .then(() =>
          patchRequest(
            'yesterday/',
            account.google_cid ? account.google_cid : account.microsoft_cid,
            'account_info'
          )
        )
        .then(() =>
          patchRequest(
            'all-time/',
            account.google_cid ? account.google_cid : account.microsoft_cid,
            'account_info'
          )
        )
        .then(() => patchRequest('jan/', account.google_cid, 'account'))
        .then(() => patchRequest('feb/', account.google_cid, 'account'))
        .then(() => patchRequest('mar/', account.google_cid, 'account'))
        .then(() => patchRequest('apr/', account.google_cid, 'account'))
        .then(() => patchRequest('may/', account.google_cid, 'account'))
        .then(() => patchRequest('jun/', account.google_cid, 'account'))
        .then(() => patchRequest('jul/', account.google_cid, 'account'))
        .then(() => patchRequest('aug/', account.google_cid, 'account'))
        .then(() => patchRequest('sept/', account.google_cid, 'account'))
        .then(() => patchRequest('oct/', account.google_cid, 'account'))
        .then(() => patchRequest('nov/', account.google_cid, 'account'))
        .then(() => patchRequest('dec/', account.google_cid, 'account'))
        .then(() => patchRequest('30-days/', account.google_cid, 'account'))
        .then(() => patchRequest('90-days/', account.google_cid, 'account'))
        .then(() => patchRequest('this-mo/', account.google_cid, 'account'))
        .then(() => patchRequest('3-days/', account.google_cid, 'account'))
        .then(() => patchRequest('yesterday/', account.google_cid, 'account'))
        .then(() => patchRequest('all-time/', account.google_cid, 'account'))
        .then(() => patchRequest('jan/', account.microsoft_cid, 'bing_account'))
        .then(() => patchRequest('feb/', account.microsoft_cid, 'bing_account'))
        .then(() => patchRequest('mar/', account.microsoft_cid, 'bing_account'))
        .then(() => patchRequest('apr/', account.microsoft_cid, 'bing_account'))
        .then(() => patchRequest('may/', account.microsoft_cid, 'bing_account'))
        .then(() => patchRequest('jun/', account.microsoft_cid, 'bing_account'))
        .then(() => patchRequest('jul/', account.microsoft_cid, 'bing_account'))
        .then(() => patchRequest('aug/', account.microsoft_cid, 'bing_account'))
        .then(() =>
          patchRequest('sept/', account.microsoft_cid, 'bing_account')
        )
        .then(() => patchRequest('oct/', account.microsoft_cid, 'bing_account'))
        .then(() => patchRequest('nov/', account.microsoft_cid, 'bing_account'))
        .then(() => patchRequest('dec/', account.microsoft_cid, 'bing_account'))
        .then(() =>
          patchRequest('30-days/', account.microsoft_cid, 'bing_account')
        )
        .then(() =>
          patchRequest('90-days/', account.microsoft_cid, 'bing_account')
        )
        .then(() =>
          patchRequest('this-mo/', account.microsoft_cid, 'bing_account')
        )
        .then(() =>
          patchRequest('3-days/', account.microsoft_cid, 'bing_account')
        )
        .then(() =>
          patchRequest('yesterday/', account.microsoft_cid, 'bing_account')
        )
        .then(() =>
          patchRequest('all-time/', account.microsoft_cid, 'bing_account')
        )
        .then(() => handleRefresh())
        .catch((err) => {
          setIsLoadingData(false);
          console.log(err);
        });
    }
  };

  return (
    <FormLayoutBase handleSubmit={handleSubmit} btnLabel='Submit Change'>
      <FormInput
        value={input.attName}
        title='Name'
        onChange={handleChange}
        defaultValue={account.name}
        name='attName'
      />
      <FormInput
        value={input.attEmail}
        title='Email'
        onChange={handleChange}
        defaultValue={
          account.email === 'nan' ||
          account.email === 'your@email.com' ||
          !account.email
            ? ''
            : account.email
        }
        name='attEmail'
      />
      <FormInput
        title='Budget'
        onChange={handleChange}
        value={input.attBudget ? input.attBudget : null}
        defaultValue={account.budget}
        name='attBudget'
      />
      <FormDropdown
        title='PPC Manager'
        handlePick={handleChange}
        options={ppcSpecialists}
        name='attPPC'
        defaultValue={account.ppc_specialist}
      />
      <FormDropdown
        title='Account Manager'
        handlePick={handleChange}
        options={accountManagers}
        name='attAM'
        defaultValue={account.account_manager}
      />
      <FormInput
        title='State And Country (Format: NY, US)'
        onChange={handleChange}
        value={input.attState}
        defaultValue={account.state_country}
        name='attState'
      />
    </FormLayoutBase>
  );
};

export default ChangeAttForm;
