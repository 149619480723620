import React from 'react';
import LogOutButton from '../buttons/LogOutButton';
import {
  isOpenProfModalState,
  lightModeState,
  userState,
} from '../../recoil/atoms';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { BsPersonCircle } from 'react-icons/bs';
import { BiLock } from 'react-icons/bi';
import { IoMdClose } from 'react-icons/io';
import { Link } from 'react-router-dom';

const Profile = () => {
  const user = useRecoilValue(userState);
  const setIsOpenProfModal = useSetRecoilState(isOpenProfModalState);
  const lightMode = useRecoilValue(lightModeState);
  return (
    <div
      className={`fixed ml-1 left-12 bottom-4 mt-1 border-l border-r border-b border-t ${
        lightMode
          ? 'bg-gray-200 border-gray-300 text-gray-900 shadow-lg'
          : 'bg-gray-900 border-gray-800 text-white shadow'
      } px-5 py-5 z-50 rounded  flex items-center gap-x-5`}
    >
      <IoMdClose
        className='absolute top-5 right-5 cursor-pointer'
        onClick={() => setIsOpenProfModal(false)}
      />
      <BsPersonCircle className='text-5xl' />
      <div className='mt-2'>
        <p className='text-sm mb-2'>{user?.email}</p>
        <div className='flex items-center gap-x-2'>
          <LogOutButton />
          <Link
            to='/password'
            className={`text-white transition duration-300 ${
              lightMode
                ? 'bg-yellow-300 hover:bg-yellow-400'
                : 'bg-yellow-500 hover:bg-yellow-600'
            } p-2 rounded`}
            title='Change Password'
          >
            <BiLock />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Profile;
