import React, { useState } from 'react';
import { useRecoilValue } from 'recoil';
import PlatformSelect from '../filter-comps/PlatformSelect';
import {
  googleDataSelectedState,
  microsoftDataSelectedState,
  adDataSelectedState,
  googleReportsSelectedState,
  microsoftReportsSelectedState,
  lsaReportsSelectedState,
  adReportsSelectedState,
  accountState,
  userState,
  lightModeState,
  pageState,
} from '../../recoil/atoms';
import { adminAccountState } from '../../recoil/selectors/dataSelectors';

type Props = {
  styleClass?: string;
};

const PlatformDropdown = ({ styleClass }: Props) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const lightMode = useRecoilValue(lightModeState);
  const page = useRecoilValue(pageState);
  const user = useRecoilValue(userState);
  const superAdminAccount = useRecoilValue(accountState);
  const adminAccount = useRecoilValue(adminAccountState);
  const account =
    user?.role === 'superadmin' ? superAdminAccount : adminAccount;

  const googleSelected = useRecoilValue(
    page === 'data' ? googleDataSelectedState : googleReportsSelectedState
  );
  const microsoftSelected = useRecoilValue(
    page === 'data' ? microsoftDataSelectedState : microsoftReportsSelectedState
  );
  const lsaSelected = useRecoilValue(lsaReportsSelectedState);

  const adSelected = useRecoilValue(
    page === 'data' ? adDataSelectedState : adReportsSelectedState
  );
  const addGoogle = googleSelected ? 1 : 0;
  const addMicrosoft = microsoftSelected ? 1 : 0;
  const addLSA = lsaSelected ? 1 : 0;
  const addAD = adSelected ? 1 : 0;
  let platformSum = addGoogle + addMicrosoft;
  if (page === 'reports') platformSum = addGoogle + addMicrosoft + addLSA;

  platformSum =
    platformSum + (account?.additional_data || page === 'data' ? addAD : 0);

  const dropdownTitle = platformSum > 0 ? `${platformSum} Selected` : 'None';

  const handleClick = () => {
    setShowDropdown(!showDropdown);
  };

  return (
    <div
      className={` ${
        user?.role === 'superadmin'
          ? `${
              page !== 'reports' ? 'w-5/12' : 'w-7/12 sm:w-1/5 -ml-16 sm:ml-0'
            } `
          : 'mt-11 sm:mt-9 w-2/3 sm:w-1/2 '
      } ${lightMode ? 'text-gray-900' : 'text-white'} ${styleClass}`}
    >
      <section
        className={`p-2 mt-2 rounded-sm w-full border-b border-t border-l border-r cursor-pointer text-center transition duration-300 ${
          lightMode
            ? `${
                showDropdown ? 'bg-gray-200' : 'bg-gray-200'
              } hover:bg-gray-300 border-gray-400`
            : `${
                showDropdown ? 'bg-gray-800' : 'bg-gray-800'
              } hover:bg-gray-700 border-gray-600`
        }  `}
        onClick={handleClick}
      >
        <div className='font-semibold text-2xs sm:text-base'>
          Platforms{' '}
          <p
            className={`hidden ${
              user?.role === 'admin' ? 'sm:inline' : 'xs:inline'
            }`}
          >
            : {dropdownTitle}
          </p>{' '}
          {!showDropdown ? ' ▾' : ' ▴'}
        </div>
      </section>
      <div
        className={`mt-1 z-50 ${
          page === 'reports' ? 'w-1/4 xs:w-5/12' : 'w-5/12'
        } sm:w-1/5  ${
          lightMode ? 'bg-gray-200' : 'bg-gray-800'
        } xs:px-4 sm:py-1 absolute ${
          user?.role === 'admin' ? 'right-5 sm:right-16' : ''
        } rounded-lg ${showDropdown ? '' : 'hidden'}`}
      >
        <PlatformSelect />
      </div>
    </div>
  );
};

export default PlatformDropdown;
