import React, { useState } from 'react';
import {
  accounts30DaysAgoState,
  accountState,
  userLocation30DaysAgoState,
  userLocationAprState,
  userLocationAugState,
  userLocationDecState,
  userLocationFebState,
  userLocationJanState,
  userLocationJulState,
  userLocationJunState,
  userLocationMarState,
  userLocationMayState,
  userLocationNovState,
  userLocationOctState,
  userLocationSeptState,
  bingUserLocation30DaysAgoState,
  bingUserLocationJanState,
  bingUserLocationFebState,
  bingUserLocationMarState,
  bingUserLocationAprState,
  bingUserLocationMayState,
  bingUserLocationJunState,
  bingUserLocationJulState,
  bingUserLocationAugState,
  bingUserLocationSeptState,
  bingUserLocationOctState,
  bingUserLocationNovState,
  bingUserLocationDecState,
  monNameState,
  lightModeState,
  isLoadingUserLocationState,
  userState,
  userLocationFilterState,
} from '../../recoil/atoms';
import { useRecoilValue } from 'recoil';
import { month } from '../../helpers/dateUtils';
import TablePagination from '../table-comps/TablePagination';
import UserLocationTable from '../tables/UserLocationTable';
import LoadAnimation from '../universal-decorations/LoadAnimation';
import UserLocationDropdown from '../filters/UserLocationDropwdown';
import ReportsCompFilter from '../filter-comps/ReportsCompFilter';

const UserLocation = () => {
  const userLocationMonths = [
    userLocationJanState,
    userLocationFebState,
    userLocationMarState,
    userLocationAprState,
    userLocationMayState,
    userLocationJunState,
    userLocationJulState,
    userLocationAugState,
    userLocationSeptState,
    userLocationOctState,
    userLocationNovState,
    userLocationDecState,
  ];

  const bingUserLocationMonths = [
    bingUserLocationJanState,
    bingUserLocationFebState,
    bingUserLocationMarState,
    bingUserLocationAprState,
    bingUserLocationMayState,
    bingUserLocationJunState,
    bingUserLocationJulState,
    bingUserLocationAugState,
    bingUserLocationSeptState,
    bingUserLocationOctState,
    bingUserLocationNovState,
    bingUserLocationDecState,
  ];

  const [showFilter, setShowFilter] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);

  const [isSortCity, setIsSortCity] = useState(1);
  const [isSortState, setIsSortState] = useState(1);
  const [isSortImp, setIsSortImp] = useState(1);
  const [isSortClicks, setIsSortClicks] = useState(1);
  const [isSortCTR, setIsSortCTR] = useState(1);
  const [isSortCPC, setIsSortCPC] = useState(1);
  const [isSortCost, setIsSortCost] = useState(1);
  const [isSortConv, setIsSortConv] = useState(1);
  const [isSortCPL, setIsSortCPL] = useState(1);

  const isSortValues = [
    isSortState,
    isSortCity,
    isSortImp,
    isSortClicks,
    isSortCTR,
    isSortCPC,
    isSortCost,
    isSortConv,
    isSortCPL,
  ];

  const setters = [
    setIsSortState,
    setIsSortCity,
    setIsSortImp,
    setIsSortClicks,
    setIsSortCTR,
    setIsSortCPC,
    setIsSortCost,
    setIsSortConv,
    setIsSortCPL,
  ];

  const userLocationFilter = useRecoilValue(userLocationFilterState);
  const user = useRecoilValue(userState);
  const lightMode = useRecoilValue(lightModeState);
  const monName = useRecoilValue(monNameState);
  const isLoading = useRecoilValue(isLoadingUserLocationState);
  const adminAccount = useRecoilValue(accounts30DaysAgoState)[0];
  const superadminAccount = useRecoilValue(accountState);
  const account =
    user?.role === 'superadmin' ? superadminAccount : adminAccount;
  const userLocation = useRecoilValue(
    userLocationFilter === 'Google'
      ? monName === '30-Day'
        ? userLocation30DaysAgoState
        : userLocationMonths[month.indexOf(monName)]
      : monName === '30-Day'
      ? bingUserLocation30DaysAgoState
      : bingUserLocationMonths[month.indexOf(monName)]
  ).filter((item) =>
    userLocationFilter === 'Google'
      ? item.account === account.google_cid
      : item.account === account.microsoft_cid
  );

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isFloatSorter = (isSort: number) => {
    isSort === 2
      ? userLocation.sort((a, b) => {
          const aSort =
            isSort === isSortImp
              ? a.impressions
              : isSort === isSortClicks
              ? a.clicks
              : isSort === isSortCTR
              ? a.ctr
              : isSort === isSortCPC
              ? a.avg_cpc
              : isSort === isSortCost
              ? a.spend
              : isSort === isSortConv
              ? a.conversions
              : a.cost_per_conversion;
          const bSort =
            isSort === isSortImp
              ? b.impressions
              : isSort === isSortClicks
              ? b.clicks
              : isSort === isSortCTR
              ? b.ctr
              : isSort === isSortCPC
              ? b.avg_cpc
              : isSort === isSortCost
              ? b.spend
              : isSort === isSortConv
              ? b.conversions
              : b.cost_per_conversion;
          return bSort - aSort;
        })
      : isSort === 3 &&
        userLocation.sort((a, b) => {
          const aSort =
            isSort === isSortImp
              ? a.impressions
              : isSort === isSortClicks
              ? a.clicks
              : isSort === isSortCTR
              ? a.ctr
              : isSort === isSortCPC
              ? a.avg_cpc
              : isSort === isSortCost
              ? a.spend
              : isSort === isSortConv
              ? a.conversions
              : a.cost_per_conversion;
          const bSort =
            isSort === isSortImp
              ? b.impressions
              : isSort === isSortClicks
              ? b.clicks
              : isSort === isSortCTR
              ? b.ctr
              : isSort === isSortCPC
              ? b.avg_cpc
              : isSort === isSortCost
              ? b.spend
              : isSort === isSortConv
              ? b.conversions
              : b.cost_per_conversion;
          return aSort - bSort;
        });
  };

  const isStringSorter = (isSort: number) => {
    isSort === 2
      ? userLocation.sort((a, b) => {
          const aSort = isSort === isSortState ? a.state : a.city;
          const bSort = isSort === isSortState ? b.state : b.city;

          if (aSort > bSort) return -1;
          if (aSort < bSort) return 1;
          return 0;
        })
      : isSort === 3 &&
        userLocation.sort((a, b) => {
          const aSort = isSort === isSortState ? a.state : a.city;
          const bSort = isSort === isSortState ? b.state : b.city;

          if (aSort > bSort) return 1;
          if (aSort < bSort) return -1;
          return 0;
        });
  };

  isSortValues
    .slice(0, 2)
    .forEach((isSortValue) => isStringSorter(isSortValue));
  isSortValues.slice(2).forEach((isSortValue) => isFloatSorter(isSortValue));

  interface options {
    isCity?: boolean;
    isState?: boolean;
    isCPC?: boolean;
    isCPL?: boolean;
    isCTR?: boolean;
    isClicks?: boolean;
    isConv?: boolean;
    isImp?: boolean;
    isCost?: boolean;
  }

  const notSortList = (options: options) => {
    const list = [];
    if (!options.isCity) list.push(setIsSortCity);
    if (!options.isState) list.push(setIsSortState);
    if (!options.isImp) list.push(setIsSortImp);
    if (!options.isClicks) list.push(setIsSortClicks);
    if (!options.isCTR) list.push(setIsSortCTR);
    if (!options.isCPC) list.push(setIsSortCPC);
    if (!options.isCost) list.push(setIsSortCost);
    if (!options.isConv) list.push(setIsSortConv);
    if (!options.isCPL) list.push(setIsSortCPL);
    return list;
  };

  const handleNotSort = ([
    setNotSort1,
    setNotSort2,
    setNotSort3,
    setNotSort4,
    setNotSort5,
    setNotSort6,
    setNotSort7,
    setNotSort8,
  ]: React.Dispatch<React.SetStateAction<number>>[]) => {
    setNotSort1(1);
    setNotSort2(1);
    setNotSort3(1);
    setNotSort4(1);
    setNotSort5(1);
    setNotSort6(1);
    setNotSort7(1);
    setNotSort8(1);
  };

  const handleSort = (
    setMainSort: React.Dispatch<React.SetStateAction<number>>,
    mainSort: number
  ) => {
    setMainSort(mainSort < 3 ? mainSort + 1 : 1);
    setPage(0);
  };

  return (
    <div className={userLocation.length === 0 ? 'mb-3' : ''}>
      <div
        className={`grid grid-rpws-2 sm:flex  sm:justify-between items-center ${
          lightMode ? 'text-gray-900' : 'text-white'
        } text-xs sm:text-base w-full text-left`}
      >
        <div className='hidden sm:inline'>
          <TablePagination
            items={userLocation}
            rowsPerPage={rowsPerPage}
            page={page}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </div>
        <div className='w-5/12 sm:w-1/4 '>
          <ReportsCompFilter
            onClick={() => setShowFilter(!showFilter)}
            showFilter={showFilter}
            title='UL Platforms'
          />
          <UserLocationDropdown
            showFilter={showFilter}
            setShowFilter={setShowFilter}
          />
        </div>
        <div className='sm:hidden inline'>
          <TablePagination
            items={userLocation}
            rowsPerPage={rowsPerPage}
            page={page}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </div>
      </div>
      <div className='animate__animated animate__backInRight'>
        {isLoading ? (
          <div className='py-32'>
            <LoadAnimation />
          </div>
        ) : (
          <div className='overflow-x-scroll'>
            <UserLocationTable
              items={userLocation.slice(
                page * rowsPerPage,
                page * rowsPerPage + rowsPerPage
              )}
              isSortArr={isSortValues}
              setters={setters}
              handleSort={handleSort}
              handleNotSort={handleNotSort}
              notSortList={notSortList}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default UserLocation;
