import { lightModeState, userState } from '../../recoil/atoms';
import { useRecoilState, useRecoilValue } from 'recoil';
import { BiLock, BiPlus, BiSun, BiMoon } from 'react-icons/bi';
import { BsPersonCircle } from 'react-icons/bs';
import { Link } from 'react-router-dom';
import LogOutButton from '../buttons/LogOutButton';

type Props = {
  handleClose: () => void;
};

const MobileNavFooter = ({ handleClose }: Props) => {
  const [lightMode, setLightMode] = useRecoilState(lightModeState);
  const user = useRecoilValue(userState);

  return (
    <div
      className={` ${
        lightMode ? 'bg-white text-gray-900' : 'bg-gray-900 text-white'
      } h-10`}
    >
      <div className='flex gap-x-4 pb-4 pl-3'>
        <Link
          className={`px-3 py-1 border-l border-r border-b border-t ${
            lightMode ? 'border-black text-gray-900' : 'border-white text-white'
          } rounded flex gap-x-2 items-center no-underline`}
          to='/adduser'
          onClick={handleClose}
        >
          <BiPlus /> Add User
        </Link>
        <div
          className={`px-3 py-1 border-l border-r border-b border-t ${
            lightMode ? 'border-black' : 'border-white'
          } rounded flex gap-x-2 items-center`}
          onClick={() => setLightMode(!lightMode)}
        >
          {lightMode ? <BiMoon /> : <BiSun />}{' '}
          {lightMode ? 'Dark Mode' : 'Light Mode'}
        </div>
      </div>
      <div
        className={`py-10 pl-5 border-t ${
          lightMode ? 'border-black' : 'border-white'
        } flex items-center gap-x-5 w-full`}
      >
        <BsPersonCircle className='text-6xl' />
        <div className=''>
          {user?.email}
          <div className='flex gap-x-2 pt-2 '>
            <LogOutButton handleClose={handleClose} />
            <Link
              to='/password'
              className={`text-white transition duration-300 ${
                lightMode
                  ? 'bg-yellow-300 hover:bg-yellow-400'
                  : 'bg-yellow-500 hover:bg-yellow-600'
              } p-2 rounded`}
              title='Change Password'
              onClick={handleClose}
            >
              <BiLock />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MobileNavFooter;
