import React, { useState } from 'react';
import {
  useRecoilState,
  useRecoilValue,
  useSetRecoilState,
  useResetRecoilState,
} from 'recoil';
import {
  dataCoverageState,
  accounts3DaysAgoState,
  accounts90DaysAgoState,
  accountsYesterdayState,
  bmsAccount30DaysState,
  bmsAccountJanState,
  bmsAccountFebState,
  bmsAccountMarState,
  bmsAccountAprState,
  bmsAccountMayState,
  bmsAccountJunState,
  bmsAccountJulstate,
  bmsAccountAugState,
  bmsAccountSeptState,
  bmsAccountOctState,
  bmsAccountNovState,
  bmsAccountDecState,
  isLoadingDataState,
  bmsAccountAllTimeState,
  idxFilterOptionsState,
  idxPlatformsFilterState,
  accountManagersFilterState,
  accManagerOptionState,
  ppcSpecialistsFilterState,
  ppcFilterOptionState,
  statesFilterState,
  statesOptionState,
  statusFilterState,
  statusOptionState,
  budgetFilterState,
  budgetComparatorState,
  costComparatorState,
  costFilterState,
  remainingBudgetComparatorState,
  remainingBudgetFilterState,
  paceComparatorState,
  paceFilterState,
  cplComparatorState,
  cplFilterState,
  convComparatorState,
  convFilterState,
  notesFilterState,
  notesOptionState,
  isEditingColumsState,
} from '../../recoil/atoms';
import type { SetterOrUpdater } from 'recoil';
import { month } from '../../helpers/dateUtils';
import { headerState } from '../../recoil/selectors/dataSelectors';
import axios from 'axios';
import { Account } from '../../helpers/types';
import CoverageTemplate from '../date-related-templates/CoverageTemplate';

const BMSCoverage = () => {
  const env = process.env.NODE_ENV;
  const headers = useRecoilValue(headerState);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [accounts3DaysAgo, setAccounts3DaysAgo] = useRecoilState(
    accounts3DaysAgoState
  );
  const [accounts90DaysAgo, setAccounts90DaysAgo] = useRecoilState(
    accounts90DaysAgoState
  );
  const [accountsYesterday, setAccountsYesterday] = useRecoilState(
    accountsYesterdayState
  );
  const [accounts30DaysAgo, setAccounts30DaysAgo] = useRecoilState(
    bmsAccount30DaysState
  );
  const [accountsJan, setAccountsJan] = useRecoilState(bmsAccountJanState);
  const [accountsFeb, setAccountsFeb] = useRecoilState(bmsAccountFebState);
  const [accountsMar, setAccountsMar] = useRecoilState(bmsAccountMarState);
  const [accountsApr, setAccountsApr] = useRecoilState(bmsAccountAprState);
  const [accountsMay, setAccountsMay] = useRecoilState(bmsAccountMayState);
  const [accountsJun, setAccountsJun] = useRecoilState(bmsAccountJunState);
  const [accountsJul, setAccountsJul] = useRecoilState(bmsAccountJulstate);
  const [accountsAug, setAccountsAug] = useRecoilState(bmsAccountAugState);
  const [accountsSept, setAccountsSept] = useRecoilState(bmsAccountSeptState);
  const [accountsOct, setAccountsOct] = useRecoilState(bmsAccountOctState);
  const [accountsNov, setAccountsNov] = useRecoilState(bmsAccountNovState);
  const [accountsDec, setAccountsDec] = useRecoilState(bmsAccountDecState);
  const [accountsAllTime, setAccountsAllTime] = useRecoilState(
    bmsAccountAllTimeState
  );
  const [dataCoverage, setDataCoverage] = useRecoilState(dataCoverageState);
  const setIsLoadingData = useSetRecoilState(isLoadingDataState);
  const setIsEditingColumn = useSetRecoilState(isEditingColumsState);

  const resetIDXFilter = useResetRecoilState(idxPlatformsFilterState);
  const resetIDXOptions = useResetRecoilState(idxFilterOptionsState);
  const resetAMFilter = useResetRecoilState(accountManagersFilterState);
  const resetAMOptions = useResetRecoilState(accManagerOptionState);
  const resetPPCFilter = useResetRecoilState(ppcSpecialistsFilterState);
  const resetPPCOptions = useResetRecoilState(ppcFilterOptionState);
  const resetStatesFilter = useResetRecoilState(statesFilterState);
  const resetStatesOptions = useResetRecoilState(statesOptionState);
  const resetStatusFilter = useResetRecoilState(statusFilterState);
  const resetStatusOptions = useResetRecoilState(statusOptionState);
  const resetBudgetFilter = useResetRecoilState(budgetFilterState);
  const resetBudgetComparator = useResetRecoilState(budgetComparatorState);
  const resetCostFilter = useResetRecoilState(costFilterState);
  const resetCostComparator = useResetRecoilState(costComparatorState);
  const resetRemainingBudgetFilter = useResetRecoilState(
    remainingBudgetFilterState
  );
  const resetRemainingBudgetComparator = useResetRecoilState(
    remainingBudgetComparatorState
  );
  const resetPaceFilter = useResetRecoilState(paceFilterState);
  const resetPaceComparator = useResetRecoilState(paceComparatorState);
  const resetCPLFilter = useResetRecoilState(cplFilterState);
  const resetCPLComparator = useResetRecoilState(cplComparatorState);
  const resetConvFilter = useResetRecoilState(convFilterState);
  const resetConvComparator = useResetRecoilState(convComparatorState);
  const resetNotesFilter = useResetRecoilState(notesFilterState);
  const resetNotesOptions = useResetRecoilState(notesOptionState);

  const customOptions = [
    {
      option: 'This Month',
      onClickOption: () => handleClickOption('This Mo'),
    },
    {
      option: 'Last 30 Days',
      onClickOption: () =>
        handleClickOption(
          '30-Day',
          accounts30DaysAgo,
          setAccounts30DaysAgo,
          '30-days'
        ),
    },
    {
      option: 'Last 90 Days',
      onClickOption: () =>
        handleClickOption(
          '90-Day',
          accounts90DaysAgo,
          setAccounts90DaysAgo,
          '90-days'
        ),
    },
    {
      option: 'Last 3 Days',
      onClickOption: () =>
        handleClickOption(
          '3-Day',
          accounts3DaysAgo,
          setAccounts3DaysAgo,
          '3-days'
        ),
    },
    {
      option: 'Yesterday',
      onClickOption: () =>
        handleClickOption(
          'Yesterday',
          accountsYesterday,
          setAccountsYesterday,
          'yesterday'
        ),
    },
  ];

  const clearFilter = () => {
    resetIDXFilter();
    resetIDXOptions();
    resetAMFilter();
    resetAMOptions();
    resetPPCFilter();
    resetPPCOptions();
    resetStatesFilter();
    resetStatesOptions();
    resetStatusFilter();
    resetStatusOptions();
    resetBudgetFilter();
    resetBudgetComparator();
    resetCostFilter();
    resetCostComparator();
    resetRemainingBudgetFilter();
    resetRemainingBudgetComparator();
    resetPaceFilter();
    resetPaceComparator();
    resetCPLFilter();
    resetCPLComparator();
    resetConvFilter();
    resetConvComparator();
    resetNotesFilter();
    resetNotesOptions();
  };

  const handleClickOption = async (
    coverage:
      | '90-Day'
      | '30-Day'
      | '3-Day'
      | 'Yesterday'
      | 'This Mo'
      | 'All Time'
      | 'Jan'
      | 'Feb'
      | 'Mar'
      | 'Apr'
      | 'May'
      | 'Jun'
      | 'Jul'
      | 'Aug'
      | 'Sept'
      | 'Oct'
      | 'Nov'
      | 'Dec',
    accounts?: Account[],
    setAccounts?: SetterOrUpdater<Account[]>,
    timeFrame?: string
  ) => {
    clearFilter();
    setDataCoverage(coverage);
    setIsMenuOpen(false);
    setIsEditingColumn(false);
    if (!accounts || accounts.length >= 1) return;
    setIsLoadingData(true);
    await axios({
      method: 'get',
      headers: headers,
      url: `${
        env === 'production' ? 'https://apimakeforth.herokuapp.com' : ''
      }/api/account_info/${timeFrame}/`,
    })
      .then((res) => {
        if (setAccounts) setAccounts(res.data);
        setIsLoadingData(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoadingData(false);
      });
  };

  return (
    <CoverageTemplate
      isMenuOpen={isMenuOpen}
      setIsMenuOpen={setIsMenuOpen}
      coverage={dataCoverage}
      customOptions={customOptions}
      onClickJan={() =>
        handleClickOption(
          month[0],
          accountsJan,
          setAccountsJan,
          month[0].toLowerCase()
        )
      }
      onClickFeb={() =>
        handleClickOption(
          month[1],
          accountsFeb,
          setAccountsFeb,
          month[1].toLowerCase()
        )
      }
      onClickMar={() =>
        handleClickOption(
          month[2],
          accountsMar,
          setAccountsMar,
          month[2].toLowerCase()
        )
      }
      onClickApr={() =>
        handleClickOption(
          month[3],
          accountsApr,
          setAccountsApr,
          month[3].toLowerCase()
        )
      }
      onClickMay={() =>
        handleClickOption(
          month[4],
          accountsMay,
          setAccountsMay,
          month[4].toLowerCase()
        )
      }
      onClickJun={() =>
        handleClickOption(
          month[5],
          accountsJun,
          setAccountsJun,
          month[5].toLowerCase()
        )
      }
      onClickJul={() =>
        handleClickOption(
          month[6],
          accountsJul,
          setAccountsJul,
          month[6].toLowerCase()
        )
      }
      onClickAug={() =>
        handleClickOption(
          month[7],
          accountsAug,
          setAccountsAug,
          month[7].toLowerCase()
        )
      }
      onClickSept={() =>
        handleClickOption(
          month[8],
          accountsSept,
          setAccountsSept,
          month[8].toLowerCase()
        )
      }
      onClickOct={() =>
        handleClickOption(
          month[9],
          accountsOct,
          setAccountsOct,
          month[9].toLowerCase()
        )
      }
      onClickNov={() =>
        handleClickOption(
          month[10],
          accountsNov,
          setAccountsNov,
          month[10].toLowerCase()
        )
      }
      onClickDec={() =>
        handleClickOption(
          month[11],
          accountsDec,
          setAccountsDec,
          month[11].toLowerCase()
        )
      }
      onClickAllTime={() =>
        handleClickOption(
          'All Time',
          accountsAllTime,
          setAccountsAllTime,
          'all-time'
        )
      }
    />
  );
};

export default BMSCoverage;
