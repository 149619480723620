import React from 'react';
import DoughnutGraph from '../graph-templates/DoughnutGraph';
import {
  adminAccountState,
  getAccountState,
} from '../../recoil/selectors/dataSelectors';
import { accountState, userState } from '../../recoil/atoms';
import { useRecoilValue } from 'recoil';

type Props = {
  isLabel?: boolean;
  isPrint?: boolean;
};

const LeadsDeviceBingDoughnutChart = ({ isLabel, isPrint }: Props) => {
  const user = useRecoilValue(userState);
  const adminAccount = useRecoilValue(adminAccountState);
  const superadminAccount = useRecoilValue(accountState);
  const account =
    user?.role === 'superadmin' ? superadminAccount : adminAccount;

  const { bing_mobile, bing_desktop } = useRecoilValue(getAccountState);
  const mLeads = bing_mobile ? bing_mobile.conversions : '';
  const dLeads = bing_desktop ? bing_desktop.conversions : '';

  const other =
    account && account.microsoft_account
      ? parseFloat(account.microsoft_account.conversions) -
        (parseFloat(mLeads) + parseFloat(dLeads))
      : 0;
  const otherString = Math.sign(other) > 0 ? other.toString() : '';

  return (
    <DoughnutGraph
      data1={mLeads}
      data2={dLeads}
      data3={otherString}
      label1='Mobile'
      label2='Desktop'
      label3='Other'
      title='Leads Per Device (Microsoft)'
      color1='#16C79A'
      color2='#6A5495'
      color3='#EB5E0B'
      hovercolor1='#CFFFDC'
      hovercolor2='#D5D5D5'
      hovercolor3='#FCECDD'
      isLabel={isLabel}
      isPrint={isPrint}
    />
  );
};

export default LeadsDeviceBingDoughnutChart;
