import React from 'react';
import {
  googleDataSelectedState,
  microsoftDataSelectedState,
  adDataSelectedState,
  googleReportsSelectedState,
  microsoftReportsSelectedState,
  lsaReportsSelectedState,
  adReportsSelectedState,
  pageState,
  userState,
  accountState,
} from '../../recoil/atoms';
import { useRecoilState, useRecoilValue } from 'recoil';
import type { SetterOrUpdater } from 'recoil';
import { adminAccountState } from '../../recoil/selectors/dataSelectors';

const PlatformSelect = () => {
  const env = process.env.NODE_ENV;
  const page = useRecoilValue(pageState);
  const user = useRecoilValue(userState);
  const superAdminAccount = useRecoilValue(accountState);
  const adminAccount = useRecoilValue(adminAccountState);
  const account =
    user?.role === 'superadmin' ? superAdminAccount : adminAccount;
  const [googleSelected, setGoogleSelected] = useRecoilState(
    page === 'data' ? googleDataSelectedState : googleReportsSelectedState
  );
  const [microsoftSelected, setMicrosoftSelected] = useRecoilState(
    page === 'data' ? microsoftDataSelectedState : microsoftReportsSelectedState
  );
  const [lsaSelected, setLsaSelected] = useRecoilState(lsaReportsSelectedState);

  const [adSelected, setAdSelected] = useRecoilState(
    page === 'data' ? adDataSelectedState : adReportsSelectedState
  );

  const handleCheck = (setSelected: SetterOrUpdater<boolean>) => {
    setSelected((selected) => !selected);
  };

  return (
    <ul className={`${env === 'production' ? 'py-3' : 'pt-3'}`}>
      <li>
        <label className='cursor-pointer px-2'>
          <input
            type='checkbox'
            checked={googleSelected}
            onChange={() => handleCheck(setGoogleSelected)}
            className='rounded'
          />
          <span className=' ml-2 text-2xs sm:text-base'>Google</span>
        </label>
      </li>
      <li>
        <label className='cursor-pointer px-2'>
          <input
            type='checkbox'
            checked={microsoftSelected}
            onChange={() => handleCheck(setMicrosoftSelected)}
            className='rounded'
          />
          <span className='ml-2 text-2xs sm:text-base'>Microsoft</span>
        </label>
      </li>
      {page === 'reports' && (
        <li>
          <label className='cursor-pointer px-2'>
            <input
              type='checkbox'
              checked={lsaSelected}
              onChange={() => handleCheck(setLsaSelected)}
              className='rounded'
            />
            <span className='ml-2 text-2xs sm:text-base'>LSA</span>
          </label>
        </li>
      )}
      {(account?.additional_data || page === 'data') && (
        <li>
          <label className='cursor-pointer px-2'>
            <input
              type='checkbox'
              checked={adSelected}
              onChange={() => handleCheck(setAdSelected)}
              className='rounded'
            />
            <span className=' ml-2 text-2xs sm:text-base'>Manually Added</span>
          </label>
        </li>
      )}
    </ul>
  );
};

export default PlatformSelect;
