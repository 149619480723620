import { useRecoilValue } from 'recoil';
import { getAccountState } from '../../recoil/selectors/dataSelectors';
import PrintBoxTemplate from '../info-boxes-templates/PrintBoxTemplate';
import { Commaed } from '../../helpers/utils';

type Props = {
  reportBox: string;
};

const PrintBox = ({ reportBox }: Props) => {
  const { gAccount, mAccount, lAccount, adAccount } =
    useRecoilValue(getAccountState);

  const gData =
    gAccount?.spend &&
    (reportBox === 'Ad Investment' || reportBox === 'Cost Per Lead')
      ? gAccount.spend
      : gAccount?.conversions && reportBox === 'Conversions'
      ? gAccount.conversions
      : gAccount?.impressions && reportBox === 'Impressions'
      ? gAccount.impressions
      : gAccount?.clicks && (reportBox === 'Clicks' || reportBox === 'CTR')
      ? gAccount.clicks
      : '0';
  const mData =
    mAccount?.spend &&
    (reportBox === 'Ad Investment' || reportBox === 'Cost Per Lead')
      ? mAccount.spend
      : mAccount?.conversions && reportBox === 'Conversions'
      ? mAccount.conversions
      : mAccount?.impressions && reportBox === 'Impressions'
      ? mAccount.impressions
      : mAccount?.clicks && (reportBox === 'Clicks' || reportBox === 'CTR')
      ? mAccount.clicks
      : '0';
  const lData =
    lAccount?.spend &&
    (reportBox === 'Ad Investment' || reportBox === 'Cost Per Lead')
      ? lAccount.spend
      : lAccount?.conversions && reportBox === 'Conversions'
      ? lAccount.conversions
      : '0';
  const adData =
    adAccount?.spend &&
    (reportBox === 'Ad Investment' || reportBox === 'Cost Per Lead')
      ? adAccount.spend
      : adAccount?.conversions && reportBox === 'Conversions'
      ? adAccount.conversions
      : '0';
  const dataTotal =
    parseFloat(gData) +
    parseFloat(mData) +
    parseFloat(lData) +
    parseFloat(adData);

  const gData2 =
    gAccount?.conversions && reportBox === 'Cost Per Lead'
      ? gAccount.conversions
      : gAccount?.clicks && reportBox === 'CTR'
      ? gAccount.impressions
      : '0';
  const mData2 =
    mAccount?.conversions && reportBox === 'Cost Per Lead'
      ? mAccount.conversions
      : mAccount?.clicks && reportBox === 'CTR'
      ? mAccount.impressions
      : '0';
  const lData2 =
    lAccount?.conversions && reportBox === 'Cost Per Lead'
      ? lAccount.conversions
      : '0';
  const adData2 =
    adAccount?.conversions && reportBox === 'Cost Per Lead'
      ? adAccount.conversions
      : '0';

  let data2Total =
    parseFloat(gData2) +
    parseFloat(mData2) +
    parseFloat(lData2) +
    parseFloat(adData2);

  if (data2Total === 0) data2Total = 1;

  const total =
    reportBox === 'Cost Per Lead'
      ? dataTotal / data2Total
      : reportBox === 'CTR'
      ? (dataTotal / data2Total) * 100
      : dataTotal;

  return (
    <PrintBoxTemplate
      data={`${
        reportBox === 'Ad Investment' || reportBox === 'Cost Per Lead'
          ? '$'
          : ''
      }${Commaed(total.toFixed(2))}${reportBox === 'CTR' ? '%' : ''}`}
      label={reportBox}
    />
  );
};

export default PrintBox;
