import { atom } from 'recoil';
import { Account, UserLocation, SearchTerm } from '../../helpers/types';
import { CheckboxOptions } from '../../helpers/types';

export const thisMonthAccountsState = atom<Account[]>({
  key: 'thisMonthAccountsState',
  default: [],
});

export const accountsState = atom<Account[]>({
  key: 'accountsState',
  default: [],
});

export const accounts2MonthsAgoState = atom<Account[]>({
  key: 'accounts2MonthsAgoState',
  default: [],
});

export const accounts3MonthsAgoState = atom<Account[]>({
  key: 'accounts3MonthsAgoState',
  default: [],
});

export const accountState = atom<Account>({
  key: 'accountState',
  default: {
    id: '',
    name: 'None',
    email: 'your@email.com',
    google_cid: undefined,
    microsoft_cid: '',
    lsa_cid: undefined,
    account_manager: '',
    ppc_specialist: '',
    state_country: '',
    city: '',
    idx: '',
    ad_var: '',
    google_account: {
      id: 0,
      name: '',
      spend: '',
      conversions: '',
      cost_per_conversion: '',
      impressions: '',
      clicks: '',
      ctr: '',
    },
    microsoft_account: {
      id: '',
      name: '',
      spend: '',
      conversions: '',
      cost_per_conversion: '',
      impressions: '',
      clicks: '',
      ctr: '',
    },
  },
});

export const googleDataSelectedState = atom({
  key: 'googleDataSelectedState',
  default: true,
});

export const microsoftDataSelectedState = atom({
  key: 'microsoftDataSelectedState',
  default: true,
});

export const adDataSelectedState = atom({
  key: 'adDataSelectedState',
  default: true,
});

export const googleReportsSelectedState = atom({
  key: 'googleReportsSelectedState',
  default: true,
});

export const microsoftReportsSelectedState = atom({
  key: 'microsoftReportsSelectedState',
  default: true,
});

export const lsaReportsSelectedState = atom({
  key: 'lsaReportsSelectedState',
  default: true,
});

export const adReportsSelectedState = atom({
  key: 'adReportsSelectedState',
  default: true,
});

export const accounts30DaysAgoState = atom<Account[]>({
  key: 'accounts30DaysAgoState',
  default: [],
});

export const searchTerm30DaysAgoState = atom<SearchTerm[]>({
  key: 'searchTerm30DaysAgoState',
  default: [],
});

export const bingSearchTerm30DaysAgoState = atom<SearchTerm[]>({
  key: 'bingSearchTerm30DaysAgoState',
  default: [],
});

export const accounts90DaysAgoState = atom<Account[]>({
  key: 'accounts90DaysAgoState',
  default: [],
});

export const accounts3DaysAgoState = atom<Account[]>({
  key: 'accounts3DaysAgoState',
  default: [],
});

export const accountsYesterdayState = atom<Account[]>({
  key: 'accountsYesterdayState',
  default: [],
});

export const accountsJanState = atom<Account[]>({
  key: 'accountsJanState',
  default: [],
});

export const searchTermJanState = atom<SearchTerm[]>({
  key: 'searchTermJanState',
  default: [],
});

export const bingSearchTermJanState = atom<SearchTerm[]>({
  key: 'bingSearchTermJanState',
  default: [],
});

export const userLocationJanState = atom<UserLocation[]>({
  key: 'userLocationJanState',
  default: [],
});

export const accountsFebState = atom<Account[]>({
  key: 'accountsFebState',
  default: [],
});

export const searchTermFebState = atom<SearchTerm[]>({
  key: 'searchTermFebState',
  default: [],
});

export const bingSearchTermFebState = atom<SearchTerm[]>({
  key: 'bingSearchTermFebState',
  default: [],
});

export const userLocationFebState = atom<UserLocation[]>({
  key: 'userLocationFebState',
  default: [],
});

export const accountsMarState = atom<Account[]>({
  key: 'accountsMarState',
  default: [],
});

export const searchTermMarState = atom<SearchTerm[]>({
  key: 'searchTermMarState',
  default: [],
});

export const bingSearchTermMarState = atom<SearchTerm[]>({
  key: 'bingSearchTermMarState',
  default: [],
});

export const userLocationMarState = atom<UserLocation[]>({
  key: 'userLocationMarState',
  default: [],
});

export const accountsAprState = atom<Account[]>({
  key: 'accountsAprState',
  default: [],
});

export const searchTermAprState = atom<SearchTerm[]>({
  key: 'searchTermAprState',
  default: [],
});

export const bingSearchTermAprState = atom<SearchTerm[]>({
  key: 'bingSearchTermAprState',
  default: [],
});

export const userLocationAprState = atom<UserLocation[]>({
  key: 'userLocationAprState',
  default: [],
});

export const accountsMayState = atom<Account[]>({
  key: 'accountsMayState',
  default: [],
});

export const searchTermMayState = atom<SearchTerm[]>({
  key: 'searchTermMayState',
  default: [],
});

export const bingSearchTermMayState = atom<SearchTerm[]>({
  key: 'bingSearchTermMayState',
  default: [],
});

export const userLocationMayState = atom<UserLocation[]>({
  key: 'userLocationMayState',
  default: [],
});

export const accountsJunState = atom<Account[]>({
  key: 'accountsJunState',
  default: [],
});

export const searchTermJunState = atom<SearchTerm[]>({
  key: 'searchTermJunState',
  default: [],
});

export const bingSearchTermJunState = atom<SearchTerm[]>({
  key: 'bingSearchTermJunState',
  default: [],
});

export const userLocationJunState = atom<UserLocation[]>({
  key: 'userLocationJunState',
  default: [],
});

export const accountsJulState = atom<Account[]>({
  key: 'accountsJulState',
  default: [],
});

export const searchTermJulState = atom<SearchTerm[]>({
  key: 'searchTermJulState',
  default: [],
});

export const bingSearchTermJulState = atom<SearchTerm[]>({
  key: 'bingSearchTermJulState',
  default: [],
});

export const userLocationJulState = atom<UserLocation[]>({
  key: 'userLocationJulState',
  default: [],
});

export const accountsAugState = atom<Account[]>({
  key: 'accountsAugState',
  default: [],
});

export const searchTermAugState = atom<SearchTerm[]>({
  key: 'searchTermAugState',
  default: [],
});

export const bingSearchTermAugState = atom<SearchTerm[]>({
  key: 'bingSearchTermAugState',
  default: [],
});

export const userLocationAugState = atom<UserLocation[]>({
  key: 'userLocationAugState',
  default: [],
});

export const accountsSeptState = atom<Account[]>({
  key: 'accountsSeptState',
  default: [],
});

export const searchTermSeptState = atom<SearchTerm[]>({
  key: 'searchTermSeptState',
  default: [],
});

export const bingSearchTermSeptState = atom<SearchTerm[]>({
  key: 'bingSearchTermSeptState',
  default: [],
});

export const userLocationSeptState = atom<UserLocation[]>({
  key: 'userLocationSeptState',
  default: [],
});

export const accountsOctState = atom<Account[]>({
  key: 'accountsOctState',
  default: [],
});

export const searchTermOctState = atom<SearchTerm[]>({
  key: 'searchTermOctState',
  default: [],
});

export const bingSearchTermOctState = atom<SearchTerm[]>({
  key: 'bingSearchTermOctState',
  default: [],
});

export const userLocationOctState = atom<UserLocation[]>({
  key: 'userLocationOctState',
  default: [],
});

export const accountsNovState = atom<Account[]>({
  key: 'accountsNovState',
  default: [],
});

export const searchTermNovState = atom<SearchTerm[]>({
  key: 'searchTermNovState',
  default: [],
});

export const bingSearchTermNovState = atom<SearchTerm[]>({
  key: 'bingSearchTermNovState',
  default: [],
});

export const userLocationNovState = atom<UserLocation[]>({
  key: 'userLocationNovState',
  default: [],
});

export const accountsDecState = atom<Account[]>({
  key: 'accountsDecState',
  default: [],
});

export const searchTermDecState = atom<SearchTerm[]>({
  key: 'searchTermDecState',
  default: [],
});

export const bingSearchTermDecState = atom<SearchTerm[]>({
  key: 'bingSearchTermDecState',
  default: [],
});

export const userLocationDecState = atom<UserLocation[]>({
  key: 'userLocationDecState',
  default: [],
});

export const accountsAllTimeState = atom<Account[]>({
  key: 'accountAllTimeState',
  default: [],
});

export const bmsAccount30DaysState = atom<Account[]>({
  key: 'bmsAccount30DaysState',
  default: [],
});

export const bmsAccountJanState = atom<Account[]>({
  key: 'bmsAccountJanState',
  default: [],
});

export const bmsAccountFebState = atom<Account[]>({
  key: 'bmsAccountFebState',
  default: [],
});

export const bmsAccountMarState = atom<Account[]>({
  key: 'bmsAccountMarState',
  default: [],
});

export const bmsAccountAprState = atom<Account[]>({
  key: 'bmsAccountAprState',
  default: [],
});

export const bmsAccountMayState = atom<Account[]>({
  key: 'bmsAccountMayState',
  default: [],
});

export const bmsAccountJunState = atom<Account[]>({
  key: 'bmsAccountJunState',
  default: [],
});

export const bmsAccountJulstate = atom<Account[]>({
  key: 'bmsAccountJulstate',
  default: [],
});

export const bmsAccountAugState = atom<Account[]>({
  key: 'bmsAccountAugState',
  default: [],
});

export const bmsAccountSeptState = atom<Account[]>({
  key: 'bmsAccountSeptState',
  default: [],
});

export const bmsAccountOctState = atom<Account[]>({
  key: 'bmsAcccountOctState',
  default: [],
});

export const bmsAccountNovState = atom<Account[]>({
  key: 'bmsAccountNovState',
  default: [],
});

export const bmsAccountDecState = atom<Account[]>({
  key: 'bmsAccountDecState',
  default: [],
});

export const bmsAccountAllTimeState = atom<Account[]>({
  key: 'bmsAccountAllTimeState',
  default: [],
});

export const selectedAccountState = atom({
  key: 'selectedAccountState',
  default: '',
});

export const idxPlatformsFilterState = atom<(string | boolean)[]>({
  key: 'idxPlatformsFilterState',
  default: [],
});

export const ppcSpecialistsFilterState = atom<(string | boolean)[]>({
  key: 'ppcSpecialistsFilterState',
  default: [],
});

export const accountManagersFilterState = atom<(string | boolean)[]>({
  key: 'accountManagersFilterState',
  default: [],
});

export const statesFilterState = atom<(string | boolean)[]>({
  key: 'statesFilterState',
  default: [],
});

export const statusFilterState = atom<(string | boolean)[]>({
  key: 'statusFilterState',
  default: [],
});

export const notesFilterState = atom<(string | boolean)[]>({
  key: 'notesFilterState',
  default: [],
});

export const adsPaidByFilterState = atom<(string | boolean)[]>({
  key: 'adsPaidByFilterState',
  default: [],
});

export const platformFilterState = atom<(string | boolean)[]>({
  key: 'platformFilterState',
  default: ['Google', 'Microsoft', 'Manually Added'],
});

export const idxFilterOptionsState = atom<CheckboxOptions>({
  key: 'idxFilterOptionsState',
  default: {
    Brivity: false,
    Chime: false,
    EAP: false,
    'EAP-RETS': false,
    KVCore: false,
    RG: false,
    SI: false,
    StellarMLS: false,
    Ylopo: false,
  },
});

export const ppcFilterOptionState = atom<CheckboxOptions>({
  key: 'ppcFilterOptionState',
  default: {
    Gwen: false,
    'Andrew Slawson': false,
    'Jose Ramirez': false,
    'Joshua Simpson': false,
    'Joyce Raypan': false,
    Mich: false,
    'Red Quijano': false,
    'Minah Co': false,
  },
});

export const accManagerOptionState = atom<CheckboxOptions>({
  key: 'accManagerOptionState',
  default: {
    'Allyson Hunt': false,
    'Mark Lorentz': false,
    'Philip Pasma': false,
    'Valeria Angel': false,
    'Vanessa Iles': false,
  },
});

export const statesOptionState = atom<CheckboxOptions>({
  key: 'statesOptionState',
  default: {
    'AK, US': false,
    'AL, US': false,
    'AR, US': false,
    'AS, US': false,
    'AZ, US': false,
    'CA, US': false,
    'CO, US': false,
    'CT, US': false,
    'DE, US': false,
    'FL, US': false,
    'GA, US': false,
    'GU, US': false,
    'HI, US': false,
    'IA, US': false,
    'ID, US': false,
    'IL, US': false,
    'IN, US': false,
    'KS, US': false,
    'KY, US': false,
    'LA, US': false,
    'MA, US': false,
    'MD, US': false,
    'ME, US': false,
    'MH, US': false,
    'MI, US': false,
    'MN, US': false,
    'MO, US': false,
    'MP, US': false,
    'MS, US': false,
    'MT, US': false,
    MX: false,
    'NC, US': false,
    'ND, US': false,
    'NE, US': false,
    'NH, US': false,
    'NJ, US': false,
    'NM, US': false,
    'NV, US': false,
    'NY, US': false,
    'OH, US': false,
    'OK, US': false,
    'OR, US': false,
    'PA, US': false,
    'PR, US': false,
    'RI, US': false,
    'SC, US': false,
    'SD, US': false,
    'TN, US': false,
    'TX, US': false,
    'UT, US': false,
    'VA, US': false,
    'VI, US': false,
    'VT, US': false,
    'WA, US': false,
    'WI, US': false,
    'WV, US': false,
    'WY, US': false,
    'NL, CA': false,
    'PE, CA': false,
    'NS, CA': false,
    'NB, CA': false,
    'QC, CA': false,
    'ON, CA': false,
    'MB, CA': false,
    'SK, CA': false,
    'AB, CA': false,
    'BC, CA': false,
    'YT, CA': false,
    'NT, CA': false,
    'NU, CA': false,
  },
});

export const statusOptionState = atom<CheckboxOptions>({
  key: 'statusOptionState',
  default: {
    Paused: false,
    Active: false,
  },
});

export const notesOptionState = atom<CheckboxOptions>({
  key: 'notesOptionState',
  default: {
    'With Note': false,
    'Without Note': false,
  },
});

export const adsPaidByOptionState = atom<CheckboxOptions>({
  key: 'adsPaidByOptionState',
  default: {
    Asterisk: false,
    Client: false,
  },
});

export const platformOptionState = atom<CheckboxOptions>({
  key: 'platformOptionState',
  default: {
    Google: true,
    Microsoft: true,
    'Manually Added': true,
  },
});

export const userLocation30DaysAgoState = atom<UserLocation[]>({
  key: 'userLocation30DaysAgoState',
  default: [],
});

export const dataFormDataState = atom<{
  id: number | string;
  name: string;
  spend: string;
  conversions: string;
}>({
  key: 'dataFormDataState',
  default: {
    id: '',
    name: '',
    spend: '',
    conversions: '',
  },
});

export const bingUserLocation30DaysAgoState = atom<UserLocation[]>({
  key: 'bingUserLocation30DaysAgoState',
  default: [],
});

export const bingUserLocationJanState = atom<UserLocation[]>({
  key: 'bingUserLocationJanState',
  default: [],
});

export const bingUserLocationFebState = atom<UserLocation[]>({
  key: 'bingUserLocationFebState',
  default: [],
});

export const bingUserLocationMarState = atom<UserLocation[]>({
  key: 'bingUserLocationMarState',
  default: [],
});

export const bingUserLocationAprState = atom<UserLocation[]>({
  key: 'bingUserLocationAprState',
  default: [],
});

export const bingUserLocationMayState = atom<UserLocation[]>({
  key: 'bingUserLocationMayState',
  default: [],
});

export const bingUserLocationJunState = atom<UserLocation[]>({
  key: 'bingUserLocationJunState',
  default: [],
});

export const bingUserLocationJulState = atom<UserLocation[]>({
  key: 'bingUserLocationJulState',
  default: [],
});

export const bingUserLocationAugState = atom<UserLocation[]>({
  key: 'bingUserLocationAugState',
  default: [],
});

export const bingUserLocationSeptState = atom<UserLocation[]>({
  key: 'bingUserLocationSeptState',
  default: [],
});

export const bingUserLocationOctState = atom<UserLocation[]>({
  key: 'bingUserLocationOctState',
  default: [],
});

export const bingUserLocationNovState = atom<UserLocation[]>({
  key: 'bingUserLocationNovState',
  default: [],
});

export const bingUserLocationDecState = atom<UserLocation[]>({
  key: 'bingUserLocationDecState',
  default: [],
});

export const ppcFilterIDState = atom({
  key: 'ppcFilterIDState',
  default: '',
});
