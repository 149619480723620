import { lightModeState } from '../../recoil/atoms';
import { useRecoilValue } from 'recoil';

type Props = {
  Icon: JSX.Element;
  title: string;
  description: string;
};

const GlossaryinfoTemplate = ({ Icon, title, description }: Props) => {
  const lightMode = useRecoilValue(lightModeState);

  return (
    <div
      className={`flex flex-col items-center p-2 gap-y-5 ${
        lightMode ? 'text-gray-900' : 'text-white'
      }`}
    >
      <div className={`text-6xl ${lightMode ? 'text-red-400' : ''}`}>
        {Icon}
      </div>
      <div className='w-full text-center'>
        <h1 className='font-semibold text-lg'>{title}</h1>
      </div>
      <div className='text-left'>
        <p>{description}</p>
      </div>
    </div>
  );
};

export default GlossaryinfoTemplate;
