import React, { useEffect } from 'react';
import { lightModeState, pageState } from '../recoil/atoms';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import Layout from '../components/layout/Layout';
import PasswordComponent from '../components/forms/non-modal/PasswordComponent';
import Title from '../components/universal-decorations/Title';

const Password = () => {
  const lightMode = useRecoilValue(lightModeState);
  const setPage = useSetRecoilState(pageState);
  useEffect(() => {
    setPage('password');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Layout>
      <div>
        <Title title='Change Your Password' />

        <div className='w-full h-full md:grid  md:grid-cols-3'>
          <div className={lightMode ? 'bg-gray-100' : 'bg-gray-900'}></div>
          <PasswordComponent />
          <div className={lightMode ? 'bg-gray-100' : 'bg-gray-900'}></div>
        </div>
      </div>
    </Layout>
  );
};

export default Password;
