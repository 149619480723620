import React from 'react';
import { useRecoilState } from 'recoil';
import { deviceChartFilterState } from '../../recoil/atoms';
import ReportsCompFilterDropdown from '../filter-templates/ReportsCompFilterDropdown';

type Props = {
  showFilter: boolean;
  setShowFilter: React.Dispatch<React.SetStateAction<boolean>>;
};

const DeviceChartsDropdown = ({ showFilter, setShowFilter }: Props) => {
  const [deviceChartFilter, setDeviceChartFilter] = useRecoilState(
    deviceChartFilterState
  );
  const handleSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    if (value === 'Google' || value === 'Microsoft')
      setDeviceChartFilter(value);
    setShowFilter(false);
  };
  return (
    <ReportsCompFilterDropdown
      showFilter={showFilter}
      filter={deviceChartFilter}
      handleSelect={handleSelect}
      name='device-platforms'
    />
  );
};

export default DeviceChartsDropdown;
