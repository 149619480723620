import React from 'react';
import { pageState, lightModeState } from '../../recoil/atoms';
import { useRecoilValue } from 'recoil';

type Props = {
  children: React.ReactNode;
  addPadding?: boolean;
  handleSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
  btnLabel: string;
  disableButton?: boolean;
  btn2Label?: string | JSX.Element;
  handleBtn2Click?: () => Promise<void>;
  disableButton2?: boolean;
};

const FormLayoutBase = ({
  children,
  addPadding,
  handleSubmit,
  btnLabel,
  disableButton,
  btn2Label,
  handleBtn2Click,
  disableButton2,
}: Props) => {
  const page = useRecoilValue(pageState);
  const lightMode = useRecoilValue(lightModeState);
  return (
    <form className={addPadding ? 'p-3' : ''} onSubmit={handleSubmit}>
      {children}
      <div className='flex gap-x-2'>
        <input
          className={`px-2 py-1 mt-2 font-semibold ${
            disableButton
              ? page === 'data' && !lightMode
                ? 'bg-gray-700 text-gray-400'
                : 'bg-gray-400 text-white'
              : page === 'data' && !lightMode
              ? 'bg-red-700 hover:bg-red-600 text-white'
              : 'bg-indigo-500 hover:bg-indigo-600 text-white'
          } rounded ${page !== 'data' && 'shadow'}`}
          type='submit'
          value={btnLabel}
          disabled={disableButton}
        />
        {btn2Label && (
          <button
            className={`px-2 py-1 mt-2 font-semibold ${
              disableButton2
                ? page === 'data' && !lightMode
                  ? 'bg-gray-700 text-gray-400'
                  : 'bg-gray-400 text-white'
                : page === 'data' && !lightMode
                ? 'bg-yellow-600 hover:bg-yellow-500 text-white'
                : 'bg-yellow-500 hover:bg-yellow-600 text-white'
            } rounded ${page !== 'data' && 'shadow'}`}
            type='button'
            disabled={disableButton2}
            onClick={handleBtn2Click}
          >
            {btn2Label}
          </button>
        )}
      </div>
    </form>
  );
};

export default FormLayoutBase;
