import React from 'react';
import { useRecoilValue } from 'recoil';
import { Commaed } from '../../helpers/utils';
import { lightModeState } from '../../recoil/atoms';

type Props = {
  google: string;
  microsoft: string;
  manual: string;
};

const EachDataPlatform = ({ google, microsoft, manual }: Props) => {
  const lightMode = useRecoilValue(lightModeState);
  return (
    <div
      className={`grid grid-cols-3 min-w-full  ${
        lightMode ? 'bg-gray-300' : 'bg-gray-800'
      } justify-items-center w-64`}
    >
      <div className={`text-center p-3`}>
        <p
          className='font-semibold text-sxs
        mb-2'
        >
          {Commaed(parseFloat(google).toFixed(2))}
        </p>
        <span className='text-2xs'>Google</span>
      </div>
      <div
        className={`text-center p-3 min-w-full  ${
          lightMode ? 'bg-gray-200' : 'bg-gray-700'
        }`}
      >
        <p className='font-semibold text-sxs mb-2'>
          {Commaed(parseFloat(microsoft).toFixed(2))}
        </p>
        <span className='text-2xs'>Microsoft</span>
      </div>
      <div className={`text-center p-3 min-w-full`}>
        <p className='font-semibold text-sxs mb-2'>
          {Commaed(parseFloat(manual).toFixed(2))}
        </p>
        <span className='text-2xs'>Manual</span>
      </div>
    </div>
  );
};

export default EachDataPlatform;
