import React, { useState } from 'react';
import { useRecoilValue } from 'recoil';
import {
  accountState,
  accounts30DaysAgoState,
  bingSearchTerm30DaysAgoState,
  searchTerm30DaysAgoState,
  searchTermAprState,
  searchTermAugState,
  searchTermDecState,
  searchTermFebState,
  searchTermJanState,
  searchTermJulState,
  searchTermJunState,
  searchTermMarState,
  searchTermMayState,
  searchTermNovState,
  searchTermOctState,
  searchTermSeptState,
  bingSearchTermAprState,
  bingSearchTermAugState,
  bingSearchTermDecState,
  bingSearchTermFebState,
  bingSearchTermJanState,
  bingSearchTermJulState,
  bingSearchTermJunState,
  bingSearchTermMarState,
  bingSearchTermMayState,
  bingSearchTermNovState,
  bingSearchTermOctState,
  bingSearchTermSeptState,
  lightModeState,
  isLoadingSearchTermState,
  monNameState,
  userState,
  searchTermFilterState,
} from '../../recoil/atoms';
import SearchTermTable from '../tables/SearchTermTable';
import TablePagination from '../table-comps/TablePagination';
import LoadAnimation from '../universal-decorations/LoadAnimation';
import { month } from '../../helpers/dateUtils';
import ReportsCompFilter from '../filter-comps/ReportsCompFilter';
import SearchTermDropdown from '../filters/SearchTermDropdown';

const SearchTerm = () => {
  const searchTermMonths = [
    searchTermJanState,
    searchTermFebState,
    searchTermMarState,
    searchTermAprState,
    searchTermMayState,
    searchTermJunState,
    searchTermJulState,
    searchTermAugState,
    searchTermSeptState,
    searchTermOctState,
    searchTermNovState,
    searchTermDecState,
  ];
  const bingSearchTermMonths = [
    bingSearchTermJanState,
    bingSearchTermFebState,
    bingSearchTermMarState,
    bingSearchTermAprState,
    bingSearchTermMayState,
    bingSearchTermJunState,
    bingSearchTermJulState,
    bingSearchTermAugState,
    bingSearchTermSeptState,
    bingSearchTermOctState,
    bingSearchTermNovState,
    bingSearchTermDecState,
  ];
  const user = useRecoilValue(userState);
  const monName = useRecoilValue(monNameState);
  const searchTermFilter = useRecoilValue(searchTermFilterState);
  const adminAccount = useRecoilValue(accounts30DaysAgoState)[0];
  const superadminAccount = useRecoilValue(accountState);
  const account =
    user?.role === 'superadmin' ? superadminAccount : adminAccount;
  const searchTerm = useRecoilValue(
    searchTermFilter === 'Google'
      ? monName === '30-Day'
        ? searchTerm30DaysAgoState
        : searchTermMonths[month.indexOf(monName)]
      : monName === '30-Day'
      ? bingSearchTerm30DaysAgoState
      : bingSearchTermMonths[month.indexOf(monName)]
  ).filter((item) =>
    searchTermFilter === 'Google'
      ? item.account === account.google_cid
      : item.account === account.microsoft_cid
  );

  const [isSortName, setIsSortName] = useState(1);
  const [isSortImpressions, setIsSortImpressions] = useState(1);
  const [isSortClicks, setIsSortClicks] = useState(1);
  const [isSortCTR, setIsSortCTR] = useState(1);
  const [isSortCPC, setIsSortCPC] = useState(1);
  const [isSortCost, setIsSortCost] = useState(1);
  const [isSortConversions, setIsSortConversions] = useState(1);
  const [isSortCPL, setIsSortCPL] = useState(1);
  const isLoading = useRecoilValue(isLoadingSearchTermState);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);

  const [showFilter, setShowFilter] = useState(false);

  const setters = [
    setIsSortName,
    setIsSortImpressions,
    setIsSortClicks,
    setIsSortCTR,
    setIsSortCPC,
    setIsSortCost,
    setIsSortConversions,
    setIsSortCPL,
  ];

  const isValues = [
    isSortName,
    isSortImpressions,
    isSortClicks,
    isSortCTR,
    isSortCPC,
    isSortCost,
    isSortConversions,
    isSortCPL,
  ];

  isSortName === 2
    ? searchTerm.sort(({ name: a }, { name: b }) => {
        if (a > b) return -1;
        if (a < b) return 1;
        return 0;
      })
    : isSortName === 3 &&
      searchTerm.sort(({ name: a }, { name: b }) => {
        if (a > b) return 1;
        if (a < b) return -1;
        return 0;
      });

  const isSorter = (isSort: number) => {
    isSort === 2
      ? searchTerm.sort((a, b) => {
          const aSort =
            isSort === isSortImpressions
              ? a.impressions
              : isSort === isSortClicks
              ? a.clicks
              : isSort === isSortCTR
              ? a.ctr
              : isSort === isSortCPC
              ? a.avg_cpc
              : isSort === isSortCost
              ? a.spend
              : isSort === isSortConversions
              ? a.conversions
              : a.cost_per_conversion;
          const bSort =
            isSort === isSortImpressions
              ? b.impressions
              : isSort === isSortClicks
              ? b.clicks
              : isSort === isSortCTR
              ? b.ctr
              : isSort === isSortCPC
              ? b.avg_cpc
              : isSort === isSortCost
              ? b.spend
              : isSort === isSortConversions
              ? b.conversions
              : b.cost_per_conversion;
          return bSort - aSort;
        })
      : isSort === 3 &&
        searchTerm.sort((a, b) => {
          const aSort =
            isSort === isSortImpressions
              ? a.impressions
              : isSort === isSortClicks
              ? a.clicks
              : isSort === isSortCTR
              ? a.ctr
              : isSort === isSortCPC
              ? a.avg_cpc
              : isSort === isSortCost
              ? a.spend
              : isSort === isSortConversions
              ? a.conversions
              : a.cost_per_conversion;
          const bSort =
            isSort === isSortImpressions
              ? b.impressions
              : isSort === isSortClicks
              ? b.clicks
              : isSort === isSortCTR
              ? b.ctr
              : isSort === isSortCPC
              ? b.avg_cpc
              : isSort === isSortCost
              ? b.spend
              : isSort === isSortConversions
              ? b.conversions
              : b.cost_per_conversion;
          return aSort - bSort;
        });
  };

  isValues.slice(1).forEach((isSort) => isSorter(isSort));

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleClickFilter = () => {
    setShowFilter(!showFilter);
  };

  interface options {
    isName?: boolean;
    isCPC?: boolean;
    isCPL?: boolean;
    isCTR?: boolean;
    isClicks?: boolean;
    isConversions?: boolean;
    isImpressions?: boolean;
    isCost?: boolean;
  }

  const notSortList = (options: options) => {
    const list = [];
    if (!options.isName) list.push(setIsSortName);
    if (!options.isCPC) list.push(setIsSortCPC);
    if (!options.isCPL) list.push(setIsSortCPL);
    if (!options.isCTR) list.push(setIsSortCTR);
    if (!options.isClicks) list.push(setIsSortClicks);
    if (!options.isConversions) list.push(setIsSortConversions);
    if (!options.isImpressions) list.push(setIsSortImpressions);
    if (!options.isCost) list.push(setIsSortCost);
    return list;
  };

  const handleNotSort = ([
    setNotSort1,
    setNotSort2,
    setNotSort3,
    setNotSort4,
    setNotSort5,
    setNotSort6,
    setNotSort7,
  ]: React.Dispatch<React.SetStateAction<number>>[]) => {
    setNotSort1(1);
    setNotSort2(1);
    setNotSort3(1);
    setNotSort4(1);
    setNotSort5(1);
    setNotSort6(1);
    setNotSort7(1);
  };

  const handleSort = (
    setMainSort: React.Dispatch<React.SetStateAction<number>>,
    mainSort: number
  ) => {
    setMainSort(mainSort < 3 ? mainSort + 1 : 1);
    setPage(0);
  };

  const lightMode = useRecoilValue(lightModeState);

  return (
    <div className={searchTerm.length === 0 ? 'mb-3' : ''}>
      <div
        className={`grid grid-cols-2 sm:flex  sm:justify-between items-center ${
          lightMode ? 'text-gray-900' : 'text-white'
        } text-xs sm:text-base w-full text-left`}
      >
        <div className='hidden sm:inline'>
          <TablePagination
            items={searchTerm}
            rowsPerPage={rowsPerPage}
            page={page}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </div>
        <div className='w-11/12 sm:w-1/4 '>
          <ReportsCompFilter
            onClick={handleClickFilter}
            showFilter={showFilter}
            title='KW Platforms'
          />
          <SearchTermDropdown
            showFilter={showFilter}
            setShowFilter={setShowFilter}
          />
        </div>
      </div>
      <div className='w-fit sm:hidden'>
        <TablePagination
          items={searchTerm}
          rowsPerPage={rowsPerPage}
          page={page}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </div>
      <div className='animate__animated animate__backInRight'>
        {isLoading ? (
          <div className='py-32'>
            <LoadAnimation />
          </div>
        ) : (
          <div className='overflow-x-scroll'>
            <SearchTermTable
              handleSort={handleSort}
              handleNotSort={handleNotSort}
              setters={setters}
              isSortArr={isValues}
              notSortList={notSortList}
              items={searchTerm.slice(
                page * rowsPerPage,
                page * rowsPerPage + rowsPerPage
              )}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default SearchTerm;
