import React from 'react';
import { useRecoilValue } from 'recoil';
import {
  pageState,
  lightModeState,
  userState,
  isAccountModalState,
  isAddAccountOpenState,
  isUpdateFormOpenState,
  isDataFormOpenState,
} from '../../recoil/atoms';
import { Link } from 'react-router-dom';
import { FaTable, FaChartPie } from 'react-icons/fa';
import { AiFillHome } from 'react-icons/ai';
import { BiBookAlt } from 'react-icons/bi';
type Props = {
  mobile?: boolean;
  handleClick?: () => void;
};

const NavOptions = ({ mobile, handleClick }: Props) => {
  const page = useRecoilValue(pageState);
  const lightMode = useRecoilValue(lightModeState);
  const user = useRecoilValue(userState);
  const isAccountModalOpen = useRecoilValue(isAccountModalState);
  const isAddAccountOpen = useRecoilValue(isAddAccountOpenState);
  const isUpdateFormOpen = useRecoilValue(isUpdateFormOpenState);
  const isDataFormOpen = useRecoilValue(isDataFormOpenState);

  const isAnyModalOpen =
    isAccountModalOpen ||
    isAddAccountOpen ||
    isUpdateFormOpen ||
    isDataFormOpen;

  return (
    <div
      className={`md:ml-auto md:mr-auto flex flex-col gap-y-3 flex-wrap items-start  justify-center ${
        mobile
          ? 'flex-col text-4xl mt-10 tracking-wider space-y-6 justify-center'
          : 'hidden sm:inline text-base flex-col'
      }`}
    >
      <Link
        className={`font-semibold mr-5 hover:no-underline flex items-center gap-x-4 rounded-lg ${
          page === 'home'
            ? lightMode
              ? 'text-red-400'
              : 'text-red-600'
            : lightMode
            ? 'text-black'
            : 'text-white'
        }`}
        to={isAnyModalOpen ? '#' : '/'}
        onClick={handleClick}
      >
        <AiFillHome
          className={`text-5xl ${
            page === 'home' ? '' : lightMode ? 'text-red-400' : 'text-red-600'
          }`}
        />{' '}
        Home
      </Link>

      {user?.role === 'superadmin' && (
        <Link
          className={`font-semibold mr-5 hover:no-underline flex items-center gap-x-4 rounded-lg ${
            page === 'data'
              ? lightMode
                ? 'text-red-400'
                : 'text-red-600'
              : lightMode
              ? 'text-black'
              : 'text-white'
          }`}
          to={isAnyModalOpen ? '#' : '/bms'}
          onClick={handleClick}
        >
          <FaTable
            className={`text-5xl ${
              page === 'data' ? '' : lightMode ? 'text-red-400' : 'text-red-600'
            }`}
          />{' '}
          BMS
        </Link>
      )}
      <Link
        className={`font-semibold mr-5 hover:no-underline flex items-center gap-x-4 rounded-lg ${
          page === 'reports'
            ? lightMode
              ? 'text-red-400'
              : 'text-red-600'
            : lightMode
            ? 'text-black'
            : 'text-white'
        }`}
        to={isAnyModalOpen ? '#' : '/reports'}
        onClick={handleClick}
      >
        <FaChartPie
          className={`text-5xl ${
            page === 'reports'
              ? ''
              : lightMode
              ? 'text-red-400'
              : 'text-red-600'
          }`}
        />{' '}
        Reports
      </Link>
      <Link
        className={`font-semibold mr-5 hover:no-underline flex items-center gap-x-4 rounded-lg ${
          page === 'glossary'
            ? lightMode
              ? 'text-red-400'
              : 'text-red-600'
            : lightMode
            ? 'text-black'
            : 'text-white'
        }`}
        to={isAnyModalOpen ? '#' : '/glossary'}
        onClick={handleClick}
      >
        <BiBookAlt
          className={`text-5xl ${
            page === 'glossary'
              ? ''
              : lightMode
              ? 'text-red-400'
              : 'text-red-600'
          }`}
        />{' '}
        Glossary
      </Link>
    </div>
  );
};
export default NavOptions;
