import React from 'react';
import LineGraph from '../graph-templates/LineGraph';
import {
  lightModeState,
  userState,
  accountState,
  accountsState,
  accounts2MonthsAgoState,
  accounts3MonthsAgoState,
  accounts30DaysAgoState,
  googleReportsSelectedState,
  microsoftReportsSelectedState,
  lsaReportsSelectedState,
  adDataSelectedState,
} from '../../recoil/atoms';
import { adminAccountState } from '../../recoil/selectors/dataSelectors';
import { useRecoilValue } from 'recoil';
import { Account } from '../../helpers/types';

type Props = {
  isPrint?: boolean;
};

const BudgetLineGraph = ({ isPrint }: Props) => {
  const lightMode = useRecoilValue(lightModeState);
  const googleSelected = useRecoilValue(googleReportsSelectedState);
  const microsoftSelected = useRecoilValue(microsoftReportsSelectedState);
  const lsaSelected = useRecoilValue(lsaReportsSelectedState);
  const adSelected = useRecoilValue(adDataSelectedState);
  const user = useRecoilValue(userState);
  const adminAccount = useRecoilValue(adminAccountState);
  const superadminAccount = useRecoilValue(accountState);
  const account =
    user?.role === 'superadmin' ? superadminAccount : adminAccount;

  const filterAccount = (accs: Account[]) => {
    return accs.filter((acc) => acc.name === account.name);
  };

  const accounts = useRecoilValue(accountsState);
  const accounts2MonthsAgo = useRecoilValue(accounts2MonthsAgoState);
  const accounts3MonthsAgo = useRecoilValue(accounts3MonthsAgoState);
  const accounts30DaysAgo = useRecoilValue(accounts30DaysAgoState);

  const accountLastMonth = filterAccount(accounts)[0];
  const account2MonthsAgo = filterAccount(accounts2MonthsAgo)[0];
  const account3MonthsAgo = filterAccount(accounts3MonthsAgo)[0];
  const account30DaysAgo = filterAccount(accounts30DaysAgo)[0];

  const budget3MonthsAgo = account3MonthsAgo
    ? (account3MonthsAgo.google_account && googleSelected
        ? parseFloat(account3MonthsAgo.google_account.spend)
        : 0) +
      (account3MonthsAgo.microsoft_account && microsoftSelected
        ? parseFloat(account3MonthsAgo.microsoft_account.spend)
        : 0) +
      (account3MonthsAgo.lsa_account && lsaSelected
        ? parseFloat(account3MonthsAgo.lsa_account.spend)
        : 0) +
      (adSelected && account3MonthsAgo.additional_data?.spend
        ? account3MonthsAgo.additional_data?.spend_operation === '+'
          ? parseFloat(account3MonthsAgo.additional_data?.spend)
          : -parseFloat(account3MonthsAgo.additional_data?.spend)
        : 0)
    : 0;

  const budget2MonthsAgo = account2MonthsAgo
    ? (account2MonthsAgo.google_account && googleSelected
        ? parseFloat(account2MonthsAgo.google_account.spend)
        : 0) +
      (account2MonthsAgo.microsoft_account && microsoftSelected
        ? parseFloat(account2MonthsAgo.microsoft_account.spend)
        : 0) +
      (account2MonthsAgo.lsa_account && lsaSelected
        ? parseFloat(account2MonthsAgo.lsa_account.spend)
        : 0) +
      (adSelected && account2MonthsAgo.additional_data?.spend
        ? account2MonthsAgo.additional_data?.spend_operation === '+'
          ? parseFloat(account2MonthsAgo.additional_data?.spend)
          : -parseFloat(account2MonthsAgo.additional_data?.spend)
        : 0)
    : 0;

  const budgetLastMonth = accountLastMonth
    ? (accountLastMonth.google_account && googleSelected
        ? parseFloat(accountLastMonth.google_account.spend)
        : 0) +
      (accountLastMonth.microsoft_account && microsoftSelected
        ? parseFloat(accountLastMonth.microsoft_account.spend)
        : 0) +
      (accountLastMonth.lsa_account && lsaSelected
        ? parseFloat(accountLastMonth.lsa_account.spend)
        : 0) +
      (adSelected && accountLastMonth.additional_data?.spend
        ? accountLastMonth.additional_data?.spend_operation === '+'
          ? parseFloat(accountLastMonth.additional_data?.spend)
          : -parseFloat(accountLastMonth.additional_data?.spend)
        : 0)
    : 0;

  const budget30DaysAgo = account30DaysAgo
    ? (account30DaysAgo.google_account && googleSelected
        ? parseFloat(account30DaysAgo.google_account.spend)
        : 0) +
      (account30DaysAgo.microsoft_account && microsoftSelected
        ? parseFloat(account30DaysAgo.microsoft_account.spend)
        : 0) +
      (account30DaysAgo.lsa_account && lsaSelected
        ? parseFloat(account30DaysAgo.lsa_account.spend)
        : 0)
    : 0;

  return (
    <LineGraph
      title='Budget Per Coverage'
      data1={budget3MonthsAgo}
      data2={budget2MonthsAgo}
      data3={budgetLastMonth}
      data4={budget30DaysAgo}
      backgroundColor={
        lightMode ? 'rgba(184, 185, 210, .3)' : 'rgba(21, 29, 59, .3)'
      }
      borderColor='rgb(35, 26, 136)'
      pointBorderColor='rgb(35, 26, 136)'
      isPrint={isPrint}
    />
  );
};

export default BudgetLineGraph;
