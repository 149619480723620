import { useRef, useState } from 'react';
import { useReactToPrint } from 'react-to-print';
import { useRecoilValue } from 'recoil';
import {
  deviceChartsPrintState,
  lightModeState,
  remarketingPrintState,
} from '../../recoil/atoms';
import Paper from '../print-comps/Paper';
import PrintButton from '../print-comps/PrintButton';
import PrintComponentCB from '../print-comps/PrintComponentCB';
import {
  MdOutlineArrowBackIos,
  MdOutlineArrowForwardIos,
} from 'react-icons/md';

const Print = () => {
  const ref = useRef<HTMLDivElement | null>(null);
  const [page, setPage] = useState(1);
  const remarketing = useRecoilValue(remarketingPrintState);
  const deviceCharts = useRecoilValue(deviceChartsPrintState);
  const onPrint = useReactToPrint({
    content: () => ref.current,
  });
  const lightMode = useRecoilValue(lightModeState);

  return (
    <div className='hidden sm:inline'>
      <div className='flex items-start justify-between'>
        <PrintButton onPrint={onPrint} />
        <PrintComponentCB setPage={setPage} />
      </div>
      <div
        className={`mt-5 ml-28 ${
          lightMode ? 'text-gray-900' : 'text-white'
        } text-lg font-semibold flex items-center gap-x-5 mb-10`}
      >
        Page: {page} -{' '}
        {remarketing['Remarketing'] ||
        deviceCharts['Leads Per Device (Google)'] ||
        deviceCharts['Budget Per Device (Google)'] ||
        deviceCharts['Leads Per Device (Microsoft)'] ||
        deviceCharts['Budget Per Device (Microsoft)']
          ? '2'
          : '1'}
        <div className='flex gap-x-3 text-base h-full'>
          <div
            className={`${
              (remarketing['Remarketing'] ||
                deviceCharts['Leads Per Device (Google)'] ||
                deviceCharts['Budget Per Device (Google)'] ||
                deviceCharts['Leads Per Device (Microsoft)'] ||
                deviceCharts['Budget Per Device (Microsoft)']) &&
              page === 2
                ? `transition duration-300 rounded-full hover:bg-opacity-25 ${
                    lightMode ? 'hover:bg-gray-500' : 'hover:bg-gray-300'
                  }`
                : lightMode
                ? 'text-gray-300'
                : 'text-gray-800'
            } cursor-pointer p-1.5`}
            onClick={() => setPage(1)}
          >
            <MdOutlineArrowBackIos />
          </div>
          <div
            className={`${
              (remarketing['Remarketing'] ||
                deviceCharts['Leads Per Device (Google)'] ||
                deviceCharts['Budget Per Device (Google)'] ||
                deviceCharts['Leads Per Device (Microsoft)'] ||
                deviceCharts['Budget Per Device (Microsoft)']) &&
              page === 1
                ? `transition duration-300 rounded-full hover:bg-opacity-25 ${
                    lightMode ? 'hover:bg-gray-500' : 'hover:bg-gray-300'
                  }`
                : lightMode
                ? 'text-gray-300'
                : 'text-gray-800'
            } cursor-pointer p-1.5`}
            onClick={() => {
              if (
                remarketing['Remarketing'] ||
                deviceCharts['Leads Per Device (Google)'] ||
                deviceCharts['Budget Per Device (Google)'] ||
                deviceCharts['Leads Per Device (Microsoft)'] ||
                deviceCharts['Budget Per Device (Microsoft)']
              ) {
                setPage(2);
              }
            }}
          >
            <MdOutlineArrowForwardIos />
          </div>
        </div>
      </div>
      <div className='w-5/6 mx-auto bg-gray-100 min-h-screen '>
        <div className='py-20 px-20'>
          <Paper page={page} isShow />
        </div>
      </div>
      <div
        className='w-5/6 mx-auto bg-gray-100 max-h-0 transform scale-0'
        style={{
          maxHeight: 0,
        }}
      >
        <div ref={ref} className='py-16 px-20'>
          <Paper />
        </div>
      </div>
    </div>
  );
};

export default Print;
