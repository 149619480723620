import LineGraph from '../graph-templates/LineGraph';
import {
  lightModeState,
  accountState,
  accountsState,
  accounts2MonthsAgoState,
  accounts3MonthsAgoState,
  accounts30DaysAgoState,
  userState,
  googleReportsSelectedState,
  microsoftReportsSelectedState,
} from '../../recoil/atoms';
import { adminAccountState } from '../../recoil/selectors/dataSelectors';
import { useRecoilValue } from 'recoil';
import { Account } from '../../helpers/types';

const BudgetTabletLineGraph = () => {
  const lightMode = useRecoilValue(lightModeState);
  const user = useRecoilValue(userState);
  const account = useRecoilValue(
    user?.role === 'superadmin' ? accountState : adminAccountState
  );
  const accountsLastMonth = useRecoilValue(accountsState);
  const accounts2MonthsAgo = useRecoilValue(accounts2MonthsAgoState);
  const accounts3MonthsAgo = useRecoilValue(accounts3MonthsAgoState);
  const accounts30DaysAgo = useRecoilValue(accounts30DaysAgoState);
  const googleSelected = useRecoilValue(googleReportsSelectedState);
  const microsoftSelect = useRecoilValue(microsoftReportsSelectedState);

  const filterAccount = (accs: Account[]) =>
    accs.filter((acc) => acc.name === account.name);

  const accountLastMonth = filterAccount(accountsLastMonth)[0];
  const account2MonthsAgo = filterAccount(accounts2MonthsAgo)[0];
  const account3MonthsAgo = filterAccount(accounts3MonthsAgo)[0];
  const account30DaysAgo = filterAccount(accounts30DaysAgo)[0];

  const bingSpendLastMonth = accountLastMonth
    ? (accountLastMonth.microsoft_account && microsoftSelect
        ? parseFloat(accountLastMonth.microsoft_account.spend)
        : 0) -
      ((accountLastMonth.bing_mobile && microsoftSelect
        ? parseFloat(accountLastMonth.bing_mobile.spend)
        : 0) +
        (accountLastMonth.bing_desktop && microsoftSelect
          ? parseFloat(accountLastMonth.bing_desktop.spend)
          : 0))
    : 0;
  const bingSpend2MonthsAgo = account2MonthsAgo
    ? (account2MonthsAgo.microsoft_account && microsoftSelect
        ? parseFloat(account2MonthsAgo.microsoft_account.spend)
        : 0) -
      ((account2MonthsAgo.bing_mobile && microsoftSelect
        ? parseFloat(account2MonthsAgo.bing_mobile.spend)
        : 0) +
        (account2MonthsAgo.bing_desktop && microsoftSelect
          ? parseFloat(account2MonthsAgo.bing_desktop.spend)
          : 0))
    : 0;
  const bingSpend3MonthsAgo = account3MonthsAgo
    ? (account3MonthsAgo.microsoft_account && microsoftSelect
        ? parseFloat(account3MonthsAgo.microsoft_account.spend)
        : 0) -
      ((account3MonthsAgo.bing_mobile && microsoftSelect
        ? parseFloat(account3MonthsAgo.bing_mobile.spend)
        : 0) +
        (account3MonthsAgo.bing_desktop && microsoftSelect
          ? parseFloat(account3MonthsAgo.bing_desktop.spend)
          : 0))
    : 0;

  const bingSpend30DaysAgo = account30DaysAgo
    ? (account30DaysAgo.microsoft_account && microsoftSelect
        ? parseFloat(account30DaysAgo.microsoft_account.spend)
        : 0) -
      ((account30DaysAgo.bing_mobile && microsoftSelect
        ? parseFloat(account30DaysAgo.bing_mobile.spend)
        : 0) +
        (account30DaysAgo.bing_desktop && microsoftSelect
          ? parseFloat(account30DaysAgo.bing_desktop.spend)
          : 0))
    : 0;

  const spendLastMonth = accountLastMonth
    ? (accountLastMonth.tablet && googleSelected
        ? parseFloat(accountLastMonth.tablet.spend)
        : 0) +
      (microsoftSelect && bingSpendLastMonth > 0 ? bingSpendLastMonth : 0)
    : 0;
  const spend2MonthsAgo = account2MonthsAgo
    ? (account2MonthsAgo.tablet && googleSelected
        ? parseFloat(account2MonthsAgo.tablet.spend)
        : 0) +
      (microsoftSelect && bingSpend2MonthsAgo > 0 ? bingSpend2MonthsAgo : 0)
    : 0;
  const spend3MonthsAgo = account3MonthsAgo
    ? (account3MonthsAgo.tablet && googleSelected
        ? parseFloat(account3MonthsAgo.tablet.spend)
        : 0) +
      (microsoftSelect && bingSpend3MonthsAgo > 0 ? bingSpend3MonthsAgo : 0)
    : 0;
  const spend30DaysAgo = account30DaysAgo
    ? (account30DaysAgo.mobile && googleSelected
        ? parseFloat(account30DaysAgo.mobile.spend)
        : 0) +
      (microsoftSelect && bingSpend30DaysAgo > 0 ? bingSpend30DaysAgo : 0)
    : 0;

  return (
    <LineGraph
      title='Tablet Budget Graph'
      data1={spendLastMonth}
      data2={spend2MonthsAgo}
      data3={spend3MonthsAgo}
      data4={spend30DaysAgo}
      backgroundColor={
        lightMode ? 'rgba(119, 128, 181, 0.3)' : 'rgba(5, 15, 77, 0.1)'
      }
      borderColor='rgb(6, 33, 207)'
      pointBorderColor='rgb(6, 33, 207)'
    />
  );
};

export default BudgetTabletLineGraph;
