import React from 'react';
import { useRecoilValue, useRecoilState } from 'recoil';
import { secondPlatformChartState } from '../../recoil/atoms';
import { getAccountState } from '../../recoil/selectors/dataSelectors';
import DoughnutGraph from '../graph-templates/DoughnutGraph';

type Props = {
  isLabel?: boolean;
  isPrint?: boolean;
};

const PlatformSecondDoughnutChart = ({ isLabel, isPrint }: Props) => {
  const { gAccount, mAccount, lAccount, adAccount } =
    useRecoilValue(getAccountState);
  const [platformChart, setPlatformChart] = useRecoilState(
    secondPlatformChartState
  );

  const gData = gAccount
    ? platformChart === 'Budget'
      ? gAccount?.spend
      : platformChart === 'Leads'
      ? gAccount?.conversions
      : platformChart === 'Impressions'
      ? gAccount?.impressions
      : gAccount?.clicks
    : '';
  const mData = mAccount
    ? platformChart === 'Budget'
      ? mAccount?.spend
      : platformChart === 'Leads'
      ? mAccount?.conversions
      : platformChart === 'Impressions'
      ? mAccount?.impressions
      : mAccount?.clicks
    : '';
  const lsaData = lAccount
    ? platformChart === 'Budget'
      ? lAccount?.spend
      : platformChart === 'Leads'
      ? lAccount?.conversions
      : ''
    : '';
  const adData =
    platformChart === 'Budget'
      ? adAccount?.spend
      : platformChart === 'Leads'
      ? adAccount?.conversions
      : '';

  return (
    <DoughnutGraph
      label1='Microsoft'
      label2='Google'
      label3='LSA'
      label4='Manually Added'
      data1={mData}
      data2={gData}
      data3={lsaData}
      data4={adData}
      title={`${platformChart} Per Platform`}
      setTitle={setPlatformChart}
      color1='#2B580C'
      color2='#FF7600'
      color3='#3D087B'
      color4='#125B50'
      hovercolor1='#B3E283'
      hovercolor2='#FFA900'
      hovercolor3='#BFA2DB'
      hovercolor4='#BDF2D5'
      isLabel={isLabel}
      isPrint={isPrint}
    />
  );
};

export default PlatformSecondDoughnutChart;
