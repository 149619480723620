import React from 'react';
import PlatformFirstDoughnutChart from '../graphs/PlatformFirstDoughnutChart';
import PlatformSecondDoughnutChart from '../graphs/PlatformSecondDoughnutChart';

const ReportsGraph = () => {
  return (
    <div>
      <div className='animate__animated animate__backInRight sm:flex sm:justify-evenly'>
        <PlatformFirstDoughnutChart isLabel />
        <PlatformSecondDoughnutChart isLabel />
      </div>
    </div>
  );
};

export default ReportsGraph;
