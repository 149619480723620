import React, { useState } from 'react';
import FilterSelect from '../filter-comps/FilterSelect';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import {
  accountManagersFilterState,
  accManagerOptionState,
  idxPlatformsFilterState,
  idxFilterOptionsState,
  ppcSpecialistsFilterState,
  ppcFilterOptionState,
  statesFilterState,
  statesOptionState,
  statusFilterState,
  statusOptionState,
  budgetFilterState,
  budgetComparatorState,
  costComparatorState,
  costFilterState,
  remainingBudgetComparatorState,
  remainingBudgetFilterState,
  paceComparatorState,
  paceFilterState,
  cplComparatorState,
  cplFilterState,
  convComparatorState,
  convFilterState,
  showFilterModalState,
  dataCoverageState,
  notesFilterState,
  notesOptionState,
  lightModeState,
  platformFilterState,
  platformOptionState,
  adsPaidByFilterState,
  adsPaidByOptionState,
  ppcFilterIDState,
  firebaseUserState,
} from '../../recoil/atoms';
import FilterBtns from '../filter-comps/FilterBtns';
import FilterSelectNumber from '../filter-comps/FilterSelectNumber';
import type { SetterOrUpdater } from 'recoil';
import { CheckboxOptions } from '../../helpers/types';
import axios from 'axios';
import { headerState } from '../../recoil/selectors/dataSelectors';
import swal from 'sweetalert';

const FilterModal = () => {
  const environment = process.env.NODE_ENV;
  const dataCoverage = useRecoilValue(dataCoverageState);
  const header = useRecoilValue(headerState);
  const user = useRecoilValue(firebaseUserState);
  const [showFilterModal, setShowFilterModal] =
    useRecoilState(showFilterModalState);
  const ppcFilterID = useRecoilValue(ppcFilterIDState);
  const [idxPlatformsFilter, setIDXPlatformsFilter] = useRecoilState(
    idxPlatformsFilterState
  );
  const [ppcSpecialistsFilter, setPPCSpecialistsFilter] = useRecoilState(
    ppcSpecialistsFilterState
  );
  const [accountManagersFilter, setAccountManagersFilter] = useRecoilState(
    accountManagersFilterState
  );
  const [stateFilter, setStatesFilter] = useRecoilState(statesFilterState);
  const [statusFilter, setStatusFilter] = useRecoilState(statusFilterState);
  const [notesFilter, setNotesFilter] = useRecoilState(notesFilterState);
  const [adsPaidByFilter, setAdsPaidByFilter] =
    useRecoilState(adsPaidByFilterState);
  const setPlatformFilter = useSetRecoilState(platformFilterState);
  const [budgetComparator, setBudgetComparator] = useRecoilState(
    budgetComparatorState
  );
  const [costComparator, setCostComparator] =
    useRecoilState(costComparatorState);
  const [remainingBudgetComparator, setRemainingBudgetComparator] =
    useRecoilState(remainingBudgetComparatorState);
  const [paceComparator, setPaceComparator] =
    useRecoilState(paceComparatorState);
  const [cplComparator, setCPLComparator] = useRecoilState(cplComparatorState);
  const [convComparator, setConvComparator] =
    useRecoilState(convComparatorState);
  const [idxFilterOptions, setIDXOptionsFilter] = useRecoilState(
    idxFilterOptionsState
  );
  const [ppcFilterOptions, setPPCFilterOptions] =
    useRecoilState(ppcFilterOptionState);
  const [accManagerOptions, setAccManagerOptions] = useRecoilState(
    accManagerOptionState
  );
  const [budgetFilter, setBudgetFilter] = useRecoilState(budgetFilterState);
  const [costFilter, setCostFilter] = useRecoilState(costFilterState);
  const [remainingBudgetFilter, setRemainingBudgetFilter] = useRecoilState(
    remainingBudgetFilterState
  );
  const [paceFilter, setPaceFilter] = useRecoilState(paceFilterState);
  const [cplFilter, setCPLFilter] = useRecoilState(cplFilterState);
  const [convFilter, setConvFilter] = useRecoilState(convFilterState);
  const [statesOptions, setStatesOptions] = useRecoilState(statesOptionState);
  const [statusOptions, setStatusOptions] = useRecoilState(statusOptionState);
  const [notesOptions, setNotesOptions] = useRecoilState(notesOptionState);
  const [adsPaidByOptions, setAdsPaidByOptions] =
    useRecoilState(adsPaidByOptionState);
  const [platformOptions, setPlatformOptions] =
    useRecoilState(platformOptionState);
  const [selectAllIDX, setSelectAllIDX] = useState(false);
  const [selectAllPPC, setSelectAllPPC] = useState(false);
  const [selectAllAM, setSelectAllAM] = useState(false);
  const [selectAllStates, setSelectStates] = useState(false);
  const [selectAllStatus, setSelectAllStatus] = useState(false);
  const [selectAllNotes, setSelectAllNotes] = useState(false);
  const [selectAllAdsPaidBy, setSelectAllAdsPaidBy] = useState(false);
  const [selectAllPlatforms, setSelectAllPlatforms] = useState(true);

  const adsPlatforms = ['Google', 'Microsoft', 'Manually Added'];
  const idxPlatforms = [
    'Brivity',
    'Chime',
    'EAP',
    'EAP-RETS',
    'KVCore',
    'RG',
    'SI',
    'StellarMLS',
    'Ylopo',
  ];
  const ppcSpecialists = [
    'Gwen',
    'Jose Ramirez',
    'Joshua Simpson',
    'Joyce Raypan',
    'Mich',
    'Red Quijano',
    'Mike More',
  ];
  const accountManagers = [
    'Allyson Hunt',
    'Mark Lorentz',
    'Philip Pasma',
    'Valeria Angel',
    'Vanessa Iles',
    'Amanda Cattle',
  ];
  const states = [
    'AK, US',
    'AL, US',
    'AR, US',
    'AS, US',
    'AZ, US',
    'CA, US',
    'CO, US',
    'CT, US',
    'DE, US',
    'FL, US',
    'GA, US',
    'GU, US',
    'HI, US',
    'IA, US',
    'ID, US',
    'IL, US',
    'IN, US',
    'KS, US',
    'KY, US',
    'LA, US',
    'MA, US',
    'MD, US',
    'ME, US',
    'MH, US',
    'MI, US',
    'MN, US',
    'MO, US',
    'MP, US',
    'MS, US',
    'MT, US',
    'MX',
    'NC, US',
    'ND, US',
    'NE, US',
    'NH, US',
    'NJ, US',
    'NM, US',
    'NV, US',
    'NY, US',
    'OH, US',
    'OK, US',
    'OR, US',
    'PA, US',
    'PR, US',
    'RI, US',
    'SC, US',
    'SD, US',
    'TN, US',
    'TX, US',
    'UT, US',
    'VA, US',
    'VI, US',
    'VT, US',
    'WA, US',
    'WI, US',
    'WV, US',
    'WY, US',
    'NL, CA',
    'PE, CA',
    'NS, CA',
    'NB, CA',
    'QC, CA',
    'ON, CA',
    'MB, CA',
    'SK, CA',
    'AB, CA',
    'BC, CA',
    'YT, CA',
    'NT, CA',
    'NU, CA',
  ];
  const status = [true, false];
  const notes = ['With Note', 'Without Note'];
  const adsPaidBy = ['Asterisk', 'Client'];
  const lightMode = useRecoilValue(lightModeState);

  const reqData = {
    id: user?.uid,
    idxPlatforms: idxPlatformsFilter,
    ppcSpecialists: ppcSpecialistsFilter,
    accountManagers: accountManagersFilter,
    states: stateFilter,
    accountStatus: statusFilter,
    accountNotes: notesFilter,
    adsPaidBy: adsPaidByFilter,
    budget: budgetFilter,
    budgetOp: budgetComparator,
    cost: costFilter,
    costOp: costComparator,
    remainingBudget: remainingBudgetFilter,
    remainingBudgetOp: remainingBudgetComparator,
    pace: paceFilter,
    paceOp: paceComparator,
    cpl: cplFilter,
    cplOp: cplComparator,
    conversions: convFilter,
    conversionsOp: convComparator,
  };

  const clearValues = (setFilter: SetterOrUpdater<CheckboxOptions>) => {
    setFilter((fitler) =>
      Object.fromEntries(Object.keys(fitler).map((key) => [key, false]))
    );
  };

  const onClear = () => {
    setTimeout(() => {
      setShowFilterModal(false);
    }, 0.0000000000001);
    setIDXPlatformsFilter([]);
    setPPCSpecialistsFilter([]);
    setAccountManagersFilter([]);
    setStatesFilter([]);
    setStatusFilter([]);
    setNotesFilter([]);
    setPlatformFilter([]);

    clearValues(setIDXOptionsFilter);
    clearValues(setPPCFilterOptions);
    clearValues(setAccManagerOptions);
    clearValues(setStatesOptions);
    clearValues(setStatusOptions);
    clearValues(setNotesOptions);

    setBudgetFilter('');
    setCostFilter('');
    setRemainingBudgetFilter('');
    setPaceFilter('');
    setCPLFilter('');
    setConvFilter('');

    setBudgetComparator('=');
    setCostComparator('=');
    setRemainingBudgetComparator('=');
    setPaceComparator('=');
    setCPLComparator('=');
    setConvComparator('=');
  };

  const onSave = () => {
    setShowFilterModal(false);
    if (!ppcFilterID) {
      axios({
        method: 'post',
        url: `${
          environment === 'production'
            ? 'https://apimakeforth.herokuapp.com'
            : ''
        }/api/bms_filter/`,
        headers: header,
        data: reqData,
      })
        .then(() =>
          swal(
            'Filter Saved',
            `Your filter has successfully been saved`,
            'success',
            {
              timer: 1000,
              buttons: [false],
            }
          )
        )
        .catch((err) => swal('Unable To Save Filter', err.message, 'error'));
    } else {
      axios({
        method: 'put',
        url: `${
          environment === 'production'
            ? 'https://apimakeforth.herokuapp.com'
            : ''
        }/api/bms_filter/${ppcFilterID}/`,
        headers: header,
        data: reqData,
      })
        .then(() =>
          swal(
            'Filter Saved',
            `Your filter has successfully been saved`,
            'success',
            {
              timer: 1000,
              buttons: [false],
            }
          )
        )
        .catch((err) => swal('Unable To Save Filter', err.message, 'error'));
    }
  };

  return (
    <div
      className={`${!showFilterModal && 'hidden'} ${
        lightMode ? 'bg-gray-50' : 'bg-gray-800'
      } z-10 w-1/2 max-h-2/5 sm:max-h-2/3 overflow-y-scroll overflow-x-hidden absolute rounded shadow sm:left-0 z-40 overflow-y-overlay`}
    >
      <div
        className={`pt-3 sticky top-0 w-full z-50 ${
          lightMode ? 'bg-gray-50' : 'bg-gray-800'
        } `}
      ></div>
      <section className='w-full'>
        <div className='mb-1 gap-y-0'>
          <FilterSelect
            label='Ads Platforms'
            options={adsPlatforms}
            setFilter={setPlatformFilter}
            input={platformOptions}
            setInput={setPlatformOptions}
            selectAll={selectAllPlatforms}
            setSelectAll={setSelectAllPlatforms}
          />
          <FilterSelect
            label='IDX Platforms'
            options={idxPlatforms}
            setFilter={setIDXPlatformsFilter}
            input={idxFilterOptions}
            setInput={setIDXOptionsFilter}
            selectAll={selectAllIDX}
            setSelectAll={setSelectAllIDX}
          />
          <FilterSelect
            label='PPC Specialists'
            options={ppcSpecialists}
            setFilter={setPPCSpecialistsFilter}
            input={ppcFilterOptions}
            setInput={setPPCFilterOptions}
            selectAll={selectAllPPC}
            setSelectAll={setSelectAllPPC}
          />
          <FilterSelect
            label='Account Managers'
            options={accountManagers}
            setFilter={setAccountManagersFilter}
            input={accManagerOptions}
            setInput={setAccManagerOptions}
            selectAll={selectAllAM}
            setSelectAll={setSelectAllAM}
          />
          <FilterSelect
            label='States'
            options={states}
            setFilter={setStatesFilter}
            input={statesOptions}
            setInput={setStatesOptions}
            selectAll={selectAllStates}
            setSelectAll={setSelectStates}
          />
          {dataCoverage === 'This Mo' && (
            <FilterSelect
              label='Account Status'
              options={status}
              setFilter={setStatusFilter}
              input={statusOptions}
              setInput={setStatusOptions}
              selectAll={selectAllStatus}
              setSelectAll={setSelectAllStatus}
            />
          )}
          {dataCoverage === 'This Mo' && (
            <FilterSelect
              label='Account Notes'
              options={notes}
              setFilter={setNotesFilter}
              input={notesOptions}
              setInput={setNotesOptions}
              selectAll={selectAllNotes}
              setSelectAll={setSelectAllNotes}
            />
          )}
          {dataCoverage === 'This Mo' && (
            <FilterSelect
              label='Ads Paid By'
              options={adsPaidBy}
              setFilter={setAdsPaidByFilter}
              input={adsPaidByOptions}
              setInput={setAdsPaidByOptions}
              selectAll={selectAllAdsPaidBy}
              setSelectAll={setSelectAllAdsPaidBy}
            />
          )}
          <div className='grid grid-cols-1 sm:grid-cols-2 pb-4 mx-4'>
            {dataCoverage === 'This Mo' && (
              <FilterSelectNumber
                label='Budget'
                comparator={budgetComparator}
                setComparator={setBudgetComparator}
                filter={budgetFilter}
                setFilter={setBudgetFilter}
              />
            )}
            <FilterSelectNumber
              label='Cost'
              comparator={costComparator}
              setComparator={setCostComparator}
              filter={costFilter}
              setFilter={setCostFilter}
            />
            {dataCoverage === 'This Mo' && (
              <FilterSelectNumber
                label='Remaining Budget'
                comparator={remainingBudgetComparator}
                setComparator={setRemainingBudgetComparator}
                filter={remainingBudgetFilter}
                setFilter={setRemainingBudgetFilter}
              />
            )}
            {dataCoverage === 'This Mo' && (
              <FilterSelectNumber
                label='Pace'
                comparator={paceComparator}
                setComparator={setPaceComparator}
                filter={paceFilter}
                setFilter={setPaceFilter}
              />
            )}
            <FilterSelectNumber
              label='CPL'
              comparator={cplComparator}
              setComparator={setCPLComparator}
              filter={cplFilter}
              setFilter={setCPLFilter}
            />
            <FilterSelectNumber
              label='Conversions'
              comparator={convComparator}
              setComparator={setConvComparator}
              filter={convFilter}
              setFilter={setConvFilter}
            />
          </div>
        </div>
        <FilterBtns
          onClose={() => {
            setShowFilterModal(false);
          }}
          onClear={onClear}
          onSave={onSave}
        />
      </section>
    </div>
  );
};

export default FilterModal;
