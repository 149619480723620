import React from 'react';
import { useRecoilValue } from 'recoil';
import { getAccountState } from '../../recoil/selectors/dataSelectors';
import DoughnutGraph from '../graph-templates/DoughnutGraph';

type Props = { isLabel?: boolean; isPrint?: boolean };

const LeadsDoughnutChart = ({ isLabel, isPrint }: Props) => {
  const { gAccount, mAccount, lAccount, adAccount } =
    useRecoilValue(getAccountState);

  const gconversions = gAccount ? gAccount.conversions : '';
  const mconversions = mAccount ? mAccount.conversions : '';
  const lsaconversions = lAccount ? lAccount.conversions : '';
  const adConversions = adAccount?.conversions;

  return (
    <DoughnutGraph
      label1='Microsoft'
      label2='Google'
      label3='LSA'
      label4='Manually Added'
      data1={mconversions}
      data2={gconversions}
      data3={lsaconversions}
      data4={adConversions}
      title='Leads Per Platform'
      color1='#001E6C'
      color2='#EC0101'
      color3='#FFB319'
      color4='#F14A16'
      hovercolor1='#035397'
      hovercolor2='#FF6767'
      hovercolor3='#FFE194'
      hovercolor4='#FFC069'
      isLabel={isLabel}
      isPrint={isPrint}
    />
  );
};

export default LeadsDoughnutChart;
