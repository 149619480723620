import {
  monNameState,
  reportBoxOneState,
  reportBoxTwoState,
  reportBoxThreeState,
  userState,
  accountState,
  googleReportsSelectedState,
  microsoftReportsSelectedState,
  platformChartsPrintState,
  deviceChartsPrintState,
  coverageGraphPrintState,
  remarketingPrintState,
} from '../../recoil/atoms';
import { useRecoilValue } from 'recoil';
import { getCoverageOption, month } from '../../helpers/dateUtils';
import PrintBox from '../info-boxes/PrintBox';
import PrintBoxTemplate from '../info-boxes-templates/PrintBoxTemplate';
import PlatformFirstDoughnutChart from '../graphs/PlatformFirstDoughnutChart';
import PlatformSecondDoughnutChart from '../graphs/PlatformSecondDoughnutChart';
import CoverageFirstLineGraph from '../graphs/CoverageFirstLineGraph';
import CoverageSecondLineGraph from '../graphs/CoverageSecondLineGraph';
import LeadsDeviceDoughnutChart from '../graphs/LeadsDeviceDoughnutChart';
import BudgetDeviceDoughnutChart from '../graphs/BudgetDeviceDoughnutChart';
import LeadsDeviceBingDoughnutChart from '../graphs/LeadsDeviceBingDoughnutChart';
import BudgetDeviceBingDoughnutChart from '../graphs/BudgetDeviceBingDoughnutChart';
import { adminAccountState } from '../../recoil/selectors/dataSelectors';
import Logo from '../../images/logo (1).png';
import PrintKW from '../info-boxes/PrintKW';
import PrintUL from '../info-boxes/PrintUL';

type Props = {
  page?: number;
  isShow?: boolean;
};

const Paper = ({ page, isShow }: Props) => {
  const user = useRecoilValue(userState);
  const account = useRecoilValue(
    user?.role === 'superadmin' ? accountState : adminAccountState
  );
  const monName = useRecoilValue(monNameState);
  const googleSelected = useRecoilValue(googleReportsSelectedState);
  const microsoftSelected = useRecoilValue(microsoftReportsSelectedState);
  const reportsBoxOne = useRecoilValue(reportBoxOneState);
  const reportsBoxTwo = useRecoilValue(reportBoxTwoState);
  const reportsBoxThree = useRecoilValue(reportBoxThreeState);
  const platformCharts = useRecoilValue(platformChartsPrintState);
  const deviceCharts = useRecoilValue(deviceChartsPrintState);
  const coverageGraph = useRecoilValue(coverageGraphPrintState);
  const remarketing = useRecoilValue(remarketingPrintState);

  const accountCPL = account
    ? account.remarketing_campaign &&
      account.bing_remarketing_campaign &&
      googleSelected &&
      microsoftSelected
      ? (parseFloat(account.remarketing_campaign.spend) +
          parseFloat(account.bing_remarketing_campaign.spend)) /
        (parseFloat(account.remarketing_campaign.conversions) +
          parseFloat(account.bing_remarketing_campaign.conversions) >
        0
          ? parseFloat(account.remarketing_campaign.conversions) +
            parseFloat(account.bing_remarketing_campaign.conversions)
          : 1)
      : account.remarketing_campaign && googleSelected
      ? parseFloat(account.remarketing_campaign.cost_per_conversion)
      : account.bing_remarketing_campaign && microsoftSelected
      ? parseFloat(account.bing_remarketing_campaign.cost_per_conversion)
      : 0
    : 0;
  const accountSpend = account
    ? account.remarketing_campaign &&
      account.bing_remarketing_campaign &&
      googleSelected &&
      microsoftSelected
      ? parseFloat(account.remarketing_campaign.spend) +
        parseFloat(account.bing_remarketing_campaign.spend)
      : account.remarketing_campaign && googleSelected
      ? parseFloat(account.remarketing_campaign.spend)
      : account.bing_remarketing_campaign && microsoftSelected
      ? parseFloat(account.bing_remarketing_campaign.spend)
      : 0
    : 0;
  const accountConversions = account
    ? account.remarketing_campaign &&
      account.bing_remarketing_campaign &&
      googleSelected &&
      microsoftSelected
      ? parseFloat(account.remarketing_campaign.conversions) +
        parseFloat(account.bing_remarketing_campaign.conversions)
      : account.remarketing_campaign && googleSelected
      ? parseFloat(account.remarketing_campaign.conversions)
      : account.bing_remarketing_campaign && microsoftSelected
      ? parseFloat(account.bing_remarketing_campaign.conversions)
      : 0
    : 0;

  return (
    <>
      <h1 className='font-semibold text-2xl gap-x-1 flex items-center justify-between'>
        {' '}
        <img src={Logo} className='w-1/4 h-1/4' alt='Asterisk Logo' />
        PPC Reports: {getCoverageOption(month.indexOf(monName))}
      </h1>
      <div className='mx-auto'>
        {((page === 1 && isShow) || !isShow) && (
          <div>
            <div className='px-4 py-2 border-t border-l border-b border-r border-gray-800 w-full text-center mt-10 mx-auto'>
              <p className='font-semibold text-2xl my-auto'>{account?.name}</p>
            </div>
            <div className='grid grid-cols-3 items-center gap-x-2 h-full mt-10'>
              <PrintBox reportBox={reportsBoxOne} />
              <PrintBox reportBox={reportsBoxTwo} />
              <PrintBox reportBox={reportsBoxThree} />
            </div>
            <div className='flex justify-center'>
              {platformCharts['First Chart'] && (
                <PlatformFirstDoughnutChart isLabel isPrint />
              )}
              {platformCharts['Second Chart'] && (
                <PlatformSecondDoughnutChart isLabel isPrint />
              )}
            </div>
            <div className='flex justify-center'>
              <div className='flex-grow w-full'>
                {coverageGraph['First Graph'] && (
                  <CoverageFirstLineGraph isPrint />
                )}
              </div>
              <div className='flex-grow w-full'>
                {coverageGraph['Second Graph'] && (
                  <CoverageSecondLineGraph isPrint />
                )}
              </div>
            </div>
            <div className='flex justify-evenly gap-x-20 mt-20'>
              <PrintKW />
              <PrintUL />
            </div>
          </div>
        )}
        {(remarketing['Remarketing'] ||
          deviceCharts['Leads Per Device (Google)'] ||
          deviceCharts['Budget Per Device (Google)'] ||
          deviceCharts['Leads Per Device (Microsoft)'] ||
          deviceCharts['Budget Per Device (Microsoft)']) &&
          ((page === 2 && isShow) || !isShow) && (
            <div className='page-break'>
              {remarketing['Remarketing'] && (
                <div className='border-l border-t border-b border-r border-gray-900 px-3 py-3 mt-10 rounded'>
                  <p className='text-xl font-semibold text-center'>
                    Remarketing
                  </p>
                  <div className='grid grid-cols-3 items-center gap-x-2 h-full mt-3 px-2'>
                    <PrintBoxTemplate
                      data={'$' + accountCPL.toFixed(2)}
                      label='Cost Per Lead'
                    />
                    <PrintBoxTemplate
                      data={'$' + accountSpend.toFixed(2)}
                      label='Costs'
                    />
                    <PrintBoxTemplate
                      data={accountConversions.toFixed(2)}
                      label='Conversions'
                    />
                  </div>
                </div>
              )}
              <div className='flex justify-center'>
                {deviceCharts['Leads Per Device (Google)'] && (
                  <LeadsDeviceDoughnutChart isPrint isLabel />
                )}
                {deviceCharts['Budget Per Device (Google)'] && (
                  <BudgetDeviceDoughnutChart isPrint isLabel />
                )}
              </div>
              <div className='flex justify-center'>
                {deviceCharts['Leads Per Device (Microsoft)'] && (
                  <LeadsDeviceBingDoughnutChart isPrint isLabel />
                )}
                {deviceCharts['Budget Per Device (Microsoft)'] && (
                  <BudgetDeviceBingDoughnutChart isPrint isLabel />
                )}
              </div>
            </div>
          )}
      </div>
    </>
  );
};

export default Paper;
