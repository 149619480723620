import React from 'react';
import { useRecoilValue } from 'recoil';
import { getAccountState } from '../../recoil/selectors/dataSelectors';
import DoughnutGraph from '../graph-templates/DoughnutGraph';

type Props = {
  isLabel?: boolean;
  isPrint?: boolean;
};

const BudgetDoughnutChart = ({ isLabel, isPrint }: Props) => {
  const { gAccount, mAccount, lAccount, adAccount } =
    useRecoilValue(getAccountState);

  const gspend = gAccount ? gAccount.spend : '';
  const mspend = mAccount ? mAccount.spend : '';
  const lsaspend = lAccount ? lAccount.spend : '';
  const adSpend = adAccount?.spend;

  return (
    <DoughnutGraph
      label1='Microsoft'
      label2='Google'
      label3='LSA'
      label4='Manually Added'
      data1={mspend}
      data2={gspend}
      data3={lsaspend}
      data4={adSpend}
      title='Budget Per Platform'
      color1='#2B580C'
      color2='#FF7600'
      color3='#3D087B'
      color4='#125B50'
      hovercolor1='#B3E283'
      hovercolor2='#FFA900'
      hovercolor3='#BFA2DB'
      hovercolor4='#BDF2D5'
      isLabel={isLabel}
      isPrint={isPrint}
    />
  );
};

export default BudgetDoughnutChart;
