import { atom } from 'recoil';
import { recoilPersist } from 'recoil-persist';
import { FirebaseUser } from '../../firebase/firebase';
import { User } from '../../helpers/types';
const { persistAtom } = recoilPersist();

export const isAuthenticatedState = atom<boolean>({
  key: 'isAuthenticatedState',
  default: false,
  effects_UNSTABLE: [persistAtom],
});

export const userState = atom<User | null>({
  key: 'userState',
  default: null,
  effects_UNSTABLE: [persistAtom],
});

export const jwtState = atom<string>({
  key: 'jwtState',
  default: '',
  effects_UNSTABLE: [persistAtom],
});

export const firebaseUserState = atom<FirebaseUser | null>({
  key: 'firebaseUserState',
  default: null,
  effects_UNSTABLE: [persistAtom],
  dangerouslyAllowMutability: true,
});
