import LineGraph from '../graph-templates/LineGraph';
import {
  lightModeState,
  accountState,
  accountsState,
  accounts2MonthsAgoState,
  accounts3MonthsAgoState,
  accounts30DaysAgoState,
  userState,
  googleReportsSelectedState,
  microsoftReportsSelectedState,
} from '../../recoil/atoms';
import { adminAccountState } from '../../recoil/selectors/dataSelectors';
import { useRecoilValue } from 'recoil';
import { Account } from '../../helpers/types';

const BudgetDesktopLineGraph = () => {
  const lightMode = useRecoilValue(lightModeState);
  const user = useRecoilValue(userState);
  const account = useRecoilValue(
    user?.role === 'superadmin' ? accountState : adminAccountState
  );
  const accountsLastMonth = useRecoilValue(accountsState);
  const accounts2MonthsAgo = useRecoilValue(accounts2MonthsAgoState);
  const accounts3MonthsAgo = useRecoilValue(accounts3MonthsAgoState);
  const accounts30DaysAgo = useRecoilValue(accounts30DaysAgoState);
  const googleSelected = useRecoilValue(googleReportsSelectedState);
  const microsoftSelect = useRecoilValue(microsoftReportsSelectedState);

  const filterAccount = (accs: Account[]) =>
    accs.filter((acc) => acc.name === account.name);

  const accountLastMonth = filterAccount(accountsLastMonth)[0];
  const account2MonthsAgo = filterAccount(accounts2MonthsAgo)[0];
  const account3MonthsAgo = filterAccount(accounts3MonthsAgo)[0];
  const account30DaysAgo = filterAccount(accounts30DaysAgo)[0];

  const spendLastMonth = accountLastMonth
    ? (accountLastMonth.desktop && googleSelected
        ? parseFloat(accountLastMonth.desktop.spend)
        : 0) +
      (accountLastMonth.bing_desktop && microsoftSelect
        ? parseFloat(accountLastMonth.bing_desktop.spend)
        : 0)
    : 0;
  const spend2MonthsAgo = account2MonthsAgo
    ? (account2MonthsAgo.desktop && googleSelected
        ? parseFloat(account2MonthsAgo.desktop.spend)
        : 0) +
      (account2MonthsAgo.bing_desktop && microsoftSelect
        ? parseFloat(account2MonthsAgo.bing_desktop.spend)
        : 0)
    : 0;
  const spend3MonthsAgo = account3MonthsAgo
    ? (account3MonthsAgo.desktop && googleSelected
        ? parseFloat(account3MonthsAgo.desktop.spend)
        : 0) +
      (account3MonthsAgo.bing_desktop && microsoftSelect
        ? parseFloat(account3MonthsAgo.bing_desktop.spend)
        : 0)
    : 0;
  const spend30DaysAgo = account30DaysAgo
    ? (account30DaysAgo.desktop && googleSelected
        ? parseFloat(account30DaysAgo.desktop.spend)
        : 0) +
      (account30DaysAgo.bing_desktop && microsoftSelect
        ? parseFloat(account30DaysAgo.bing_desktop.spend)
        : 0)
    : 0;

  return (
    <LineGraph
      title='Desktop Budget Graph'
      data1={spendLastMonth}
      data2={spend2MonthsAgo}
      data3={spend3MonthsAgo}
      data4={spend30DaysAgo}
      backgroundColor={
        lightMode ? 'rgba(252, 144, 214, 0.3)' : 'rgba(66, 1, 43, 0.1)'
      }
      borderColor='rgb(232, 0, 151)'
      pointBorderColor='rgb(232, 0, 151)'
    />
  );
};

export default BudgetDesktopLineGraph;
