import React from 'react';
import { lightModeState } from '../../recoil/atoms';
import { useRecoilValue } from 'recoil';
import type { SetterOrUpdater } from 'recoil';
import { isNumeric } from '../../helpers/utils';

type Props = {
  label: string;
  data: string | JSX.Element | null;
  options: string[];
  setLabel: SetterOrUpdater<string>;
};

const OverviewInfoBox = ({ label, data, options, setLabel }: Props) => {
  const lightMode = useRecoilValue(lightModeState);
  return (
    <section
      className={`${lightMode ? 'text-gray-900' : 'text-white'} body-font`}
    >
      <div
        className={`mt-0 sm:mt-3 rounded ${
          lightMode
            ? 'bg-gray-200 border-b border-t border-l border-r border-gray-300'
            : 'bg-gray-800 border-b border-t border-l border-r border-gray-700'
        } shadow-md py-10 min-h-full`}
      >
        <div className='dropdown dropdown-right h-full'>
          <span
            className={`inline-block py-1 px-2 ml-3 rounded transition duration-300 ${
              lightMode
                ? 'bg-gray-300 text-gray-900 hover:bg-gray-100'
                : 'bg-gray-700 text-white hover:bg-gray-600'
            } text-xs font-medium sm:tracking-wide cursor-pointer uppercase`}
            tabIndex={0}
          >
            {label}
          </span>
          <ul
            className={`dropdown-content menu w-44 border-b border-t border-l border-r  ${
              lightMode
                ? 'bg-gray-300 border-gray-300'
                : 'bg-gray-700 border-gray-500'
            } rounded text-xs font-semibold uppercase `}
            tabIndex={0}
          >
            {options.map((option) => (
              <li
                key={option}
                className={`py-2 px-3 cursor-pointer ${
                  lightMode ? 'hover:bg-gray-100' : 'hover:bg-gray-600'
                } ${
                  label.includes(option) &&
                  (lightMode ? 'bg-gray-100' : 'bg-gray-600')
                }`}
                onClick={() => setLabel(option)}
              >
                {option}
              </li>
            ))}
          </ul>
        </div>
        <p
          className={`${
            isNumeric(typeof data === 'string' ? data : '')
              ? 'font-semibold text-lg'
              : 'text-base'
          } text-center mt-3 whitespace-pre-line`}
        >
          {data}
        </p>
      </div>
    </section>
  );
};

export default OverviewInfoBox;
