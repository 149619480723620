import React, { useState } from 'react';
import { useSetRecoilState, useRecoilValue } from 'recoil';
import Logo from '../../../images/logo (2).png';
import {
  isAuthenticatedState,
  jwtState,
  userState,
  lightModeState,
  firebaseUserState,
} from '../../../recoil/atoms';
import { signIn, snapshot } from '../../../helpers/auth';
import swal from 'sweetalert';
const SignInComponent = () => {
  const setIsAuthenticated = useSetRecoilState(isAuthenticatedState);
  const setFirebaseUser = useSetRecoilState(firebaseUserState);

  const [showPassword, setShowPassword] = useState(false);
  const [loginCred, setLoginCred] = useState({
    email: '',
    password: '',
  });
  const setUser = useSetRecoilState(userState);
  const setJWT = useSetRecoilState(jwtState);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setLoginCred({ ...loginCred, [name]: value });
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    await signIn(loginCred.email, loginCred.password)
      .then(async (user) => {
        const userRef = user.user ? await snapshot(user.user) : undefined;
        const token = await user.user?.getIdToken();
        setFirebaseUser(user.user);
        if (token) setJWT(token);
        setUser({
          displayName: userRef?.displayName,
          email: userRef?.email,
          googleCID: userRef?.googleCID,
          microsoftCID: userRef?.microsoftCID,
          role: userRef?.role,
          isPPCManager: userRef?.isPPCManager,
        });
        setIsAuthenticated(true);
        swal('Login Success!', `Welcome ${userRef?.displayName}!`, 'success', {
          timer: 1000,
          buttons: [false],
        });
      })
      .catch((err) => {
        swal(
          "Wasn't able to login",
          err.message.includes('signIn')
            ? 'Wrong Email Or Password'
            : err.message,
          'error'
        );
        console.log(err);
        setLoginCred({
          email: '',
          password: '',
        });
      });
  };

  const lightMode = useRecoilValue(lightModeState);
  return (
    <div
      className={`w-screen sm:w-full flex flex-col ${
        lightMode
          ? 'bg-gradient-to-r from-gray-900 to-gray-800'
          : 'bg-gradient-to-r from-red-800 to-red-900'
      } h-full`}
    >
      <div className='my-auto md:pt-0'>
        <img src={Logo} className='mx-auto w-1/3 h-1/4 mt-5 ' alt='Logo' />
        <form className='mx-4 sm:mx-0 flex flex-col' onSubmit={handleSubmit}>
          <div className='flex flex-col pt-4'>
            <label htmlFor='email' className='text-lg text-white '>
              Email
            </label>

            <input
              type='email'
              id='email'
              placeholder='your@email.com'
              onChange={handleChange}
              name='email'
              value={loginCred.email}
              className={`shadow appearance-none rounded py-2.5 px-3 text-gray-700 mt-1 leading-tight focus:outline-none focus:shadow-outline w-full ${
                !lightMode && 'bg-gray-700 text-white'
              }`}
            />
          </div>

          <div className='flex flex-col pt-4'>
            <label htmlFor='password' className='text-lg text-white'>
              Password
            </label>
            <input
              type={showPassword ? 'text' : 'password'}
              id='password'
              placeholder='Password'
              onChange={handleChange}
              name='password'
              value={loginCred.password}
              className={`shadow appearance-none rounded py-2.5 px-3 text-gray-700 mt-1 leading-tight focus:outline-none focus:shadow-outline w-full ${
                !lightMode && 'bg-gray-700 text-white'
              }`}
            />
          </div>
          <label className='text-sm text-white mt-3 cursor-pointer'>
            <input
              type='checkbox'
              checked={showPassword}
              onChange={() => setShowPassword(!showPassword)}
              className='cursor-pointer rounded  border-l border-r border-t border-b border-gray-700'
            />
            <span className='ml-2.5 text-2xs sm:text-base text-gray-200'>
              Show Password
            </span>
          </label>

          <button
            type='submit'
            className={`${
              lightMode
                ? 'bg-black hover:bg-gray-700 '
                : 'bg-gray-900 hover:bg-black'
            }  text-white font-bold text-lg p-2 mt-9 rounded w-full`}
          >
            Log In
          </button>
        </form>
        <div className='text-center pt-12 pb-12'></div>
      </div>
    </div>
  );
};

export default SignInComponent;
