const date = new Date();
export const year = date.getFullYear();
export const currentMonth = date.getMonth();

// This helps you get the index of the month base off of the number of months you want to go back. The max is 3
export const getIndexOfMonth = (xMonthsAgo: number) => {
  let indexOfMonth = currentMonth - xMonthsAgo;
  if (indexOfMonth === -1) indexOfMonth = 11;
  if (indexOfMonth === -2) indexOfMonth = 10;
  if (indexOfMonth === -3) indexOfMonth = 9;
  return indexOfMonth;
};

export const month: (
  | '90-Day'
  | '30-Day'
  | '3-Day'
  | 'Yesterday'
  | 'This Mo'
  | 'All Time'
  | 'Jan'
  | 'Feb'
  | 'Mar'
  | 'Apr'
  | 'May'
  | 'Jun'
  | 'Jul'
  | 'Aug'
  | 'Sept'
  | 'Oct'
  | 'Nov'
  | 'Dec'
)[] = [];
month[0] = 'Jan';
month[1] = 'Feb';
month[2] = 'Mar';
month[3] = 'Apr';
month[4] = 'May';
month[5] = 'Jun';
month[6] = 'Jul';
month[7] = 'Aug';
month[8] = 'Sept';
month[9] = 'Oct';
month[10] = 'Nov';
month[11] = 'Dec';

// This returns a shortened name of the month with the current year or the year before depending on whether or not that month has passed this year
export const getCoverageOption = (mon: number) => {
  let yearToday = year;
  if (mon >= currentMonth) yearToday = yearToday - 1;
  return `${month[mon]} ${yearToday}`;
};
