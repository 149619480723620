import React, { useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import { lightModeState, dataCoverageState } from '../../recoil/atoms';
import type { SetterOrUpdater } from 'recoil';
import { CheckboxOptions } from '../../helpers/types';
import { month } from '../../helpers/dateUtils';

type Props = {
  options: string[] | boolean[];
  label: string;
  setFilter: SetterOrUpdater<(string | boolean)[]>;
  input: CheckboxOptions;
  setInput: SetterOrUpdater<CheckboxOptions>;
  selectAll: boolean;
  setSelectAll: SetterOrUpdater<boolean>;
};

const FilterSelect = ({
  options,
  label,
  setFilter,
  input,
  setInput,
  selectAll,
  setSelectAll,
}: Props) => {
  const lightMode = useRecoilValue(lightModeState);
  const dataCoverage = useRecoilValue(dataCoverageState);

  const handleChange = (item: string) => {
    setInput({ ...input, [item]: !input[item] });
  };

  const handleSetFilter = (item: string | boolean, customKey: string) => {
    if (!input[customKey]) {
      setFilter((filters: (string | boolean)[]) => [...filters, item]);
    } else {
      setFilter((filters: (string | boolean)[]) =>
        filters.filter((filterItem: string | boolean) => filterItem !== item)
      );
    }
  };

  const handleSetAllFilter = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSelectAll(!selectAll);
    if (e.target.checked) {
      setFilter(options);
      setInput(
        Object.fromEntries(Object.keys(input).map((key) => [key, true]))
      );
    } else {
      setFilter([]);
      setInput(
        Object.fromEntries(Object.keys(input).map((key) => [key, false]))
      );
    }
  };
  useEffect(() => {
    if (Object.values(input).every((value) => value)) {
      setSelectAll(true);
    } else {
      setSelectAll(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [input]);

  return (
    <div
      className={`mt-0 mb-0 border-b ${
        lightMode ? 'text-black border-gray-300' : 'text-white border-gray-600'
      } mx-4`}
    >
      <h1 className='font-semibold text-base sm:text-lg sm:px-4 pt-3 pb-2'>
        {label}
      </h1>
      <ul className='transition duration-200 ml-4 my-3 sm:px-6 grid grid-cols-1 sm:grid-cols-4 gap-x-7'>
        <li>
          <label className='cursor-pointer form-check-label'>
            <input
              type='checkbox'
              className={`form-check-input cursor-pointer rounded checkbox checkbox-xs ${
                lightMode
                  ? 'border-l border-r border-b border-t border-gray-400'
                  : 'border-l border-r border-b border-t border-gray-500'
              }`}
              onChange={handleSetAllFilter}
              checked={selectAll}
            />
            <span className=' ml-2 text-2xs sm:text-base font-semibold'>
              Select All
            </span>
          </label>
        </li>
        {options.map((item, index) => {
          const customKey =
            label === 'Account Status'
              ? item === true
                ? 'Active'
                : 'Paused'
              : typeof item === 'string'
              ? item
              : '';

          return (
            <li
              className={
                item === 'Manually Added' &&
                dataCoverage !== 'This Mo' &&
                !month.includes(dataCoverage)
                  ? 'hidden'
                  : 'inline'
              }
              key={index}
            >
              <label className='cursor-pointer form-check-label'>
                <input
                  type='checkbox'
                  className={`form-check-input cursor-pointer rounded checkbox checkbox-xs ${
                    lightMode
                      ? 'border-l border-r border-b border-t border-gray-400'
                      : 'border-l border-r border-b border-t border-gray-500'
                  }`}
                  checked={input[customKey]}
                  onChange={() => {
                    handleChange(customKey);
                    handleSetFilter(item, customKey);
                  }}
                />
                <span className=' ml-2 text-2xs sm:text-base font-semibold'>
                  {customKey}
                </span>
              </label>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default FilterSelect;
